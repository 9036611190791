<template>
    <div class="app-card intent rounded elevation-2 skeleton-loading">
        <div class="intent-header d-flex align-items-center">
            <div class="intent-type mr-2 bg-default-dark">
            </div>

            <h4 class="font-weight-bold font-small title m-0 flex-grow-1 bg-default-dark text-default-dark p-2 rounded"></h4>
        </div>

        <div class="mt-2 mb-2 d-flex flex-wrap bg-default-dark rounded p-4"></div>

        <hr/>

        <div class="d-flex justify-content-between">
            <div>
                <a
                    href="javascript:void(0)"
                    class="btn bg-default-dark btn-sm font-small mr-1">
                    <i class="mdi mdi-eye text-default-dark"></i>
                </a>

                <a
                    href="javascript:void(0)"
                    class="btn bg-default-dark btn-sm font-small mr-1">
                    <i class="mdi mdi-pencil text-default-dark"></i>
                </a>

                <a
                    href="javascript:void(0)"
                    class="btn bg-default-dark btn-sm font-small mr-1">
                    <i class="mdi mdi-pencil text-default-dark"></i>
                </a>
            </div>

            <div>
                <a href="javascript:void(0)"
                class="btn bg-default-dark btn-sm font-small mr-2">
                    <span class="text-default-dark">
                        View Ad
                    </span>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import Ad from './Ad';
import { mapActions } from 'vuex';
import SummaryAd from './SummaryAd';
import AdSkeleton from './Ad-Skeleton.vue';

export default {
    props: ['intent'],

    components: {
        Ad,
        SummaryAd,
        AdSkeleton,
    },

    data() {
        return {
            showAds: false,

            loading: false,
            selected: null
        }
    },

    computed: {
        intentType() {
            return this.intent ? this.intent.type : null;
        },

        values(){
            return this.intent ? this.intent.values : []
        },

        properties() {
            let properties = [
                { name: 'Type', value: this.intent.type.name },
                { name: 'Expiry', value: moment(this.intent.expiry_date).format(this.dateFormat) },
                { name: 'Status', value: this.intent.status.name }
            ];

            return properties;
        },

        intentAd() {
            return this.selected ? this.selected : this.ads[1];
        },

        ads() {
            return [
                {
                    id: 1,
                    image: 'puma.jpeg',
                    logo: `puma.png`,
                    name: 'Puma Anzarun FS',
                    text: `Refresh your rotation with these men's Anzarun FS trainers from Puma. In an navy colourway, these retro kicks are built with a sporty mesh upper for breathable support while a tonal lace up fastening locks you in. They're sat on a SoftFoam+ sockliner for a fresh fit with a chunky midsole for essential cushioning. Underfoot, these creps have a grippy rubber tread, and are finished with a woven heel pull, PUMA branding to the heel and the iconic Formstrip to the sidewalls in white.`
                },

                {
                    id: 2,
                    image: `nike.jpeg`,
                    logo: `nike.png`,
                    name: 'Nike Air Max Zephyr',
                    text: `Next level comf' meets cutting-edge innovation with these men's Air Max Zephyr sneakers from Nike. With its futuristic look and feel these creps come in a Phonton Dust, Black Volt and Hyper Pink colourway. The sleek, track inspired sneaks are built with durable no-sew skins and airy tactile mesh for a contemporary look while its plush padded collar liberates every step. Bringin' Nike's biggest innovation to the upper, zonal Air units to the sides bring cushioning and a bold new look. Sat on a 720 Air unit for unrivalled comfort, these kicks have a foam midsole, which is soft and springy and a plush, low-cut collar with added pillow pads. With a translucent outsole and a jewelled Air pocket to the tongue for a modern look, these futuristic kicks are finished with the iconic Swoosh logo.`
                },

                {
                    id: 3,
                    image: 'adidas.jpeg',
                    logo: `adidas.png`,
                    name: 'adidas Originals NMD_R1 Spectoo',
                    text: `Keep your runners lookin' real streamline with these men's NMD_R1 Spectoo trainers from adidas Originals. Combining the retro retro looks of the OG NMD and remixing them, these futuristic tech these sneakers come in a black colourway and have a sock-like, breathable knit upper with a subtle grid texture. They feature a tonal lace up fastening and a distinctive Boost midsole underfoot for ultra-light cushioning. They sit on EVA blue and red midsole plugs to complete the signature NMD look and are finished with a Scarlet Trefoil logo to the heel.`
                }
            ]
        }
    },

    methods: {
        ...mapActions({
            destroy: 'Intents/destroy'
        }),

        async deleteIntent() {
            let result = await this.swalConfirm('Are you sure?', `This will remove "${this.intent.title}" and its attributes.`, `Yes, Delete Intent`);

            if (!result.value) return;

            this.showLoader('Deleting intent... Please wait');

            this.destroy(this.intent.id)
                .then(response => {
                    this.hideLoader();
                })
                .catch(error => {
                    this.hideLoader();
                    this.swalError('Something unexpected happened', error.message);
                })
        },

        loadNext() {
            this.loading = true;

            setTimeout(() => {
                this.loading = false;
                this.intentAd = {}
            }, 3000);
        },

        loadPrev() {
            this.loading = true;

            setTimeout(() => {
                this.loading = false;
                this.intentAd = {}
            }, 3000);
        }
    },

    mounted() {
    }
}
</script>