<template>
    <aside class="mobile-sidebar" :class="{'open': sidebarOpened}" @click.prevent.stop="closeSidebar">
        <div class="content" @click.prevent.stop="">
            <customer v-if="isCustomerUser"/>
            <merchant v-if="isMerchantUser"/>

            <div class="actions">
                <a href="#">
                    <i class="mdi mdi-logout icon mr-1"></i> Logout
                </a>
                <a href="#">
                    <i class="mdi mdi-settings icon mr-1"></i> Settings
                </a>
            </div>
        </div>
    </aside>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Customer from './../desktop/header/Customer.vue';
import Merchant from './../desktop/header/Merchant.vue';

export default {
    components: {
        Customer,
        Merchant
    },

    computed:{
        ...mapGetters({
            sidebarOpened: 'getSidebarOpened'
        })
    },

    methods: {
        ...mapActions({
            closeSidebar: 'closeSidebar'
        })
    }
}
</script>