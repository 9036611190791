<template>
    <div class="">
        <div class="row" v-if="showIntents">
            <div class="col-md-4" v-for="(intent, index) in all" :key="index">
                <intent
                    class="mb-4"
                    :intent="intent"
                    @view="viewIntent"
                    @edit="editIntent"/>
            </div>
        </div>

        <empty title="No active channel found" v-if="!all.length && !loading"/>

        <div class="mt-3" v-if="loading">
            <intent-loader
                v-for="n in 10"
                :key="`loader-${n}`"
                class="mb-3"/>
        </div>

        <scroll-loader :loader-method="loadNextPage" :loader-disable="!showLoadMore"/>

        <filter-intents
            @cancel="showFilter = false;"
            :show="showFilter"
            v-model="params"
            @submit="performFiltering"/>
    </div>
</template>

<script>
import Intent from './partials/Intent';
import AddIntent from './partials/AddIntent';
import ViewIntent from './partials/ViewIntent';
import { mapActions, mapGetters } from 'vuex';
import FilterIntents from './partials/FilterIntents';
import IntentLoader from './partials/IntentLoader';
import PageTitle from '@/components/shared/PageTitle.vue';
import InnerTab from '../../../components/shared/InnerTab.vue';

export default {
    components: {
        Intent,
        AddIntent,
        ViewIntent,
        PageTitle,
        FilterIntents,
        IntentLoader,
        InnerTab
    },


    data() {
        return {
            showAddIntent: false,
            showViewIntent: false,
            intent: null,
            showFilter: false,

            keyword: '',

            params: {
                status: 'all'
            },

            showIntents: false,

            loading: false
        }
    },

    computed: {
        ...mapGetters({
            all: 'Intents/getAll',
            pageDetails: 'Intents/getPageDetails'
        })
    },

    methods:{
        ...mapActions({
            index: 'Intents/index',
            show: 'Intents/show'
        }),

        filterStatus(status) {
            this.params.status = status;
            this.loadData({clearState: true});
        },

        addEditIntent() {
            this.$router.push({name: 'customer_create_intent'});
        },

        performFiltering() {
            this.loadData({clearState: true});
        },

        async showIntent(id, params = {}) {
            this.showLoader('Getting intent data... Please wait');

            try {
                let intent = await this.show({id, params});
                this.hideLoader();
                return intent;
            } catch (error) {
                this.hideLoader();

                return null;
            }
        },

        async editIntent(intent) {
            if (intent) {
                intent = await this.showIntent(intent.id);
            }

            this.intent = intent;
            this.showAddIntent = !!intent;
        },

        async viewIntent(intent) {
            if (intent) {
                intent = await this.showIntent(intent.id, {with_attributes: 1});
            }

            this.intent = intent;
            this.showViewIntent = !!intent;
        },

        loadNextPage() {
            if (this.showLoadMore) {
                this.loadData(this.loadMore);
            }
        },

        loadData(query) {
            let params = {
                paginate: true,
                per_page: 20,
                ...query,
                ...this.params,
                keyword: this.keyword
            }

            this.loading = true;

            if (query.clearState) {
                this.showIntents = false;
            }

            this.index(params)
                .then(response => {
                    this.loading = false;
                    // this.hideLoader();
                    this.showIntents = true;
                })
                .catch(error => {
                    this.loading = false;
                    // this.hideLoader();
                    this.showIntents = true;
                })
        }
    },

    created() {
        this.loadData({clearState: true});
    }
}
</script>