<template>
    <div class="ad-poll bg-default rounded p-3">
        <p class="font-small m-0 font-weight-bold">
            {{ poll.question }}
        </p>

        <div class="options mt-3">
            <a href="#" class="option font-small text-capitalize" v-for="(option, index) in options" :key="index">
                <i class="mdi mdi-radiobox-blank icon"></i>
                {{ option }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: ['poll'],

    computed: {
        options() {
            return this.poll ? this.poll.options : []
        }
    }
}
</script>