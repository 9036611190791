<template>
    <content-wrapper>
        <settings v-model="activeTab"/>

        <div class="row" v-if="active == 'ed_pr'">
            <div class="col-md-12">
                <edit-profile @cancel="activeTab = null"/>
            </div>
        </div>

        <div class="row" v-if="active == 'ch_pa'">
            <div class="col-md-6">
                <change-password @cancel="activeTab = null"/>
            </div>
        </div>

        <div class="row" v-if="active == 'ref'">
            <div class="col-md-8">
                <referrals @cancel="activeTab = null"/>
            </div>
        </div>

        <div class="row" v-if="active == 'pr_se'">
            <div class="col-md-6">
                <privacy @cancel="activeTab = null"/>
            </div>
        </div>

        <div class="row" v-if="active == '2fa'">
            <div class="col-md-12">
                <two-factor-authentication @cancel="activeTab = null"/>
            </div>
        </div>

        <div class="row" v-if="active == 'tutorials'">
            <div class="col-12">
                <tutorials @cancel="activeTab = null"/>
            </div>
        </div>

        <div class="row justify-content-center" v-if="active == 'about'">
            <div class="col-12">
                <about @cancel="activeTab = null"/>
            </div>
        </div>

        <div class="row" v-if="active == 'features'">
            <div class="col-12 col-md-8">
                <features @cancel="activeTab = null"/>
            </div>
        </div>

        <div class="row" v-if="active == 'support'">
            <div class="col-12">
                <support @cancel="activeTab = null"/>
            </div>
        </div>
    </content-wrapper>
</template>
<script>
import About from './partials/About';
import Privacy from './partials/Privacy';
import Features from './partials/Features';
import Referrals from './partials/Referrals';
import Settings from './partials/Settings.vue';
import Support from './partials/support/Index.vue';
import ChangePassword from './partials/ChangePassword';
import TwoFactorAuthentication from './partials/2FA.vue';
import EditProfile from '@/views/customer/profile/Index.vue';

export default {
    components: {
        About,
        Support,
        Privacy,
        Features,
        Settings,
        Referrals,
        EditProfile,
        ChangePassword,
        TwoFactorAuthentication
    },

    data() {
        return {
            activeTab: null
        }
    },

    computed: {
        active() {
            if (this.activeTab) {
                return this.activeTab.id;
            }

            return null
        }
    }
}
</script>