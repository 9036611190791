<template>
    <content-wrapper>
        <div class="row no-gutters font-small">
            <div class="col-md-6 p-1">
                <app-card>
                    <div class="d-flex justify-content-between mb-3">
                        <h5 class="font-big">Intent Types</h5>
                        <!-- <div>
                            <button type="button" class="btn btn-secondary font-small btn-sm ml-1">
                                <i class="mdi mdi-plus"></i> Add Intent Type
                            </button>
                        </div> -->
                    </div>

                    <table class="table table-striped">
                        <tbody>
                            <tr v-for="(type, index) in types" :key="index">
                                <td>
                                    <div class="d-flex justify-content-between align-items-center mb-3">
                                        <label class="font-weight-bold">{{ type.name }}</label>
                                        <div>
                                            <button type="button" class="btn btn-success btn-sm font-small">
                                                <i class="mdi mdi-settings"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <p class="font-small text-muted">{{ type.description }}</p>

                                    <div class="text-justify">
                                        <span class="badge bg-soft-primary p-2 m-1 text-uppercase" v-for="(attr, i) in type.attributes" :key="i">
                                            {{ attr.name }} <span class="text-danger font-big" v-if="attr.pivot.is_required">*</span>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </app-card>
            </div>

            <div class="col-md-6 p-1">
                <app-card>
                    <div class="d-flex justify-content-between mb-3">
                        <h5 class="font-big">Intent Attributes</h5>
                        <div>
                            <button type="button" @click.prevent="showAddAttribute = true" class="btn btn-secondary font-small btn-sm ml-1">
                                <i class="mdi mdi-plus"></i> Add Attribute
                            </button>
                        </div>
                    </div>
                    <table class="table table-striped">
                        <tbody>
                            <tr v-for="(attribute, index) in attributes" :key="index">
                                <td>
                                    <div class="d-flex justify-content-between align-items-center mb-3">
                                        <label class="font-weight-bold">{{ attribute.name }} ({{ attribute.system_name }})</label>
                                        <div>
                                            <button @click.prevent="editAttr(attribute)" type="button" class="btn btn-warning btn-sm font-small">
                                                <i class="mdi mdi-pencil"></i>
                                            </button>
                                            <button type="button" class="btn btn-danger btn-sm ml-1 font-small">
                                                <i class="fa fa-trash-o"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <p class="font-small text-muted">{{ attribute.description }}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <pagination v-if="pageDetails.lastPage > 1" :pageDetails="pageDetails" @navigate="loadAttributes"/>
                </app-card>
            </div>
        </div>

        <add-intent-attribute
            :show="showAddAttribute"
            @cancel="showAddAttribute = false"
            :attribute="selectedAttribute"/>
    </content-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AddIntentAttribute from './partials/AddIntentAttribute';

export default {
    components: {
        AddIntentAttribute
    },

    data() {
        return {
            showAddAttribute: false,

            selectedAttribute: null
        }
    },

    computed: {
        ...mapGetters({
            pageDetails: 'IntentAttributes/getPageDetails',
            attributes: 'IntentAttributes/getAll',
            types: 'IntentTypes/getAll'
        })
    },

    methods: {
        ...mapActions({
            getAttributes: 'IntentAttributes/index',
            getIntentTypes: 'IntentTypes/index'
        }),

        editAttr(attribute) {
            this.selectedAttribute = attribute;
            this.showAddAttribute = !!attribute;
        },

        loadTypes() {
            this.getIntentTypes()
                .then(() => null)
                .catch(error => null);
        },

        loadAttributes(query) {
            let params = {
                ...query,
                paginate: true,
                per_page: 50,
            };

            this.showLoader('Loading attributes... Please wait');

            this.getAttributes(params)
                .then(response => {
                    this.hideLoader();
                })
                .catch(error => {
                    this.hideLoader();
                })
        }
    },

    created() {
        this.loadTypes();
        this.loadAttributes();
    }
}
</script>