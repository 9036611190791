<template>
    <div class="pt-5">
        <heading title="EMAIL VERIFICATION" description="We are setting up your account.."/>


        <div class="row justify-content-center mt-5">
            <div class="col-lg-4 col-md-6 col-12 pr-4 pl-4" v-if="response">
                <app-card class="text-center font-small p-3 pt-4 pb-4">
                    <h4 class="text-uppercase" :class="{'text-danger': failed, 'text-success': !failed}">
                        {{ response.message }}
                    </h4>
                    <hr/>
                    <p class="text-muted">
                        {{ response.text }}
                    </p>
                </app-card>

                <button v-if="failed" type="button" @click="resendLink" class="btn btn-warning font-small pb-3 pt-3 btn-block mt-4 elevation-2">
                    RESEND VERIFICATION LINK
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import Heading from '../authentication/partials/GuestHeading';

export default {
    props: ['token'],

    components: {
        Heading
    },

    data() {
        return {
            response: null,
            failed: false
        }
    },

    computed: {
    },

    methods: {
        ...mapActions({
            verifyEmailToken: 'Authentications/verifyEmailToken',
        }),

        verify() {
            this.showLoader('Verifying Email... Please wait');

            this.verifyEmailToken({
                token: this.token
            })
            .then(response => {
                this.hideLoader();

                if (response.message) {
                    this.swalSuccess(response.message);
                }

                this.$router.replace({name: `settings`, query: {p: '2fa', skipable: 1}})
            })
            .catch(error => {
                this.hideLoader();
                this.swalError(error.message, error.text || ``, false);

                this.$router.replace({name: 'login'})
            });
        }
    },

    created() {
        this.verify();
    }
}
</script>