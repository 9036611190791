<template>
<modal
    :show="show"
    :centered="true"
    :showFooter="true"
    :maxHeight="true"
    :showInfo="true"
    infoText="Reset"
    okText="Filter"
    @info="reset"
    @ok="submit"
    @cancel="cancel"
    infoIcon="mdi mdi-sync">

    <h5 class="m-0 font-small font-weight-normal" slot="header">
        Filter Purchasing Intents
    </h5>

    <div slot="body" class="p-3">
        <div class="row no-gutters">
            <div class="col-6">
                <div class="form-group type-3">
                    <label class="font-xs">Expiry Start Date</label>
                    <datetime
                        v-model="params.start_date"
                        input-class="form-control font-small"
                        placeholder="Expiry Start Date"
                        title="Expiry Start Date"></datetime>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group type-3">
                    <label class="font-xs">Expiry End Date</label>
                    <datetime
                        v-model="params.end_date"
                        input-class="form-control font-small"
                        placeholder="Expiry End Date"
                        title="Expiry End Date"></datetime>
                </div>
            </div>
        </div>

        <div>
            <base-select label="Status" v-model="params.status">
                <option value="">Explore or Ready</option>
                <option value="explore">Explore</option>
                <option value="ready">Ready</option>
            </base-select>
        </div>

        <div class="row no-gutters">
            <div class="col-6">
                <base-select label="Sort By" v-model="params.sort_column">
                    <option value="expiry_date">Expiry Date</option>
                    <option value="title">Title</option>
                </base-select>
            </div>
            <div class="col-6">
                <base-select label="Sort Order" v-model="params.sort_order">
                    <option value="asc">Ascending</option>
                    <option value="desc">Descending</option>
                </base-select>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
export default {
    props: ['show'],

    data() {
        return {
            params: {
                start_date: '',
                end_date: '',
                status: '',
                sort_column: 'expiry_date',
                sort_order: 'asc'
            }
        }
    },

    watch: {
        params: {
            deep: true,
            immediate: true,
            handler() {
                this.save()
            }
        }
    },

    methods: {
        cancel() {
            this.$emit('cancel');
        },

        save() {
            this.$emit('input', this.params)
        },

        submit() {
            this.$emit('submit', this.params);
            this.cancel();
        },

        reset() {
            this.params = {
                ...this.params,
                start_date: '',
                end_date: '',
                status: '',
                sort_column: 'expiry_date',
                sort_order: 'asc'
            }

            this.save();

            this.submit();
        }
    },

    created() {
        this.save()
    }
}
</script>