<template>
    <div class="p-3 pt-4">
        <div class="row justify-content-between">
            <div class="col-md-8 mb-3">
                <button
                    @click.prevent="chartType = 'bar'"
                    class="status-btn mb-1 font-xs cadet-blue"
                    :class="{'active': chartType == 'bar'}"
                    type="button">
                    <i class="mdi mdi-chart-bar"></i> Bar
                </button>

                <button
                    @click.prevent="chartType = 'pie'"
                    :class="{'active': chartType == 'pie'}"
                    class="status-btn mb-1 font-xs cadet-blue"
                    type="button">
                    <i class="mdi mdi-chart-pie"></i> Pie
                </button>

                <button
                    @click.prevent="chartType = 'doughnut'"
                    class="status-btn mb-1 font-xs cadet-blue"
                    :class="{'active': chartType == 'doughnut'}"
                    type="button">
                    <i class="mdi mdi-chart-arc"></i> Doughnut
                </button>
            </div>
            <div class="col-md-4">
                <base-select label="Version" v-model="version_id">
                    <option v-for="(item, index) of pollVersions" :key="index" :value="item.id">
                        <template v-if="item.id === campaign.poll.id">
                            {{ item.version_name }} (Current)
                        </template>
                       <template v-else>
                            {{ item.version_name }} ({{ item.created_at | formattedDateTime }})
                       </template>
                    </option>
                </base-select>
            </div>
        </div>

        <div>
            <canvas :id="chartId" style="max-height:500px;"></canvas>
        </div>

    </div>
</template>
<script>
import {shuffle} from 'lodash';
import Chart from '@/chart.js';
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['campaign'],

    data() {
        return {
            loading: false,
            report: null,
            chartType: 'bar',
            version_id: null,
            colors: []
        }
    },

    computed: {
        ...mapGetters({
            reports: 'Reports/getPollReports',
            stateColors: 'getColors'
        }),

        chartId() {
            return `cps-${Math.random().toString(16).split('.').pop()}`;
        },

        pollVersions() {
            return this.campaign?.polls
        }
    },

    watch: {
        report: {
            deep: true,
            immediate: true,
            handler() {
                if (!this.report) return;

                this.drawChart();
            }
        },

        chartType() {
            this.drawChart();
        },

        version_id() {
            this.getReport();
        }
    },

    methods: {
        ...mapActions({
            getPollReports: 'Reports/getPollReports'
        }),

        drawChart() {
            // console.log(this.campaign.poll)
            this.$nextTick().then(() => {
                const config = {
                    tension: 0.5,
                    fill: true,
                    borderWidth: 2
                };

                if (this.chart) {
                    this.chart.destroy();
                }

                this.chart = new Chart($(`#${this.chartId}`), {
                    type: this.chartType,
                    data: {
                        labels: this.report.labels,

                        datasets: [
                            {
                                label: 'Results',
                                data: this.report.datasets,
                                borderColor: 'transparent',
                                backgroundColor: this.stateColors,
                                ...config
                            }
                        ]
                    },

                    options: {
                        plugins: {
                            title: {
                                display: true,
                                text: this.report.title
                            },
                            legend: {
                                display: false
                            }
                        },
                    }
                });
            })
        },

        getReport() {
            this.report = this.reports.find(item => item.campaign_id == this.campaign.id);

            if (!this.report) {
                this.loading = true;
            }

            this.getPollReports({
                campaign_id: this.campaign.id,
                version_id: this.version_id
            })
            .then(response => {
                this.report = response;
                // console.log(response);
                this.loading = false;
            })
            .catch(error => {
                this.loading = false;
            })
        }
    },

    mounted() {
        // this.colors = shuffle(this.stateColors);
        this.version_id = this.campaign?.poll?.id;

        this.getReport();
    }
}
</script>