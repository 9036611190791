<template>
    <div class="app-card intent info rounded elevation-2">
        <div class="intent-header d-flex align-items-center">
            <div class="intent-type elevation-2 mr-2" v-if="intentType">
                <img src="/img/product.png" v-if="intentType.name == 'Product'"/>
                <img src="/img/service.png" v-if="intentType.name == 'Service'"/>
            </div>

            <h4 class="font-weight-bold font-small title m-0 flex-grow-1">
                {{ intent.title }}
            </h4>

            <!-- <div class="btn-group dropleft">
                <a href="#" class="text-white" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="mdi mdi-dots-vertical"></i>
                </a>
                <div class="dropdown-menu">
                    <a class="dropdown-item" @click="me" href="#">Action</a>
                    <a class="dropdown-item" href="#">Another action</a>
                    <a class="dropdown-item" href="#">Something else here</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">Separated link</a>
                </div>
            </div> -->
        </div>

        <div class="mt-2 mb-2 d-flex flex-wrap">
            <span class="badge bg-intent-info-soft border border-info elevation text-dark p-1 font-small m-1 text-left pl-2 pr-2" v-for="(property, index) in properties" :key="index">
                <small class="font-weight-bold">{{ property.name }}</small>
                <hr class="m-0 mt-1 mb-1"/>
                <small class="">{{ property.value }}</small>
            </span>
        </div>

        <hr/>

        <div class="d-flex justify-content-between">
            <div>
                <a href="#" title="View Intent" @click.prevent="$emit('view', intent)" class="btn btn-success btn-sm font-small elevation-2 mr-1">
                    <i class="mdi mdi-eye"></i>
                </a>

                <a href="#" title="Edit Intent" @click.prevent="$emit('edit', intent)" class="btn btn-warning btn-sm font-small elevation-2 mr-1">
                    <i class="mdi mdi-pencil"></i>
                </a>
                <a href="#" title="Delete Intent" @click.prevent="deleteIntent" class="btn btn-danger btn-sm font-small elevation-2">
                    <i class="mdi mdi-delete"></i>
                </a>
            </div>

            <div>
                <a href="#" @click.prevent="showAds = !showAds" class="btn btn-dark btn-sm font-small elevation-2 mr-2">
                    {{ showAds ? 'Hide' : 'View' }} Ads <span class="badge badge-light ml-1">14</span>
                </a>
            </div>
        </div>




        <!-- <transition name="router"> -->
            <div v-if="showAds" class="mt-2">
                <hr/>
                <div class="bg-intent-info-soft rounded p-1 mt-3">
                    <div class="intent-ads d-flex flex-column justify-content-center rounded m-0">
                        <ad-skeleton v-if="loading"/>

                        <div class="" v-else>
                            <ad v-if="intentAd" :ad="intentAd"/>
                            <p class="font-small text-center" v-else>No ad available</p>
                        </div>
                    </div>

                    <div class="intent-controls mt-1 bg-intent-info-soft rounded">

                        <div class="ad-set text-justify d-flex">
                            <summary-ad
                                v-for="(ad, index) in ads"
                                :key="index"
                                :ad="ad"
                                @view="myad => selected = myad"
                                :class="{'active': intentAd.id == ad.id}"/>
                        </div>

                        <div class="controls prev" @click.prevent="loadPrev">
                            <a href="#" class="">
                                <i class="mdi mdi-arrow-left-bold-circle-outline"></i>
                            </a>
                        </div>

                        <div class="controls next">
                            <a href="#" class="" @click.prevent="loadNext">
                                <i class="mdi mdi-arrow-right-bold-circle-outline"></i>
                            </a>
                        </div>

                        <div class="circles bg-default">
                            <span class=""></span>
                            <span class="active"></span>
                            <span class=""></span>
                        </div>
                    </div>
                </div>
            </div>
        <!-- </transition> -->
    </div>
</template>
<script>
import Ad from './Ad';
import { mapActions } from 'vuex';
import SummaryAd from './SummaryAd';
import AdSkeleton from './Ad-Skeleton.vue';

export default {
    props: ['intent'],

    components: {
        Ad,
        SummaryAd,
        AdSkeleton,
    },

    data() {
        return {
            showAds: false,

            loading: false,
            selected: null
        }
    },

    computed: {
        intentType() {
            return this.intent ? this.intent.type : null;
        },

        values(){
            return this.intent ? this.intent.values : []
        },

        properties() {
            let properties = [
                { name: 'Type', value: this.intent.type.name },
                { name: 'Expiry', value: moment(this.intent.expiry_date).format(this.dateFormat) },
                { name: 'Status', value: this.intent.status.name }
            ];

            return properties;
        },

        intentAd() {
            return this.selected ? this.selected : this.ads[1];
        },

        ads() {
            return [
                {
                    id: 1,
                    image: 'puma.jpeg',
                    logo: `puma.png`,
                    name: 'Puma Anzarun FS',
                    text: `Refresh your rotation with these men's Anzarun FS trainers from Puma. In an navy colourway, these retro kicks are built with a sporty mesh upper for breathable support while a tonal lace up fastening locks you in. They're sat on a SoftFoam+ sockliner for a fresh fit with a chunky midsole for essential cushioning. Underfoot, these creps have a grippy rubber tread, and are finished with a woven heel pull, PUMA branding to the heel and the iconic Formstrip to the sidewalls in white.`
                },

                {
                    id: 2,
                    image: `nike.jpeg`,
                    logo: `nike.png`,
                    name: 'Nike Air Max Zephyr',
                    text: `Next level comf' meets cutting-edge innovation with these men's Air Max Zephyr sneakers from Nike. With its futuristic look and feel these creps come in a Phonton Dust, Black Volt and Hyper Pink colourway. The sleek, track inspired sneaks are built with durable no-sew skins and airy tactile mesh for a contemporary look while its plush padded collar liberates every step. Bringin' Nike's biggest innovation to the upper, zonal Air units to the sides bring cushioning and a bold new look. Sat on a 720 Air unit for unrivalled comfort, these kicks have a foam midsole, which is soft and springy and a plush, low-cut collar with added pillow pads. With a translucent outsole and a jewelled Air pocket to the tongue for a modern look, these futuristic kicks are finished with the iconic Swoosh logo.`
                },

                {
                    id: 3,
                    image: 'adidas.jpeg',
                    logo: `adidas.png`,
                    name: 'adidas Originals NMD_R1 Spectoo',
                    text: `Keep your runners lookin' real streamline with these men's NMD_R1 Spectoo trainers from adidas Originals. Combining the retro retro looks of the OG NMD and remixing them, these futuristic tech these sneakers come in a black colourway and have a sock-like, breathable knit upper with a subtle grid texture. They feature a tonal lace up fastening and a distinctive Boost midsole underfoot for ultra-light cushioning. They sit on EVA blue and red midsole plugs to complete the signature NMD look and are finished with a Scarlet Trefoil logo to the heel.`
                }
            ]
        }
    },

    methods: {
        ...mapActions({
            destroy: 'Intents/destroy'
        }),

        async deleteIntent() {
            let result = await this.swalConfirm('Are you sure?', `This will remove "${this.intent.title}" and its attributes.`, `Yes, Delete Intent`);

            if (!result.value) return;

            this.showLoader('Deleting intent... Please wait');

            this.destroy(this.intent.id)
                .then(response => {
                    this.hideLoader();
                })
                .catch(error => {
                    this.hideLoader();
                    this.swalError('Something unexpected happened', error.message);
                })
        },

        loadNext() {
            this.loading = true;

            setTimeout(() => {
                this.loading = false;
                this.intentAd = {}
            }, 3000);
        },

        loadPrev() {
            this.loading = true;

            setTimeout(() => {
                this.loading = false;
                this.intentAd = {}
            }, 3000);
        }
    },

    mounted() {
    }
}
</script>