<template>
    <div class="hold-list p-1">
        <div class="mb-5 bg-white p-3 elevation" v-for="(group, index) in holdListGroups" :key="index">
            <page-title :title="group.intent.product_types.map(pt => pt.name).join(', ')"
                :desc="
                    group.intent.number_of_shares ?
                `Shared with ${group.intent.number_of_shares} friends`
                    :
                `Created ${$options.filters.fromNow(group.intent.created_at)}`
                "
                className="m-0">
                <button
                    class="btn btn-primary font-small elevation-2 pt-2 pb-2 ml-2"
                    @click="shareIntent= group.intent"
                    v-if="type !== 'friends'">
                    <i class="mdi mdi-share"></i> Share
                </button>

                <div class="d-flex flex-column align-items-center" v-else>
                    <img :src="group.intent.created_by.image_url" class="avatar-md circle object-fit-cover" style="border:5px solid #eee;"/>
                    <span class="font-weight-bold text-primary">
                        @{{ group.intent.created_by.username }}
                    </span>
                </div>
            </page-title>

            <div class="mb-3">
                <span v-for="item in group.items" :key="item.advert.id" class="bg-soft-secondary p-1 pr-2 pl-2 font-xs rounded m-1">
                    {{ item.advert.name }}
                </span>
            </div>

            <div class="row">
                <div class="col-md-6 mb-3" v-for="item in getItems(group.items, group.intent.id)" :key="item.advert.id">
                    <hold-list-item
                        :type="type"
                        :item="item"
                        :intent="group.intent"
                        :advert_id="advert_id"
                        @view="viewAdvert"
                        @viewStatus="selectedUpdate = item.advert"
                        @addComment="addComment"/>
                </div>
            </div>

            <hr/>

            <div class="text-left">
                <button
                    class="btn font-xs mr-2"
                    @click="currentlyShownThread === group.intent.id ? currentlyShownThread = null : currentlyShownThread = group.intent.id"
                    :class="{
                        'btn-default-dark': currentlyShownThread === group.intent.id,
                        'btn-warning': currentlyShownThread !== group.intent.id
                    }">
                    {{ currentlyShownThread === group.intent.id ? 'Hide Ads' : 'Show More Ads' }}
                </button>
                <button class="btn btn-secondary font-xs" @click="viewAllComments(group)">View All Comments</button>
            </div>
        </div>

        <transition-group name="swipe" class="row">
        </transition-group>

        <empty title="Your hold list is empty" v-if="!all.length"/>

        <comments
            :show="showComments"
            :data="commentData"
            @cancel="showComments = false; advert_name = null;"/>

        <share
            :show="!!shareIntent"
            :intent="shareIntent"
            @cancel="shareIntent = null"/>

        <transition name="router">
            <view-status
                v-if="selectedUpdate"
                :advert="selectedUpdate"
                @cancel="selectedUpdate = null;"
                @navigate="selectedUpdate = null"/>
        </transition>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Comments from './partials/Comments.vue';
import Share from './partials/Share.vue';
import HoldListItem from './partials/HoldListItem';
import ViewStatus from '../interests/partials/ViewStatus.vue';

export default {
    props: ['advert_id', 'type'],

    components: {
        Comments,
        Share,
        ViewStatus,
        HoldListItem
    },

    data() {
        return {
            selectedUpdate: null,

            showComments: false,
            commentData: null,

            shareIntent: null,
            currentlyShownThread: null
        }
    },

    computed: {
        ...mapGetters({
            all: 'Statuses/getHoldList'
        }),

        holdListGroups() {
            let groups = {};

            for (let item of this.all) {
                if (!groups.hasOwnProperty(item.intent_id)) {
                    groups[item.intent_id] = {
                        intent: item.intent,
                        items: []
                    };
                }

                groups[item.intent_id].items.push(item);
            }

            return Object.values(groups);
        },

        items() {

        }
    },

    methods:  {
        ...mapActions({
            index: 'Statuses/getHoldList',
            clearHoldList: 'Statuses/clearHoldList'
        }),

        getItems(items, intent_id) {
            if (this.currentlyShownThread != intent_id) {
                items = items.slice(0, 2);
            }

            return items;
        },

        viewAllComments(group) {
            this.commentData = {intent: group.intent};
            this.showComments = true;
        },

        addComment(data) {
            this.commentData = data;
            this.showComments = !!data;
        },

        viewAdvert(advert) {
            if (this.advert_id) return;

            this.$router.push({name: 'customer_view_hold_list_item', params: {advert_id: advert.id}});
        },

        viewAdvert(data) {
            return this.$router.push({
                name: 'customer_view_store_front',
                params: { posting_id: data.advert.id },
                query: { plug: data.intent.id }
            });
        },

        loadLists(query) {
            let params = {
                ...query,
                campaign_id: this.campaign_id,
                type: this.type
            }

            if (this.campaign_id) {
                this.clearHoldList(this.campaign_id);
            }

            this.index(params)
                .then(response => {
                    // console.log(this.holdListGroups)
                    // console.log(this.all);
                })
                .catch(error => {
                    console.log(error);
                })
        }
    },

    created() {
        this.loadLists();

        // console.log(this.advert_id)
    }
}
</script>