<template>
<div>
    <div class="row no-gutters mb-3">
        <div class="col-md-4 col-6 p-1" v-for="(item, index) in items" :key="index">
            <div class="bg-white elevation p-3 rounded">
                <p class="text-muted">{{ item.label }}</p>
                <div class="d-flex align-items-center">
                    <h3>{{ item.value | numberFormat }}</h3>
                    <span class="text-success font-weight-bold ml-1">{{ item.subtitle }}</span>
                </div>
            </div>
        </div>

        <!-- <div class="col-md-4 col-6 p-1">
            <div class="bg-white elevation p-3 rounded">
                <p class="text-muted">Views</p>
                <div class="d-flex align-items-center">
                    <h3>{{ campaign.views | numberFormat }}</h3>
                    <span class="text-success font-weight-bold ml-1">+{{ campaign.todays_engagements.views }} today</span>
                </div>
            </div>
        </div>

        <div class="col-md-4 col-6 p-1">
            <div class="bg-white elevation p-3 rounded">
                <p class="text-muted">Clicks</p>
                <div class="d-flex align-items-center">
                    <h3>{{ campaign.clicks | numberFormat }}</h3>
                    <span class="text-success font-weight-bold ml-1">+{{ campaign.todays_engagements.clicks }} today</span>
                </div>
            </div>
        </div>

        <div class="col-md-4 col-6 p-1">
            <div class="bg-white elevation p-3 rounded">
                <p class="text-muted">Likes</p>
                <div class="d-flex align-items-center">
                    <h3>{{ campaign.no_of_likes | numberFormat }}</h3>
                    <span class="text-success font-weight-bold ml-1">+{{ campaign.todays_engagements.likes }} today</span>
                </div>
            </div>
        </div>

        <div class="col-md-4 col-6 p-1">
            <div class="bg-white elevation p-3 rounded">
                <p class="text-muted">Dislikes</p>
                <div class="d-flex align-items-center">
                    <h3>{{ campaign.no_of_dislikes | numberFormat }}</h3>
                    <span class="text-success font-weight-bold ml-1">+{{ campaign.todays_engagements.dislikes }} today</span>
                </div>
            </div>
        </div>

        <div class="col-md-4 col-6 p-1">
            <div class="bg-white elevation p-3 rounded">
                <p class="text-muted">Average View Time</p>
                <div class="d-flex align-items-center">
                    <h3>{{ campaign.view_duration | numberFormat }}s</h3>
                </div>
            </div>
        </div> -->
    </div>
</div>
</template>
<script>
export default {
    props: ['items']
}
</script>