<template>
    <div>
        <h3 class="text-center mb-4" id="account"></h3>

        <social-media class="mb-4 mt-3" title="Register with social media?"/>

        <div class="row no-gutters">
            <div class="col-md-6 col-12 pr-md-1">
                <base-input
                    label="Username"
                    required
                    typeNumber="3"
                    class="filter elevation"
                    icon="mdi mdi-account-circle"
                    v-model="payload.username"
                    :error="validationErrors.username"
                    placeholder=".e.g jdoe134"/>
            </div>

            <div class="col-md-6 col-12 pl-md-1">
                <base-input
                    label="Email"
                    type="email"
                    required
                    typeNumber="3"
                    class="filter elevation"
                    icon="mdi mdi-account-circle"
                    v-model="payload.email"
                    :error="validationErrors.email"
                    placeholder=".e.g you@example.com"
                />
            </div>
        </div>


        <div class="row no-gutters">
            <div class="col-md-6 col-12 pr-md-1">
                <base-select
                    v-model="payload.country_id"
                    :error="validationErrors.country_id"
                    label="Country"
                    required
                    typeNumber="3"
                    class="filter elevation">
                    <option value="">None</option>
                    <option v-for="(country, index) in countries" :key="index" :value="country.id">
                        {{ country.name }}
                    </option>
                </base-select>
            </div>
            <div class="col-md-6 col-12 pl-md-1">
                <base-input
                    label="Phone"
                    type="number"
                    required
                    typeNumber="3"
                    class="filter elevation"
                    icon="mdi mdi-account-circle"
                    v-model="payload.phone"
                    :error="validationErrors.phone"
                    placeholder=".e.g 23435243159"/>
            </div>
        </div>

        <prev-next
            :currentStep="currentStep"
            :isLastStep="isLastStep"
            @prev="prev"
            @next="next"
            nextText="SECURE ACCOUNT"/>
    </div>
</template>

<script>
import TypeIt from "typeit";
import { mapActions, mapGetters } from 'vuex';
import SocialMedia from './../SocialMedia';
import PrevNext from '../PrevNext';

export default {
    props: ['currentStep', 'isLastStep', 'data'],

    components: {
        PrevNext,
        SocialMedia
    },

    data() {
        return {
            payload: {
                username: '',
                email: '',
                country_id: '',
                phone: '',
            },

            validationErrors: {
                username: '',
                email: '',
                country_id: '',
                phone: '',
            },
        }
    },

    computed: {
        ...mapGetters({
            countries: 'Countries/getAll'
        }),
    },

    methods: {
        ...mapActions({
            validate: 'Authentications/validateRegistrationDetails'
        }),

        typeHeading() {
            new TypeIt("#account", {
                speed: 50,
                startDelay: 100
            })
            .empty()
            .type(`Let's create your account...`, {delay: 500})
            .go()
        },

        submit() {
            this.buildValidationErrors({});

            this.showLoader('Checking your details... Please wait');

            this.validate({type: 'account', ...this.payload})
                .then(response => {
                    this.hideLoader();

                    this.$emit('submit', this.payload);
                })
                .catch(error => {
                    this.hideLoader();
                    this.swalError(`Attention Required`, `${error.message} Please fix all issues before proceeding.`)
                    this.buildValidationErrors(error.errors);
                })
        },

        next() {
            // this.$emit('next');
            this.submit();
        },

        prev() {
            this.$emit('prev')
        },

        prepopulate() {
            if (this.data) {
                for (let key of Object.keys(this.payload)) {
                    if (this.data.hasOwnProperty(key)) {
                        this.payload[key] = this.data[key];
                    }
                }
            }
        }
    },

    mounted() {
        this.typeHeading();

        this.prepopulate();
    }
}
</script>