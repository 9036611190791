<template>
    <div class="card elevation-2 p-1 m-0 mb-2">
        <div
            @click.prevent.stop="collapse = !collapse"
            class="text-tertiary font-small bg-default-dark font-weight-bold p-2 m-0 cursor-pointer">
            {{ title }} <br/>
            <small class="text-primary font-9">
                (Click to {{ collapse ? 'expand' : 'collapse' }})
            </small>

            <span class="float-right">
                <i class="mdi mdi-menu-down-outline" v-if="collapse"></i>
                <i class="mdi mdi-menu-up-outline" v-else></i>
            </span>
        </div>

        <transition name="router">
            <slot v-if="!collapse"/>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        close: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            collapse: true
        }
    },

    watch: {
        close() {
            this.collapse = this.close;
        }
    },

    mounted() {
        this.collapse = this.close;
    }
}
</script>