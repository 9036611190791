<template>
    <div class="d-flex">
        <div class="bg-secondary p-2 pr-3 pl-3 text-white elevation rounded">
            <i class="mdi mdi-calendar font-big"></i>
        </div>

        <div class="p-2 bg-white elevation font-small flex-grow-1 ml-2 mr-2 font-weight-bold text-secondary text-center rounded">
            {{ (new Date()).toDateString() }}
        </div>

        <div class="bg-secondary font-small p-2 pr-3 pl-3 text-white elevation rounded">
            {{ time }}
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            time: '',
            timeout: null
        }
    },

    methods: {
        setTime() {
            let [hour, min,] = (new Date).toTimeString().split(':');

            this.time = `${hour}:${min}`;

            this.timeout = setTimeout(() => {
                this.setTime();
            }, 1000);
        }
    },

    mounted() {
        this.setTime()
    },

    beforeDestroy() {
        clearTimeout(this.timeout)
    }
}
</script>