<template>
    <div class="base-sidebar d-flex flex-column" :class="{'opened': sidebarOpened}">
        <div class="account">
            <div class="image">
                <img src="/img/user.jpg"/>
            </div>

            <div class="pl-2 pr-2 mt-2 text-center">
                <p class="name font-small m-0 font-weight-bold">
                    Monalisa Jerody Cherbotsky
                </p>
                <p class="email font-10 m-0">kofidahmed@gmail.com</p>
            </div>
        </div>

        <admin v-if="isAdminUser"/>

        <merchant v-if="isMerchantUser"/>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Admin from './Admin';
import Merchant from './Merchant'

export default {
    components: {
        Admin,
        Merchant
    },

    computed: {
        ...mapGetters({
            sidebarOpened: 'getSidebarOpened'
        })
    },

    methods: {
        ...mapActions({
            authLogout: 'Authentications/logout'
        }),

        logout() {
            this.showLoader('logging out... Please wait');

            this.authLogout()
                .then(response => {
                    this.hideLoader();
                })
                .catch(error => {
                    this.hideLoader();
                });
        }
    }
}
</script>