<template>
    <div class="notes p-2">
        <div class="text-right mb-3" v-if="showAddNote">
            <button class="btn btn-secondary font-xs btn-blocsk" @click.prevent.stop="$emit('addNote')">
            <i class="mdi mdi-plus"></i> Add Note
            </button>
        </div>

        <div class="note pb-2 font-xs" v-for="(note, index) in notes" :key="index">
            <p class="text-danger text-shadow mb-1">{{ note.created_at | formattedDateTime }}</p>
            <p class="m-0">{{ note.text }}</p>
        </div>

        <empty title="You haven't added any note" v-if="!notes.length"/>
    </div>
</template>

<script>
export default {
    props: ['campaign', 'showAddNote'],

    computed: {
        notes() {
            return this.campaign && this.campaign.notes ? this.campaign.notes : []
        }
    }
}
</script>