<template>
<modal
    :show="show"
    :centered="true"
    :showFooter="true"
    :maxHeight="true"
    :showInfo="false"
    okText="Update"
    @ok="submit"
    @cancel="cancel"
    okIcon="mdi mdi-account-circle">

    <h5 class="m-0 font-small font-weight-normal" slot="header">
        Help
    </h5>

    <div slot="body" class="p-3 font-small">
        content
    </div>
</modal>
</template>
<script>
export default {
    props: ['show'],
    methods: {
        ok(){

        },

        cancel() {

        }
    }
}
</script>