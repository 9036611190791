<template>
    <div @click.prevent="view" class="card-box p-1 m-0 mb-2 elevation-2 d-flex notification-item align-items-center bg-hover-secondary rounded bg-white">
        <div class="read-label elevation" v-if="!notification.read_at" :class="{'bg-secondary': notification.read_at, 'bg-danger': !notification.read_at}">
            <i class="mdi mdi-email shake-bell" v-if="!notification.read_at"></i>
            <i class="mdi mdi-email-open" v-else></i>
        </div>

        <div class="bg-default icon mr-1 rounded d-flex justify-content-center align-items-center">
            <img :src="notification.notification.source_image" class="rounded"/>
        </div>

        <div class="flex-grow-1 p-1">
            <p class="m-0 font-xs text-success d-flex justify-content-between">
                <time-counter :timestamp="notification.updated_at"/>
                <span class="badge bg-soft-warning text-warning p-1" v-if="notification.read_at">Read</span>
                <span class="badge bg-soft-success text-success p-1" v-else>New</span>
            </p>

            <h5 class="font-small mt-1 mb-1 title" v-html="notification.notification.title"></h5>

            <p class="font-xs mb-0 text-muted" style="margin-top:5px;" v-html="notification.notification.description"></p>
        </div>
        <div class="bg-default delete ml-2 d-flex justify-content-center align-items-center rounded">
            <a href="#" class="text-muted" @click.stop.prevent="deleteNotification">
                <i class="mdi mdi-delete"></i>
            </a>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    props: ['notification'],

    methods: {
        ...mapActions({
            deleteAll: 'Notifications/delete',
            markAsRead: 'Notifications/markAsRead',
        }),

        delete() {
            this.deleteAll(this.notification.id)
                .then(response => null)
                .catch(error => {
                });
        },

        deleteNotification() {
            if (this.notification.read_at) {
                this.delete();
            } else {
                this.swalConfirm(`Delete unread notification?`, `This notification contains a link which you haven't visited yet, do you want to delete this notification? You can not undo once you delete it`, `YES, I'm sure`, `NO, I'll keep it`)
                .then(result => {
                    if (result.value) {
                        this.delete();
                    }
                });
            }
        },

        readNotification() {
            this.markAsRead({id: this.notification.id, params: {}})
                .catch(error => null);
        },

        view() {
            if (!this.notification.read_at) {
                this.readNotification();
            }

            if (this.notification.notification.url) {
                this.$router.push(this.notification.notification.url);
            }
        }
    }
}
</script>