<template>
    <div class="custom-modal-wrapper" @click="cancel">
        <div class="content" @click.stop="">
            <div class="header elevation">
                <button class="btn btn-close font-small" @click.prevent="cancel">
                    <i class="mdi mdi-close"></i>
                </button>

                <h5 class="m-0 font-big ml-2 text-center flex-grow-1 font-weight-bold">{{ title }}</h5>
            </div>
            <div class="content-body">
                <slot/>
            </div>

            <div class="footer elevation text-right" v-if="showFooter">
                <slot name="footer"/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: String,
        showFooter: {
            type: Boolean,
            default: true
        }
    },

    methods: {
        cancel() {
            this.$emit('cancel');
        }
    }
}
</script>