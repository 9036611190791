import { axios } from './index';

export default {
    /**
     * Get available countries
     *
     * @param {Object} params
     * @returns {Object}
     */
    index(params) {
        return axios.get('countries', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    getStates(params) {
        return axios.get('country_states', {params})
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    }
}