<template>
<div class="mb-5">
    <label class="text-blue font-xs font-weight-bold">Select Caption Style</label>

    <div class="row mb-3">
        <div class="col-md-4">
            <div class="caption-item position-relative" @click="$emit('input', 'template-1')" :class="{'active': value == 'template-1'}">
                <i class="mdi mdi-check-all selected"></i>
                <div class="advert-media-caption preview template-1">
                    <div class="fsize">Buy <br/> Now</div>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="caption-item position-relative" @click="$emit('input', 'template-2')" :class="{'active': value == 'template-2'}">
                <i class="mdi mdi-check-all selected"></i>
                <div class="advert-media-caption preview template-2">
                    <div class="fsize">Starting at <br/> $22</div>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="caption-item position-relative" @click="$emit('input', 'template-3')" :class="{'active': value == 'template-3'}">
                <i class="mdi mdi-check-all selected"></i>
                <div class="advert-media-caption preview template-3">
                    <div class="text fsize">From $22</div>
                </div>
            </div>
        </div>
    </div>

    <p class="text-danger font-xs">
        {{ error }}
    </p>
</div>
</template>
<script>
export default {
    props: ['value', 'error'],
}
</script>