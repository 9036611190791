<template>
    <div class="indicator">
        <div class="fill" :style="{width: `${width}%`}"></div>
    </div>
</template>
<script>
import bus from '@/event-bus.js';

export default {
    props: ['index', 'value', 'media', 'videoFrame','videoFrameIndex'],

    data() {
        return {
            frameDuration: 10,
            currentFrame: 0,
            width: 0,

            timer: null
        }
    },

    computed: {
        numberOfFrames() {
            if (this.media.duration) {
                return this.media.duration * 1000 / this.frameDuration;
            }

            return 0
        },

        percentIncrementPerFrame() {
            return 100 / this.numberOfFrames;
        }
    },

    watch: {
        index() {
            if (this.index <= this.value) {
                this.width = 0;
            }

            if (this.index > this.value) {
                this.width = 100;
            }

            if (this.timer) {
                clearInterval(this.timer)
            }
        },

        videoFrame() {
            if (this.videoFrame) {
                if (this.index == this.value) {
                    this.width = this.videoFrame;
                }
            }
        }
    },

    methods: {
        reset() {
            this.currentFrame = 0;
            this.width = 0;

            if (this.timer) {
                clearInterval(this.timer);

                this.timer = null;
            }
        },

        start() {
            this.reset();

            this.timer = setInterval(() => {
                if (this.currentFrame >= this.numberOfFrames - 1) {
                    this.$emit('nextMedia', this.value + 1);

                    clearInterval(this.timer);
                }

                this.currentFrame++;
                this.width += this.percentIncrementPerFrame;

                // console.log(this.width)

            }, this.frameDuration);
        },

        startProgress(index) {
            if (index == this.value) {
                this.start();
            }
        }
    },

    created() {
        bus.$on('start-status-progress', this.startProgress);
        bus.$on('campaign-changed', this.reset)
    },

    beforeDestroy() {
        bus.$off('start-status-progress', this.startProgress)
    }
}
</script>