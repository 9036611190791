<template>
    <header class="new-header">
        <div class="top">
            <div class="boxed-content d-flex justify-content-between align-items-center">
                <div class="app-logo avatar-md">
                    <logo/>
                </div>

                <div class="d-flex flex-column justify-content-center align-items-center text-content">
                    <span class="title text-white font-weight-bold">The First Smart Shopping Broker</span>

                    <p class="font-weight-lighter m-0 description text-white mt-1">
                        Bringing <i>speed</i>, <i>savings</i> and <i>privacy</i> to your online shopping
                    </p>
                </div>

                <div class="d-flex align-items-center">
                    <div>
                        <a href="#" class="text-white button" @click.prevent="logout">
                            <i class="mdi mdi-logout"></i>
                            Logout
                        </a>
                    </div>
                    <router-link  :to="{name: 'settings'}" class="ml-3 text-white">
                        <img :src="authUser.image_url" class="circle avatar-xs mr-2"/>
                        {{ authUser.username }}
                    </router-link>
                </div>
            </div>
        </div>
        <div class="bottom">
            <!-- <logo-text class="logo-text avatar-xl woonmi-text"/> -->
            <div class="boxed-content">
                <admin v-if="isAdminUser"/>
                <merchant v-if="isMerchantUser"/>
                <customer v-if="isCustomerUser"/>
            </div>
        </div>
    </header>
</template>

<script>
import Admin from './Admin';
import Merchant from './Merchant';
import Customer from './Customer';

import { mapGetters, mapActions } from 'vuex';
export default {
    props: ['placeholder'],

    components: {
        Admin,
        Merchant,
        Customer
    },

    computed: {
        ...mapGetters({
            sidebarOpened: 'getSidebarOpened',
            getUnreadCount: 'Notifications/getUnreadCount'
        }),

        title() {
            return this.$route.meta.headerText || this.$route.meta.title;
        },

        showHome() {
            return this.$route.name != 'home'
        }
    },

    methods: {
        ...mapActions({
            openSidebar: 'openSidebar',
            closeSidebar: 'closeSidebar',
            countUnread: 'Notifications/countUnread',
            authLogout: 'Authentications/logout'
        }),

        logout() {
            this.showLoader('logging out... Please wait');

            this.authLogout()
                .then(response => {
                    this.hideLoader();
                })
                .catch(error => {
                    this.hideLoader();
                });
        },
    },

    created() {
        this.countUnread()
        .catch(error => null)
    }
}
</script>