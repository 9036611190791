<template>
<modal
    :show="show"
    :centered="true"
    :showFooter="true"
    :maxHeight="true"
    :showInfo="false"
    okText="Update"
    @ok="submit"
    @cancel="cancel"
    okIcon="mdi mdi-account-circle">

    <h5 class="m-0 font-small font-weight-normal" slot="header">
        {{ attribute && attribute.question ? attribute.question : `What is your ${ attribute && attribute.name }?` }}

    </h5>

    <div slot="body" class="p-3 font-small">
        <div class="" v-if="attribute">
            <radio-group
                v-if="attribute.system_type == 'YesNo'"
                name="yes_no"
                :options="yesNoOptions"
            />

            <div class="bg-default mb-3" v-if="attribute.system_type == 'SelectOption'">
                <label class="pl-3 font-xs text-dark-base font-weight-bold">{{ attribute.name }}</label>
                <radio-group
                    :noDescription="true"
                    :horizontal="true"
                    v-model="value"
                    :name="attribute.system_name"
                    :options="attribute.settings && attribute.settings.options"/>
            </div>

            <div class="bg-default mb-3" v-if="attribute.system_type == 'Country'">
                <label class="pl-3 font-xs text-dark-default">{{ attribute.name }}</label>
                <radio-group
                    :noDescription="true"
                    :horizontal="true"
                    v-model="value"
                    :name="attribute.system_name"
                    :options="attribute.settings_data && attribute.settings_data.countries"/>
            </div>

            <base-input
                :typeNumber="3"
                :label="attribute.name"
                v-model="value"
                :name="attribute.system_name"
                :placeholder="attribute.question || attribute.description || attribute.name"
                v-if="attribute.system_type == 'TextInput'"
            />

            <base-input
                :typeNumber="3"
                :label="attribute.name"
                v-model="value"
                :name="attribute.system_name"
                :placeholder="attribute.question || attribute.description || attribute.name"
                type="number"
                v-if="attribute.system_type == 'NumericInput'"
            />

            <div class="form-group type-3" v-if="attribute.system_type == 'DateInput'">
                <label class="font-xs">{{ attribute.name }}</label>
                <datetime
                    v-model="value"
                    input-class="form-control font-xs"
                    :placeholder="attribute.question || attribute.description || attribute.name"
                    :title="attribute.name"
                ></datetime>
            </div>

            <div class="rounded mb-3" v-if="attribute.system_type == 'GPSCoordinates'">
                <div class="d-flex justify-content-between font-xs">
                    <label class="font-xs text-dark-default">{{ attribute.name }}</label>

                    <label>
                        <input type="checkbox"/>
                        Use GPS
                    </label>
                </div>
                <div class="row no-gutters">
                    <div class="col-6 pr-1">
                        <base-input
                            :typeNumber="3"
                            label="Latitude"
                            placeholder="Enter coordinate for latitude"/>
                    </div>
                    <div class="col-6 pl-1">
                        <base-input
                            :typeNumber="3"
                            label="Longitude"
                            placeholder="Enter coordinate for longitude"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
import Fields from '@/mixins/fields';
import {mapGetters, mapActions} from 'vuex';

export default {
    mixins: [Fields],

    data() {
        return {
            show: false,
            attribute: null,
            value: ''
        }
    },

    methods: {
        ...mapActions({
            enquire: 'ProfileAttributes/enquire',
            saveProfile: 'ProfileAttributes/saveProfile'
        }),

        fullWidth() {
            return ['SelectOption', 'Country', 'GPSCoordinates'].includes(this.attribute.system_type);
        },

        cancel() {
            this.show = false;
        },

        submit() {
            if (!this.value) {
                return this.swalError(`${this.attribute.name} is required`);
            }

            this.showLoader('Updating Profile... Please wait');

            this.saveProfile({profile_attributes: [{
                attribute_id: this.attribute.id,
                value: this.value,
                was_prompted: 1
            }]}).then(response => {
                this.hideLoader()
                this.show = false;
            })
            .catch(error => {
                this.hideLoader()
            })
        },

        enquireProfileAttributes() {
            this.enquire()
                .then(response => {
                    if (response && response.id) {
                        this.show = true;
                        this.attribute = response;
                    }
                })
                .catch(error => {
                    this.show = false;
                })
        }
    },

    mounted() {
        if (this.authUser.email_verified_at) {
            this.enquireProfileAttributes();
        }
    }
}
</script>