<template>
    <app-card class="">
        <h5 class="text-tertiary font-weight-bold font-small mb-1">Audience</h5>
        <p class="mb-4 text-muted">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>

        
        <button v-if="!audience" class="btn btn-default font-weight-bold text-blue font-xs mb-3" @click.prevent="openModal">
            <i class="mdi mdi-plus-circle"></i> Add Audience
        </button>

        <view-audience :audience="audience"/>

        <button v-if="audience" class="btn btn-default font-weight-bold text-blue font-xs" @click.prevent="openModal">
            <i class="mdi mdi-plus-circle"></i> Update Audience
        </button>
    </app-card>
</template>
<script>
import bus from '@/event-bus';
import ViewAudience from './partials/audience/partials/ViewAudience.vue';

export default {
    props: ['advert'],

    components: {
        ViewAudience
    },

    computed: {
        audience() {
            return this.advert && this.advert.audience
        }
    },

    methods: {
        openModal() {
            bus.$emit('create-ad > show-modal', 'audience');
        },
    }
}
</script>