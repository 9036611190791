<template>
<div>
    <div class="hold-list-item rounded mb-2 bg-white p-3 pointer"  :class="{'elevation-2': !flat}" @click.prevent="viewAdvert" v-if="advert">
        <profile :isFriends="isFriends" :advert="advert" :isPreview="isPreview" :visibility="item && item.visibility"/>

        <p class="description font-xs mt-2">{{ advert.description }}</p>

        <div class="content rounded font-xs">
            <div class="inner">
                <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt quos provident rerum recusandae assumenda, expedita magni labore praesentium, odio ad, nobis iure unde ipsam quidem ipsa. Tempore non temporibus totam!</p> -->

                <hold-list-media
                    :intent="intent"
                    :advert="advert"
                    @viewStatus="viewStatus"
                    v-if="activeTab == 'media'"
                />
            </div>

            <div class="actions" @click.stop="">
                <button type="button" class="btn font-xs like" @click.prevent="interactWithAdvert('like')" v-if="showLike">
                    <i class="mdi mdi-heart-outline"></i> Like
                </button>

                <button type="button" @click.prevent="interactWithAdvert('unlike')" class="btn font-xs unlike" v-else>
                    <i class="mdi mdi-heart"></i> Unlike
                </button>

                <button type="button" class="btn font-xs notes" @click="addComment">
                    <i class="mdi mdi-comment-text-outline"></i> Comments
                </button>

                <button type="button" class="btn font-xs view" @click.prevent="$emit('view', {advert, intent})">
                    <i class="mdi mdi-details"></i> View
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapActions } from 'vuex';
import Profile from './Profile.vue';
import HoldListMedia from './tabs/Media.vue';

export default {
    props: ['item', 'advert_id', 'isPreview', 'flat', 'type', 'intent'],

    components: {
        Profile,
        HoldListMedia
    },

    data() {
        return {
            activeTab: 'media',

            showLike: true,
        }
    },

    computed: {
        notes() {
            return this.advert && this.advert.notes ? this.advert.notes : []
        },

        advert() {
            return this.item && this.item.advert;
        },

        isFriends() {
            return this.type == 'friends';
        }
    },

    methods: {
        ...mapActions({
            updateClicks: 'Statuses/updateClicks',
            holdOrUnhold: 'Statuses/holdOrUnhold',
            interact: 'Statuses/interact',
        }),

        interactWithAdvert(type) {
            if (type == 'like') {
                this.showLike = false;
            } else if (type == 'unlike') {
                this.showLike = true;
            }

            this.interact({
                advert_id: this.advert.id,
                interaction_type: type
            })
            .then(response => {
                // console.log(response);
            })
            .catch(error => null)
        },

        addComment() {
            this.$emit('addComment', {
                advert: this.advert,
                intent: this.intent
            })
        },

        viewAdvert() {
            if (this.isPreview) return;

            // this.$emit('view')
        },

        viewStatus() {
            this.$emit('viewStatus')
        },

        setLikeAndDislike() {
            this.showLike = !this.advert ||
                !this.advert.likes ||
                !Array.isArray(this.advert.likes) ||
                !this.advert.likes.length;

            this.showDislike = !this.advert ||
                !this.advert.dislikes ||
                !Array.isArray(this.advert.dislikes) ||
                !this.advert.dislikes.length;
        },
    },

    mounted() {
        this.setLikeAndDislike();
    }
}
</script>