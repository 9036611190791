import Vue from 'vue'
import store from './store'
import router from './router'
import plugins from './plugins'
import './registerServiceWorker'
import App from './views/common/layouts/App.vue';
import GlobalMixins from './mixins/global';

Vue.config.productionTip = false

Vue.use(plugins)

Vue.mixin({
  mixins: [GlobalMixins]
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
