<template>
<custom-modal :title="`Analytics - ${advert.name}`" @cancel="$emit('cancel')">
    <div>
        <engagement-counts :items="engagementCounts"/>
        <hr/>
        <engagement-reports :campaign="advert"/>
        <hr/>
        <audience-distribution :campaign="advert"/>
        <hr/>
        <poll-reports :campaign="advert"/>
    </div>
</custom-modal>
</template>

<script>
import Layout from '@/views/merchant/profile/partials/Layout.vue';
import PollReports from '@/views/merchant/campaigns/reports/PollReports.vue';
import EngagementReports from '@/views/merchant/campaigns/reports/Engagements.vue';
import EngagementCounts from '@/views/merchant/campaigns/reports/EngagementCounts.vue';
import AudienceDistribution from '@/views/merchant/campaigns/reports/AudienceDistribution.vue';
import { mapActions } from 'vuex';

export default {
    props: ['viewableAdvert'],

    components: {
        EngagementCounts,
        EngagementReports,
        PollReports,
        AudienceDistribution
    },

    data() {
        return {
            advert: null
        }
    },

    computed: {
        engagementCounts() {
            if (!this.advert) return [];

            const engagements = this.advert.todays_engagements;

            return [
                {
                    label: 'Impressions',
                    value: this.advert.no_of_impressions,
                    subtitle: `+${ engagements ? engagements.impressions : '0'} today`
                },
                {
                    label: 'Views',
                    value: this.advert.no_of_views,
                    subtitle: `+${ engagements ? engagements.views : '0'} today`
                },
                {
                    label: 'Clicks',
                    value: this.advert.no_of_clicks,
                    subtitle: `+${ engagements ? engagements.clicks : '0'} today`
                },
                {
                    label: 'Likes',
                    value: this.advert.no_of_likes,
                    subtitle: `+${ engagements ? engagements.likes : '0'} today`
                },
                {
                    label: 'Dislikes',
                    value: this.advert.no_of_dislikes,
                    subtitle: `+${ engagements ? engagements.dislikes : '0'} today`
                },
                {
                    label: 'Average View Time',
                    value: this.advert.view_duration,
                    subtitle: null
                },
            ]
        }
    },

    methods: {
        ...mapActions({
            show: 'Adverts/show'
        }),

        getAdvert() {
            this.show(this.viewableAdvert.id).then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            })
        }
    },

    created() {
        this.advert = {...this.viewableAdvert};

        this.getAdvert();
    }
}
</script>

<style>

</style>