import { axios } from './index';

export default {
    getEngagementReports(params) {
        return axios.get('reports/campaigns/engagements', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    getAudienceDistribution(params) {
        return axios.get('reports/campaigns/audience-distributions', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    getPollReports(params) {
        return axios.get('reports/campaigns/polls', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },
}