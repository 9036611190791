<template>
<modal
    :show="show"
    :centered="true"
    :showFooter="true"
    :fixedHeight="true"
    okText="Update"
    okIcon="mdi mdi-refresh"
    @ok="submit"
    @cancel="cancel">

    <h5 class="m-0 font-small font-weight-normal text-capitalize" slot="header">Browse Interests</h5>

    <div slot="body" class="p-1 mt-3 font-small">
        <base-search-box
            v-model="keyword"
            @search="search"
            :showLeftBtn="false"
            :showRightBtn="false"
            :placeholder="`Filter interests by keyword`">
        </base-search-box>

        <div class="topics mt-3">
            <transition-group name="swipe">
                <div class="topic" :class="{'active': selectedIds.includes(interest.id)}" v-for="interest in filtered" :key="interest.id" @click="toggleSelected(interest)">
                    <div class="flex-grow-1">
                        <p class="">{{ interest.name }}</p>
                        <p class="text-muted font-10 mt-1">
                            {{ interest.description || interest.name }}
                        </p>
                    </div>

                    <span class="icon mdi mdi-checkbox-marked-circle-outline"></span>
                </div>
            </transition-group>

            <empty title="No interest matches your search criteria." v-if="!filtered.length"></empty>
        </div>
    </div>
</modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['show', 'value'],

    data() {
        return {
            keyword: '',
            filtered: [],
            interests: [],

            selected: []
        }
    },

    computed: {
        ...mapGetters({
        }),

        selectedIds() {
            let value = this.value && Array.isArray(this.value) ? this.value : [];

            return [...this.selected.map(item => item.id), ...value];
        }
    },

    watch: {
        keyword() {
            this.filterInterests();
        },
    },

    methods: {
        ...mapActions({
            index: 'Interests/getTypes',
        }),

        toggleSelected(interest) {
            if (this.selectedIds.includes(interest.id)) {
                for (let [index, item] of this.selected.entries()) {
                    if (item.id == interest.id) {
                        this.selected.splice(index, 1);
                        return;
                    }
                }
            } else {
                this.selected.push(interest);
            }
        },

        loadInterests() {
            this.index()
                .then(response => {
                    this.interests = response;

                    this.filterInterests();
                })
                .catch(error => {
                    console.log(error);
                })
        },

        filterInterests() {
            if (!this.keyword) {
                this.filtered = this.interests;
                return;
            }

            let parts = this.keyword.split(/[-\s+]/).filter(p => !!p.trim());

            let fields = ['name', 'description'];

            this.filtered = this.interests.filter(interest => {
                for (let part of parts) {
                    for (let field of fields) {
                        let value = interest[field];

                        if (!value) continue;

                        if (value.toLowerCase().includes(part.toLowerCase())) {
                            return true;
                        }
                    }
                }

                return false;
            })
        },

        cancel() {
            this.$emit('cancel');
            this.keyword = ''
        },

        submit() {
            this.$emit('submit', this.selected);
            this.cancel();
        },

        search() {

        }
    },

    created() {
        this.loadInterests();
    }
}
</script>