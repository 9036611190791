<template>
    <app-card class="">
        <h5 class="text-tertiary font-weight-bold font-small mb-1">Product Attributes & Preferences</h5>
        <p class="mb-4 text-muted">Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam debitis earum iure officiis.</p>

        <div class="mb-3">
            <h5 class="font-small bg-default p-2 text-blue font-weight-bold rounded d-flex justify-content-between align-items-center">
                Attributes

                <button
                    class="btn btn-warning font-weight-bold font-xs"
                    @click.prevent="openModal('attributes')">
                    <i class="mdi mdi-plus-circle"></i> Attributes
                </button>
            </h5>

            <div class="d-flex flex-wrap">
                <div class="bg-default-dark text-left p-2 font-xs m-1 rounded" v-for="(attributeValue, index) in values" :key="index">
                    <p class="font-weight-normal text-blue m-0">{{ attributeValue.attribute.name }}</p>
                    <p class="m-0" v-if="attributeValue.attribute.system_name == 'release_date'">{{ attributeValue.value | formattedDate }}</p>
                    <p class="m-0" v-else-if="attributeValue.attribute.system_name == 'material'">
                        <span
                            class="bg-default p-1 m-1 rounded"
                            v-for="material in attributeValue.value.split(',')"
                            :key="material">
                            {{ material }}
                        </span>
                    </p>
                    <p class="m-0" v-else>{{ attributeValue.value }}</p>
                </div>
            </div>
        </div>

        <div class="mb-3">
            <h5 class="font-small bg-default p-2 text-blue font-weight-bold rounded d-flex justify-content-between align-items-center">
                Preferences

                <button
                    class="btn btn-warning font-weight-bold font-xs"
                    @click.prevent="openModal('preferences')">
                    <i class="mdi mdi-plus-circle"></i> Preferences
                </button>
            </h5>

            <div class="">
                <img
                    v-for="(preference, index) in preferences"
                    :key="index"
                    :src="preference.image_url" class="avatar-md m-1 border-dark-default p-2 rounded"/>
            </div>
        </div>
    </app-card>
</template>
<script>
import {mapGetters} from 'vuex';
import bus from '@/event-bus.js';

export default {
    props: ['advert'],

    data() {
        return {
            activeTab: 'attributes',
            preferences: []
        }
    },

    computed: {
        ...mapGetters({
            attributeValues: 'Adverts/getAttributeValues'
        }),

        values() {
            return this.attributeValues.filter(item => {
                if (typeof value == 'string') {
                    item.value = value.trim();
                }

                return !!item.value;
            });
        }
    },

    methods: {
        openModal(eventName) {
            bus.$emit(`create-ad > show-modal`, eventName);
        },

        setPreferences(prefs) {
            this.preferences = prefs;
        }
    },

    created() {
        if (this.advert) {
            this.setPreferences(this.advert.preferences || []);
        }

        bus.$on('create-ad > preferences', this.setPreferences);
    },

    beforeDestroy() {
        bus.$off('create-ad > preferences', this.setPreferences);
    }
}
</script>