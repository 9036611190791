<template>
    <div class="master-layout font-small" :class="{'stretch': isAdminUser}">
        <!-- <base-header/> -->
        <desktop-header/>

        <mobile-header/>

        <mobile-sidebar/>

        <desktop-sidebar v-if="isAdminUser"/>

        <transition name="router">
            <router-view/>
        </transition>

        <!-- <update-profile v-if="isCustomerUser"/> -->

        <verify-code
            :show="showVerify2FA"
            :isSetup="false"
            @success="goIntended"
            @cancel="showVerify2FA = false"/>

        <field-explainer/>

        <view-related-ad/>
    </div>
</template>

<script>
import UpdateProfile from './UpdateProfile';
import { mapActions, mapGetters } from 'vuex';

import ViewRelatedAd from '@/views/customer/adverts/ViewRelatedAd.vue';

import DesktopHeader from '@/components/navs/desktop/header/NewHeader.vue';
import DesktopSidebar from '@/components/navs/desktop/sidebar/Index.vue';

import MobileHeader from '@/components/navs/mobile/Header.vue';
import MobileSidebar from '@/components/navs/mobile/Sidebar.vue';

import FieldExplainer from '@/components/shared/field-explainer/FieldExplainer.vue';
// import BaseMobileNav from '@/components/navs/BaseMobileNav.vue';
import VerifyCode from '@/views/common/authentication/partials/Verify2FA.vue';

export default {
    components: {
        DesktopHeader,
        // BaseMobileNav,
        UpdateProfile,
        VerifyCode,
        FieldExplainer,

        DesktopHeader,
        DesktopSidebar,

        MobileHeader,
        MobileSidebar,

        ViewRelatedAd
    },

    data() {
        return {
            showProfileAttribute: false,

            showVerify2FA: false
        }
    },

    computed: {
        ...mapGetters({
            sidebarOpened: 'getSidebarOpened'
        })
    },

    methods: {
        ...mapActions({
            check: 'Authentications/check2FA',
        }),

        check2FA() {
            this.showLoader('Getting things ready... Please wait');

            this.check()
                .then(response => {
                    this.hideLoader();

                    this.createIntentFromOTF();
                })
                .catch(error => {
                    this.hideLoader();
                    this.showVerify2FA = true;
                })
        },

        goIntended() {
            this.showVerify2FA = false;
        },

        createIntentFromOTF() {
            const onthefly = !!sessionStorage.getItem('last-onthefly-data');

            if (onthefly) {
                this.$router.push({name: 'customer_create_intent'});
                return;
            }
        }
    },

    created() {
        this.check2FA();
    }
}
</script>