<template>
    <tr class="tabular-campaign">
        <td class="">
            <div class="d-flex align-items-start">
                <div class="media avatar-md mr-2">
                    <img :src="campaign.file_url" class="avatar-md rounded" :class="{'p-2': !campaign.image}"/>
                </div>
                <div class="">
                    <p class="m-0 font-weight-bold">{{ campaign && campaign.name }}</p>
                    <p class="m-0 font-xs">{{ campaign && campaign.description }}</p>
                    <p class="m-0 font-10 text-muted">{{ campaign.last_activity }}</p>
                    <p class="m-0 font-10 text-muted">{{ campaign && campaign.objective && campaign.objective.name }}</p>
                </div>
            </div>
        </td>

        <td class="no-wrap">
            <button
                class="status-btn active success font-xs"
                style="border-radius:50px;"
                type="button"
                v-if="statusText == 'Published'">
                <i class="mdi mdi-checkbox-marked-circle"></i> Published
            </button>

            <button
                class="status-btn active font-10 warning"
                style="border-radius:50px;"
                type="button"
                v-if="statusText == 'In Review'">
                <i class="mdi mdi-sync"></i> In Review
            </button>

            <button
                class="status-btn active font-10 purple"
                style="border-radius:50px;"
                type="button"
                v-if="statusText == 'Scheduled'">
                <i class="mdi mdi-clock"></i> Scheduled
            </button>

            <button
                class="status-btn active font-10 info"
                style="border-radius:50px;"
                type="button"
                v-if="statusText == 'Draft'">
                <i class="mdi mdi-paperclip"></i> Draft
            </button>
        </td>

        <td class="no-wrap">
           <p class="m-0">{{ campaign.impressions_count | numberFormat }}</p>
           <p class="m-0 text-muted font-xs">Impressions</p>

           <hr class="mb-1 mt-1"/>

           <p class="m-0">{{ campaign.clicks | numberFormat }}</p>
           <p class="m-0 text-muted font-xs">Clicked</p>
        </td>

        <td class="no-wrap">
           <p class="m-0">{{ campaign.views | numberFormat }}</p>
           <p class="m-0 text-muted font-xs">Views</p>

           <hr class="mb-1 mt-1"/>

           <p class="m-0">{{ campaign.view_duration | numberFormat }}s</p>
           <p class="m-0 text-muted font-xs">Average View Time</p>
        </td>

        <td class="no-wrap">
           <p class="m-0">{{ campaign.no_of_likes | numberFormat }}</p>
           <p class="m-0 text-muted font-xs">Likes <i class="mdi mdi-heart-outline text-danger"></i></p>

           <hr class="mb-1 mt-1"/>

           <p class="m-0">{{ campaign.no_of_dislikes | numberFormat }}</p>
           <p class="m-0 text-muted font-xs">Dislikes <i class="mdi mdi-emoticon-sad"></i></p>
        </td>

        <td class="text-right no-wrap">
            <router-link
                :to="{name: 'merchant_view_campaign', params: {campaign_id: campaign.id}}"
                :href="`/merchant/campaigns/view/${campaign.id}`"
                class="status-btn font-xs warning"
                type="button">
                <i class="mdi mdi-link mr-1"></i> Open
            </router-link>
        </td>
    </tr>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    props: ['campaign'],

    data() {
        return {
            status: 1,
            hasExpired: false
        }
    },

    computed: {
        advert() {
            if (!this.campaign) return null;

            return this.campaign.advert;
        },

        statusText() {
            return this.campaign.status.name;
        }
    },

    methods: {
        ...mapActions({
            destroy: 'Campaigns/destroy'
        }),

        init() {

        },

        async deleteCampaign() {
            let result = await this.swalConfirm(`Delete Advert?`, `This will irreversibly delete this advert, and all its associated contents, attributes, analytics and polls`);

            if (!result.value) return;

            this.destroy(this.advert.id)
            .then(response => {
            })
            .catch(error => {
                this.swalError(error.message);
            })
        }
    },

    mounted() {
        this.init();
    }
}
</script>