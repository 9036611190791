<template>
    <app-card class="">
        <h5 class="text-tertiary font-weight-bold font-small mb-3">Media Setup</h5>
        <p class="mb-4 text-muted">
            This describes how your add will be displayed to customers. <a href="#">Learn More</a>
        </p>

        <div class="overlap-images mb-3">
            <template v-for="(item, index) in media">
                <img v-if="item.mime_type.startsWith('image')" :src="item.file_url" class="elevation-2" :key="index"/>
                <video v-if="item.mime_type.startsWith('video')" :src="item.file_url" class="elevation-2" :key="index"/>
            </template>
        </div>

        <button class="btn btn-warning font-weight-bold font-xs mr-3" @click.prevent="openModal('media')">
            <i class="mdi mdi-plus-circle"></i> Add Media
        </button>

        <button class="btn btn-default-dark font-weight-bold font-xs" @click.prevent="openModal('testimonial')">
            <i class="mdi mdi-plus-circle"></i> Add Testimonial
        </button>
    </app-card>
</template>
<script>
import bus from '@/event-bus.js';
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['advert'],

    data() {
        return {
            display_format_id: 1
        }
    },

    computed: {
        ...mapGetters({
            formats: 'AdvertMedia/getDisplayFormats'
        }),

        media() {
            if (this.advert && Array.isArray(this.advert.advert_media)) {
                return this.advert.advert_media;
            }

            return []
        }
    },

    watch: {
        display_format_id() {
            if(this.advert.display_format_id != this.display_format_id) {
                this.submit();
            }
        }
    },

    methods: {
        ...mapActions({
            getDisplayFormats: 'AdvertMedia/getDisplayFormats'
        }),

        openModal(type) {
            bus.$emit('create-ad > show-modal', type);
        },

        submit() {
            bus.$emit('update-advert', {
                id: this.advert.id,
                data: {
                    stage: 'fields',
                    display_format_id: this.display_format_id
                }
            });
        }
    },

    created() {
        this.display_format_id = 1;
    }
}
</script>