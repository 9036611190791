<template>
    <content-wrapper class="">
        <profile-cover/>
    </content-wrapper>
</template>

<script>
import {debounce} from 'lodash';
import ProfileCover from './partials/Cover'
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
        ProfileCover
    },

    data() {
        return {

        }
    },

    watch: {
        keyword() {
            this.performFiltering()
        }
    },

    methods: {
        ...mapActions({
            index: 'ProfileAttributes/index',
            saveProfile: 'ProfileAttributes/saveProfile'
        }),
    },

    created() {
    }
}
</script>