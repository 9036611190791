<template>
    <div class="auth-multi-step">
        <template v-for="(step, index) in steps">
            <div class="step" :key="`step-${index}`" :class="{'active': step.id == active}" @click.prevent="$emit('navigate', step.id)">
                <div class="icon mb-2">
                    <i :class="step.icon"></i>
                </div>
                <p class="m-0 text-muted">{{ step.name }}</p>
            </div>
            <!-- <div class="line flex-grow-1" :key="`line-${index}`" v-if="index < steps.length - 1"></div> -->
        </template>
    </div>
</template>
<script>
export default {
    props: ['steps', 'active']
}
</script>