<template>
    <div class="form-group mb-3" :class="divClass + ` type-${typeNumber} `">

        <div class="icon" v-if="icon">
            <span :class="`${icon} ${error ? 'shake-bell text-danger' : ''}`"></span>
        </div>

        <label class="font-xs" style="margin-bottom:0 !important;" v-if="!noLabel">{{label ? label : placeholder}}</label>

        <textarea
            class="form-control font-small"
            :class="{'padded': !!error, 'text-input': isTextInput}"
            :disabled="disabled"
            :placeholder="placeholder"
            @input="onInput"
            :value="value"
            :name="name"
            :ref="reference"
            :rows="rows"
            @keypress.enter="$emit('enter')"
        ></textarea>

        <span class="font-10 error-label" v-if="error">{{ error }}</span>
    </div>
</template>

<script>

export default {
    props: {
        value: {
            type: [String, Number]
        },

        typeNumber: {
            type: [Number, String],
            default: 4
        },

        icon: String,

        isTextInput: {
            type: Boolean,
            default: true
        },

        name: {
            type: String
        },

        type: {
            type: String,
            default: 'text'
        },

        label: {
            type: String,
            default: ''
        },

        error: {
            type: String,
            default: ''
        },

        placeholder: {
            type: String,
            default: ''
        },

        noLabel: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        reference: {
            type: String
        },

        divClass: {
            type: String
        },

        desc: {
            type: String
        },

        rows: {
            type: Number,
            default: 5
        }
    },

    data () {
        return {
            payload: ''
        }
    },

    methods: {
        /**
         * Emits typed value
         *
         * @param {Object} $event
         * @return {Undefined}
         */
        onInput($event) {
            this.$emit('input', $event.target.value);
        }
    }
}
</script>

<style>

</style>
