<template>
    <modal
        :show="show"
        :centered="true"
        :maxHeight="true"
        :lg="true"
        :showCancel="false"
        @ok="okay"
        okText="Okay">
        <h5 class="m-0 font-small font-weight-bold text-capitalize" slot="header">{{title}}</h5>

        <div slot="body" class="p-3 font-small">
            <div v-if="type === 'why'">
                <p>Woonmi is a play on an African phrase “Wun Mi' which means “my choice”.</p>

                <p>At Woonmi, we believe that you as a customer shouldn't have to spend hours getting lost inside search after search after search, trying to find what you want to buy and who to buy from. Even worse is that all that wandering around on the Web exposes you to privacy vulnerabilities like being tracked by ad publishers who then inundate your online experiences with irrelevant and disruptive pop-up and social media ads and emails. We also don't believe that merchants should be wasting ~$40B yearly on targeting advertising that fails to connect with the right customers because the existing model gives only limited insight into customers' intents. Further, this problem is expected to get even worse because of emerging data privacy regulations that limit how personal data can be acquired and used.</p>

                <p>So Woonmi has reimagined how online shopping should be done to give both customers and merchants the best outcomes. At its core, Woonmi differs fundamentally from the existing advertising paradigm in which advertising and engagement is largely driven by merchants and ad publishers without customers having any control about what ads they see and when. Woonmi introduces tools to make customers co-pilot engagement, directing ad traffic based on their specific needs at a particular time. As a result, merchants get a more accurate, up-to-date picture of customers' shopping intents.</p>

                <p>Woonmi's brokered online shopping model is a win-win for both customers and merchants. <b>Woon'You join the movement?</b></p>
            </div>

            <div v-else>
                <p>About woonmi</p>
            </div>
        </div>
    </modal>
</template>
<script>
import bus from '@/event-bus';
import { mapActions } from 'vuex';

export default {
    components: {
    },

    data() {
        return {
            show: false,
            type: ''
        }
    },

    computed: {
        title() {
            if (this.type == 'how') {
                return `How Woonmi works`;
            }

            return `Why Woonmi?`;
        }
    },

    methods: {
        okay() {
            this.cancel();
        },

        init(type) {
            this.type = type;
            this.show = true;
        },

        cancel() {
            this.show = false;
            this.type = ''
        }
    },

    created() {
        bus.$on('how-it-works', this.init)
    },

    beforeDestroy() {
        bus.$off('how-it-works', this.init)
    }
}
</script>