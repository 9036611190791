<template>
<div class="guest-layout">
    <heading
        title="Brokered Online Shopping"
        description="For people who value their time, money & privacy while shopping"/>

    <div class="row justify-content-center" style="margin-top:100px;">
        <div class="col-md-10">
            <create-intent-wizard
                top="70px"
                :isGuest="true"/>
        </div>
    </div>

    <how-it-works/>
</div>
</template>
<script>
import HowItWorks from '@/views/common/HowItWorks.vue';
import CreateIntentWizard from './CreateIntentWizard.vue';
import Heading from '@/views/common/authentication/partials/GuestHeading.vue';
import { mapActions } from 'vuex';

export default {
    components: {
        CreateIntentWizard,
        Heading,
        HowItWorks
    },

    methods: {
        ...mapActions({
            index: 'Statuses/index'
        })
    },

    created() {
        sessionStorage.removeItem('last-onthefly-data');

        if (this.isMerchantUser) {
            return this.$router.replace({name: 'login'});
        }
    }
}
</script>