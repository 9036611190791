<template>
    <modal
        :show="show"
        :centered="true"
        :maxHeight="true"
        okText="Verify Password"
        @cancel="cancel"
        @ok="submit"
        okIcon="mdi mdi-marker-check">

        <h5 class="m-0 font-small font-weight-bold" slot="header">Verify that you're {{ authUser.fullname }}</h5>

        <div class="" slot="body">
            <base-input
                label="Enter Password"
                type="password"
                placeholder="e.g secret"
                name="password"
                @enter="submit"
                v-model="password"/>
        </div>
    </modal>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    props: ['show'],

    data() {
        return {
            password: ''
        }
    },

    methods: {
        ...mapActions({
            verifyPassword: 'Authentications/verifyPassword'
        }),

        cancel() {
            this.password = '';

            this.$emit('cancel');
        },

        submit() {
            if (!this.password) {
                return this.swalError(`Password Required`, `To verify that you're ${this.authUser.fullname}, you must enter the password you used for this account.`);
            }

            this.showLoader('Verifying Password... Please Wait');

            this.verifyPassword({password: this.password})
                .then(response => {
                    this.hideLoader();
                    this.$emit('success');
                    this.cancel();
                })
                .catch(error => {
                    this.hideLoader();

                    if (error.message) {
                        this.swalError(`Oops`, error.message, false)
                    }
                })
        }
    }
}
</script>