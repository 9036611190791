<template>
    <modal
    :show="show"
    :centered="true"
    :lg="true"
    :showFooter="false"
    :maxHeight="true"
    @cancel="cancel">
        <h5 class="m-0 font-small font-weight-normal text-capitalize" slot="header">{{ audience ? 'Edit' : 'Add' }} Audience</h5>

        <add-audience slot="body" class="m-4" @cancel="cancel"/>
    </modal>
</template>
<script>
import AddAudience from '../../campaigns/partials/audience/partials/AddAudience';

export default {
    props: ['show', 'audience'],

    components: {
        AddAudience
    },

    methods: {
        cancel() {
            this.$emit('cancel');
        }
    }
}
</script>