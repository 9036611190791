<template>
    <div class="">
        <div class="row">
            <div class="col-md-6 mb-4 v-stretch" v-for="(campaign, index) in all" :key="index">
                <campaign :campaign="campaign"/>
            </div>
        </div>
    </div>
</template>
<script>
import Campaign from './partials/GridCampaign.vue';

export default {
    props: ['all'],

    components: {
        Campaign
    }
}
</script>