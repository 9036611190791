<template>
    <div>
        <h3 class="text-center mb-4" id="merchant"></h3>

        <div class="row no-gutters">
            <div class="col-md-12 col-12">
                <base-input
                    label="Business name"
                    required
                    class="filter elevation"
                    icon="mdi mdi-home"
                    v-model="payload.merchant_name"
                    :error="validationErrors.merchant_name"
                    placeholder=".e.g Woonmi"/>
            </div>
        </div>


        <div class="row no-gutters">
            <div class="col-md-6 col-12 pr-md-1">
                <base-select
                    v-model="payload.merchant_country_id"
                    :error="validationErrors.merchant_country_id"
                    icon="mdi mdi-flag-outline"
                    label="Country"
                    required
                    class="filter elevation">
                    <option value="">None</option>
                    <option v-for="(country, index) in countries" :key="index" :value="country.id">
                        {{ country.name }}
                    </option>
                </base-select>
            </div>
            <div class="col-md-6 col-12 pl-md-1">
                <base-select-wrapper
                    :error="validationErrors.merchant_state_id"
                    icon="mdi mdi-flag-outline"
                    label="State"
                    required
                    class="filter elevation">
                    <select class="form-control font-xs pb-1" v-model="payload.merchant_state_id">
                        <option value="">None</option>
                        <option v-for="(state, index) in states" :key="index" :value="state.id">
                            {{ state.name }}
                        </option>
                    </select>
                </base-select-wrapper>
            </div>
        </div>

        <div class="row no-gutters">
            <div class="col-md-6 col-12 pr-md-1">
                <base-input
                    label="City"
                    class="filter elevation"
                    icon="mdi mdi-map-marker"
                    v-model="payload.merchant_city"
                    :error="validationErrors.merchant_city"
                    placeholder=".e.g Raleigh"/>
            </div>

            <div class="col-md-6 col-12 pl-md-1">
                <base-input
                    label="Address"
                    type="email"
                    class="filter elevation"
                    icon="mdi mdi-map-marker"
                    v-model="payload.merchant_address"
                    :error="validationErrors.merchant_address"
                    placeholder=".e.g 123 Street address"
                />
            </div>
        </div>

        <base-input
            label="Website"
            type="text"
            class="filter elevation"
            icon="mdi mdi-earth"
            v-model="payload.merchant_website"
            :error="validationErrors.merchant_website"
            placeholder=".e.g example.com"
        />

        <prev-next
            :currentStep="currentStep"
            :isLastStep="isLastStep"
            @prev="prev"
            @next="next"
            nextText="NEXT"/>
    </div>
</template>

<script>
import TypeIt from "typeit";
import { mapActions, mapGetters } from 'vuex';
import SocialMedia from './../SocialMedia';
import PrevNext from '../PrevNext';

export default {
    props: ['currentStep', 'isLastStep', 'data'],

    components: {
        PrevNext,
        SocialMedia
    },

    data() {
        return {
            payload: {
                merchant_name: '',
                merchant_country_id: '',
                merchant_state_id: '',
                merchant_city: '',
                merchant_address: '',
                merchant_website: ''
            },

            validationErrors: {
                merchant_name: '',
                merchant_country_id: '',
                merchant_state_id: '',
                merchant_city: '',
                merchant_address: '',
                merchant_website: ''
            },

            states: []
        }
    },

    computed: {
        ...mapGetters({
            countries: 'Countries/getAll'
        }),
    },

    watch: {
        'payload.merchant_country_id'() {
            this.loadStates();
        }
    },

    methods: {
        ...mapActions({
            validate: 'Authentications/validateRegistrationDetails',
            getStates: 'Countries/getStates'
        }),

        loadStates() {
            this.showLoader('Loading country states... Please wait')

            this.getStates({country_id: this.payload.merchant_country_id})
                .then(response => {
                    this.hideLoader();

                    let state = response.find(s => s.id == this.payload.merchant_state_id);

                    if (state && state.country_id != this.payload.merchant_country_id) {
                        this.payload.merchant_state_id = '';
                    }

                    this.states = response;
                })
                .catch(error => {
                    this.hideLoader();
                })
        },

        typeHeading() {
            new TypeIt("#merchant", {
                speed: 50,
                startDelay: 100
            })
            .empty()
            .type(`Hi...`, {delay: 500})
            .delete()
            .type(`Tell us about your business.`)
            .go()
        },

        submit() {
            this.buildValidationErrors({});

            this.showLoader('Checking your details... Please wait');

            this.validate({type: 'merchant', ...this.payload})
                .then(response => {
                    this.hideLoader();

                    this.$emit('submit', this.payload);
                })
                .catch(error => {
                    this.hideLoader();
                    this.swalError(`Attention Required`, `${error.message} Please fix all issues before proceeding.`)
                    this.buildValidationErrors(error.errors);
                })
        },

        next() {
            // this.$emit('next');
            this.submit();
        },

        prev() {
            this.$emit('prev')
        },

        prepopulate() {
            if (this.data) {
                for (let key of Object.keys(this.payload)) {
                    if (this.data.hasOwnProperty(key)) {
                        this.payload[key] = this.data[key];
                    }
                }
            }

            console.log(this.data, this.payload)
        }
    },

    mounted() {
        this.typeHeading();

        this.prepopulate();
    }
}
</script>