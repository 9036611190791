<template>
    <content-wrapper class="">
        <div class="row">
            <div class="col-md-4" v-for="(media, index) in all" :key="index">
                <media-file
                    class="elevation-2"
                    :media="media"/>
            </div>
        </div>

        <empty title="No campaign media found" v-if="!all.length"/>
    </content-wrapper>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import MediaFile from '../campaigns/partials/media/Media';

export default {
    components: {
        MediaFile,
    },

    data() {
        return {
        }
    },

    computed: {
        ...mapGetters({
            all: 'AdvertMedia/getAll',
            pageDetails: 'AdvertMedia/getPageDetails'
        })
    },

    methods: {
        ...mapActions({
            index: 'AdvertMedia/index'
        }),

        loadData(query) {
            let params = {
                ...query
            }

            this.showLoader('Loading media files... Please wait')

            this.index(params)
            .then(response => {
                this.hideLoader();
                console.log(response);
            })
            .catch(error => {
                this.hideLoader();
                console.log(error);
            })
        }
    },

    created() {
        this.loadData({clearState: true});
    }
}
</script>