<template>
    <div class="">
        <div class="create-intent">
            <div class="add-form mb-md-3" id="add-form" ref="addForm">
                <div class="row justify-content-center align-items-center">
                    <div class="col-md-10 col-12 mb-1">
                        <p class="">
                            Enter keyword for a product type <i>(e.g. laptops)</i>.
                            Select nearest category from list shown below to add to channel.
                        </p>

                        <base-input
                            :noLabel="true"
                            typeNumber="0"
                            v-model="keyword"
                            icon="mdi mdi-magnify"
                            :noMargin="true"
                            placeholder="Laptops"/>
                    </div>
                </div>
            </div>

            <div class="row no-gutters">
                <div class="col-md-6 col-12 p-1" v-for="(productType, index) in productTypes" :key="index">
                    <div
                        class="product-type-item elevation p-3 bg-white cursor-pointer d-flex align-items-center font-small"
                        @click="submit(productType)" :class="{'active': productType.id == selectedProductTypeId}">
                        <div class="icon text-primary mr-3">
                            <i class="mdi mdi-view-dashboard"></i>
                        </div>
                        <div class="flex-grow-1 text-truncate">
                            <div class="name text-primary font-weight-">{{ productType.name }}</div>
                            <p class="m-0 text-muted">
                                <span class="font-xs" v-if="productType.parent"> > {{ productType.parent.name }}</span>
                                <span class="font-xs" v-else>Main category</span>
                            </p>
                        </div>
                        <div class="icon text-primary ml-3" v-if="productType.id == selectedProductTypeId">
                            <i class="mdi mdi-check-circle text-xl"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['productTypeName'],
    components: {
    },

    data() {
        return {
            keyword: '',
            selectedProductTypeId: null
        }
    },

    computed: {
        ...mapGetters({
            product_types: 'ProductTypes/getAll',
        }),

        productTypes() {
            if (!this.keyword) return [];

            let parts = this.keyword.toLowerCase().split(' ').filter(part => part.trim());

            let product_types = this.product_types

            for (let part of parts) {
                product_types = product_types.filter(item => {
                    const names = item.name.toLowerCase().split(' ')

                    for (let name of names) {
                        if (name.startsWith(part)) return true;
                    }
                })
            }

            return product_types;
        }
    },

    watch: {
        keyword() {
            if (window.innerWidth <= 768) {
                let scrollDiv = document.getElementById("add-form").offsetTop;
                window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
            }

            this.updateRouteQuery({q: this.keyword});
        },

        selectedProductTypeId() {
            this.$emit('input', this.selectedProductTypeId);

            toastr.info(`Product type selected`, this.keyword);
        },

        productTypeName() {
            this.setProductTypeName();
        }
    },

    methods:{
        ...mapActions({
            checkDuplicates: 'Intents/checkDuplicates',
        }),

        setProductTypeName() {
            this.keyword = this.productTypeName;
            toastr.info(`Product type selected`, this.keyword);
        },

        setValue(productType) {
            this.selectedProductTypeId = productType.id;
            // console.log(productType);
            this.keyword = productType.name;
            this.updateRouteQuery({pt_id: productType.id});
            this.$emit('scroll')
        },

        cancelAddEdit() {
            this.$router.push({name: 'customer_intents'})
        },

        async proceed(response, productType) {
            if (!response  || !response.id) {
                this.setValue(productType)
                return;
            }

            let result = await this.swalConfirm(
                `Duplicate exists`,
                `We've found that you already have created an intent to purchase ${productType.name}. Do you still want to create a new intent?`, `Create New Intent`
            );

            if (!result.value) return;

            this.setValue(productType)
        },

        submit(productType) {
            this.proceed(null, productType);
        },
    },

    mounted() {
        if (this.productTypeName) {
            this.setProductTypeName();
        }
    },

    created() {
        if (this.$route.query.pt_id) {
            this.selectedProductTypeId = this.$route.query.pt_id;
        }

        if (this.$route.query.q) {
            this.keyword = this.$route.query.q;
        }
    }
}
</script>