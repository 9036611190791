<template>
    <modal
        :show="show"
        :centered="true"
        :maxHeight="true"
        :showInfo="!isSetup"
        :showCancel="isSetup == true"
        :opaque="isSetup == false"
        infoText="Logout"
        infoClass="btn btn-warning"
        @info="logout"
        okText="Verify Authentication"
        @cancel="cancel"
        @ok="submit"
        okIcon="mdi mdi-marker-check">

        <h5 class="m-0 font-small font-weight-bold" slot="header">Two Factor Authentication</h5>

        <div slot="body">
            <div class="text-center">
                <span class="mdi mdi-lock-outline text-success" style="font-size:50px;"></span>

                <h5 class="mb-4 mt-2">Enter Verification Code</h5>

                <p class="mb-3 text-muted">
                    A six digit verification code has been sent to your email (<span class="text-secondary">{{ authUser.email }}</span>). <br/>
                    <b class="text-black">If you did not make this request then we highly recommend you reset your password now.</b>
                </p>
            </div>

            <base-input
                label="Authentication Code"
                type="text"
                placeholder="e.g 234239"
                name="password"
                @enter="submit"
                v-model="code"/>
        </div>
    </modal>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    props: ['show', 'isSetup'],

    data() {
        return {
            code: ''
        }
    },

    watch: {
        show() {
            this.code = '';
        }
    },

    methods: {
        ...mapActions({
            verifyAuthentication: 'Authentications/verifyAuthentication',
            signOut: 'Authentications/logout'
        }),

        logout() {
            this.cancel();

            this.signOut();
        },

        cancel() {
            this.code = '';

            this.$emit('cancel');
        },

        submit() {
            if (!this.code) {
                return this.swalError(`Code Required`, `To verify that you're ${this.authUser.fullname}, you must enter the code which was sent to your email address above.`);
            }

            this.showLoader('Verifying... Please Wait');

            this.verifyAuthentication({code: this.code})
                .then(response => {
                    this.hideLoader();
                    this.$emit('success');
                })
                .catch(error => {
                    this.hideLoader();

                    if (error.message) {
                        this.swalError(`Oops`, error.message, false)
                    }
                })
        }
    }
}
</script>