import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters({
            apiToken: 'Authentications/getToken'
        }),
    },

    methods: {
        initTypeAhead(element, url, options = {}, callback = null) {
            const THIS = this;

            var engine = new Bloodhound({
                remote: {
                    url,
                    prepare: function (query, settings) {
                        settings.url = settings.url + '?keyword=' + query;

                        if (THIS.queryParams) {
                            settings.url += `&${new URLSearchParams(THIS.queryParams)}`;
                        }

                        settings.headers = {
                            "Authorization": `Bearer ${THIS.apiToken}`
                        };

                        return settings;
                    }
                },
                datumTokenizer: Bloodhound.tokenizers.whitespace('keyword'),
                queryTokenizer: Bloodhound.tokenizers.whitespace
            });

            $(element).typeahead({
                hint: true,
                highlight: false,
                minLength: 1
            }, {
                source: engine.ttAdapter(),

                ...options,

                templates: {
                    empty: [
                        `<div class="typehead-result-item cursor-pointer rounded bg-default-dark p-3 elevation font-xs">
                            No data matches your search
                        </div>`
                    ],

                    header: [],

                    suggestion: function (item) {
                        return `
                        <div class="typehead-result-item cursor-pointer">
                            <div class="data">
                                <p class="m-0 font-xs name font-weight-bold">${item.name}</p>
                                <p class="m-0 font-8 category text-muted">${item.category || ''}</p>
                            </div>
                        </div>`
                    }
                }
            }).on('typeahead:selected', function (event, selection) {
                THIS.$emit('selection', selection);

                if (callback) {
                    callback(selection)
                }
            });
        }
    }
}