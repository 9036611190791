<template>
<modal
    :show="show"
    :centered="true"
    :showFooter="false"
    :maxHeight="true"
    :showInfo="false"
    :okText="member ? 'Update' : 'Create'"
    @ok="submit"
    @cancel="cancel"
    okIcon="mdi mdi-account-circle">

    <h5 class="m-0 font-small font-weight-normal" slot="header">
        {{ member ? 'Edit' : 'Add New' }} Team member
    </h5>

    <div slot="body" class="pt-3 pb-3 font-small">

        <div class="row no-gutters">
            <div class="col-6 p-1">
                <base-input
                    label="Firstname"
                    placeholder="e.g John"
                    v-model="payload.firstname"
                    :error="validationErrors.firstname"/>
            </div>
            <div class="col-6 p-1">
                <base-input
                    label="Lastname"
                    placeholder="e.g Doe"
                    v-model="payload.lastname"
                    :error="validationErrors.lastname"/>
            </div>
        </div>

        <div class="row no-gutters">
            <div class="col-6 p-1">
                <base-input
                    label="Username"
                    placeholder="e.g jdoe"
                    v-model="payload.username"
                    :error="validationErrors.username"/>
            </div>
            <div class="col-6 p-1">
                <base-select
                    label="Country"
                    v-model="payload.country_id"
                    :error="validationErrors.country_id">
                    <option value="">None</option>
                    <option v-for="(country, index) in countries" :key="index" :value="country.id">{{ country.name }}</option>
                </base-select>
            </div>
        </div>

        <div class="row no-gutters">
            <div class="col-6 p-1">
                <base-input
                    label="Email Address"
                    type="text"
                    placeholder="e.g you@example.com"
                    v-model="payload.email"
                    :error="validationErrors.email"/>
            </div>
            <div class="col-6 p-1">
                <base-input
                    label="Phone Number"
                    type="text"
                    placeholder="e.g +123 945678912"
                    v-model="payload.phone"
                    :error="validationErrors.phone"/>
            </div>
        </div>

        <div class="text-center mt-4">
            <button
                type="button"
                @click="submit"
                class="btn elevation font-xs login-btn text-uppercase btn-block"
                :class="{'btn-success': !member, 'btn-warning': member}">
                <template v-if="member">
                    <i class="mdi mdi-content-save-edit-outline"></i> Save Changes
                </template>
                <template v-else>
                    <i class="mdi mdi-plus"></i> Add member
                </template>
            </button>
        </div>
    </div>
</modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default{
    props: ['show', 'member'],

    data() {
        return {
            payload: {
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                username: '',
                country_id: ''
            },

            validationErrors: {
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                username: '',
                country_id: ''
            }
        }
    },

    computed: {
        ...mapGetters({
            countries: 'Countries/getAll'
        })
    },

    watch: {
        member() {
            if (this.member) {
                this.payload.firstname = this.member.user.firstname;
                this.payload.lastname = this.member.user.lastname;
                this.payload.email = this.member.user.email;
                this.payload.username = this.member.user.username;
                this.payload.country_id = this.member.user.country_id;
                this.payload.phone = this.member.user.phone;
            } else {
                this.cancel();
            }
        }
    },

    methods: {
        ...mapActions({
            store: 'MerchantTeamMembers/store',
            update: 'MerchantTeamMembers/update',
        }),

        cancel() {
            for (let key of Object.keys(this.payload)) {
                this.payload[key] = '';
                this.validationErrors[key] = '';
            }

            this.$emit('cancel');
        },

        submit() {
            this.buildValidationErrors({})

            let method = null, data = null;

            if (this.member) {
                method = this.update;
                data = {id: this.member.id, data: this.payload};
                data.data.user_id = this.member.user_id;
            } else {
                method = this.store;
                data = {...this.payload};
            }

            if (method && data) {
                this.showLoader('Adding Team Member... Please Wait');

                method(data)
                .then(response => {
                    this.hideLoader();
                    this.cancel();
                    this.$emit('done');
                })
                .catch(error => {
                    this.hideLoader();
                    this.buildValidationErrors(error.errors);

                    if (error.status_code == 403) {
                        this.cancel();
                        this.swalError('Action Denied!', `You do not have the authorization to add new team members`);
                    }
                });
            }
        }
    },

    created() {
        //
    }
}
</script>