<template>
    <div class="create-audience">
        <!-- <h5 class="font-big mb-4 text-center">Create New Audience</h5> -->

        <div class="mb-5 text-center text-muted">
            Between <b>202,1928</b> to <b>921,283</b> people can be reached
        </div>

        <div class="row mb-3">
            <div class="col-md-4">
                <div class="form-group-holder select-color p-0 d-flex justify-content-between align-items-center" @click="showLabelColors = true">
                    <span class="ml-3">{{ payload.label_color }}</span>
                    <label class="font-xs">Label Color</label>
                    <div class="label-color" :style="{background: payload.label_color}"></div>
                </div>
            </div>
            <div class="col-md-4">
                <base-input
                    required
                    v-model="payload.name"
                    label="Audience name"
                    placeholder="This will help you remeber this audience"/>
            </div>
            <div class="col-md-4">
                <base-select label="Gender Group" v-model="payload.gender">
                    <option value="">All</option>
                    <option value="male">Men</option>
                    <option value="female">Women</option>
                    <option value="other">Others</option>
                </base-select>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <base-select label="Demographical Restriction" v-model="payload.restriction_type">
                    <option value="">No Restriction</option>
                    <option value="region">Restricted to Region</option>
                    <option value="gps">Restricted by GPS</option>
                </base-select>
            </div>

            <template v-if="payload.restriction_type == 'region'">
                <div class="col-md-4">
                    <base-select-wrapper
                        required
                        label="Country">
                        <select
                            class="form-control font-small"
                            v-model="payload.country_id">
                            <option value="">None</option>
                            <option v-for="(country, index) in countries" :key="index" :value="country.id">{{ country.name }}</option>
                        </select>
                    </base-select-wrapper>
                </div>

                <div class="col-md-4">
                    <base-select-wrapper
                        optional
                        label="State">
                        <select
                            class="form-control font-small"
                            v-model="payload.state_id">
                            <option value="">None</option>
                            <option v-for="(state, index) in states" :key="index" :value="state.id">{{ state.name }}</option>
                        </select>
                    </base-select-wrapper>
                </div>

                <!-- <div class="col-md-4">
                    <base-input
                        optional
                        label="City"
                        placeholder="Leave blank if not applicable"
                        v-model="payload.city"/>
                </div> -->
            </template>

            <template v-if="payload.restriction_type == 'gps'">
                <div class="col-md-12">
                    <div class="form-group-holder mt-3 mb-4 pt-4 pb-3">
                        <label class="font-xs">Select GPS Coordinates</label>

                        <div class="row">
                            <div class="col-md-4">
                                <base-input v-model="payload.gps_lat" label="Latitude" placeholder="Enter latitude"/>
                            </div>
                            <div class="col-md-4">
                                <base-input v-model="payload.gps_long" label="Longitude" placeholder="Enter longitude"/>
                            </div>
                            <div class="col-md-4">
                                <button type="button" class="btn btn-outline-dark font-xs elevation-2" @click="useGPS">
                                    <i class="mdi mdi-crosshairs-gps"></i>
                                    Use GPS Coordinates
                                </button>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group-holder mt-3 pt-3 pb-3">
                                    <label class="font-xs">Distance in km, from given coordinates</label>
                                    <input type="range" id="distance"  min="0" max="100" step="1" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

        </div>

        <div class="row">
            <div class="mb-3" :class="{'col-md-6': showInterests, 'col-md-12': !showInterests}">
                <div class="form-group-holder mt-3 pt-3 pb-3">
                    <label class="font-xs">Age Group</label>
                    <input type="range" id="age_range"  min="0" max="100" step="10"  value="40" />
                </div>
            </div>

            <div class="col-md-6" v-if="showInterests">
                <div class="form-group-holder mt-3 pt-3">
                    <label class="font-xs">Targeting by Interest <span class="badge badge-warning">{{ interests.length }}</span></label>

                    <div v-if="interests.length">
                        <span
                            class="badge bg-default-dark text-tertiary border no-radius font-xs p-2 m-1"
                            v-for="(interest, index) in interests"
                            :key="index">
                            {{ interest.name }}
                        </span>
                    </div>

                    <p class="text-muted font-small" v-else>No interest selected</p>

                    <div class="text-right">
                        <button type="button" class="btn btn-outline-dark font-xs elevation-2" @click="showInterest = true">
                            <i class="mdi mdi-folder-multiple-outline"></i>
                            Browse Interests
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5 text-right">
            <button @click.prevent="submit" type="button" class="btn btn-success font-xs elevation-2">
                <i class="mdi mdi-plus mr-1"></i> CREATE AUDIENCE
            </button>
        </div>

        <browse-interest
            :show="showInterest"
            :value="interestIds"
            @submit="(items) => interests = items"
            @cancel="showInterest = false"/>

        <choose-label-color
            :value="payload.label_color"
            @submit="color => payload.label_color = color"
            :show="showLabelColors"
            @cancel="showLabelColors = false"/>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import BrowseInterest from './BrowseInterest';
import ChooseLabelColor from './ChooseLabelColor';

export default {
    props: ['audience', 'advert_id', 'campaign'],

    components: {
        BrowseInterest,
        ChooseLabelColor
    },

    data() {
        return {
            showInterest: false,

            interests: [],

            payload: {
                label_color: '#EFF6F6',
                name: '',
                gender: '',
                age_from: 13,
                age_to: 100,

                restriction_type: '',
                gps_lat: '',
                gps_long: '',
                gps_distance: 1,
                country_id: '',
                state_id: '',
                city: '',
            },

            states: [],

            showLabelColors: false
        }
    },

    computed: {
        ...mapGetters({
            countries: 'Countries/getAll'
        }),

        showInterests() {
            return this.campaign && this.campaign.matching_strategy && this.campaign.matching_strategy.name == 'Interest Based'
        },

        interestIds() {
            if (this.interests && Array.isArray(this.interests)) {
                return this.interests.map(interest => interest.id);
            }

            return []
        }
    },

    watch: {
        'payload.country_id'() {
            this.payload.state_id = '';

            this.loadStates();
        },

        'payload.restriction_type' () {
            if (this.payload.restriction_type == 'gps') {
                setTimeout(() => {
                    this.setupDistance();
                }, 10);
            }
        }
    },

    methods: {
        ...mapActions({
            getStates: 'Countries/getStates',
            store: 'Audience/store',
            update: 'Audience/update',
        }),

        useGPS() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((params) => {
                    this.payload.gps_lat = params.coords.latitude;
                    this.payload.gps_long = params.coords.longitude;
                });
            }
        },

        clearForm() {
            for (let key of Object.keys(this.payload)) {
                this.payload[key] = '';
            }

            this.payload.label_color = '#EFF6F6';
            this.interests = []
        },

        loadStates() {
            if (!this.payload.country_id) return;

            this.showLoader('Loading states... Please wait');

            this.getStates({country_id: this.payload.country_id})
                .then(response => {
                    this.hideLoader()
                    this.states = response;
                })
                .catch(error => {
                    this.hideLoader()
                })
        },

        setupAgeSlider(options) {
            $("#age_range").ionRangeSlider({
                skin: 'square',
                type: "double",
                grid: true,
                min: 13,
                max: 100,
                from: 13,
                to: 100,
                ...options,

                onFinish: (data) => {
                    this.payload.age_from = data.from;
                    this.payload.age_to = data.to;
                }
            });
        },

        setupDistance(options) {
            console.log('setting up distance');

            $("#distance").ionRangeSlider({
                skin: 'square',
                type: "single",
                grid: true,
                min: 1,
                max: 1000,
                from: 1,
                to: 1,
                ...options,

                onFinish: (data) => {
                    this.payload.gps_distance = data.from;
                }
            });
        },

        submit() {
            let method;

            let data = {
                campaign_id: this.campaign.id,
                advert_id: this.advert_id,
                ...this.payload,
                interests: [...this.interestIds]
            }

            if (this.audience) {
                this.showLoader(`Updating audience... Please wait`);

                method = this.update({id: this.audience.id, data});
            } else {
                this.showLoader(`Creating audience... Please wait`);
                method = this.store(data);
            }

            if (method) {
                method.then(response => {
                    this.hideLoader();
                    this.clearForm();
                    this.$emit('cancel')
                    console.log(response)
                })
                .catch(error => {
                    this.hideLoader();
                    console.log(error);
                })
            }
        }
    },

    mounted() {
        this.setupAgeSlider({from: this.payload.age_from, to: this.payload.age_to});
    }
}
</script>