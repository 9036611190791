<template>
<modal
    :show="show"
    :centered="true"
    :lg="true"
    :maxHeight="true"
    okText="Update Testimonial"
    okIcon="mdi mdi-check"
    @ok="submit"
    @cancel="cancel">
    <h5 class="m-0 font-small font-weight-bold text-capitalize" slot="header">
        Update Testimonial
    </h5>

    <div slot="body" class="p-5">
        <label class="text-blue font-xs font-weight-bold">Select Template Style</label>
        <div class="row mb-5">
            <div class="col-md-4">
                <div
                    class="testimonial-item" @
                    @click="payload.template = 'template-1'"
                    :class="{'active': payload.template == 'template-1'}">
                    <testimonial :preview="true" :testimonial="template1"/>
                </div>
            </div>
            <div class="col-md-4">
                <div
                    class="testimonial-item"
                    @click="payload.template = 'template-2'"
                    :class="{'active': payload.template == 'template-2'}">
                    <testimonial :preview="true" :testimonial="template2"/>
                </div>
            </div>
            <div class="col-md-4">
                <div
                    class="testimonial-item"
                    @click="payload.template = 'template-3'"
                    :class="{'active': payload.template == 'template-3'}">
                    <testimonial :preview="true" :testimonial="template3"/>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <base-input v-model="payload.author" type="text" placeholder="Author" label="Author"/>
            </div>
            <div class="col-md-6">
                <base-input v-model="payload.stars" type="number" placeholder="Number of Stars" label="Stars"/>
            </div>
            <div class="col-md-12">
                <base-textarea v-model="payload.text" placeholder="Review comment" label="Comment"/>
            </div>
        </div>
    </div>
</modal>
</template>
<script>
import bus from '@/event-bus.js';
import { mapActions } from 'vuex';
import Testimonial from '@/views/customer/interests/partials/Testimonial.vue';

export default {
    props: ['show', 'advert'],

    components: {
        Testimonial
    },

    data() {
        return {
            payload: {
                template: '',
                text: '',
                author: '',
                stars: 5,
            }
        }
    },

    computed: {
        template() {
            return {
                text: 'Comment',
                author: 'Author',
                stars: 5,
            }
        },

        template1() {
            return {
                ...this.template,
                template: 'template-1'
            }
        },

        template2() {
            return {
                ...this.template,
                template: 'template-2'
            }
        },

        template3() {
            return {
                ...this.template,
                template: 'template-3'
            }
        },
    },

    watch: {
        show() {
            if (this.show && this.advert.testimonial) {
                this.payload = {...JSON.parse(this.advert.testimonial)};
            }
        }
    },

    methods: {
        ...mapActions({
        }),


        submit() {
            if (!this.payload.text || !this.payload.author || !this.payload.stars) {
                this.swalError('Please fill in all fields', `Make sure you provide valid values for all fields.`);
                return;
            }

            bus.$emit('update-advert', {
                id: this.advert.id,
                data: {
                    stage: 'fields',
                    testimonial: JSON.stringify(this.payload)
                }
            });

            this.cancel();
        },

        cancel() {
            this.$emit('cancel');
        }
    },

    mounted() {
        //
    }
}
</script>