<template>
    <div class="p-2  intent rounded elevation-2">
        <div>
            <h5 class="font-xs ml-1 text-primary">Products:</h5>
            <div class="mt-2 mb-2 d-flex flex-wrap">
                <span
                    class="token p-2 m-1 d-inline-flex align-items-center font-xs round elevation pr-3 pl-3 bg-default-dark"
                    v-for="(type, index) in productTypes" :key="index">
                    {{ type.name }}
                </span>
            </div>
        </div>

        <div class="billboard">
            <div class="height-4">
                <img :src="billboard.primary.file_url" v-if="billboard && billboard.primary"/>
                <img src="/img/empty.jpg" v-else/>
            </div>

            <div class="d-flex">
                <div class="height-3 flex-grow-1">
                    <img :src="billboard.secondary.file_url" v-if="billboard && billboard.secondary"/>
                    <img src="/img/empty.jpg" v-else/>
                </div>
                <div class="height-3 flex-grow-1">
                    <img :src="billboard.tertiary.file_url" v-if="billboard && billboard.tertiary"/>
                    <img src="/img/empty.jpg" v-else/>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-between">
            <div class="justify-content-between bg-intent-info-soft p-1 rounded">
                <!-- <a href="#" title="View purchasing intents" @click.prevent="$emit('view', intent)" class="btn btn-success btn-sm font-small elevation-2 mr-1">
                    <i class="mdi mdi-eye"></i>
                </a> -->

                <router-link :to="{name: 'customer_update_intent', params: {intent_id: intent.id}}" href="#" title="Edit purchasing intents" @click.prevent="$emit('edit', intent)" class="btn btn-warning btn-sm font-small elevation-2 mr-2">
                    <i class="mdi mdi-pencil"></i>
                </router-link>

                <a href="#" title="Delete purchasing intents" @click.prevent="deleteIntent" class="btn btn-danger btn-sm font-small elevation-2">
                    <i class="mdi mdi-delete"></i>
                </a>
            </div>

            <div class="p-1 rounded">
                <router-link :to="{name: 'customer_view_intent', params: {intent_id: intent.id}}" class="btn btn-primary btn-sm font-small m-0 elevation">
                    View Channels
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import Ad from './Ad';
import bus from '@/event-bus.js';
import { mapActions } from 'vuex';
import SummaryAd from './SummaryAd';
import AdSkeleton from './Ad-Skeleton.vue';

export default {
    props: ['intent', 'isPinned'],

    components: {
        Ad,
        SummaryAd,
        AdSkeleton,
    },

    data() {
        return {
            showAds: false,

            loading: false,
            selected: null,

            pinned: false
        }
    },

    computed: {
        productTypes() {
            if (!this.intent.product_types || !this.intent.product_types.length) return [];

            return this.intent.product_types;
        },
        intentType() {
            return this.intent ? this.intent.type : null;
        },

        values(){
            return this.intent ? this.intent.values : []
        },

        properties() {
            let properties = [
                { name: 'Type', value: this.intent.type.name },
                { name: 'Expiry', value: moment(this.intent.expiry_date).format(this.dateFormat) },
                { name: 'Status', value: this.intent.status.name },
            ];

            return properties;
        },

        billboard() {
            return this.intent && this.intent.billboard;
        },
    },

    methods: {
        ...mapActions({
            destroy: 'Intents/destroy',
            pin: 'Intents/pin'
        }),

        async deleteIntent() {
            let result = await this.swalConfirm('Are you sure?', `This will remove "${this.intent.product_type.name}" and its attributes.`, `Yes, Delete Intent`);

            if (!result.value) return;

            this.showLoader('Deleting intent... Please wait');

            this.destroy(this.intent.id)
                .then(response => {
                    this.hideLoader();
                })
                .catch(error => {
                    this.hideLoader();
                    this.swalError('Something unexpected happened', error.message);
                })
        },

        loadNext() {
            this.loading = true;

            setTimeout(() => {
                this.loading = false;
                this.intentAd = {}
            }, 3000);
        },

        loadPrev() {
            this.loading = true;

            setTimeout(() => {
                this.loading = false;
                this.intentAd = {}
            }, 3000);
        },

        pinIntent(pin = 1) {
            this.pin({intent_id: this.intent.id, pin})
                .then(response => {
                    bus.$emit('pin-unpin-intent', response)
                })
                .catch(error => null)
        },

        unPin() {
            this.pinIntent(0);
        },

        setPin({id, pinned}) {
            this.pinned = this.intent.id == id ? true : false;
        },

        getRandomOrder() {
            return Math.floor(Math.random() * 10) + 1;
        },
    },

    mounted() {
        this.pinned = !!this.intent.pinned;

        console.log(this.billboard)

        bus.$on('pin-unpin-intent', this.setPin);
    },

    beforeDestroy() {
        bus.$off('pin-unpin-intent', this.setPin);
    }
}
</script>