<template>
<modal
    :show="show"
    :centered="true"
    :lg="true"
    :showFooter="true"
    :fixedHeight="true"
    okText="Save Changes"
    okIcon="mdi mdi-content-save-all"
    @ok="submit"
    @cancel="cancel">
    <h5 class="m-0 font-small font-weight-bold text-capitalize" slot="header">
        Update Preferences for {{ advert && advert.name }}
    </h5>

    <div slot="body">
        <preferences type="product" v-model="preferences" :sticky="false"/>
    </div>
</modal>
</template>
<script>
import { mapActions } from 'vuex'
import bus from '@/event-bus.js';
import Preferences from '@/views/customer/intents/partials/Preferences.vue';

export default {
    props: ['show', 'advert'],

    components: {
        Preferences
    },

    data() {
        return {
            preferences: []
        }
    },

    watch: {
        advert() {
            this.setPreferences()
        }
    },

    methods: {
        ...mapActions({
            update: 'Campaigns/update'
        }),

        cancel() {
            this.$emit('cancel');
        },

        submit() {
            if (!this.preferences.length) {
                return this.cancel();
            }

            bus.$emit('update-advert', {
                id: this.advert.id,
                data: {
                    stage: 'preferences',
                    preferences: this.preferences
                }
            })

            this.cancel();
        },

        setPreferences() {
            if (this.advert && this.advert.preferences) {
                this.preferences = this.advert.preferences.map(item => item.id);
            }
        }
    },

    mounted() {
        this.setPreferences();
    }
}
</script>