import { axios } from './index';

export default {
    /**
     * Get User notifications
     *
     * @param {Object} params
     * @returns {Object}
     */
    index(params) {
        return axios.get('notifications', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    countUnread(params) {
        return axios.get('notifications/count_unread', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Mark notification as read
     *
     * @param {Object} params
     * @returns {Object}
     */
    markAsRead(id, params) {
        return axios.put(`notifications/${id}`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Delete notifications
     *
     * @param {Object} id
     * @returns {Object}
     */
    delete(id) {
        return axios.delete(`notifications/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    }
}