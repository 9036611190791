<template>
    <span>{{ text }}</span>
</template>

<script>
export default {
    props: ['timestamp'],

    data() {
        return {
            text: ''
        }
    },

    watch: {
        timestamp() {
            this.setTime();
        }
    },

    methods: {
        setTime() {
            this.text = moment(this.timestamp).fromNow();

            setTimeout(this.setTime, 60 * 1000);
        }
    },

    mounted() {
        this.setTime();
    }
}
</script>