<template>
    <div class="radio-group">
        <div class="option elevation rounded mb-2" v-for="(option, index) in options" :key="index" :class="{'active': value.includes(option.id)}" @click="selectOption(option)">
            <div class="icon">
                <i class="mdi mdi-checkbox-blank-outline unchecked"></i>
                <i class="mdi  mdi-checkbox-marked checked"></i>
            </div>
            <div class="content">
                <div class="title mb-0">
                    <span class="name">{{ option.name }}</span>
                </div>
                <div class="description">
                    {{ option.description }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true
        },

        options: {
            type: Array,
            required: true
        },

        value: Array
    },

    methods: {
        selectOption(option) {
            let values = [...this.value];
            let index = values.indexOf(option.id);

            if (index > -1) {
                values.splice(index, 1);
            } else {
                values.push(option.id);
            }

            this.$emit('input', values);
        }
    }
}
</script>