<template>
<modal
    :show="show"
    :centered="true"
    :maxHeight="true"
    :md="true"
    okText="Create"
    @cancel="cancel"
    okIcon="mdi mdi-plus"
    @ok="submit">

    <h5 class="m-0 font-small font-weight-bold" slot="header">Create New Campaign</h5>


    <div class="font-small mt-2" slot="body">
        <field-description
            :items="helpItems"
            v-if="showHelpInfo"
            @cancel="showHelpInfo = false"/>

        <div class="row" v-else>
            <div class="col-12">
                <div class="row no-gutters">
                    <div class="col-6 p-1">
                        <base-select
                            :tooltip="true"
                            @help="showHelp"
                            label="Campaign Objective"
                            name="campaign_objective_id"
                            v-model="payload.campaign_objective_id">
                            <option value="">-- none --</option>
                            <option v-for="(type, index) in campaignObjectives" :key="index" :value="type.id">
                                {{ type.name }}
                            </option>
                        </base-select>
                    </div>

                    <div class="col-6 p-1">
                        <base-select
                            :tooltip="true"
                            @help="showHelp"
                            label="Matching Strategy"
                            name="matching_strategy_id"
                            v-model="payload.matching_strategy_id">
                            <option value="">-- none --</option>
                            <option v-for="(strategy, index) in matchingStrategies" :key="index" :value="strategy.id">
                                {{ strategy.name }}
                            </option>
                        </base-select>
                    </div>
                </div>

                <div class="p-1">
                    <base-input label="Title or name" v-model="payload.name" placeholder="Short title in 7 words or less."/>
                </div>

                <div class="form-group type-4 m-1 mb-3">
                    <label class="font-xs" style="margin-bottom:0 !important;">Description</label>
                    <textarea
                        class="form-control text-input font-small p-2"
                        placeholder="Optional description which will help you identify this ad"
                        rows="4"
                        v-model="payload.description"></textarea>
                </div>
            </div>
        </div>
    </div>

</modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import FieldDescription from '@/components/shared/field-explainer/FieldDescription.vue';

export default {
    props: ['show', 'campaign'],

    components: {
        FieldDescription
    },

    data() {
        return {
            payload: {
                matching_strategy_id: '',
                campaign_objective_id: '',
                description: '',
                name: ''
            },

            campaignObjectives: [],
            matchingStrategies: [],

            showHelpInfo: false,
            helpItems: []
        }
    },

    computed: {
        ...mapGetters({
            intentTypes: 'IntentTypes/getAll',
        })
    },

    watch: {
        show() {
            this.reset()
        },

        campaign() {
            this.setCampaign()
        },
    },

    methods: {
        ...mapActions({
            getMatchingStrategies: 'Campaigns/getMatchingStrategies',
            getObjectives: 'Campaigns/getObjectives',
            store: 'Campaigns/store',
        }),

        showHelp(name) {
            this.helpItems = [];

            if (name == 'campaign_objective_id') {
                this.helpItems = this.campaignObjectives;
            } else if (name == 'matching_strategy_id') {
                this.helpItems = this.matchingStrategies;
            }

            this.showHelpInfo = !! (this.helpItems && this.helpItems.length);
        },

        setCampaign() {
            if (!this.campaign || !this.campaign.advert) return;

            let { advert } = this.campaign;

            this.payload.name = advert.name;
            this.payload.intent_type_id = advert.intent_type_id;
            this.payload.campaign_objective_id = advert.campaign_objective_id;
            this.payload.description = advert.description;
        },

        reset() {
            for (let key of Object.keys(this.payload)) {
                this.payload[key] = '';
            }
        },

        cancel() {
            this.reset();
            this.$emit('cancel');
        },

        submit() {
            if (!this.payload.campaign_objective_id) {
                let types = this.campaignObjectives.map(t => t.name).join(', ');

                return this.swalError('Campaign Type Error', `Every Campaign must have a type. Please select one of these ${types}`);
            }

            if (!this.payload.matching_strategy_id) {
                let strategies = this.matchingStrategies.map(t => t.name).join(', ');

                return this.swalError('Matching Strategy Error', `Please select one of these ${strategies}`);
            }

            if (!this.payload.name) {
                return this.swalError('Name Error', `You must give this campaign a recognisable name or title`);
            }

            this.showLoader('Creating Campaign... Please wait');

            this.store(this.payload)
                .then(response => {
                    this.hideLoader()

                    this.cancel();

                    setTimeout(() => {
                        this.$router.push({name: 'merchant_view_campaign', params: {campaign_id: response.id}});
                    }, 0);
                })
                .catch(error => {
                    this.hideLoader()
                })
        }
    },

    created() {
        if (!this.matchingStrategies.length) {
            this.getMatchingStrategies()
            .then(response => {
                this.matchingStrategies = response;
            })
            .catch(error => null)
        }

        if (!this.campaignObjectives.length) {
            this.getObjectives()
            .then((response) => {
                this.campaignObjectives = response;
                this.setCampaign();
            })
            .catch(error => null)
        }
    },

    mounted() {
        this.setCampaign();
    }
}
</script>