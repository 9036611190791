<template>
<modal
    :show="show"
    :centered="true"
    :showFooter="true"
    :maxHeight="true"
    okText="Share"
    okIcon="mdi mdi-share"
    @ok="submit"
    @cancel="cancel">

    <h5 class="m-0 font-small font-weight-normal text-capitalize" slot="header">Share {{ productTypes }}</h5>

    <div slot="body" class="p-3 pl-5 pr-5 font-small">
        <p class="text-justify">Sharing {{ productTypes }} category with your friends will enable them to review any public advert you add to this group</p>

        <div class="mt-2 mb-5 d-flex flex-wrap justify-content-start">
            <div
                class="font-xs token p-1 m-1 d-inline-flex align-items-center font-small round elevation pr-2 pl-2 bg-default-dark text-primary"
                v-for="(username, index) in selectedUsers" :key="index">
                {{ username }} <a href="#" @click.prevent="removeUser(index)"><i class="mdi mdi-close ml-2 text-danger"></i></a>
            </div>
        </div>

        <div class="search-user">
            <base-input type="text" v-model="keyword" placeholder="Search your friends by username or email"/>
            <div class="mt-2 mb-5 d-flex flex-wrap justify-content-start">
                <a
                    href="#"
                    @click.prevent="addUser(user)"
                    class="font-xs token p-1 m-1 d-inline-flex align-items-center font-small round elevation pr-2 pl-2 bg-default-dark text-primary"
                    v-for="(user, index) in users" :key="index">
                    {{ user.username }}
                </a>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {debounce} from 'lodash';

export default {
    props: ['show', 'intent'],

    data() {
        return {
            keyword: '',
            selectedUsers: [],
            data: []
        }
    },

    computed: {
        productTypes() {
            return this.intent && this.intent.product_types.map(pt => pt.name).join(', ');
        },

        users() {
            return this.data.filter(user => !this.selectedUsers.includes(user.username));
        }
    },

    watch: {
        keyword() {
            this.search();
        }
    },

    methods: {
        ...mapActions({
            searchUsers: 'Users/searchUsers',
            shareIntent: 'Intents/shareIntent'
        }),

        addUser(user) {
            this.selectedUsers.push(user.username);

            for (let [index, entry] of this.users.entries()) {
                if (user.id === entry.id) {
                    this.users.splice(index, 1);
                    return;
                }
            }
        },

        removeUser(index) {
            this.selectedUsers.splice(index, 1);
        },

        search: debounce(function() {
            this.searchUsers({keyword: this.keyword})
                .then(response => this.data = response)
                .catch(error => null)
        }, 200),

        submit() {
            const data = {
                intent_id: this.intent.id,
                users: [...this.selectedUsers]
            };

            this.cancel();

            this.shareIntent(data)
            .then(response => {
                console.log(response);
            }).catch(error => {
                console.log(error);
            });
        },

        cancel() {
            this.selectedUsers = [];
            this.data = [];
            this.keyword = '';
            this.$emit('cancel');
        },
    }
}
</script>