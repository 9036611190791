<template>
    <content-wrapper>
        <div class="row no-gutters">
            <div class="col-xl-7 col-lg-12 col-md-12 p-1">
                <div class="row no-gutters">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 p-1" v-for="(intent, index) in all" :key="index">
                        <intent
                            class="mb-4"
                            :intent="intent"
                            @view="viewIntent"
                            @edit="editIntent"/>
                    </div>
                </div>
            </div>
        </div>

        <scroll-loader :loader-method="loadNextPage" :loader-disable="!showLoadMore"/>

        <add-intent
            :show="showAddIntent"
            :intent="intent"
            @cancel="editIntent(null)"/>

        <view-intent
            :show="showViewIntent"
            :intent="intent"
            @cancel="viewIntent(null)"/>

        <button class="btn floating pl-3 pr-3 font-small" @click.prevent="showAddIntent = true">
            <i class="mdi mdi-plus"></i> <span class="font-14 ml-1">Add Intent</span>
        </button>
    </content-wrapper>
</template>

<script>
import Intent from './partials/Intent';
import AddIntent from './partials/AddIntent';
import ViewIntent from './partials/ViewIntent';
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
        Intent,
        AddIntent,
        ViewIntent
    },

    data() {
        return {
            showAddIntent: false,
            showViewIntent: false,
            intent: null
        }
    },

    computed: {
        ...mapGetters({
            all: 'Intents/getAll',
            pageDetails: 'Intents/getPageDetails'
        })
    },

    methods:{
        ...mapActions({
            index: 'Intents/index',
            show: 'Intents/show'
        }),

        async showIntent(id, params = {}) {
            this.showLoader('Getting intent data... Please wait');

            try {
                let intent = await this.show({id, params});
                this.hideLoader();
                return intent;
            } catch (error) {
                this.hideLoader();

                return null;
            }
        },

        async editIntent(intent) {
            if (intent) {
                intent = await this.showIntent(intent.id);
            }

            this.intent = intent;
            this.showAddIntent = !!intent;
        },

        async viewIntent(intent) {
            if (intent) {
                intent = await this.showIntent(intent.id, {with_attributes: 1});
            }

            this.intent = intent;
            this.showViewIntent = !!intent;
        },

        loadNextPage() {
            if (this.showLoadMore) {
                this.loadData(this.loadMore);
            }
        },

        loadData(query) {
            let params = {
                paginate: true,
                per_page: 20,
                ...query,
            }

            if (query && query.clearState) {
                this.showLoader('Loading your intents... Please wait');
            }

            this.index(params)
                .then(response => {
                    this.hideLoader();
                })
                .catch(error => {
                    this.hideLoader();
                })
        }
    },

    created() {
        this.loadData({clearState: true});
    }
}
</script>