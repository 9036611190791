<template>
    <div class="nav-description" :class="{'guest': isGuest}">
        <button class="btn how-it-works d-flex align-items-center mr-2 elevation-2" @click="howItWorks('why')">
            <span class="description d-flex flex-column text-left">
                <span class="text-white font-weight-bold title">Why Woonmi?</span>
            </span>

            <span class="icon">
                <i class="mdi mdi-eye"></i>
            </span>
        </button>

        <button class="btn how-it-works d-flex align-items-center mr-2 elevation-2" @click="howItWorks('how')">
            <span class="description d-flex flex-column text-left">
                <span class="text-white font-weight-bold title">How It Works</span>
                <span class="desc font-xs text-white">In 4 simple steps</span>
            </span>

            <span class="icon">
                <i class="mdi mdi-eye"></i>
            </span>
        </button>
    </div>
</template>
<script>
import bus from '@/event-bus';

export default {
    name: 'NavDescription',
    props: ['isGuest'],

    methods: {
        howItWorks(type) {
            bus.$emit('how-it-works', type);
        }
    }
}
</script>