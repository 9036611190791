<template>
    <content-wrapper no_padding>
        <page-title
            title="Friends Hold List"
            desc="Review products people have shared with you. They need your opinion">
        </page-title>
        <hold-list type="friends"/>
    </content-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import HoldList from './../hold-list/Index.vue';

export default {
    components: {
        HoldList,
    },

    data() {
        return {
            //
        }
    },
}
</script>