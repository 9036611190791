<template>
    <modal
        :show="show"
        :md="true"
        okText="Save Changes"
        :centered="true"
        :showFooter="false"
        :showHeader="true"
        :showCancel="false"
        @cancel="$emit('cancel')">
        <h4 class="modal-title font-small" slot="header">Complete Account Setup</h4>
        <form @submit.prevent="submit" class="p-2" slot="body">

            <div class="d-flex justify-content-center align-items-center mb-3" v-if="authUser.image_url">
                    <img :src="authUser.image_url" class="bg-secondary circle" style="object-fit:cover;width:100px;height:100px;"/>
                </div>


            <div class="row no-gutters">
                <div class="col-md-6 col-12 pr-md-1">
                    <base-select-wrapper label="Country" icon="mdi mdi-flag" :error="validationErrors.country_id">
                        <select class="form-control font-small" v-model="payload.country_id">
                            <option value="">None</option>
                            <option v-for="(country, index) in countries" :key="index" :value="country.id">
                                {{ country.name }}
                            </option>
                        </select>
                    </base-select-wrapper>
                </div>

                <div class="col-md-6 col-12 pl-md-1">
                    <base-input
                        label="Phone"
                        type="number"
                        required
                        icon="mdi mdi-account-circle"
                        v-model="payload.phone"
                        :error="validationErrors.phone"
                        placeholder=".e.g 23435243159"/>
                </div>
            </div>

            <button
                type="submit"
                class="btn btn-success font-small pb-3 pt-3 btn-block mt-2 mb-2 elevation-2">
                SAVE CHANGES
            </button>

            <div class="text-center mt-3 mb-3">
                <a href="#" class="text-secondary font-small" @click.prevent="$emit('logout')">Logout?</a>
            </div>
        </form>
    </modal>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    props: ['show'],

    data() {
        return {
            payload: {
                country_id: '',
                phone: '',
            },

            validationErrors: {
                country_id: '',
                phone: ''
            }
        }
    },

    computed: {
        ...mapGetters({
            countries: 'Countries/getAll'
        }),
    },

    methods: {
        ...mapActions({
            store: 'Authentications/completeSetup'
        }),

        submit() {
            this.buildValidationErrors({});

            if (!this.payload.country_id) {
                this.validationErrors.country_id = `Country is required`;
            }

            if (!this.payload.phone) {
                this.validationErrors.phone = `Phone is required`;
            }

            if (Object.values(this.validationErrors).filter(value => value.trim()).length) {
                return this.swalError(`Incorrect data`, `Both your country and phone are required. Please provide valid values`);
            }

            this.showLoader('Completing setup... Please wait')

            this.store(this.payload)
                .then(response => {
                    this.hideLoader();

                    this.$emit('done')
                })
                .catch(error => {
                    this.hideLoader();

                    if (error.message) {
                        this.swalError(`Attention!`, error.message);
                    }

                    this.buildValidationErrors(error.errors);
                })
        }
    },

    mounted() {
        if (this.authUser.country_id) {
            this.payload.country_id = this.authUser.country_id;
        }

        if (this.authUser.phone) {
            this.payload.phone = this.authUser.phone;
        }
    }
}
</script>