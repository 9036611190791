<template>
    <div class="d-flex justify-content-center flex-column mt-5">
        <router-link
            v-for="(link, index) in links"
            :key="index"
            :to="{name: link.name}"
            :href="link.href"
            class="font-big font-weight-bolder mb-4 text-blue  cursor-pointer">
            {{ link.title }} <br/>

            <span class="text-muted font-small font-weight-lighter">
                {{ link.text }}
            </span>

            <i class="mdi mdi-arrow-right ml-2 text-danger float-right"></i>
        </router-link>
    </div>
</template>

<script>
export default {
    props: ['links']
}
</script>