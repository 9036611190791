<template>
    <div class="p-2 bg-white elevation rounded">
        <div class="bg-random1 p-2 rounded mb-2">
            <h4 class="font-small font-weight-bold text-blue">Days</h4>
            <p class="m-0">Product can be returned only within <b>{{ policy.days }} days</b></p>
        </div>

        <div class="bg-random2 p-2 rounded mb-2">
            <h4 class="font-small font-weight-bold text-blue">Return Policy</h4>
            <p class="m-0 text-capitalize">{{ policy.return_type.replace(/_/, ' ') }}</p>
        </div>

        <div class="bg-random3 p-2 rounded mb-2">
            <h4 class="font-small font-weight-bold text-blue">Shipping Policy</h4>
            <p class="m-0 text-capitalize">{{ policy.return_shipping.replace(/_/, ' ') }}</p>
        </div>
    </div>
</template>
<script>
export default {
    props: ['advert'],

    computed: {
        policy() {
            return this.advert && this.advert.return_policy;
        }
    }
}
</script>