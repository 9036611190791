import Service from '@/services/interests';

const state = {
    all: []
};

const getters = {
    getAll(state) {
        return state.all;
    }
};

const mutations = {
    SET_INTERESTS(state, payload) {
        state.all = payload.data ? payload.data : payload;
    }
};

const actions = {
    index({ commit }, params) {
        return Service.index(params)
            .then(response => {
                commit('SET_INTERESTS', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    getTypes({ commit }, params) {
        return Service.getTypes(params)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    store({ commit }, data) {
        return Service.store(data)
            .then(response => {
                commit(`Authentications/SET_USER`, response, { root: true });

                return Promise.resolve(response)
            })
            .catch(error => Promise.reject(error));
    },

    saveSnoozeSettings({ commit }, data) {
        return Service.saveSnoozeSettings(data)
            .then(response => {
                commit(`Authentications/SET_USER`, response, { root: true });

                return Promise.resolve(response)
            })
            .catch(error => Promise.reject(error));
    },
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
}