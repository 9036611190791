import router from '@/router';
import bus from './../../event-bus';
import Service from '@/services/authentications';

const tokenName = 'woonmi_token';
const userName = 'woonmi_user';

/**
 * Method to convert string values into their real types
 *
 * @param {String} value
 * @returns {Any}
 */
const makeRealType = (value) => {
    switch (value) {
        case 'undefined':
            return undefined;
            break;

        case 'true':
            return true;
            break;

        case 'false':
            return false;
            break;

        case 'null':
            return null;
            break;

        default:
            return value;
    }
}

const state = {
    auth: {
        isLoggedIn: !!localStorage.getItem(tokenName),
        user: makeRealType(localStorage.getItem(userName)) ? JSON.parse(localStorage.getItem(userName)) : {},
        token: makeRealType(localStorage.getItem(tokenName)) ? localStorage.getItem(tokenName) : null,
    },

    two_factor_value: null
};

const getters = {
    get2FAStatus(state) {
        return state.two_factor_value;
    },

    /**
     * Checks if user is logged on to the system
     *
     * @param {Object} state
     * @returns {Boolean}
     */
    isLoggedIn(state) {
        return state.auth.isLoggedIn && !!state.auth.token;
    },


    /**
     * Gets the currently authenticated users data
     *
     * @param {Object} state
     * @returns {Object}
     */
    getUser(state) {
        return state.auth.user;
    },

    /**
     * Gets the authenticated token for user
     *
     * @param {Object} state
     * @returns {String}
     */
    getToken(state) {
        return state.auth.token;
    }
};

const mutations = {
    /**
     * Sets the authenticated user details
     *
     * @param {Object} state
     * @param {Object} payload
     * @returns {Undefined}
     */
    ['SET_AUTH_USER'](state, payload) {
        if (!payload) {
            state.auth.token = null;
            state.auth.isLoggedIn = false;
            state.auth.user = null;

            localStorage.removeItem(tokenName);
            localStorage.removeItem(userName);
            return;
        }

        state.auth.token = payload.access_token;
        state.auth.isLoggedIn = !!payload.access_token;
        state.auth.user = { ...payload.user };

        localStorage.setItem(tokenName, state.auth.token);
        localStorage.setItem(userName, JSON.stringify(state.auth.user, true));

        bus.$emit('user-logged-in', {});
    },

    /**
     * Sets user object
     *
     * @param {Object} state
     * @param {Object} payload
     * @returns {Undefined}
     */
    ['SET_USER'](state, payload) {
        state.auth.user = payload;
        localStorage.setItem(userName, JSON.stringify(payload));
    },

    SET_2FA_STATUS(state, payload) {
        state.two_factor_value = payload.value;
    }
};

const actions = {
    getBuyerTypes({commit}, params) {
        return Service.getBuyerTypes(params)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    validateRegistrationDetails({commit}, data) {
        return Service.validateRegistrationDetails(data)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    /**
     * Logs the user in
     *
     * @param {Object} context.commit
     * @param {Object} credentials
     */
    login({ commit }, credentials) {
        return Service.login(credentials)
            .then(response => {
                commit('SET_AUTH_USER', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error))
    },

    /**
     * Register a user
     *
     * @param {Object} context.commit
     * @param {Object} data
     */
    register({ commit }, data) {
        return Service.register(data)
            .then(response => {
                commit('SET_AUTH_USER', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error))
    },

    /**
     * Sends email for password reset
     *
     * @param {Object} context.commit
     * @param {Object} data
     */
    forgotPassword({ commit }, data) {
        return Service.forgotPassword(data)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    /**
     * Verifies the validity of user password
     *
     * @param {Object} context.commit
     * @param {Object} data
     */
    verifyPassword({ commit }, data) {
        return Service.verifyPassword(data)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    verifyAuthentication({ commit }, data) {
        return Service.verifyAuthentication(data)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    send2FAToken({ commit }, data) {
        return Service.send2FAToken(data)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    toggle2FA({ commit }, data) {
        return Service.toggle2FA(data)
            .then(response => {
                commit('SET_2FA_STATUS', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error))
    },

    check2FA({ commit }, data) {
        return Service.check2FA(data)
            .then(response => {
                commit('SET_2FA_STATUS', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error))
    },

    /**
     * Saves new user passwords
     *
     * @param {Object} context.commit
     * @param {Object} data
     */
    resetPassword({ commit }, data) {
        return Service.resetPassword(data)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    /**
     * Verify User phone number
     *
     * @param {Object} param
     * @param {Object} data
     * @returns {Object}
     */
    verifyPhone({ commit }, data) {
        return Service.verifyPhone(data)
            .then(response => {
                commit('SET_USER', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    /**
     * Resends verification code to user
     *
     * @param {Object} context.commit
     * @param {Object} data
     * @returns {Object}
     */
    resendEmailVerificationLink({ commit }, data) {
        return Service.resendEmailVerificationLink(data)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    /**
     * Updates user password
     *
     * @param {Object} context.commit
     * @param {Object} credentials
     * @returns {Object}
     */
    updatePassword({ commit }, credentials) {
        return Service.updatePassword(credentials)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    /**
     * Verifies email token
     *
     * @param {Object} context.commit
     * @param {Object} data
     * @returns {Object}
     */
    verifyEmailToken({ commit }, data) {
        return Service.verifyEmailToken(data)
            .then(response => {
                commit('SET_AUTH_USER', response.login);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    /**
     * Gets authenticated user
     *
     * @param {Object} param0
     */
    getAuth({ commit }) {
        return Service.getAuth()
            .then(response => {
                commit('SET_USER', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    /**
     * Clears the browser section off of user data
     *
     * @param {Object} context.commit
     * @returns {Undefined}
     */
    localLogout({ commit }) {
        commit('SET_AUTH_USER', null);
        router.replace('/');
    },

    /**
     * Logs user out of server and browser
     *
     * @param {Object} context.commit
     * @returns {Object}
     */
    logout({ commit, dispatch }) {
        return Service.logout()
            .then(response => {
                dispatch('Authentications/localLogout', null, {root: true});

                return Promise.resolve(response);
            })
            .catch(error => {
                dispatch('Authentications/localLogout', null, { root: true });

                return Promise.reject(error);
            });
    },

    socialLoginGetRedirectUrl({ commit }, provider) {
        return Service.socialLoginGetRedirectUrl(provider)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    socialLoginHandleCallback({ commit }, data) {
        return Service.socialLoginHandleCallback(data.provider, data.params)
            .then(response => {
                if (response.login.access_token && response.login.user) {
                    commit('SET_AUTH_USER', response.login);
                }

                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error))
    },

    completeSetup({ commit }, data) {
        return Service.completeSetup(data)
            .then(response => {
                commit('SET_USER', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },
};

const namespaced = true;

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced
}