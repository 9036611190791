<template>
    <div class="sideshow">
        <video v-if="isVideo" :src="currentMedia.file_url" />

        <div class="slide-images" :id="`slideshow-ad-${this.advert.id}`">
            <img
                v-for="(media, index) in medias"
                :key="index"
                :src="media.file_url"
                :id="`media-${media.id}`"
                :class="index > 0 ? 'd-none' : ''"/>
        </div>

        <template v-if="currentMedia">
            <div class="advert-media-caption template-1"
                :style="{ background: currentMedia.background_color, color: currentMedia.text_color }"
                v-if="currentMedia.caption_template === 'template-1'">
                <div class="fsize text-center" v-html="currentMedia.caption"></div>
            </div>

            <div class="advert-media-caption template-2"
                :style="{ 'text-shadow': `-1px 0 ${currentMedia.background_color}, 0 1px ${currentMedia.background_color}, 1px 0 ${currentMedia.background_color}, 0 -1px ${currentMedia.background_color}`, color: currentMedia.text_color }"
                v-if="currentMedia.caption_template === 'template-2'">
                <div class="fsize" v-html="currentMedia.caption"></div>
            </div>

            <div class="advert-media-caption template-3"
                :style="{ border: `5px solid ${currentMedia.background_color}`, color: currentMedia.text_color }"
                v-if="currentMedia.caption_template === 'template-3'">
                <div class="text fsize"
                    :style="{ color: currentMedia.text_color, background: currentMedia.background_color }"
                    v-html="currentMedia.caption"></div>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    props: ['advert'],

    data() {
        return {
            currentIndex: -1
        }
    },

    computed: {
        medias() {
            return (this.advert && this.advert.advert_media) || [];
        },

        currentMedia() {
            const media = this.medias[this.currentIndex];

            if (media) {
                media.text_color = media.text_color || '#dc1c67';
                media.background_color = media.background_color || '#1C0E6F';
            }

            return media;
        },

        isVideo() {
            if (!this.currentMedia) return false;

            return this.currentMedia.mime_type.startsWith('video')
        },
    },

    methods: {
        showSlide(slides) {
            if (slides.length === 1) {
                this.currentIndex = 0;
                return;
            };

            for (let index = 0; index <= slides.length; index++) {
                if (!slides[index]) continue;

                const slide = $(slides[index]);

                if (!slide.hasClass('d-none')) {
                    this.currentIndex = index;
                    break;
                }
            }

            this.currentIndex++;

            if (this.currentIndex >= slides.length) {
                this.currentIndex = 0;
            }

            for (let index = 0; index <= slides.length; index++) {
                if (!slides[index]) continue;

                const slide = $(slides[index]);

                if (index === this.currentIndex) {
                    slide.removeClass('d-none');
                } else {
                    slide.addClass('d-none');
                }
            }

            let interval = Math.random() * 3000 + 5000;

            setTimeout(() => {
                this.showSlide(slides);
            }, interval);
        }
    },

    mounted() {
        const slides = $(`.slide-images#slideshow-ad-${this.advert.id} img`);

        this.showSlide(slides);
    }
}
</script>