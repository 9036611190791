<template>
    <app-card class="">
        <h5 class="text-tertiary font-weight-bold font-small mb-1">Call To Action</h5>
        <p class="mb-4 text-muted">
            Specify what will happen when customers click on the action button
        </p>

        <div class="row">
            <div class="col-md-6">
                <base-select-wrapper label="Action Type" tooltip>
                    <select class="form-control font-small" v-model="payload.type_id">
                        <option v-for="(item,index) in callToActionTypes" :key="index" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>
                </base-select-wrapper>
            </div>
            <div class="col-md-6" v-if="showButtonLabel">
                <base-input
                    label="Button Label"
                    v-model="payload.name"
                    placeholder="e.g Come Join Us"/>
            </div>
        </div>

        <div class="row" v-if="showUrlField">
            <div class="col-md-12">
                <base-input
                    label="Destination URL"
                    v-model="payload.external_link"
                    placeholder="e.g www.woonmi.com"/>
            </div>
        </div>

        <div class="" v-if="showFields">
            <p class="mb-1 ml-1">What information should be collected?</p>
            <label
                class="font-small cursor-pointer bg-default rounded p-2 font-weight-bold m-1"
                v-for="(field, index) in formFields"  :key="index">
                <input name="form_fields" type="checkbox" v-model="payload.form_fields" :value="field"/> {{ field }}
            </label>
        </div>

    </app-card>
</template>
<script>
import  bus from '@/event-bus';
import {debounce} from 'lodash';
import { mapActions, mapGetters } from 'vuex'

export default {
    props: ['advert'],

    data() {
        return {
            payload: {
                type_id: 1,
                name: 'Buy Now',
                external_link: '',
                form_fields: []
            }
        }
    },

    computed: {
        ...mapGetters({
            callToActionTypes: 'Adverts/getCallToActionTypes'
        }),

        formFields() {
            return [`Name`, 'Email', 'Phone']
        },

        actionType() {
            return this.callToActionTypes.find(item => item.id == this.payload.type_id);
        },

        showButtonLabel() {
            return this.actionType &&  [`Buy Now`, `External Link`].includes(this.actionType.name);
        },

        showUrlField() {
            return this.actionType && this.actionType.name == 'External Link';
        },

        showFields() {
            return this.actionType && this.actionType.name == 'Instant Form';
        },
    },

    watch: {
        actionType() {
            if (this.showUrlField) {
                return this.payload.name = `Learn More`
            } else {
                return this.payload.name  = this.actionType.name;
            }
        },

        payload: {
            immediate: true,
            deep: true,
            handler() {
                this.submit();
            }
        }
    },

    methods: {
        ...mapActions({
            getCallToActionTypes: 'Adverts/getCallToActionTypes'
        }),

        submit: debounce(function () {
            bus.$emit('update-advert', {
                id: this.advert.id,
                data: {
                    stage: 'call_to_action',
                    ...this.payload
                }
            });
        }, 2000),

        populate() {
            if (!this.advert || !this.advert.call_to_action) return;

            const call_to_action = this.advert.call_to_action;

            // console.log(call_to_action)

            this.payload.type_id = call_to_action.type_id;
            this.payload.name = call_to_action.name;
            this.payload.external_link = call_to_action.external_link;
            this.payload.form_fields = call_to_action.form_fields || [];
        }
    },

    created() {
        if (!this.callToActionTypes.length) {
            this.getCallToActionTypes().catch(error => null);
        }

        this.populate();
    }
}
</script>