<template>
    <div class="">
        <modal
            :show="show"
            :centered="true"
            :maxHeight="true"
            @cancel="cancel"
            @ok="submit"
            okText="Submit">
            <h5 class="m-0 font-small font-weight-bold text-capitalize" slot="header">{{title}}</h5>

            <div slot="body" class="p-3 font-small">
                <base-input label="Title" placeholder="short summary" v-model="payload.title"/>
                <base-textarea label="Comment" v-model="payload.comment" placeholder="leave a comment"/>

                <label for="attachment" class="btn btn-default">
                    <i class="mdi mdi-paperclip"></i> Attachment: <span class="text-primary">{{payload.image && payload.image.name}}</span>
                </label>
                <a href="#" @click.prevent="removeAttachment" class="text-danger ml-3" v-if="payload.image"><i class="fa fa-trash-o"></i></a>

                <input type="file" class="d-none" id="attachment" @change="addAttachment" accept="image/*"/>
            </div>
        </modal>

        <button class="btn btn-success testing-comment elevation-2 avatar-xs font-small" @click.prevent="show = true">
            <span class="">
                <i class="mdi mdi-help"></i>
            </span>
        </button>
    </div>
</template>
<script>
import bus from '@/event-bus';
import { mapActions } from 'vuex';

export default {
    components: {
    },

    data() {
        return {
            show: false,
            payload: {
                image: '',
                title: '',
                comment: '',
            }
        }
    },

    computed: {
        title() {
            return `What is wrong with this page?`
        }
    },

    methods: {
        ...mapActions({
            createIssue: 'Tickets/sendPageComments'
        }),

        addAttachment(e) {
            this.payload.image = e.target.files.length ? e.target.files[0] : '';
        },

        removeAttachment() {
            this.payload.image = '';
        },

        submit() {
            if (!this.payload.title) {
                return this.swalError('Title is required');
            }

            const data = new FormData();
            data.append('extension', this.payload.image ? this.payload.image.name.split('.').pop() : '');
            data.append('mime_type', this.payload.image ? this.payload.image.type : '');
            data.append('title', this.payload.title);
            data.append('comment', this.payload.comment);
            data.append('page_link', window.location.href);
            data.append('user_id', (this.authUser && this.authUser.id) || '')

            this.show = false;

            this.createIssue(data)
                .then(response => {
                    this.uploadFile(response.url);
                })
                .catch(error => {
                    if (error.message) {
                        this.swalError(error.message);
                    }
                });
        },

        async uploadFile(url) {
            if (url) {
                await this.uploadPresignedFile(this.payload.image, url);
            }
            this.cancel();
        },

        init() {
            this.show = true;
        },

        cancel() {
            this.payload.title = ''
            this.payload.comment = ''
            this.payload.image = ''
            this.show = false;
        }
    },

    created() {
        bus.$on('testing-message', this.init)
    },

    beforeDestroy() {
        bus.$off('testing-message', this.init)
    }
}
</script>