<template>
    <div class="p-3 pt-4">
        <div class="row justify-content-between">
            <div class="col-md-8 mb-3">
                <button
                    @click.prevent="chartType = 'line'"
                    :class="{'active': chartType == 'line'}"
                    class="status-btn mb-1 font-xs cadet-blue"
                    type="button">
                    <i class="mdi mdi-chart-line"></i> Line
                </button>

                <button
                    @click.prevent="chartType = 'bar'"
                    class="status-btn mb-1 font-xs cadet-blue"
                    :class="{'active': chartType == 'bar'}"
                    type="button">
                    <i class="mdi mdi-chart-bar"></i> Bar
                </button>

                <button
                    @click.prevent="chartType = 'radar'"
                    class="status-btn mb-1 font-xs cadet-blue"
                    :class="{'active': chartType == 'radar'}"
                    type="button">
                    <i class="mdi mdi-star-circle"></i> Radar
                </button>
            </div>

            <div class="col-md-4">
                <date-range-picker
                    label="Period"
                    placeholder="Period"
                    name="period"
                    range="Last 7 Days"
                    v-model="dateRange"/>
            </div>
        </div>

        <google-loader v-if="loading"/>

        <div>
            <canvas :id="chartId" style="max-height:500px;"></canvas>
        </div>

    </div>
</template>
<script>
import Chart from '@/chart.js';
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['campaign'],

    data() {
        return {
            loading: false,
            report: null,

            dateRange: '',
            chartType: 'line'
        }
    },

    computed: {
        ...mapGetters({
            reports: 'Reports/getEngagements'
        }),

        chartId() {
            return `ces-${Math.random().toString(16).split('.').pop()}`;
        },

        dateRangeText() {
            let format = 'Do MMM, YYYY';

            let [start, end] = this.dateRange.split('to');

            return `${moment(start).format(format)} to ${moment(end).format(format)}`
        },

        colors() {
            return {
                views: {
                    line: { bg: 'rgba(255, 76, 76, 0.25)', border: '#ff4c4c' },
                    bar: { bg: 'rgba(255, 76, 76, 0.3)', border: '#ff4c4c' },
                    radar: { bg: 'rgba(255, 76, 76, 0.25)', border: '#ff4c4c' },
                },
                impressions:{
                    line: { bg: 'rgba(0, 174, 255, 0.25)', border: '#00aeff'},
                    bar: { bg: 'rgba(0, 174, 255, 0.3)', border: '#00aeff'},
                    radar: { bg: 'rgba(0, 174, 255, 0.25)', border: '#00aeff'},
                },
                clicks: {
                    line: { bg: 'rgba(75, 192, 0, 0.25)', border: 'rgba(75, 192, 0, 0.7)'},
                    bar: { bg: 'rgba(75, 192, 0, 0.3)', border: 'rgba(75, 192, 0, 0.7)'},
                    radar: { bg: 'rgba(75, 192, 0, 0.25)', border: 'rgba(75, 192, 0, 0.7)'},
                }
            }
        }
    },

    watch: {
        report: {
            deep: true,
            immediate: true,
            handler() {
                if (!this.report) return;

                this.drawChart();
            }
        },

        chartType() {
            this.drawChart();
        },

        dateRange() {
            this.getReport();
        }
    },

    methods: {
        ...mapActions({
            getEngagementReports: 'Reports/getEngagementReports'
        }),

        drawChart() {
            this.$nextTick().then(() => {
                const config = {
                    tension: 0.5,
                    fill: true,
                    borderWidth: 2
                };

                if (this.chart) {
                    this.chart.destroy();
                }

                this.chart = new Chart($(`#${this.chartId}`), {
                    type: this.chartType,
                    data: {
                        labels: Object.keys(this.report.labels),

                        datasets: [
                            {
                                label: 'Impressions',
                                data: this.report.datasets.impressions,
                                borderColor: this.colors.impressions[this.chartType].border,
                                backgroundColor: this.colors.impressions[this.chartType].bg,
                                ...config
                            },
                            {
                                label: 'Views',
                                data: this.report.datasets.views,
                                borderColor: this.colors.views[this.chartType].border,
                                backgroundColor: this.colors.views[this.chartType].bg,
                                ...config
                            },
                            {
                                label: 'Clicks',
                                data: this.report.datasets.clicks,
                                borderColor: this.colors.clicks[this.chartType].border,
                                backgroundColor: this.colors.clicks[this.chartType].bg,
                                ...config
                            },
                        ]
                    },

                    options: {
                        plugins: {
                            title: {
                                display: true,
                                text: `Engagements for ${this.dateRangeText}`
                            },
                        },
                    }
                });
            })
        },

        getReport() {
            this.report = this.reports.find(item => item.campaign_id == this.campaign.id);

            if (!this.report) {
                this.loading = true;
            }

            if (!this.dateRange) return;

            this.getEngagementReports({
                campaign_id: this.campaign.id,
                date_range: this.dateRange
            })
            .then(response => {
                this.report = response;
                // console.log(response);
                this.loading = false;
            })
            .catch(error => {
                this.loading = false;
            })
        }
    },

    mounted() {
        this.getReport();
    }
}
</script>