<template>
    <content-wrapper>
        <div class="user m-1 mb-2 mb-md-5">
            <!-- <h1 class="text-shadow text-primary">Hi, {{ authUser.username }}</h1> -->

            <p class="">Check out some merchant promotional messages that might interest you</p>
        </div>

        <div class="d-flex" style="overflow-x:auto">
            <div class="col-md-3 col-6" v-for="(card, index) in infoCards" :key="index">
                <info-card :item="card"/>
            </div>
        </div>

        <div class="mt-5 mb-4 separator"></div>

        <h4 class="mb-3">My Active Shopping Channels</h4>
        <intents/>

        <div class="mt-5 mb-4 separator"></div>

        <h4>My Lists</h4>
        <div class="row no-gutters mt-3 mt-md-3">
            <div class="col-md-6 col-12 p-1 v-stretch">
                <media-card :item="myHoldList" class="v-stretch"/>
            </div>
            <div class="col-md-6 col-12 p-1 v-stretch">
                <media-card :item="friendsHoldList" type="friends" class="v-stretch"/>
            </div>
        </div>

        <disclaimer/>
    </content-wrapper>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import HoldList from './../hold-list/Index.vue';
import MediaCard from './partials/MediaCard.vue';
import Intents from '../intents/Listing.vue'
import InfoCard from './partials/InfoCard.vue';
import PushManager from '@/push-manager';
import Disclaimer from '@/views/common/Disclaimer.vue';

export default {
    components: {
        Intents,
        HoldList,
        MediaCard,
        InfoCard,
        Disclaimer
    },

    data() {
        return {
            keyword: '',
            interest_id: '',

            selectedUpdate: null
        }
    },

    computed: {
        ...mapGetters({
            dashboard: 'Dashboards/getCustomerSummary'
        }),

        myHoldList() {
            return {
                title: 'My Hold Lists',
                empty: 'You have no items on hold',
                description: `Offers I like`,
                theme: 'accent',
                icon: 'mdi mdi-playlist-plus',
                posting: this.dashboard.hold_list
            };
        },

        friendsHoldList() {
            return {
                title: 'Some Friends Want Your Opinions',
                empty: 'No friends have items on hold',
                description: `They want your comments`,
                theme: 'secondary',
                icon: 'mdi mdi-account-multiple-outline',
                posting: this.dashboard.friends_hold_list
            };
        },

        infoCards() {
            return [
                {title: 'Personal Development', icon: 'personal-development', value: Math.floor(Math.random() * 100)},
                {title: 'Automobiles', icon: 'cars-automobiles', value: Math.floor(Math.random() * 100)},
                {title: 'Technology', icon: 'technology', value: Math.floor(Math.random() * 100)},
                {title: 'Arts & Creative', icon: 'arts-creative', value: Math.floor(Math.random() * 100)},
                {title: 'Personal Development', icon: 'personal-development', value: Math.floor(Math.random() * 100)},
                {title: 'Automobiles', icon: 'cars-automobiles', value: Math.floor(Math.random() * 100)},
                {title: 'Technology', icon: 'technology', value: Math.floor(Math.random() * 100)},
            ]
        }
    },

    methods: {
        ...mapActions({
            getCustomerSummary: 'Dashboards/getCustomerSummary'
        }),

        async getPushPermission() {
            if (!PushManager) {
                return;
            }

            let state = await PushManager.getPermissionStatus();

            if (state == 'prompt') {
                let result = await this.swalConfirm(
                    `Turn On Notifications`,
                    `For better experience, you need to turn on notifications to receive timely information regarding your channels. If you click "Turn On" button, a popup will appear please click to allow Woonmi to send you notifications. If you click "Maybe Later", this popup will show anytime you login until you allow or deny.`, `Turn On Now`, `Maybe Later`
                );
                if (result.value) {
                    this.showLoader('Allowing subscription to notifications...');
                    await PushManager.subscribe()
                        .catch(error => {
                            this.hideLoader();
                            this.swalError(
                                `Sorry, Notification Denied`,
                                `The notification permission was not granted and blocked instead. To allow it, you will need to do so from your browser settings.`,
                                false
                            );
                        });
                    this.hideLoader();
                }
            }
        }
    },

    created() {
        this.getCustomerSummary()
        // .then(response => console.log(response))
        .catch(error => null);

        setTimeout(() => {
            navigator.serviceWorker.getRegistration()
                .then(serviceWorkerRegistration => {
                    if (serviceWorkerRegistration) {
                        this.getPushPermission();
                    }
                })
                .catch(error => null);
        }, 2000);
    }
}
</script>