import { axios } from './index';

export default {
    getBuyerTypes(params) {
        return axios.get('buyer_types', {params})
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    validateRegistrationDetails(data) {
        return axios.post('register/validate', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    /**
     * Attempts to authenticate the user
     *
     * @param {Object} credentials
     * @returns {Object}
     */
    login(credentials) {
        return axios.post('login', credentials)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Register User
     *
     * @param {Object} data
     * @returns {Object}
     */
    register(data) {
        return axios.post('register', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Verifies user phone
     *
     * @param {Object} data
     * @returns {Object}
     */
    verifyPhone(data) {
        return axios.post('verifications/phone', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Resends user phone verification code
     *
     * @param {Object} data
     * @returns {Object}
     */
    resendEmailVerificationLink(data) {
        return axios.post('verifications/emails/resend', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Changes user password
     *
     * @param {Object} credentials
     * @returns {Object}
     */
    updatePassword(credentials) {
        return axios.post('passwords/update', credentials)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    /**
     * Verifieds email token's validity
     *
     * @param {Object} data
     * @returns {Object}
     */
    verifyEmailToken(data) {
        return axios.post('verifications/emails', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    /**
     * Gets authenticated user
     *
     * @returns {Object}
     */
    getAuth() {
        return axios.get('/auth')
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Logs the user out of the system
     *
     * @returns {Object}
     */
    logout() {
        return axios.get('logout')
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Sends password reset token to user email
     *
     * @param {Object} data
     * @returns {Object}
     */
    forgotPassword(data) {
        return axios.post('passwords/forgot', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },


    /**
     * Verifies the validity of users password
     *
     * @param {Object} data
     * @returns {Object}
     */
    verifyPassword(data) {
        return axios.post('passwords/verify', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Saves new passwords for user
     *
     * @param {Object} data
     * @returns {Object}
     */
    resetPassword(data) {
        return axios.post('passwords/reset', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    verifyAuthentication(data) {
        return axios.post('passwords/verify_authentication', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    send2FAToken(data) {
        return axios.post(`2fa/send`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    toggle2FA(data) {
        return axios.post(`2fa/toggle`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    check2FA(data) {
        return axios.post(`2fa/check`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    socialLoginGetRedirectUrl(provider) {
        return axios.get(`authorize/${provider}/redirect`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    socialLoginHandleCallback(provider, params) {
        return axios.get(`authorize/${provider}/callback`, { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    completeSetup(data) {
        return axios.post(`/accounts/complete-setup`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },
}