<template>
    <div>
        <app-card class="text-center p-5">
            <span class="mdi mdi-lock-outline text-success" v-if="isEnabled" style="font-size:50px;"></span>
            <span class="mdi mdi-lock-open-outline text-muted" v-else style="font-size:50px;"></span>

            <h5 class="mb-4 mt-4">
                Two factor authentication is {{ isEnabled ? `enabled.` : `disabled.` }}
            </h5>

            <p class="mb-5 text-muted">
                Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to log in. Whenever you login, an authentication code will be sent to your email at <span class="text-secondary">{{ authUser.email }}</span> which you will use to login
            </p>

            <button
                @click.prevent="skip"
                type="button"
                class="btn btn-default-dark font-small mr-3"
                v-if="skipable">
                <i class="mdi mdi-skip-next"></i>
                Skip for now
            </button>

            <button
                @click.prevent="showVerifyPassword = true"
                type="button"
                class="btn btn-danger font-small"
                v-if="isEnabled">
                <i class="mdi mdi-lock-open-outline"></i>
                Disable two-factor authentication
            </button>

            <button
                @click.prevent="showVerifyPassword = true"
                type="button"
                class="btn btn-success font-small"
                v-if="!isEnabled">
                <i class="mdi mdi-lock-outline"></i>
                Enable two-factor authentication
            </button>
        </app-card>

        <verify-password
            :show="showVerifyPassword"
            @cancel="showVerifyPassword = false"
            @success="userVerified"/>

        <verify-code
            :show="showVerify2FA"
            :isSetup="true"
            @success="toggle2FA"
            @cancel="showVerify2FA = false"/>
    </div>
</template>
<script>
import VerifyPassword from '@/views/common/authentication/partials/VerifyPassword.vue';
import VerifyCode from '@/views/common/authentication/partials/Verify2FA.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
        VerifyPassword,
        VerifyCode
    },

    data() {
        return {
            showVerifyPassword: false,
            showVerify2FA: false,
        }
    },

    computed: {
        ...mapGetters({
            isEnabled: 'Authentications/get2FAStatus'
        }),

        skipable() {
            return parseInt(this.$route.query.skipable) === 1;
        }
    },

    methods: {
        ...mapActions({
            send2FAToken: 'Authentications/send2FAToken',
            update: 'Authentications/toggle2FA'
        }),

        skip() {
            this.$router.replace({name: `${this.userType}_dashboard`})
        },

        userVerified() {
            this.showLoader('Sending Verification Code... Please Wait');

            this.send2FAToken()
                .then(response => {
                    this.hideLoader();

                    this.showVerify2FA = true;
                })
                .catch(error => {
                    this.hideLoader();

                    if (error.message) {
                        this.swalError(error.message)
                    }
                })
        },

        toggle2FA() {
            this.showVerify2FA = false;

            this.showLoader(`Updating 2FA... Please wait`);

            this.update()
                .then(response => {
                    this.hideLoader();

                    if (response.message) {
                        this.swalSuccess(response.message);
                    }
                })
                .catch(error => {
                    this.hideLoader();

                    if (response.message) {
                        this.swalError(response.message);
                    }
                })
        }
    }
}
</script>