<template>
    <content-wrapper>
        <nav-description/>

        <div id="top"></div>

        <div class="row justify-content-center mt-5 pb-5">
            <div class="col-md-12">
                <div class="bg-white elevation p-3">
                    <div class="mt-5" v-if="isPreferences">
                        <h2 class="text-center mb-4 font-xl">Edit your vendor preferences and purchase terms <span class="font-weight-normal">(optional)</span></h2>

                        <third-header
                            :editTabs="tabs"
                            v-model="activeTab"/>

                        <div class="row no-gutters pb-4 pt-4">
                            <div class="col-md-6 mb-4">
                                <div class="side-content">
                                    <h4 class="mb-3 font-big">1. Vendor Category</h4>
                                    <p class="mb-4">Select the most important category for you</p>

                                    <preferences type="business" v-model="payload.vendor_category" class="mt-2 mb-5"/>

                                    <h4 class="mb-3 font-big">2. Vendor Rating</h4>
                                    <p class="mb-4">Click ? to read more about our vendor rating scheme</p>
                                    <div class="vendor-rating">
                                        <button
                                            type="button"
                                            @click="payload.vendor_rating = 'low'"
                                            class="rating"
                                            :class="{'active': payload.vendor_rating == 'low'}">
                                            low</button>
                                        <button
                                            type="button"
                                            @click="payload.vendor_rating = 'medium'"
                                            class="rating"
                                            :class="{'active': payload.vendor_rating == 'medium'}"
                                            >med</button>
                                        <button
                                            type="button"
                                            @click="payload.vendor_rating = 'high'"
                                            class="rating"
                                            :class="{'active': payload.vendor_rating == 'high'}">
                                            high</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-4 v-stretch">
                                <div class="side-content left-border v-stretch">
                                    <h4 class="mb-3 font-big">3. Return Policy</h4>
                                    <p class="mb-4">
                                        <input type="checkbox" class="mr-1" v-model="payload.return_policy"/>
                                        Only returnable items
                                    </p>

                                    <h4 class="mb-3 mt-5 font-big">4. Delivery Window requirements</h4>
                                    <p class="mb-4">Items must arrive by:</p>
                                    <div class="form-group type-4">
                                        <datetime
                                            input-class="form-control"
                                            title="Select Date"
                                            v-model="payload.expiry_date"
                                            placeholder="Select Date">
                                        </datetime>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-5" v-if="!isPreferences">
                        <h2 class="text-center mb-3 font-xl">Edit attributes for selected product</h2>

                        <third-header :editTabs="tabs" v-model="activeTab"/>

                        <div class="row no-gutters pb-4 pt-4">
                            <div class="col-md-12 v-stretch" id="scrollhere">
                                <div class="side-content v-stretch">
                                    <!-- <h4 class="mb-3 font-big">2. Customize your plug with "hooks".</h4> -->
                                    <div class="font-big d-flex align-items-center">
                                        <img src="/img/listicon.png" class="mr-3"/>
                                        <p>Add information about your shopping preferences to customize your channel for best results.</p>
                                    </div>


                                    <div class="row mt-5">
                                        <div class="col-md-6">
                                            <div class="side-content p-0">
                                                <div class="row align-items-center">
                                                    <div class="col-md-2 mb-1 col-12 font-weight-bold">Brand</div>
                                                    <div class="col-md-10">
                                                        <base-input
                                                            noMargin
                                                            class="m-0"
                                                            placeholder="Type brand name..."
                                                            reference="brand"
                                                            v-model="payload.brand"
                                                            :typeahead="brandConfig"
                                                            @selection="val => setTypeAheadValue('brand', val)"/>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div class="row align-items-center">
                                                    <div class="col-md-2 mb-1 col-3 font-weight-bold">Color</div>
                                                    <div class="col-md-10 p-0">
                                                        <div class="colors flex-wrap pt-4">
                                                            <div
                                                                @click="payload.color = color"
                                                                :class="{'active': payload.color === color}"
                                                                class="color elevation-2"
                                                                v-for="color in colors"
                                                                :data-title="color"
                                                                :key="color"
                                                                :style="{background: color}">
                                                                <i class="mdi mdi-check font-big"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div class="row align-items-center">
                                                    <div class="col-md-2 mb-1 col-3 font-weight-bold">Size</div>
                                                    <div class="col-md-10">
                                                        <div class="colors">
                                                            <div
                                                                class="color"
                                                                :class="{'active': payload.size === size}"
                                                                @click="payload.size = size" v-for="size in sizes" :key="size">
                                                                <span>{{ size }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div class="row align-items-center">
                                                    <div class="col-md-2 mb-1 col-3 font-weight-bold">Material</div>
                                                    <div class="col-md-10">
                                                        <base-input
                                                            noMargin
                                                            class="m-0"
                                                            placeholder="Type material name..."
                                                            reference="material"
                                                            v-model="payload.material"
                                                            :typeahead="materialConfig"
                                                            @selection="val => setTypeAheadValue('material', val)"/>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div class="row align-items-center">
                                                    <div class="col-md-2 mb-1 col-3 font-weight-bold">Gender</div>
                                                    <div class="col-md-10 d-flex">
                                                        <div
                                                            class="avatar-md mr-2 cursor-pointer bg-white gender"
                                                            v-for="gender in genders" :key="gender.id"
                                                            @click="setGender(gender)"
                                                            :class="{'active': gender.id === payload.gender}">
                                                            <img style="height:100%;width:100%;object-fit:contain" :src="gender.icon"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div class="row align-items-center">
                                                    <div class="col-md-2 mb-1 col-3 font-weight-bold">Age Group</div>
                                                    <div class="col-md-10">
                                                        <div class="colors">
                                                            <div
                                                                class="color pl-1 text-center pr-3"
                                                                :class="{'active': payload.age_group === age_group}"
                                                                @click="payload.age_group = age_group" v-for="age_group in ageGroups" :key="age_group">
                                                                <span class="font-xs text-capitalize">{{ age_group }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="side-content p-0 left-border">
                                                <div class="p-4">
                                                    <h6 class="mb-4">Product Features</h6>

                                                    <base-token-input
                                                        name="keywords"
                                                        placeholder="Enter feature name e.g. self-cleaning"
                                                        v-model="payload.tokens"
                                                        :shouldSubmit="true"/>

                                                    <preferences type="product" v-model="payload.preferences" class="mt-5"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>



                    <div class="d-flex justify-content-between">
                        <button class="btn btn-primary" @click="navigate('prev')" v-if="activeTab !== 'preferences'">
                            <i class="mdi mdi-arrow-left"></i> Prev Page
                        </button>

                        <div class="ml-2">
                            <button class="btn btn-success mr-2" @click="submit()">
                                Save Changes <i class="mdi mdi-check"></i>
                            </button>

                            <button class="btn btn-secondary" @click="navigate('next')">
                                Next Page <i class="mdi mdi-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="bottom"></div>

        <div class="scroll-up-down">
            <button type="button" class="up mb-2 elevation-2" @click="scroll('top')">
                <i class="mdi mdi-arrow-up"></i>
            </button>
            <button type="button" class="down elevation-2" @click="scroll('bottom')">
                <i class="mdi mdi-arrow-down"></i>
            </button>
        </div>
    </content-wrapper>
</template>
<script>
import storage from '@/storage';
import { mapActions, mapGetters } from 'vuex';
import AddEditIntents from '@/views/customer/intents/create/AddEditIntents.vue';
import Preferences from '@/views/customer/intents/partials/Preferences.vue';
import ThirdHeader from '@/components/navs/ThirdHeader.vue';
import NavDescription from '@/components/navs/NavDescription.vue';
import GenderAgeGroupPopup from '@/views/customer/intents/partials/GenderAgeGroupPopup.vue';

export default {
    props: ['intent_id'],

    components: {
        ThirdHeader,
        AddEditIntents,
        Preferences,
        NavDescription,
        GenderAgeGroupPopup
    },

    data() {
        return {
            intent: null,
            ageGroups: ['under 21', 'between 21 to 40', 'Over 40'],
            sizes: ['S', 'M', 'L', 'XL'],
            genders: [
                {id: 'male', name: 'Male', icon: '/img/genders/male.jpeg'},
                {id: 'female', name: 'Female', icon: '/img/genders/female.jpeg'},
            ],
            tokens: [],
            preferences: [],
            activeTab: 'preferences',

            genderAgeGroupPopup: false,

            payload: {
                brand: '',
                color: '',
                size: '',
                vendor_rating: '',
                material: '',
                product_type_id: '',
                tokens: [],
                preferences: [],
                vendor_category: [],
                age_group: '',
                gender: ''
            }
        }
    },

    computed: {
        ...mapGetters({
            colors: 'Intents/getColors'
        }),

        isPreferences() {
            return this.activeTab === 'preferences';
        },

        brandConfig() {
            return {
                url: `intent_attributes/search_values/brands`,
                options: {
                    name: 'brand',
                    display: 'name'
                }
            };
        },

        materialConfig() {
            return {
                url: `intent_attributes/search_values/materials`,
                options: {
                    name: 'material',
                    display: 'name'
                }
            };
        },

        productTypes() {
            if (!this.intent || !this.intent.product_types) return [];

            return this.intent.product_types.map(item => {
                return {id: item.id, name: item.name};
            });
        },

        tabs() {
            return [
                { id: 'preferences', name: 'Preferences' },
                ...this.productTypes
            ];
        },

        intentAttributes() {
            let values = {};
            if (!this.intent || !this.intent.values) return [];

            for (let item of this.intent.values) {
                values[item.attribute.system_name] = item.value && item.value.split(',').map(item => item.trim());
            }

            return values;
        },

        productAttributes() {
            let attributes = {};

            if (!this.intent || !this.intent.product_attributes) return [];

            for (let attribute of this.intent.product_attributes) {
                if (!attributes.hasOwnProperty(attribute.product_type_id)) {
                    attributes[attribute.product_type_id] = {};
                }

                if (!attributes[attribute.product_type_id].hasOwnProperty(attribute.system_name)) {
                    attributes[attribute.product_type_id][attribute.system_name] = [];
                }

                if (['preferences', 'tokens'].includes(attribute.system_name)) {
                    attributes[attribute.product_type_id][attribute.system_name] = attribute.value.split(',').map(id => {
                        if (/^[0-9]+$/.test(id)) {
                            return parseInt(id);
                        }

                        return id;
                    });
                } else {
                    attributes[attribute.product_type_id][attribute.system_name] = attribute.value;
                }
            }

            return attributes;
        }
    },

    watch: {
        activeTab() {
            if (this.isPreferences) return;

            this.clearPayload();
            const attributes = this.productAttributes[this.activeTab];
            this.payload = {...this.payload, ...attributes}
        }
    },

    methods: {
        ...mapActions({
            update: 'Intents/update',
            show: 'Intents/show'
        }),

        clearPayload() {
            this.payload = {
                brand: '',
                color: '',
                size: '',
                vendor_rating: '',
                material: '',
                product_type_id: '',
                tokens: [],
                preferences: [],
                vendor_category: [],
                age_group: '',
                gender: ''
            };
        },

        setGender(gender) {
            if (this.payload.gender == gender.id) {
                this.payload.gender = '';
            } else {
                this.payload.gender = gender.id
            }
        },

        getRequestPayload() {
            let data;

            if (this.isPreferences) {
                data = {
                    vendor_rating: this.payload.vendor_rating.toString(),
                    vendor_category: this.payload.vendor_category.toString(),
                    level: 'intent'
                }
            } else {
                data = {
                    ...this.payload,
                    preferences: this.payload.preferences.toString(),
                    tokens: this.payload.tokens.toString(),
                    product_type_id: this.activeTab,
                    level: 'product'
                }
            }

            data.intent_id = parseInt(this.intent_id);

            return data;
        },

        submit() {
            const data = this.getRequestPayload();

            console.log(data);

            this.showLoader('Updating channel... Please wait');

            this.update({id: this.intent_id, data})
            .then(response => {
                this.hideLoader();
                this.$router.push({ name: 'customer_view_intent', params: { intent_id: response.id } });
            })
            .catch(error => {
                this.hideLoader();
                console.log(error);
            })
        },

        setTypeAheadValue(key, value) {
            this.payload[key] = value.name;
        },

        navigate(direction) {
            let productTypeIds = Object.keys(this.productAttributes);
            let index = productTypeIds.indexOf(this.activeTab);

            // console.log(index);

            if (direction === 'prev') {
                if (index == 1) this.activeTab = productTypeIds[0];
                if (index == 0) this.activeTab = 'preferences';
            } else {
                if (this.isPreferences) {
                    this.activeTab = productTypeIds[0];
                } else {
                    if (productTypeIds.length > 1) this.activeTab = productTypeIds[1];
                }
            }
        },

        scroll(element = "scrollhere") {
            this.$nextTick().then(() => {
                let scrollDiv = document.getElementById(element).offsetTop;
                window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
            });
        },

        prepopulate() {
            this.payload.vendor_rating = this.intentAttributes.vendor_rating || '';
            this.payload.vendor_category = (this.intentAttributes.vendor_category || []).map(id => parseInt(id));
        },

        getIntent() {
            this.showLoader('Getting intent... Please wait');
            this.show(this.intent_id)
            .then(response => {
                this.hideLoader();
                this.intent = {...response}
                this.prepopulate();
            })
            .catch(error => {
                this.hideLoader();
                console.log(error);
            });
        }
    },

    created() {
        this.getIntent();
    }
}
</script>
