<template>
    <content-wrapper>
        <page-title>Support Tickets</page-title>

        <tickets/>
    </content-wrapper>
</template>

<script>
import Tickets from '@/views/common/settings/partials/support/Index';

export default {
    components: {
        Tickets
    }
}
</script>