<template>
<div>
    <engagement-counts :items="engagementReports"/>

    <layout title="Engagements" :close="!campaign" class="mb-3">
        <engagement-reports :campaign="campaign"/>
    </layout>

    <layout title="Audience Distribution" :close="!campaign">
        <audience-distribution :campaign="campaign"/>
    </layout>

    
</div>
</template>

<script>
import CreatePoll from '../pages/poll/CreatePoll.vue';
import PollVersions from '../pages/poll/Versions.vue';
import PollReports from '../../reports/PollReports.vue';
import EngagementReports from '../../reports/Engagements.vue';
import EngagementCounts from '../../reports/EngagementCounts.vue';
import Layout from '@/views/merchant/profile/partials/Layout.vue';
import AudienceDistribution from '../../reports/AudienceDistribution.vue';


export default {
    props: ['campaign'],

    components: {
        Layout,
        CreatePoll,
        PollReports,
        PollVersions,
        EngagementCounts,
        EngagementReports,
        AudienceDistribution
    },

    data() {
        return {
            tabs: {
                poll: 'results'
            }
        }
    },

    computed: {
        

        engagementReports() {
            if (!this.campaign) return [];

            return [
                {
                    label: 'Impressions',
                    value: this.campaign.impressions_count,
                    subtitle: `+${ this.campaign.todays_engagements.impressions } today`
                },
                {
                    label: 'Views',
                    value: this.campaign.views,
                    subtitle: `+${ this.campaign.todays_engagements.views } today`
                },
                {
                    label: 'Clicks',
                    value: this.campaign.clicks,
                    subtitle: `+${ this.campaign.todays_engagements.clicks } today`
                },
                {
                    label: 'Likes',
                    value: this.campaign.no_of_likes,
                    subtitle: `+${ this.campaign.todays_engagements.likes } today`
                },
                {
                    label: 'Dislikes',
                    value: this.campaign.no_of_dislikes,
                    subtitle: `+${ this.campaign.todays_engagements.dislikes } today`
                },
                {
                    label: 'Average View Time',
                    value: this.campaign.view_duration,
                    subtitle: null
                },
            ]
        }
    }
}
</script>