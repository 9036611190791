import usersService from './services/users';

let pushManager = null;

const vapidKey = `BI18Y4Y28UwUfzteuobAEo9tz1SblyAXfpouYMhwLHM7CpeD0H13sPY7GNVgFb9vRPhS_lfA-uVNaHJHP-fPnXs`;

if (firebase.messaging.isSupported()) {
    var firebaseConfig = {
        apiKey: "AIzaSyDxzH6Nbfysr7LjP4_ZajbGMDeObLm6Ah8",
        authDomain: "woonmi-c77c6.firebaseapp.com",
        projectId: "woonmi-c77c6",
        storageBucket: "woonmi-c77c6.appspot.com",
        messagingSenderId: "216473558676",
        appId: "1:216473558676:web:480ebdb5f7585d886bde81",
        measurementId: "G-NRV9Z5TL14"
    };

    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();

    const messaging = firebase.messaging();

    pushManager = {
        getPermissionStatus() {
            return navigator.permissions.query({ name: 'notifications' })
                .then(result => Promise.resolve(result.state))
                .catch(error => Promise.reject(error));
        },

        /**
         * Subscribes user to push notification and obtain token
         * Sends token to server to be saved
         *
         * @returns {Undefined}
         */
        subscribe() {
            return navigator.serviceWorker.getRegistration()
                .then(serviceWorkerRegistration => {
                    if (serviceWorkerRegistration) {
                        return messaging.getToken({ vapidKey, serviceWorkerRegistration })
                            .then(token => {
                                return usersService.savePushToken({ token })
                                    .then(() => Promise.resolve())
                                    .catch(() => Promise.reject());
                            })
                            .catch(error => Promise.reject(error));
                    } else {
                        return Promise.reject('Service worker registration not found')
                    }
                })
                .catch(error => Promise.reject(error));
        }
    };
}



export default pushManager;