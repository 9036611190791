<template>
    <modal
        :show="show"
        :centered="true"
        :maxHeight="true"
        :opaque="false"
        okText="I Agree"
        @cancel="cancel"
        @ok="submit"
        okIcon="mdi mdi-thumb-up-outline">

        <h5 class="m-0 font-small font-weight-bold" slot="header">Woonmi Terms & Conditions</h5>

        <div slot="body" class="bg-default font-small p-2 rounded">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum vel dignissimos facilis laboriosam atque, amet odit commodi explicabo. Ab eaque sed vitae. Ipsum, fugiat? Iste temporibus, maiores quas dolore a commodi magnam deleniti odit quod distinctio eius porro adipisci officia, ipsum, exercitationem repudiandae deserunt! Soluta veritatis dignissimos facilis. Ex veritatis, voluptas unde ad illum et, adipisci dicta distinctio architecto suscipit nam impedit, corrupti esse blanditiis numquam laboriosam officiis sunt. Maiores explicabo itaque reiciendis numquam, nisi voluptates asperiores molestiae laudantium saepe, expedita ipsa corrupti esse eligendi laborum harum rerum, rem deleniti alias. Ex aperiam suscipit autem maxime, numquam, magni temporibus voluptatem, quibusdam dolores mollitia corporis quas ad error! Perferendis pariatur quo accusantium voluptatum ipsam laborum natus! Aspernatur officiis cumque, alias enim quaerat animi aperiam, reiciendis necessitatibus modi ipsa id culpa asperiores quo sed saepe perspiciatis, vitae autem! Deleniti expedita ipsum minima mollitia, odio quasi aut velit, fuga perferendis eligendi neque ipsa at tempore libero tenetur repudiandae fugit officiis iste quas! Non ex recusandae quod adipisci tenetur repudiandae, perspiciatis molestias fugiat odit cupiditate accusamus in expedita? Facere dolorum eaque voluptate ipsa, voluptas ullam accusamus illum perspiciatis. Modi accusantium eos nostrum tenetur, ab dolor distinctio, laboriosam blanditiis veritatis.
        </div>
    </modal>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    props: ['show'],

    data() {
        return {

        }
    },

    watch: {
        show() {

        }
    },

    methods: {
        cancel() {
            this.$emit('cancel');
        },

        submit() {
            this.$emit('agree');
        }
    }
}
</script>