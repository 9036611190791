<template>
    <div class="authentication">
        <div class="row justify-content-center m-0 p-0 w-100 content">
            <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                <form class="auth-form p-4" @submit.prevent="submit">
                    <div class="form-group mb-4">
                        <label class="font-small">New Password</label>
                        <input v-model="credentials.password" type="password" placeholder="Password" class="form-control font-big font-weight-bold"/>
                    </div>

                    <div class="form-group">
                        <label class="font-small">Confirm Password</label>
                        <input v-model="credentials.password_confirmation" type="password" placeholder="Password Confirmation" class="form-control font-big font-weight-bold"/>
                    </div>

                    <div class="d-flex justify-content-between align-items-center mt-5">
                        <h1 class="font-big font-weight-bolder">Save Password</h1>
                        <button type="submit" class="btn submit">
                            <i class="mdi mdi-arrow-right"></i>
                        </button>
                    </div>

                    <div class="d-flex justify-content-center flex-column mt-5">
                        <router-link :to="{name: 'login'}" href="/login" class="font-small font-weight-bolder mb-4">
                            Login instead? <br/>

                            <span class="text-muted font-xs font-weight-lighter">
                               If you remembered your password then login now
                            </span>

                            <i class="mdi mdi-arrow-right ml-2 text-danger float-right"></i>
                        </router-link>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    props: ['token'],

    data() {
        return {
            credentials: {
                password: '',
                password_confirmation: ''
            }
        }
    },
    methods: {
        ...mapActions({
            resetPassword: 'Authentications/resetPassword'
        }),

        submit() {
            if (!this.credentials.password) {
                return this.swalError('New Password Required', `Please enter a new password.`, false)
            }

            if (this.credentials.password.length < 6) {
                return this.swalError('Password is weak', `Your password should not be less than 6 characters`, false)
            }

            if (this.credentials.password !== this.credentials.password_confirmation) {
                return this.swalError('Password Mismatch', `Your password confirmation is incorrect. Please enter the same password as a confirmation`, false);
            }

            this.showLoader('Resetting Password... Please wait');

            this.resetPassword({...this.credentials, token: this.token})
                .then(response => {
                    this.hideLoader();
                    this.goHome();

                    this.swalSuccess(`Password Reset Successful`, `Please Login to your account.`)
                })
                .catch(error => {
                    this.hideLoader();

                    if (error.message) {
                        this.swalError('Oops', error.message, false);
                    }
                });
        },

        goHome() {
            this.$router.push({name: 'login'});
        }
    },

    created() {
        if (this.isLoggedIn) {
            this.goHome();
        }
    }
}
</script>