<template>
    <div class="bg-white elevation grid-campaign rounded v-stretch">
        <div class="heading mb-2 pb-2">
            <div>
                <a href="#" @click.prevent="activeTab = 'summary'" class="font-xs" :class="{'active': activeTab == 'summary'}">
                    <i class="icon mdi mdi-information-outline"></i> Summary
                </a>
                <a href="#" @click.prevent="activeTab = 'engagements'" class="font-xs" :class="{'active': activeTab == 'engagements'}">
                    <i class="icon mdi mdi-chart-bar"></i> Engagements
                </a>
            </div>

            <div>
                <router-link
                    :to="{name: 'merchant_view_campaign', params: {campaign_id: campaign.id}}"
                    :href="`/merchant/campaigns/view/${campaign.id}`"
                    class="status-btn font-xs pl-3 pr-3"
                    :class="{'warning active': isDraft, 'primary': !isDraft}"
                    type="button">
                    <template v-if="isDraft"><i class="mdi mdi-pencil mr-1"></i> Edit</template>
                    <template v-else><i class="mdi mdi-link mr-1"></i> Open</template>
                </router-link>
            </div>
        </div>

        <div class="content">
            <div class="d-flex align-items-start">
                <div class="media avatar-md mr-2">
                    <img :src="campaign.file_url" class="avatar-md rounded" :class="{'p-2': !campaign.image}"/>
                </div>
                <div class="">
                    <p class="m-0 font-weight-bold">{{ campaign.advert && campaign.advert.name }}</p>
                    <p class="m-0 font-xs">{{ campaign.advert && campaign.advert.description }}</p>
                    <p class="m-0 font-10 text-muted">{{ campaign.last_activity }}</p>
                    <p class="m-0 font-10 text-muted">Conversion</p>
                </div>
            </div>

            <template v-if="activeTab == 'summary'">
                <div class="table-responsive mt-3">
                    <table class="table table-bordered text-center font-xs">
                        <tbody>
                            <tr>
                                <td>
                                    <p class="m-0 font-weight-bold font-big">
                                        {{ campaign.impressions_count | numberFormat }}
                                    </p>
                                    <p class="m-0 text-muted">Impressions</p>
                                </td>
                                <td>
                                    <p class="m-0 font-weight-bold font-big">
                                        {{ campaign.views | numberFormat }}
                                    </p>
                                    <p class="m-0 text-muted">Views</p>
                                </td>
                                <td>
                                    <p class="m-0 font-weight-bold font-big">
                                        {{ campaign.view_duration | numberFormat }}s
                                    </p>
                                    <p class="m-0 text-muted">AVT</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p class="m-0 font-weight-bold font-big">
                                        {{ campaign.clicks | numberFormat }}
                                    </p>
                                    <p class="m-0 text-muted">Clicks</p>
                                </td>
                                <td>
                                    <p class="m-0 font-weight-bold font-big">
                                        {{ campaign.no_of_likes | numberFormat }}
                                    </p>
                                    <p class="m-0 text-muted">Likes</p>
                                </td>
                                <td>
                                    <p class="m-0 font-weight-bold font-big">
                                        {{ campaign.no_of_dislikes | numberFormat }}
                                    </p>
                                    <p class="m-0 text-muted">Dislikes</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>

            <div class="" v-if="activeTab == 'engagements'">
                <hr/>
                <engagement-reports :campaign="campaign"/>
            </div>
        </div>
    </div>
</template>
<script>
import EngagementReports from './../../reports/Engagements.vue';

export default {
    props: ['campaign'],

    components: {
        EngagementReports
    },

    data() {
        return {
            activeTab: 'summary'
        }
    },

    computed: {
        isDraft() {
            return this.campaign && this.campaign.status.name == 'Draft'
        }
    }
}
</script>