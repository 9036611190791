<template>
    <div>
        <h3 class="text-center mb-5 mt-4" id="password"></h3>

        <div class="row no-gutters">
            <div class="col-md-12 col-12">
                <base-input
                    label="Password"
                    icon="mdi mdi-lock-outline"
                    type="password"
                    required
                    class="filter elevation"
                    v-model="payload.password"
                    :error="validationErrors.password"
                    placeholder=".e.g secret"/>
            </div>
            <div class="col-md-12 col-12">
                <base-input
                    label="Confirm Password"
                    icon="mdi mdi-lock-outline"
                    type="password"
                    required
                    class="filter elevation"
                    v-model="payload.password_confirmation"
                    :error="validationErrors.password_confirmation"
                    placeholder=".e.g secret"/>
            </div>
        </div>

        <prev-next
            :currentStep="currentStep"
            :isLastStep="isLastStep"
            @prev="prev"
            @next="next"
            nextText="CREATE ACCOUNT"
            nextClass="btn-success"/>
    </div>
</template>

<script>
import TypeIt from "typeit";
import PrevNext from './../PrevNext';
import { mapGetters, mapActions } from 'vuex';

export default {
    props: ['currentStep', 'isLastStep'],

    components: {
        PrevNext
    },

    data() {
        return {
            payload: {
                password: '',
                password_confirmation: '',
            },

            validationErrors: {
                password: '',
                password_confirmation: ''
            },
        }
    },

    computed: {
        ...mapGetters({

        }),
    },

    methods: {
        ...mapActions({
            validate: 'Authentications/validateRegistrationDetails'
        }),

        typeHeading() {
            new TypeIt("#password", {
                speed: 50,
                startDelay: 100
            })
            .empty()
            .type(`Let's secure your account...`, {delay: 500})
            .delete()
            .type('Create your password...')
            .go()
        },

        submit() {
            this.buildValidationErrors({});

            this.showLoader('Checking your passwords... Please wait');

            this.validate({type: 'password', ...this.payload})
                .then(response => {
                    this.hideLoader();

                    this.$emit('submit', this.payload);
                })
                .catch(error => {
                    this.hideLoader();
                    this.swalError(`Attention Required`, `${error.message} Please fix all issues before proceeding.`)
                    this.buildValidationErrors(error.errors);
                })
        },

        next() {
            this.submit();
        },

        prev() {
            this.$emit('prev')
        }
    },

    mounted() {
        this.typeHeading()
    }
}
</script>