<template>
<modal
    :show="show"
    :md="true"
    :centered="true"
    :showFooter="true"
    :maxHeight="true"
    okText="Submit"
    @ok="submit"
    @cancel="$emit('cancel')"
    okIcon="mdi mdi-check-all">

    <h5 class="m-0 font-small font-weight-bold" slot="header">{{ title }}</h5>

    <div class="font-small p-2 bg-default" slot="body">
        <div class="app-card elevation-2">
            <div class="row no-gutters">
                <div class="col-12">
                    <base-input
                        type="text"
                        label="Name"
                        v-model="payload.name"
                        :error="validationErrors.name"
                        placeholder="This will be used by humans"/>
                </div>
                <div class="col-12">
                    <base-input
                        type="text"
                        label="System Name"
                        placeholder="This will be used by system"
                        :error="validationErrors.system_name"
                        v-model="payload.system_name"/>
                </div>
            </div>

            <div class="row no-gutters">
                <div class="col-12">
                    <base-select
                        label="Group"
                        v-model="payload.group_id"
                        :error="validationErrors.group_id">
                        <option value="">None</option>
                        <option v-for="(group, index) in groups" :key="index" :value="group.id">{{ group.name }}</option>
                        <option value="other">Other</option>
                    </base-select>
                </div>
                <div class="col-12" v-if="showOther">
                    <base-input
                        placeholder="if other"
                        label="If Other"
                        :disabled="!showOther"
                        v-model="payload.group"
                        :error="validationErrors.group"/>
                </div>
            </div>

            <base-select
                label="System Type"
                v-model="payload.system_type"
                :error="validationErrors.system_type">
                <option value="">None</option>
                <option v-for="(type, index) in systemTypes" :key="index" :value="type">{{ type }}</option>
            </base-select>

            <div class="bg-default rounded p-2 mb-3" v-if="payload.system_type == 'SelectOption'">
                <label class="font-small">Select Options</label>

                <div class="options d-flex flex-wrap mb-3 mt-2">
                    <span class="badge bg-intent-info-soft border border-info elevation text-dark p-1 font-small m-1 text-left pl-2 pr-2" v-for="(opt, index) in payload.settings.options" :key="index">
                        <small class="font-weight-bold">{{ opt }}</small>
                        <a href="#" @click.prevent="removeOption(index)" class="text-danger p-1"><i class="mdi mdi-close"></i></a>
                    </span>
                </div>

                <div class="row no-gutters">
                    <div class="col-9">
                        <base-input v-model="option" label="Option" placeholder="Option name"/>
                    </div>
                    <div class="col-3">
                        <button @click="addOption" class="btn btn-block btn-primary btn-lg">
                            <i class="mdi mdi-plus"></i>
                        </button>
                    </div>
                </div>
            </div>

            <base-input
                placeholder="What, How, Who, etc"
                label="Question"
                v-model="payload.question"/>

            <div class="form-group type-2">
                <label class="font-small">Description</label>
                <div class="icon"><i class="mdi mdi-information-outline"></i></div>
                <textarea
                    v-model="payload.description"
                    :error="validationErrors.description"
                    placeholder="Write some description about this attribute"
                    class="form-control font-small text-input"
                    rows="5"></textarea>
                <span class="error-label font-10 d-block mt-1 ml-1">{{ validationErrors.description }}</span>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['show', 'attribute'],

    data() {
        return {
            option: '',

            payload: {
                name: '',
                system_name: '',
                system_type: '',
                description: '',
                group: '',
                group_id: '',
                question: '',
                settings: {
                    options: []
                }
            },

            validationErrors: {
                name: '',
                system_name: '',
                system_type: '',
                description: '',
                group: '',
                group_id: '',
                question: ''
            },

            systemTypes: [],
            groups: []
        }
    },

    computed: {
        ...mapGetters({
            intentTypes: 'IntentTypes/getAll'
        }),

        title() {
            if (!this.attribute) {
                return 'Add a new intent attribute';
            }

            return `Edit ${this.attribute.name}`;
        },

        showOther() {
            return this.payload.group_id == 'other'
        }
    },

    watch: {
        show() {
            if (this.show) {
                this.reset();
            }
        },

        attribute: {
            deep: true,
            immediate: true,
            handler() {
                if (!this.attribute) return;

                this.payload.name = this.attribute.name;
                this.payload.system_name = this.attribute.system_name;
                this.payload.system_type = this.attribute.system_type;
                this.payload.description = this.attribute.description;

                this.payload.group_id = this.attribute.group_id;
                this.payload.question = this.attribute.question;
                this.payload.settings = this.attribute.settings;
            }
        }
    },

    methods: {
        ...mapActions({
            getSystemTypes: 'IntentAttributes/getSystemTypes',
            getGroups: 'ProfileAttributes/getGroups',
            store: 'ProfileAttributes/store'
        }),

        removeOption(index) {
            this.payload.settings.options.splice(index, 1);
        },

        addOption() {
            this.payload.settings.options.push(this.option)
            this.option = '';
        },

        reset() {
            this.payload.name = '';
            this.payload.system_name = '';
            this.payload.system_type = '';
            this.payload.description = '';
        },

        cancel() {
            this.reset();
            this.$emit('cancel');
        },

        submit() {
            this.buildValidationErrors({})

            this.showLoader('Adding attribute... Please wait');

            this.store(this.payload)
                .then(response => {
                    this.hideLoader();
                    this.$emit('refresh')
                    this.cancel();
                })
                .catch(error => {
                    this.hideLoader();
                    this.buildValidationErrors(error.errors);
                })
        }
    },

    created() {
        this.getGroups()
            .then(response => {
                this.groups = response;
            })
            .catch(error => null);

        this.getSystemTypes()
            .then(response => {
                this.systemTypes = response;
            })
            .catch(error => null)
    }
}
</script>