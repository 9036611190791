<template>
    <div class="links flex-grow-1">
            <div class="top">
                <router-link
                    :to="{name: 'merchant_dashboard'}"
                    href="/merchant/dashboard"
                    class="font-small rounded"
                    active-class="active">
                    <i class="mdi mdi-view-dashboard mr-1 icon"></i>
                    Dashboard
                </router-link>

                <router-link
                    :to="{name: 'merchant_campaigns'}"
                    href="/merchant/campaigns"
                    class="font-small rounded"
                    active-class="active">
                    <i class="mdi mdi-bullhorn icon mr-1 icon"></i>
                    Campaigns
                </router-link>

                <router-link
                    :to="{name: 'merchant_media'}"
                    href="/merchant/media"
                    class="font-small rounded"
                    active-class="active">
                    <i class="mdi mdi-film icon mr-1 icon"></i>
                    Media
                </router-link>

                <router-link
                    :to="{name: 'merchant_audience'}"
                    href="/merchant/audience"
                    class="font-small rounded"
                    active-class="active">
                    <i class="mdi mdi-account-multiple-outline icon mr-1 icon"></i>
                    Audience
                </router-link>

                <router-link
                    :to="{name: 'merchant_profile'}"
                    href="/merchant/profile"
                    class="font-small rounded"
                    active-class="active">
                    <i class="mdi mdi-account-box-outline icon mr-1 icon"></i>
                    Profile
                </router-link>

                <router-link
                    :to="{name: 'merchant_teams'}"
                    href="/merchant/teams"
                    class="font-small rounded"
                    active-class="active">
                    <i class="mdi mdi-account-multiple-plus icon mr-1 icon"></i>
                    Team
                </router-link>

                <router-link
                    :to="{name: 'merchant_support_tickets'}"
                    href="/merchant/tickets"
                    class="font-small rounded"
                    active-class="active">
                    <i class="mdi mdi-help-circle mr-1 icon"></i>
                    Support
                </router-link>
            </div>

            <commons/>
        </div>
</template>
<script>
import Commons from './Common';

export default {
    components: {
        Commons
    }
}
</script>