import { axios } from './index';

export default {
    /**
     * Get available intent
     *
     * @param {Object} params
     * @returns {Object}
     */
    index(params) {
        return axios.get('intents', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Store intent
     *
     * @param {Object} data
     * @returns {Object}
     */
    store(data) {
        return axios.post('intents', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    update(id, data) {
        return axios.put(`intents/${id}`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    show(id, params) {
        return axios.get(`intents/${id}`, {params})
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    destroy(id) {
        return axios.delete(`intents/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    getPreferences(params) {
        return axios.get(`intents/preferences`, {params})
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    saveAttribute(params) {
        return axios.post(`intents/attributes`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    revoveAttribute(id) {
        return axios.delete(`intents/attributes/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    updatePreferences(data) {
        return axios.post(`intents/preferences`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    checkDuplicates(params) {
        return axios.get(`intents/check-duplicates`, {params})
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    shareIntent(params) {
        return axios.post(`intents/share`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    }
}