<template>
    <div class="pt-5">
        <heading title="EMAIL ACCOUNT VERIFICATION REQUIRED" description="We are setting up your account.."/>


        <div class="row justify-content-center mt-5">
            <div class="col-lg-4 col-md-6 col-12 pr-4 pl-4">
                <app-card class="text-center font-small p-3 pt-4 pb-4">
                    <h4 class="text-uppercase">
                        AWAITING VERIFICATION
                    </h4>
                    <hr/>
                    <p class="text-muted">
                        When you created the account, a verification link has been sent to your email. Click on the link to verify this account. In case the link expired or it appeared broken then you should request a new verification link. Click the button below to receive a new verification link.
                    </p>

                    <button type="button" @click="resendLink" class="btn btn-secondary font-small pb-3 pt-3 btn-block mt-4 elevation-2">
                        RESEND VERIFICATION LINK
                    </button>

                    <div class="text-center mt-3">
                        <a href="#" class="font-weight-bold" @click.prevent="logout">
                            <i class="mdi mdi-logout"></i> Logout
                        </a>
                    </div>
                </app-card>

            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import Heading from '../authentication/partials/GuestHeading';

export default {
    components: {
        Heading
    },

    data() {
        return {
        }
    },

    computed: {
    },

    methods: {
        ...mapActions({
            resend: 'Authentications/resendEmailVerificationLink',
            signOut: 'Authentications/logout'
        }),

        logout() {
            this.signOut();
        },

        resendLink() {
            this.showLoader('Verifying Email... Please wait');

            this.resend()
            .then(response => {
                this.hideLoader();

                this.swalSuccess(`Verification Link Sent`, `An email verification link has been sent to your email ${this.authUser.email}, Please click on the link to verify your email for this account`);
            })
            .catch(error => {
                this.hideLoader();
                this.swalError(error.message, error.text || ``, false);
            });
        }
    },

    created() {
        if (this.authUser.email_verified_at) {
            this.$router.replace({name: `${this.userType}_dashboard`});
        }
    }
}
</script>