<template>
    <div>
        <div class="row">
            <div class="col-md-3">
                <layout title="Location" :close="false">
                    <div class="p-2 pt-4">
                        <div class="row">
                            <div class="col-md-6">
                                <base-select label="Country">
                                    <option value="">Any</option>
                                </base-select>
                            </div>
                            <div class="col-md-6">
                                <base-select label="State/Province">
                                    <option value="">Any</option>
                                </base-select>
                            </div>
                            <div class="col-md-6">
                                <base-select label="City">
                                    <option value="">Any</option>
                                </base-select>
                            </div>
                        </div>
                    </div>
                </layout>

                <layout title="Age & Gender" :close="false">
                    <div class="p-2 pt-4">
                        <div class="row">
                            <div class="col-md-6">
                                <base-select label="Age">
                                    <option value="">Any</option>
                                    <option value="under 21">Under 21</option>
                                    <option value="between 21 to 40">Between 21 to 40</option>
                                    <option value="Oover 40">Over 40</option>
                                </base-select>
                            </div>
                            <div class="col-md-6">
                                <base-select label="Gender">
                                    <option value="">Any</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </base-select>
                            </div>
                        </div>
                    </div>
                </layout>

                <layout title="Interest" :close="false">
                    <div class="p-2 pt-4">
                        <base-input label="Search" placeholder="e.g Cycling"/>
                    </div>
                </layout>
            </div>
            <div class="col-md-9">
                <div class="bg-white p-3 rounded elevation mb-4">
                    <div class="audience-insight-categories">
                        <button @click="activeTab = 'insights'" :class="{active: activeTab == 'insights'}" type="button">
                            Insights <span class="count">(23.8K)</span>
                        </button>
                        <button @click="activeTab = 'intended_shoppers'" :class="{active: activeTab == 'intended_shoppers'}" type="button">
                            Intended shoppers <span class="count">(23.8K)</span>
                        </button>
                        <button @click="activeTab = 'explorers'" :class="{active: activeTab == 'explorers'}" type="button">
                            Explorers <span class="count">(23.8K)</span>
                        </button>
                        <button @click="activeTab = 'deciders'" :class="{active: activeTab == 'deciders'}" type="button">
                            Deciders <span class="count">(23.8K)</span>
                        </button>
                    </div>
                </div>

                <div class="">
                    <canvas id="insights" style="max-height:500px;"></canvas>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Chart from '@/chart.js';
import Layout from '@/views/merchant/profile/partials/Layout.vue';
export default {
    components: {
        Layout
    },

    data() {
        return {
            activeTab: 'insights',
            chart: null
        }
    },

    methods: {
        drawChart() {
            const DATA_COUNT = 7;
            const NUMBER_CFG = {count: DATA_COUNT, min: -100, max: 100};

            const labels = Samples.utils.months({count: 7});

            const data = {
                labels: labels,
                datasets: [
                    {
                        label: 'Dataset 1',
                        data: Samples.utils.numbers(NUMBER_CFG),
                        borderColor: window.chartColors.red,
                        backgroundColor: Samples.utils.transparentizeFromRGBString(window.chartColors.red, 0.5),
                    },
                    {
                        label: 'Dataset 2',
                        data: Samples.utils.numbers(NUMBER_CFG),
                        borderColor: window.chartColors.blue,
                        backgroundColor: Samples.utils.transparentizeFromRGBString(window.chartColors.blue, 0.5),
                    }
                ]
            };

            console.log(data);

            const config = {
                type: 'bar',
                data: data,
                options: {
                    responsive: true,
                    plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: 'Chart.js Bar Chart'
                    }
                    }
                },
            };

            if (this.chart) {
                this.chart.destroy();
            }

            this.chart = new Chart($(`#insights`), config);
        }
    },

    mounted() {
        this.drawChart();
    }
}
</script>