<template>
    <content-wrapper :marginLevel="isGuest ? 4 : 2">

        <nav-description :isGuest="isGuest" v-if="isGuest"/>

        <div id="top"></div>

        <div class="row justify-content-center pb-5">
            <div class="col-md-12">
                <div class="p-1 pr-3 pl-3 pb-3" :class="{'bg-white elevation': activeTab !== 4}">
                    <div class="mt-5" v-if="[1,3].includes(activeTab)">
                        <h2 class="mb-5 font-big d-flex align-items-center">
                            <div class="avatar-xs mr-2">
                                <img src="/img/broadcasticon.png" class="mr-3 img-label"/>
                            </div>
                            <div class="m-0">
                                Set up a shopping
                                <div class="channel-text">
                                    channel
                                    <div class="description d-flex elevation-2 rounded">
                                        <img class="avatar-xxs" src="/img/logos/192x192.png"/>
                                        Some text will go here
                                    </div>
                                </div> for today's shopping
                            </div>
                        </h2>

                        <div class="row justify-content-center mb-2">
                            <div class="col-md-8">
                                <steps :steps="steps" v-model="activeTab"/>
                            </div>
                        </div>

                        <div class="row no-gutters pb-4 pt-4">
                            <div class="col-md-6">
                                <div class="side-content mb-3">
                                    <add-edit-intents
                                        v-model="payload.product_type_id"
                                        @scroll="scroll"
                                        :productTypeName="ontheflyproduct_type_name"/>
                                </div>
                            </div>
                            <div class="col-md-6 v-stretch" id="scrollhere">
                                <div class="side-content left-border v-stretch">
                                    <hr class="mb-5 d-md-none"/>
                                    <div class="font-big d-flex align-items-center">
                                        <div class="avatar-xs mr-2">
                                            <img src="/img/listicon.png" class="mr-3 img-label"/>
                                        </div>
                                        <p class="m-0">Customize your channel by including your choices for general product attributes (optional but recommended).</p>
                                    </div>

                                    <div class="mt-5">
                                        <div class="row align-items-center">
                                            <div class="col-md-2 mb-1 col-12 font-weight-bold">Which Brand?</div>
                                            <div class="col-md-10">
                                                <base-input
                                                    noMargin
                                                    class="m-0"
                                                    placeholder="e.g Personalyy"
                                                    reference="brand"
                                                    v-model="payload.brand"
                                                    :typeahead="brandConfig"
                                                    @selection="val => setTypeAheadValue('brand', val)"/>
                                            </div>
                                        </div>
                                        <hr class="mt-3"/>
                                        <div class="row align-items-center">
                                            <div class="col-md-2 mb-1 col-12 font-weight-bold">What Color?</div>
                                            <div class="col-md-10 p-0">
                                                <div class="colors flex-wrap pt-2">
                                                    <div
                                                        @click="payload.color = color"
                                                        :class="{'active': payload.color === color}"
                                                        class="color elevation-2"
                                                        v-for="color in colors"
                                                        :data-title="color"
                                                        :key="color"
                                                        :style="{background: color}">
                                                        <i class="mdi mdi-check font-big"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr class="mt-3"/>
                                        <div class="row align-items-center">
                                            <div class="col-md-2 mb-1 col-3 font-weight-bold">Nearest Size</div>
                                            <div class="col-md-10">
                                                <div class="colors">
                                                    <div
                                                        class="color"
                                                        :class="{'active': payload.size === size}"
                                                        @click="payload.size = size" v-for="size in sizes" :key="size">
                                                        <span>{{ size }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr class="mt-3"/>
                                        <div class="row align-items-center">
                                            <div class="col-md-2 mb-1 col-3 font-weight-bold">What Material</div>
                                            <div class="col-md-10">
                                                <base-input
                                                    noMargin
                                                    class="m-0"
                                                    placeholder="e.g Aluminium"
                                                    reference="material"
                                                    v-model="payload.material"
                                                    :typeahead="materialConfig"
                                                    @selection="val => setTypeAheadValue('material', val)"/>
                                            </div>
                                        </div>
                                        <hr class="mt-3"/>
                                        <div class="row align-items-center">
                                            <div class="col-md-2 mb-1 col-12 font-weight-bold">Which Gender</div>
                                            <div class="col-md-10 d-flex">
                                                <div
                                                    class="avatar-md mr-2 cursor-pointer bg-white gender"
                                                    v-for="gender in genders" :key="gender.id"
                                                    @click="setGender(gender)"
                                                    :class="{'active': gender.id === payload.gender}">
                                                    <img style="height:100%;width:100%;object-fit:contain" :src="gender.icon"/>
                                                </div>
                                            </div>
                                        </div>
                                        <hr class="mt-3"/>
                                        <div class="row align-items-center">
                                            <div class="col-md-2 mb-1 col-12 font-weight-bold">Age Group</div>
                                            <div class="col-md-10">
                                                <div class="colors">
                                                    <div
                                                        class="color pl-1 text-center pr-3"
                                                        :class="{'active': payload.age_group === age_group}"
                                                        @click="payload.age_group = age_group" v-for="age_group in ageGroups" :key="age_group">
                                                        <span class="font-xs text-capitalize">{{ age_group }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="border border-1 rounded mt-5 p-4">
                                        <h6 class="mb-4">Product Features</h6>

                                        <p class="font-italic">Are there specific product features that you are interested? Enter a keyword for each feature e.g. Touchscreen and hit the &lt;Return&gt; key.</p>

                                        <base-token-input
                                            name="keywords"
                                            placeholder="e.g. Touchscreen"
                                            v-model="payload.tokens"
                                            :shouldSubmit="true"/>

                                        <p class="font-italic mt-5">Select relevant options from the feature buttons below if applicable.</p>
                                        <preferences type="product" v-model="payload.preferences" class="mt-3"/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="mt-5" v-if="activeTab === 2">
                        <h2 class="text-left mb-5 font-xl">
                            Add your vendor preferences and purchase terms
                            <span class="font-weight-normal">(optional)</span>
                        </h2>

                        <div class="row justify-content-center mb-2">
                            <div class="col-md-8">
                                <steps :steps="steps" v-model="activeTab"/>
                            </div>
                        </div>

                        <div class="row no-gutters pb-4 pt-4">
                            <div class="col-md-6 mb-4">
                                <div class="side-content">
                                    <h4 class="mb-3 font-big">1. Vendor Category</h4>
                                    <p class="mb-4">Select the most important category for you</p>

                                    <preferences type="business" v-model="payload.vendor_category" class="mt-2 mb-5"/>

                                    <h4 class="mb-3 font-big">2. Vendor Rating</h4>
                                    <p class="mb-4">Click ? to read more about our vendor rating scheme</p>
                                    <div class="vendor-rating">
                                        <button
                                            type="button"
                                            @click="payload.vendor_rating = 'low'"
                                            class="rating"
                                            :class="{'active': payload.vendor_rating == 'low'}">
                                            low</button>
                                        <button
                                            type="button"
                                            @click="payload.vendor_rating = 'medium'"
                                            class="rating"
                                            :class="{'active': payload.vendor_rating == 'medium'}"
                                            >med</button>
                                        <button
                                            type="button"
                                            @click="payload.vendor_rating = 'high'"
                                            class="rating"
                                            :class="{'active': payload.vendor_rating == 'high'}">
                                            high</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-4 v-stretch">
                                <div class="side-content left-border v-stretch">
                                    <h4 class="mb-3 font-big">3. Return Policy</h4>
                                    <p class="mb-4">
                                        <input type="checkbox" class="mr-1" v-model="payload.return_policy"/>
                                        Only returnable items
                                    </p>

                                    <h4 class="mb-3 mt-5 font-big">4. Delivery Window requirements</h4>
                                    <p class="mb-4">Items must arrive by:</p>
                                    <div class="form-group type-4">
                                        <datetime
                                            input-class="form-control"
                                            title="Select Date"
                                            v-model="payload.expiry_date"
                                            placeholder="Select Date">
                                        </datetime>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-5" v-if="activeTab === 4 && isGuest">
                        <div class="mt-2 mb-5 d-flex flex-wrap justify-content-center">
                            <span
                                class="token p-2 m-1 d-inline-flex align-items-center font-small round elevation pr-3 pl-3 bg-default-dark text-primary"
                                v-for="(type, index) in productTypes" :key="index">
                                {{ type }}
                            </span>
                        </div>

                        <div class="mt-3">
                            <view-updates type="intent" :onthefly="onthefly"/>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between">
                        <button class="btn btn-primary" @click="navigate(-1)" v-if="activeTab <= 4 && activeTab > 1">
                            <i class="mdi mdi-arrow-left"></i> Prev Page
                        </button>

                        <div class="ml-2 text-right flex-grow-1">
                            <template v-if="!isGuest">
                                <button class="btn btn-secondary mr-2" @click="submit('addproduct')" v-if="activeTab == 2">
                                    Additional Product <i class="mdi mdi-plus"></i>
                                </button>
                            </template>

                            <button class="btn btn-success mr-2" @click="submit('finish')" v-if="activeTab > 1 && activeTab < 4">
                                Finish <i class="mdi mdi-check"></i>
                            </button>

                            <button class="btn btn-primary-2 text-white" @click="navigate(1)" v-if="activeTab == 1">
                                Next Page <i class="mdi mdi-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="bottom"></div>

        <div class="scroll-up-down">
            <button type="button" class="up mb-2 elevation-2" @click="scroll('top')">
                <i class="mdi mdi-arrow-up"></i>
            </button>
            <button type="button" class="down elevation-2" @click="scroll('bottom')">
                <i class="mdi mdi-arrow-down"></i>
            </button>
        </div>
    </content-wrapper>
</template>
<script>
import storage from '@/storage';
import Steps from './Steps.vue';
import { mapActions, mapGetters } from 'vuex';
import AddEditIntents from './AddEditIntents.vue';
import Preferences from '../partials/Preferences.vue';
import ThirdHeader from '@/components/navs/ThirdHeader.vue';
import NavDescription from '@/components/navs/NavDescription.vue';
import ViewUpdates from '@/views/customer/interests/ViewUpdates.vue';
import GenderAgeGroupPopup from '../partials/GenderAgeGroupPopup.vue';

export default {
    props: ['top', 'isGuest'],

    components: {
        Steps,
        ThirdHeader,
        Preferences,
        ViewUpdates,
        NavDescription,
        AddEditIntents,
        GenderAgeGroupPopup,
    },

    data() {
        return {
            ageGroups: ['under 21', 'between 21 to 40', 'Over 40'],
            sizes: ['S', 'M', 'L', 'XL'],
            genders: [
                {id: 'male', name: 'Male', icon: '/img/genders/male.jpeg'},
                {id: 'female', name: 'Female', icon: '/img/genders/female.jpeg'},
            ],
            activeTab: 1,

            genderAgeGroupPopup: false,

            payload: {
                brand: '',
                color: '',
                size: '',
                vendor_rating: '',
                material: '',
                product_type_id: '',
                tokens: [],
                preferences: [],
                vendor_category: [],
                age_group: '',
                gender: ''
            },

            onthefly: null,
            ontheflyproduct_type_name: '',
        }
    },

    computed: {
        ...mapGetters({
            colors: 'Intents/getColors'
        }),

        productTypes() {
            return [this.$route.query.q];
        },

        steps() {
            let steps = [
                {
                    id: 1,
                    name: `<i class="mdi mdi-plus"></i> Product Description`,
                    icon: 'AddProductIcon'
                },
                {
                    id: 2,
                    name: `<i class="mdi mdi-plus"></i> Feature Preferences`,
                    icon: 'AddPreferenceIcon'
                },
                {
                    id: 4,
                    name: this.isGuest ? `Results` : 'Finish & Create Channel',
                    icon: 'FinishIcon'
                },
            ];

            if (!this.isGuest) {
                steps.push({
                    id: 3,
                    name: `<i class="mdi mdi-plus"></i> Additional Product`,
                    icon: 'AddProductIcon'
                });
            }

            steps.sort((a, b) => a.id - b.id);

            return steps;
        },

        brandConfig() {
            return {
                url: `intent_attributes/search_values/brands`,
                options: {
                    name: 'brand',
                    display: 'name'
                }
            };
        },
        materialConfig() {
            return {
                url: `intent_attributes/search_values/materials`,
                options: {
                    name: 'material',
                    display: 'name'
                }
            };
        },
    },

    methods: {
        ...mapActions({
            store: 'Intents/store'
        }),

        setGender(gender) {
            if (this.payload.gender == gender.id) {
                this.payload.gender = '';
            } else {
                this.payload.gender = gender.id
            }
        },

        submit(action) {
            const data = {
                ...this.payload,
                preferences: this.payload.preferences.join(','),
                vendor_category: this.payload.vendor_category.join(','),
                tokens: this.payload.tokens.join(','),
                product_type_id: this.$route.query.pt_id || this.payload.product_type_id,
                intent_id: this.$route.query.intent_id,
            };

            if (!data.product_type_id) {
                return this.swalError('Product type is required', 'Please add a product type');
            }

            if (this.isGuest) {
                this.onthefly = data;
                this.activeTab = 4;
                return;
            }

            this.showLoader('Creating intent... Please wait');

            this.store(data)
            .then(response => {
                sessionStorage.removeItem('last-onthefly-data');

                this.hideLoader();

                if (action === 'addproduct') {
                    this.updateRouteQuery({
                        q: '',
                        pt_id: '',
                        intent_id: response.id
                    });

                    this.activeTab = 3;
                } else {
                    return this.$router.replace({ name: 'customer_view_intent', params: { intent_id: response.id } });
                }
            })
            .catch(error => {
                this.hideLoader();
            })
        },

        setTypeAheadValue(key, value) {
            this.payload[key] = value.name;
        },

        navigate(direction) {
            const activeTab = this.activeTab + direction;

            if (activeTab > 4) return this.activeTab = 4;
            if (activeTab < 0) return this.activeTab = 1;

            this.activeTab = activeTab;
        },

        scroll(element = "scrollhere") {
            this.$nextTick().then(() => {
                let scrollDiv = document.getElementById(element).offsetTop;
                window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
            });
        },

        createIntentFomOntheflyData(data) {
            const {attributes, product_type_name} = data;

            const array_fields = ['tokens', 'preferences', 'vendor_category'];

            for (let key of Object.keys(this.payload)) {
                let value = attributes[key];

                if (value) {
                    if (array_fields.includes(key)) {
                        this.payload[key] = value.split(',').map(val => isNaN(val) ? val : parseInt(val));
                    } else {
                        this.payload[key] = isNaN(value) ? value : parseInt(value);
                    }
                }
            }

            this.ontheflyproduct_type_name = product_type_name;

            this.submit();
        }
    },

    created() {
        const onthefly = sessionStorage.getItem('last-onthefly-data');

        if (!!onthefly) {
            this.createIntentFomOntheflyData(JSON.parse(onthefly));
        }

        const intent_id = this.$route.query.intent_id;

        if (intent_id) {
            this.activeTab = 3;
        }
    }
}
</script>
