<template>
    <div class="base-radio-group mb-3">
        <label class="font-small label">{{ label }}</label>

        <div :class="getLayoutClass">
            <div v-for="(option, index) in options" :key="index" class="flex-grow-1">
                <input :key="'input-' + index" type="radio" :id="`${name}-${index}`" :name="name" :value="option.id" v-model="payload"/>
                <label class="handle" :key="`label-` + index" :for="`${name}-${index}`">
                    <div class="w-100">
                        <i class="mdi mdi-radiobox-blank unchecked"></i>
                        <i class="mdi mdi-radiobox-marked checked"></i>
                        <span class="ml-1 font-small">{{ option.name }}</span>
                    </div>
                    <p class="font-11 desc w-100 m-0">
                        {{ option.description }}
                    </p>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: Array,

        type: {
            type: String,
            default: 'block'
        },

        label: {
            type: [String, Number],
            required: true
        },

        name: {
            type: String,
            required: true
        },

        value: [String, Number]
    },

    data() {
        return {
            payload: null
        }
    },

    computed: {
        getLayoutClass() {
            if (this.type == 'inline') {
                return 'd-flex'
            }

            return 'd-block'
        }
    },

    watch: {
        value() {
            this.setValue();
        },

        payload() {
            this.$emit('input', this.payload);
        }
    },

    methods: {
        setValue() {
            this.payload = this.value;
        }
    },

    mounted() {
        this.setValue();
    }
}
</script>