<template>
    <div class="media-card rounded elevation cursor-pointer" :class="item.theme" @click="navigate">
        <p class="font-weight-bold font-big mb-1 title">
            {{ item.title }}
            <span v-if="item.subtitle" class="ml-2 text-muted font-weight-normal">{{ item.subtitle }}</span>

            <span class="float-right font-xxl" :class="item.icon" v-if="item.icon"></span>

            <img class="avatar-md float-right circle" style="border:2px solid white;" :src="item.image" v-if="item.image"/>
        </p>
        <p class="text-muted title font-weight-bold">{{ item.description }}</p>

        <div class="overlap-images d-flex flex-wrap align-items-center" v-if="posting">

            <template v-for="(advert, index) in posting.data">
                <video
                    autoplay
                    muted
                    loop
                    :key="index"
                    :src="advert.cover_media.file_url"
                    v-if="advert.cover_media.mime_type.includes('video')"/>

                <img
                    v-else
                    :src="advert.cover_media.file_url"
                    :title="advert.name"
                    :key="index" :class="`img-${index}`"/>
            </template>

            <div class="mt-3 mb-3 title text-center w-100" v-if="!posting.data.length">
                {{ item.empty || 'Nothing has been shared with you yet'}}
            </div>

            <a
                href="javascript:void(0)"
                class="more-images ml-3"
                v-if="posting && posting.data.length < 5 && posting.more">{{ posting.more }}+ more</a>
        </div>

        <p class="more-images mt-3 text-right" v-if="posting && posting.more > 0">{{ posting.more }}+ more</p>
    </div>
</template>
<script>
export default {
    props: ['item', 'type'],

    computed: {
        posting() {
            return this.item && this.item.posting;
        }
    },

    methods: {
        navigate() {
            if (!this.posting || !this.posting.data.length) return;

            this.$router.push({name: this.type === 'friends' ? 'customer_friends_hold_list' : 'customer_dashboard_hold_list'});
        }
    },

    mounted() {
        // console.log(this.posting)
    }
}
</script>