<template>
    <div class="">
        <div class="p-2 elevation mb-2" v-if="preferences.length">
            <p class="m-0 mb-1 font-weight-bold text-capitalize text-primary font-xs">Preferences</p>
            <div class="d-flex flex-wrap">
                <img :title="item.name" class="avatar-md elevation rounded mr-1" v-for="item in preferences" :src="item.image_url" :key="item.id"/>
            </div>
        </div>

        <div class="p-2 elevation mb-2" v-for="(item, index) in values" :key="index">
            <p class="m-0 font-weight-bold text-capitalize text-primary font-xs">{{ item.name }}</p>
            <p class="m-0 text-justify" v-html="item.value"></p>
        </div>
    </div>
</template>
<script>
export default {
    props: ['advert', 'intentAttributes'],

    computed: {
        values() {
            const values = this.advert ? this.advert.values : [];

            return values.map(item => {
                let parts = item.value.split(',');

                // console.log(this.intentAttributes[item.name]);

                if (this.intentAttributes && this.intentAttributes[item.name]) {
                    parts = parts.map(part => {
                        if (this.intentAttributes[item.name].includes(part)) {
                            part = `<span class="text-success font-weight-bold">${part}</span>`;
                        }

                        return part;
                    });
                }

                item.value = parts.filter(part => part.length).join(', ');

                return item;
            }).filter(item => item.value && item.value);
        },

        preferences() {
            return this.advert ? this.advert.preferences : [];
        }
    },

    mounted() {
    }
}
</script>