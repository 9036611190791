<template>
    <div class="intent-ad elevation-2 summary-ad d-flex flex-column rounded bg-intent-info-soft" @click.stop.prevent="$emit('view', ad)">
        <div class="poster d-flex mb-1">
            <div class="avatar">
                <img :src="`/img/sneakers/logos/${ad.logo}`"/>
            </div>
            <div class="info flex-grow-1 ml-2">
                <h6 class="name text-primary font-weight-bold mb-1 font-9">
                    {{ ad.name }}
                    <i class="mdi mdi-verified"></i>
                </h6>
                <p class="m-0 font-7 time">{{ ad.id }} months ago</p>
            </div>
        </div>

        <div class="main flex-grow-1">
            <div class="row no-gutters">
                <div class="col-lg-12 col-md-4 col-12 p-1">
                    <div class="media">
                        <img :src="`/img/sneakers/${ad.image}`" class="rounded"/>
                    </div>
                </div>
                <div class="col-lg-12 col-md-8 col-12 p-1 d-flex flex-column">
                    <p class="text font-9 text-justify flex-grow-1">
                        {{ ad.text | trimLg }}
                    </p>

                    <div class="actions">
                        <a href="#" class="btn btn-secondary font-small elevation btn-sm text-uppercase">
                            <i class="mdi mdi-web mr-1"></i> Visit
                        </a>
                        <a href="#" class="btn btn-warning btn-sm elevation font-small text-uppercase float-right">
                            <i class="mdi mdi-pin mr-1"></i> Hold
                        </a>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
export default {
    props: ['ad']
}
</script>