<template>
    <div>
        <span class="text-danger d-block mt-1 ml-1" style="font-size:13px;" v-if="error">{{error}}</span>
        <span class="text-info" style="font-size:13px;margin-top:10px;" v-if="desc && !error">{{ desc }}</span>
    </div>
</template>

<script>
export default {
    props: ['error', 'desc']
}
</script>

<style>

</style>
