export default {
    CLOSE_SIDEBAR(state) {
        state.sidebarOpened = false;
    },

    OPEN_SIDEBAR(state) {
        state.sidebarOpened = true;
    },

    SHOW_LOADER(state, text) {
        state.loader.show = true;

        if (text) {
            state.loader.text = text;
        }
    },

    HIDE_LOADER(state) {
        state.loader = {
            show: false,
            text: 'Processing...'
        };
    }
}