<template>
    <content-wrapper class="">
        <page-title :title="campaign && campaign.name">
            <button v-if="activeTab === 'ads'" @click.prevent="onCreate" class="btn btn-secondary font-small elevation-2 pt-2 pb-2 ml-2">
                <i class="mdi mdi-plus-circle"></i> Create Ad
            </button>
        </page-title>

        <div class="campaign-filters mb-3">
            <div class="row align-items-center">
                <div class="col-md-8 mb-3">
                    <div class="">
                        <button
                            @click.prevent="activeTab = 'analytics'"
                            class="status-btn mb-1 font-xs warning"
                            :class="{'active': activeTab == 'analytics'}"
                            type="button">
                            <i class="mdi mdi-sync"></i> Analytics
                        </button>

                        <button
                            @click.prevent="activeTab = 'ads'"
                            :class="{'active': activeTab == 'ads'}"
                            class="status-btn mb-1 font-xs success"
                            type="button">
                            <i class="mdi mdi-checkbox-marked-circle"></i> Ads
                        </button>


                        <button
                            @click.prevent="activeTab = 'audiences'"
                            :class="{'active': activeTab == 'audiences'}"
                            class="status-btn mb-1 font-xs purple"
                            type="button">
                            <i class="mdi mdi-clock"></i> Audiences
                        </button>
                    </div>
                </div>
                <div class="col-md-4 mb-3">
                    <table-search-input
                        class="flex-grow-1"
                        v-model="keyword"
                        placeholder="Search by keywords...">
                    </table-search-input>
                </div>
            </div>
        </div>

        <analytics :campaign="campaign" v-if="activeTab == 'analytics'"/>

        <ads :campaign="campaign" v-if="activeTab == 'ads'" @refresh="loadData"/>

        <audiences :campaign="campaign" v-if="activeTab == 'audiences'"/>
    </content-wrapper>
</template>

<script>
import bus from '@/event-bus.js';
import Ads from './pages/Ads.vue';

import { mapActions, mapGetters } from 'vuex';
import Analytics from './pages/Analytics.vue';
import Audiences from './pages/Audiences.vue';

export default {
    props: ['campaign_id'],

    components: {
        Ads,
        Analytics,
        Audiences
    },

    data() {
        return {
            activeTab: 'analytics',
            keyword: ''
        }
    },

    computed: {
        ...mapGetters({
            campaign: 'Campaigns/getCurrentCampaign'
        }),

        availableTabs() {
            return ['analytics', 'ads', 'audiences']
        }
    },

    watch: {
        activeTab() {
            this.updateRouteQuery({tab: this.activeTab})
        }
    },

    methods:{
        ...mapActions({
            show: 'Campaigns/show',
        }),

        onCreate() {
            bus.$emit(`create-${this.activeTab}`);
        },

        loadData(query) {
            let params = {
                id: this.campaign_id,
                params: {
                    ...query
                }
            }

            this.showLoader('Loading advert information... Please wait')

            this.show(params)
                .then(response => {
                    this.hideLoader();
                })
                .catch(error => {
                    this.hideLoader();
                })
        },

        setActiveTab() {
            let tab = this.$route.query.tab;

            if (this.availableTabs.includes(tab)) {
                this.activeTab = tab;
            }
        }
    },

    created() {
        this.setActiveTab();

        this.loadData();
    }
}
</script>