<template>
    <content-wrapper no_padding>
        <hold-list/>
    </content-wrapper>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import HoldList from './../hold-list/Index.vue';

export default {
    components: {
        HoldList,
    },

    data() {
        return {
            //
        }
    },
}
</script>