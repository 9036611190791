<template>
    <div class="bottom">
        <!-- <hr/> -->

        <router-link
            :to="{name: 'settings'}"
            href="/common/settings"
            class="font-small rounded"
            active-class="active">
            <i class="mdi mdi-settings-box mr-1 icon"></i>
            Settings
        </router-link>

        <router-link
            :to="{name: 'notifications'}"
            href="/common/notifications"
            class="font-small rounded"
            active-class="active">
            <i class="mdi mdi-bell mr-1 icon"></i>
            Notifications
        </router-link>

        <a href="#" class="font-small rounded" @click.prevent="logout">
            <i class="mdi mdi-logout mr-1 icon"></i>
            Logout
        </a>
    </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    methods: {
        ...mapActions({
            authLogout: 'Authentications/logout'
        }),

        logout() {
            this.showLoader('logging out... Please wait');

            this.authLogout()
                .then(response => {
                    this.hideLoader();
                })
                .catch(error => {
                    this.hideLoader();
                });
        }
    }
}
</script>