import { axios } from './index';

export default {
    index(params) {
        let url = 'status_updates';

        if (params.onthefly) {
            url += '/onthefly';
        }

        return axios.get(url, { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    interact(params) {
        return axios.post(`status_updates/interactions`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    holdOrUnhold(params) {
        return axios.post(`status_updates/hold_unhold`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    getHoldList(params) {
        return axios.get(`status_updates/hold_list`, {params})
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    getComments(params) {
        return axios.get(`status_updates/notes`, {params})
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    addComment(params) {
        return axios.post(`status_updates/notes`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    createViews(params) {
        return axios.post(`status_updates/views`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    updateViews(params) {
        return axios.put(`status_updates/views`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    delete(id) {
        return axios.delete(`status_updates/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    updateClicks(params) {
        return axios.post(`status_updates/clicks`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    getReportReasons(params) {
        return axios.get(`status_updates/report_reasons`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    reportAdvert(params) {
        return axios.post(`status_updates/report_advert`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    shareAdvert(params) {
        return axios.post(`status_updates/share_advert`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    votePoll(params) {
        return axios.post(`status_updates/polls/vote`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    getRelatedAds(params) {
        return axios.get(`status_updates/related_postings`, {params})
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },
}