import { axios } from './index';

export default {
    /**
     * Get available intent
     *
     * @param {Object} params
     * @returns {Object}
     */
    index(params) {
        return axios.get('merchant_team_members', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Store intent
     *
     * @param {Object} data
     * @returns {Object}
     */
    store(data) {
        return axios.post('merchant_team_members', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    update(id, data) {
        return axios.put(`merchant_team_members/${id}`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    show(id, params) {
        return axios.get(`merchant_team_members/${id}`, { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    destroy(id) {
        return axios.delete(`merchant_team_members/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    }
}