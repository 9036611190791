import Creative from './Creative.vue';
import AnimalsPets from './AnimalsPets.vue';
import BusinessIndustry from './BusinessIndustry.vue';
import CarsAutomobiles from './CarsAutomobiles.vue';
import CookingFoodDrink from './CookingFoodDrink.vue';
import Education from './Education.vue';
import Entertainment from './Entertainment.vue';
import Fashion from './Fashion.vue';
import HealthBeauty from './HealthBeauty.vue';
import HomeGarden from './HomeGarden.vue';
import PersonalDev from './PersonalDevelopment.vue';
import Sports from './Sports.vue';
import Tech from './Technology.vue';
import Traveling from './Traveling.vue';

export default {
    'arts-creative': Creative,
    'cars-automobiles': CarsAutomobiles,
    'business-industry': BusinessIndustry,
    'health-beauty': HealthBeauty,
    'education': Education,
    'cooking-food-drink': CookingFoodDrink,
    'entertainment': Entertainment,
    'fashion': Fashion,
    'animals-pets': AnimalsPets,
    'home-garden': HomeGarden,
    'personal-development': PersonalDev,
    'sports': Sports,
    'technology': Tech,
    'traveling': Traveling
}