<template>
    <div class="p-2 bg-white elevation rounded">

        <div class="bg-random1 p-2 rounded mb-2">
            <h4 class="font-small font-weight-bold text-blue">Original Price</h4>
            <p class="m-0">USD {{ offer.price | numberFormat }}</p>
        </div>


        <div v-if="showXY" class="bg-random2 p-2 rounded mb-2">
            <h4 class="font-small font-weight-bold text-blue">{{ category.name.replace(/x/i, offer.x_quantity).replace(/\sy/i, ` ${offer.y_quantity}`) }}</h4>
            <p class="m-0">Whenever you buy {{ offer.x_quantity }}, you will get free {{ offer.y_quantity }}</p>
        </div>

        <div v-if="showFixedPrice" class="bg-random2 p-2 rounded mb-2">
            <h4 class="font-small font-weight-bold text-blue">{{ category.name }}</h4>
            <p class="m-0">You get a discounted price of <b>USD {{ offer.discount_price | numberFormat}}</b></p>
        </div>

        <div v-if="showXPercentOff" class="bg-random2 p-2 rounded mb-2">
            <h4 class="font-small font-weight-bold text-blue">{{ category.name.replace(/x\s/i, offer.percent_off) }}</h4>
            <p class="m-0">You will get <b>{{ offer.percent_off }}%</b> off the Original price</p>
        </div>

        <div v-if="showXamountOff" class="bg-random2 p-2 rounded mb-2">
            <h4 class="font-small font-weight-bold text-blue">USD {{ category.name.replace(/x/i, amountOff) }}</h4>
            <p class="m-0">You will get <b>USD {{ amountOff }}</b> off the Original price</p>
        </div>

        <div v-if="showFreeShippingAboveX" class="bg-random3 p-2 rounded mb-2">
            <h4 class="font-small font-weight-bold text-blue">{{ shipping.name.replace(/x\s(Amount)/i, shippingAmount) }}</h4>
            <p class="m-0">Buy more than {{shippingAmount}} worth of this product and we will ship it to you for free</p>
        </div>

        <div v-if="showFreeShipping" class="bg-random3 p-2 rounded mb-2">
            <h4 class="font-small font-weight-bold text-blue">{{ shipping.name }}</h4>
            <p class="m-0">We  will ship this product to you for free</p>
        </div>
    </div>
</template>
<script>
export default {
    props: ['advert'],

    computed: {
        amountOff() {
            return this.$options.filters.numberFormat(this.offer.amount_off)
        },

        shippingAmount() {
            return 'USD ' + this.$options.filters.numberFormat(this.offer.free_shipping_above);
        },

        offer() {
            return this.advert && this.advert.offer;
        },

        category() {
            return  this.offer && this.offer.category;
        },

        shipping() {
            return  this.offer && this.offer.shipping;
        },

        showXY() {
            // return true;
            return this.category && this.category.name == 'Buy X Get Y Free';
        },

        showFixedPrice() {
            // return true;
            return this.category && this.category.name == 'Fixed Price';
        },

        showXPercentOff() {
            // return true;
            return this.category && this.category.name == 'X % off';
        },

        showXamountOff() {
            // return true;
            return this.category && this.category.name == 'X amount off';
        },

        showFreeShippingAboveX() {
            // return true;
            return this.shipping && this.shipping.name == 'Free Shipping Above X Amount';
        },

        showFreeShipping() {
            // return true;
            return this.shipping && this.shipping.name == 'Free Shipping';
        }
    }
}
</script>