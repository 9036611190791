<template>
    <div class="pb-3">
        <view-audience :audience="audience" v-if="tab == 'view'"/>

        <add-audience
            class="p-2"
            v-if="tab == 'create'"
            :campaign_id="advert && advert.campaign_id"
            :advert_id="advert && advert.id"
            :campaign="campaign"/>

        <div class="audience-list rounded p-2" v-if="tab == 'existing'">
            <template v-if="audiences.length">
                <div class="row">
                    <div class="col-md-6" v-for="(item, index) in audiences" :key="index">
                        <div class="audience-item" :class="{'active': selectedAudience && selectedAudience.id == item.id}" @click="selectedAudience = item">
                            <div class="label-color" :style="{background: item.label_color}"></div>
                            <div class="basic flex-grow-1">
                                <p class="name text-tertiary font-weight-bold font-small">{{ item.name }}</p>
                                <p class="desc text-muted">
                                    <span class="badge bg-primary-light-3 p-1 m-1" v-if="item.country">
                                        <span class="font-weight-normal">Country</span>: <b>{{ item.country.name }}</b>
                                    </span>
                                    <span class="badge bg-primary-light-3 p-1 m-1" v-if="item.state">
                                        <span class="font-weight-normal">State</span>: <b>{{ item.state.name }}</b>
                                    </span>
                                    <span class="badge bg-primary-light-3 p-1 m-1" v-if="item.city">
                                        <span class="font-weight-normal">City</span>: <b>{{ item.city }}</b>
                                    </span>

                                    <span class="badge bg-primary-light-3 p-1 m-1" v-if="item.gender">
                                        <span class="font-weight-normal">Gender</span>: <b class="text-capitalize">{{ item.gender }}</b>
                                    </span>

                                    <span class="badge bg-primary-light-3 p-1 m-1" v-if="item.gender">
                                        <span class="font-weight-normal">Age</span>: <b class="text-capitalize">{{ item.age_from }} to {{ item.age_to }}</b>
                                    </span>
                                </p>
                            </div>
                            <!-- <div class="select-color"></div> -->
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center mt-4" v-if="showUpdateAudience">
                    <div class="col-12 col-md-6">
                        <button type="button" @click.prevent="updateAudience" class="btn btn-success login-btn btn-sm font-small btn-block">
                            <i class="mdi mdi-content-save-all"></i> SAVE SELECTED AUDIENCE
                        </button>
                    </div>
                </div>
            </template>

            <empty title="No existing audience found. Consider creating a new audience" v-else/>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import AddAudience from './partials/AddAudience';
import ViewAudience from './partials/ViewAudience';

export default {
    props: ['tab', 'advert', 'campaign'],

    components: {
        AddAudience,
        ViewAudience
    },

    data() {
        return {
            showAddAudience: false,
            selectedAudience: null
        }
    },

    computed: {
        ...mapGetters({
            audiences: 'Audience/getAll'
        }),

        audience() {
            if (this.advert) return this.advert.audience;
        },

        showUpdateAudience() {
            return this.selectedAudience && this.selectedAudience.id != this.campaign.audience_id
        }
    },

    watch: {
        tab() {
            if (this.tab == 'existing') {
                this.loadData();
            }
        }
    },

    methods: {
        ...mapActions({
            getAudiences: 'Audience/index',
            update: 'Adverts/update'
        }),

        updateAudience() {
            if (!this.showUpdateAudience) return;

            this.showLoader('Updating advert audience... Please wait');

            this.update({
                id: this.advert.id, 
                data: {
                    audience_id: this.selectedAudience.id,
                    stage: 'fields'
                }
            })
                .then(response => {
                    console.log(response);
                    this.hideLoader();
                })
                .catch(error => {
                    this.hideLoader();
                })
        },

        loadData() {
            this.getAudiences()
            .then(response => {
                this.selectedAudience = this.audience;
            })
            .catch(error => null);
        }
    },

    created() {
        // this.loadData();
    }
}
</script>