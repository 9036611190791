<template>
<custom-modal :title="payload.name || title" @cancel="$emit('cancel')">
    <div class="row">
        <div class="col-md-12">
            <template  v-if="advert">
                <app-card class="">
                    <h5 class="text-tertiary font-weight-bold font-small mb-4">Describe Ad</h5>

                    <div class="row">
                        <div class="col-md-12">
                            <base-input
                                label="Name"
                                placeholder="Give this ad a short & descriptive name"
                                v-model="payload.name"/>
                        </div>
                        <div class="col-md-12">
                            <base-textarea
                                label="Subtitle/Description"
                                placeholder="Give this ad a subtitle or description"
                                v-model="payload.subtitle"/>
                        </div>
                    </div>
                </app-card>

                <categories :advert="advert"/>

                <attributes :advert="advert"/>

                <media-setup :advert="advert"/>

                <call-to-action :advert="advert"/>

                <audience :advert="advert"/>

                <offer :advert="advert"/>

                <return-policy :advert="advert"/>

                <layout
                    :close="false"
                    v-model="pollsTab"
                    :availableTabs="availableTabs">
                    <create-poll 
                        :advert="editableAdvert" 
                        v-if="pollsTab == 'edit'" 
                        @refresh="$emit('refresh')"
                        class="mt-4 mb-4"/>

                    <poll-versions 
                        :advert="editableAdvert" 
                        v-if="pollsTab == 'versions'" 
                        class="p-1"/>
                </layout>
            </template>
        </div>
        <!-- <div class="col-md-5">
            <app-card>
                Review
            </app-card>
        </div> -->
    </div>

    <template slot="footer">
        <div class="float-left bg-soft-success p-2 rounded" v-if="loading">
            <i class="mdi mdi-sync rotate"></i> Saving...
        </div>

        <button type="button" @click="publish" class="btn btn-success font-small" v-if="showPublished">
            Publish
        </button>

        <button type="button" @click="unpublish" class="btn btn-success font-small" v-if="!showPublished">
            Unpublish
        </button>
    </template>
</custom-modal>
</template>

<script>
import Offer from './Offer.vue';
import Audience from './Audience.vue';
import Categories from './Categories.vue';
import Attributes from './Attributes.vue';
import MediaSetup from './MediaSetup.vue';
import CallToAction from './CallToAction.vue';
import ReturnPolicy from './ReturnPolicy.vue';
import CreatePoll from '../poll/CreatePoll.vue';
import PollVersions from '../poll/Versions.vue';
import Layout from '@/views/merchant/profile/partials/Layout.vue';

import { mapActions } from 'vuex';
import bus from '@/event-bus.js';
import { debounce } from 'lodash';

export default {
    props: ['editableAdvert'],

    components: {
        Offer,
        Layout,
        CreatePoll,
        PollVersions,
        Audience,
        Categories,
        Attributes,
        MediaSetup,
        ReturnPolicy,
        CallToAction
    },

    data() {
        return {
            loading: false,

            payload: {
                name: '',
                subtitle: ''
            },

            advert: null,

            pollsTab: 'edit'
        }
    },

    computed: {
        availableTabs() {
            return [
                {id: 'edit', name: 'Create New', icon: `mdi mdi-pencil`},
                {id: 'versions', name: 'Versions', icon: `mdi mdi-clock`},
            ]
        },

        title() {
            if (!this.advert) return "Create an Ad";

            if (this.advert) {
                return this.advert.name;
            }

            return "Create Related Ads";
        },

        showPublished() {
            return this.advert?.status?.name !== 'Published'
        }
    },

    watch: {
        editableAdvert: {
            deep: true,
            immediate: true,
            handler() {
                this.showAdvert();
            }
        },

        advert: {
            deep: true,
            immediate: true,
            handler() {
                if (this.advert) {
                    this.populate();
                }
            }
        },

        payload: {
            deep: true,
            immediate: true,
            handler() {
                if (!this.advert) return;

                this.updateAdvert({
                    id: this.advert.id,
                    data: {
                        ...this.payload,
                        stage: 'fields'
                    }
                })
            }
        },
    },

    methods: {
        ...mapActions({
            show: 'Adverts/show',
            update: 'Adverts/update',
            getAttributeValues: 'Adverts/getAttributeValues',
            publishPosting: 'Adverts/publishPosting',
            unpublishPosting: 'Adverts/unpublishPosting',
        }),

        async publish() {
            let result = await this.swalConfirm(
                `Publish ${this.advert?.name}?`,
                `Once published, customers will start seeing your advert.`,
                'Publish'
            );

            if (!result.value) return;

            this.showLoader('Publishing... Please wait')

            this.publishPosting({advert_id: this.advert.id})
            .then(response => {
                this.hideLoader()
                console.log(response);
            })
            .catch(error => {
                this.hideLoader()
                console.log(error);
            })
        },

        async unpublish() {
            let result = await this.swalConfirm(
                `Unpublish ${this.advert?.name}?`,
                `Once published, customers will no longer see seeing your advert.`,
                'Confirm'
            );

            if (!result.value) return;

            this.showLoader('Unpublishing... Please wait')

            this.unpublishPosting({advert_id: this.advert.id})
            .then(response => {
                this.hideLoader()
                console.log(response);
            })
            .catch(error => {
                this.hideLoader()
                console.log(error);
            })
        },

        showAdvert() {
            this.showLoader(`Getting ad details... Please wait`);
            this.show(this.editableAdvert.id)
            .then(response => {
                this.hideLoader();
                this.$set(this, 'advert', response);
            })
            .catch(error => {
                this.hideLoader();
            })
        },

        populate() {
            this.payload.name = this.advert.name;
        },

        updateAdvert: debounce(function (data) {
            this.loading = true;

            // console.log(data)

            this.update(data)
                .then(response => {
                    this.loading = false;

                    this.$set(this,  'advert', {...response});

                    // console.log(response)

                    bus.$emit('create-ad > categories', response.product_types || []);
                    bus.$emit('create-ad > preferences', response.preferences || []);

                    this.hideLoader();
                })
                .catch(error => {
                    this.loading = false;

                    this.hideLoader();

                    this.swalError(error.message);
                })
        }, 1000),

        loadAttributeValues() {
            this.getAttributeValues({advert_id: this.editableAdvert.id})
            .then(values => null)
            .catch(error => {
                console.log(error);
            });
        }
    },

    mounted() {
        this.loadAttributeValues();
    },

    created() {
        bus.$on('update-advert', this.updateAdvert);
    },

    beforeDestroy() {
        bus.$off('update-advert', this.updateAdvert);
    }
}
</script>

<style>

</style>