<template>
<div>
    <layout title="Main Ad" :close="false" v-if="advert">
        <div class="table-responsive bg-white m-0 p-1">
            <table class="table m-0">
                <tbody>
                    <ad-item :advert="advert" @edit="edit" @view="view"/>
                </tbody>
            </table>
        </div>
    </layout>

    <layout title="Related Ads" class="mt-3" :close="false" v-if="relatedAds.length">
        <div class="table-responsive bg-white p-1">
            <table class="table m-0">
                <tbody>
                    <ad-item
                        v-for="(ad, index) in relatedAds"
                        :key="index"
                        :advert="ad"
                        @edit="edit"
                        @view="view"/>
                </tbody>
            </table>
        </div>
    </layout>


    <empty v-if="!advert" title="No campaign found"/>

    <create-ad
        v-if="showCreateAdvertModal"
        :editableAdvert="editableAdvert"
        @cancel="showCreateAdvertModal = false"
        @refresh="$emit('refresh')"/>

    <analytics
        v-if="viewableAdvert"
        :viewableAdvert="viewableAdvert"
        @cancel="viewableAdvert = null"/>

    <update-product-type
        :show="showModals.product_types"
        :advert="editableAdvert"
        @cancel="showModals.product_types = false"/>

    <update-attributes
        :show="showModals.attributes"
        :advert="editableAdvert"
        @cancel="showModals.attributes = false"/>

    <update-preferences
        :show="showModals.preferences"
        :advert="editableAdvert"
        @cancel="showModals.preferences = false"/>

    <update-media
        :show="showModals.media"
        :advert="editableAdvert"
        @cancel="showModals.media = false"/>

    <update-testimonial
        :show="showModals.testimonial"
        :advert="editableAdvert"
        @cancel="showModals.testimonial = false"/>

    <update-audience
        :show="showModals.audience"
        :advert="editableAdvert"
        :campaign="campaign"
        @cancel="showModals.audience = false"/>
</div>
</template>

<script>
import bus from '@/event-bus.js';
import AdItem from './partials/AdItem.vue';
import CreateAd from './create-ad/Index.vue';
import { mapActions, mapGetters } from 'vuex';
import Layout from '@/views/merchant/profile/partials/Layout.vue';
import UpdateProductType from '../../partials/product-types/UpdateProductType.vue';
import UpdateAttributes from './create-ad/partials/UpdateAttributes.vue';
import UpdatePreferences from './create-ad/partials/UpdatePreferences.vue';
import UpdateMedia from './create-ad/partials/UpdateMedia.vue';
import UpdateTestimonial from './create-ad/partials/UpdateTestimonial.vue';
import UpdateAudience from './create-ad/partials/UpdateAudience.vue';
import Analytics from './ad-analytics/Index.vue';

export default {
    props: ['campaign'],

    components: {
        AdItem,
        Layout,
        CreateAd,
        Analytics,
        UpdateMedia,
        UpdateAudience,
        UpdateAttributes,
        UpdateProductType,
        UpdatePreferences,
        UpdateTestimonial,
    },

    data() {
        return {
            showCreateAdvertModal: false,

            showModals: {
                product_types: false,
                attributes: false,
                preferences: false,
                media: false,
                audience: false,
                testimonial: false,
            },

            editableAdvert: null,

            viewableAdvert: null
        }
    },

    computed: {
        ...mapGetters({
        }),

        advert() {
            return this.campaign && this.campaign.advert;
        },

        relatedAds() {
            if (this.campaign && this.campaign.related_ads && Array.isArray(this.campaign.related_ads)) {
                return this.campaign.related_ads;
            }

            return [];
        }
    },

    watch: {
    },

    methods:{
        ...mapActions({
            index: 'Adverts/index',
            store: 'Adverts/store'
        }),

        edit(advert) {
            if (!advert) {
                this.editableAdvert = null;
                this.showCreateAdvertModal = false;
                return;
            }

            this.editableAdvert = advert;
            this.showCreateAdvertModal = true;
        },

        view(advert) {
            this.viewableAdvert = advert;
            this.showViewAdvertModal = true;
        },

        loadData(query) {
            let params = {
                paginate: true,
                per_page: 20,
                ...query,
                ...this.params,
                keyword: this.keyword
            }

            this.loading = true;

            this.showLoader('Loading ads... Please wait');

            this.index(params)
                .then(response => {
                    this.hideLoader();

                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.hideLoader();
                })
        },

        createAdvert() {
            this.showLoader('Creating ad... Please wait');

            this.store({campaign_id: this.campaign.id})
                .then(response => {
                    this.hideLoader();

                    this.editableAdvert = response;

                    if (!this.campaign.advert && !this.campaign.main_advert_id) {
                        this.campaign.main_advert_id = response.id;
                        this.campaign.advert = response;
                    } else {
                        this.campaign.related_ads.push(response);
                    }

                    this.edit(response);
                })
                .catch(error => {
                    this.hideLoader();

                    this.swalError(error.message);
                });
        },

        showPartialsModal(key) {
            this.showModals[key] = true;
        }
    },

    created() {
        bus.$on('create-ads', this.createAdvert)
        bus.$on('create-ad > show-modal', this.showPartialsModal)
    },

    beforeDestroy() {
        bus.$off('create-ads', this.createAdvert)
        bus.$off('create-ad > show-modal', this.showPartialsModal);
    }
}
</script>