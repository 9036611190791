import Settings from '@/views/common/settings/Index.vue';
import Notifications from '@/views/common/notifications/Index.vue';


let routes = [
    {
        name: 'notifications',
        path: 'notifications',
        component: Notifications,
        meta: {
            title: 'Notifications'
        }
    },

    {
        name: 'settings',
        path: 'settings',
        component: Settings,
        meta: {
            title: 'Settings'
        }
    },


];

export default routes;