<template>
    <app-card class="profile-cover">
        <div class="cover-img bg-default rounded">
            <img :src="coverPreviewUrl" v-if="coverPreviewUrl"/>
        </div>

        <label for="cover-img" class="elevation-2 rounded p-2 font-xs cursor-pointer">
            <i class="mdi mdi-camera text-success"></i>
            Edit Cover Photo
            <input
                type="file"
                accept="images/*"
                id="cover-img"
                @change="uploadCover"
                style="display:none;"/>
        </label>

        <div class="profile-img bg-white elevation-2">
            <img :src="profilePreviewUrl" v-if="profilePreviewUrl"/>

            <label for="profile-img" class="elevation-2">
                <i class="mdi mdi-camera"></i>
                <input
                    type="file"
                    accept="images/*"
                    id="profile-img"
                    @change="uploadProfile"
                    style="display:none;"/>
            </label>
        </div>
    </app-card>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            coverPreviewUrl: '',
            profilePreviewUrl: '',

            files: {
                cover: null,
                profile: null
            }
        }
    },

    computed: {
        NOT_FOUND() {
            return 'NOT_FOUND'
        }
    },

    methods: {
        ...mapActions({
            saveProfile: 'Merchants/uploadProfileCover'
        }),

        uploadCover(event) {
            this.processImageFile(event, 'cover');
        },

        uploadProfile(event) {
            this.processImageFile(event, 'profile');

            this.saveProfileImage(this.files.profile, 'logo')
        },

        processImageFile(event, preview)
        {
            let file = event.target.files && event.target.files.length ? event.target.files[0] : null;

            if (!file) {
                reject(this.NOT_FOUND)
            }

            this.files[preview]= file;

            const reader = new FileReader();

            reader.onload = (e) => {
                this[`${preview}PreviewUrl`]= e.target.result;
            }

            reader.readAsDataURL(file);
        },

        async uploadToBucket(file, url) {
            fetch(url, {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': file.type,
                    'Cache-Control': 'public,max-age=31536000,immutable',
                    'x-amz-acl': 'public-read',
                }
            });
        },

        saveProfileImage(file, name) {
            this.saveProfile({name, mime_type: file.type})
            .then(response => {
                // console.log(response)
                return this.uploadToBucket(file, response.url);
            })
            .catch(error => {
                console.log(error)
            })
        }
    }
}
</script>