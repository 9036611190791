<template>
    <div class="inner-tab">
        <!-- <h3>Create Invoice</h3>
        <p>04 Aug 2021</p> -->

        <div class="buttons">
            <button :class="{'active': value === tab.id}" type="button" v-for="tab in tabs" :key="tab.id" class="item font-small" @click="$emit('input', tab.id)">
                {{ tab.name }}
                <i :class="tab.icon" class="font-big ml-2"></i>
            </button>
        </div>
    </div>
</template>
<script>
export default {
    props: ['tabs', 'value']
}
</script>