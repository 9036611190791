<template>
    <content-wrapper>
        <page-title>Dashboard</page-title>

        <!-- <div class="mt-1">
            <date-info class="mb-1 p-1"/>


            <div class="row no-gutters">
                <div class="col-6 p-1" v-for="n in 4" :key="n">
                    <data-card/>
                </div>
            </div>

            <div class="p-1 mt-3">
                <intent-loader v-if="loading_pinned"/>
                <intent :isPinned="true" :intent="pinnedIntent" v-if="pinnedIntent"/>
            </div>
        </div> -->
    </content-wrapper>
</template>
<script>
import { mapActions } from 'vuex'
import Partials from './partials'
import Intent from '@/views/customer/intents/partials/Intent.vue';
import IntentLoader from '@/views/customer/intents/partials/IntentLoader.vue';

export default {
    components: {
        ...Partials,
        Intent,
        IntentLoader
    },

    data() {
        return {
            loading_pinned: true,
            pinnedIntent: null
        }
    },

    methods: {
        ...mapActions({
            getPinnedIntent: 'Intents/getPinned'
        }),

        loadPinnedIntent() {
            this.loading_pinned = true;

            this.getPinnedIntent()
                .then(response => {
                    this.loading_pinned = false;
                    this.pinnedIntent = response;
                })
                .catch(error => {
                    this.loading_pinned = false;
                })
        }
    },

    created() {
        // this.loadPinnedIntent()
    }
}
</script>
