import { axios } from './index';

export default {
    /**
     * Get available intent
     *
     * @param {Object} params
     * @returns {Object}
     */
    index(params) {
        return axios.get('campaigns', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Store intent
     *
     * @param {Object} data
     * @returns {Object}
     */
    store(data) {
        return axios.post('campaigns', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    update(id, data) {
        return axios.put(`campaigns/${id}`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    show(id, params) {
        return axios.get(`campaigns/${id}`, { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    destroy(id) {
        return axios.delete(`campaigns/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    getObjectives(params) {
        return axios.get(`campaigns/postings/objectives`, { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    createThumbnailData(params) {
        return axios.get(`campaigns/create-thumbnail`, { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    publish(data) {
        return axios.post(`campaigns/publish`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    deactivate(data) {
        return axios.post(`campaigns/deactivate`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    getMatchingStrategies(params) {
        return axios.get(`campaigns/postings/matching_strategies`, {params})
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    storeImpressions(data) {
        return axios.post(`campaigns/postings/impressions`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    }
}