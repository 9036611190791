<template>
    <p class="text-center mt-3 d-flex empty-label">
        <i class="icon" :class="`${icon} ${iconClass}`"></i>
        <span class="font-small" :class="textClass">{{ title }}</span>

        <slot/>
    </p>
</template>

<script>
export default {
    props: {
        title: {
            type: String
        },

        icon:{
            type: String,
            default: 'mdi mdi-emoticon-sad-outline'
        },

        textClass: {
            type: String,
            default: 'text-muted'
        },

        iconClass: {
            type: String,
            default: 'text-default-dark'
        }
    }
}
</script>