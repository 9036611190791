<template>
    <div class="login-page">
        <heading title="Complete Your Account Setup" description="Elevate your shopping game"/>

        <complete-setup :show="show" @done="done" @logout="signOut"/>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
import CompleteSetup from './partials/CompleteSetup.vue'
import Heading from './partials/GuestHeading.vue'
export default {
    components: {
        Heading,
        CompleteSetup
    },

    data() {
        return {
            show: false
        }
    },

    methods: {
        ...mapActions({
            logout: 'Authentications/logout'
        }),

        done() {
            this.show = false;

            setTimeout(() => {
                this.toDashboard();
            }, 0);
        },

        signOut() {
            this.show = false;

            setTimeout(() => {
                this.logout()
                    .catch(error => null);
            }, 0);
        },
    },

    mounted() {
        if (!this.authUser) {
            return this.$router.replace({name: 'login'});
        }

        if (!this.authUser.phone || !this.authUser.country_id) {
            this.show = true;
        } else {
            this.toDashboard();
        }
    }
}
</script>