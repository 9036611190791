<template>
    <div class="create-intent-steps">
        <template v-for="(step, index) in steps">
            <div @click="switchTab(step.id)" class="item" :key="`item-${index}`" :class="{'active': step.id <= value}">
                <component :is="step.icon"/>
                <span class="name" v-html="step.name"></span>
            </div>

            <div
                class="line mb-4"
                v-if="index < (steps.length - 1)"
                :key="`line-${index}`"
                :class="{'active': step.id < value}">
            </div>
        </template>
    </div>
</template>
<script>
import AddProductIcon from '@/components/icons/AddProduct.vue';
import AddPreferenceIcon from '@/components/icons/AddPreference.vue';
import FinishIcon from '@/components/icons/Finish.vue';

export default {
    props: ['steps', 'value'],

    components: {
        AddProductIcon,
        AddPreferenceIcon,
        FinishIcon
    },

    methods: {
        switchTab(tab) {
            if (tab == 4) return;

            this.$emit('input', tab);
        }
    }
}
</script>