
import Campaigns from '@/views/merchant/campaigns/Index.vue';
import ViewCampaign from '@/views/merchant/campaigns/view/Index.vue';
import TeamManagement from '@/views/merchant/team/Index.vue';
import Dashboard from '@/views/merchant/dashboard/Index.vue';
import ProfilePage from '@/views/merchant/profile/Index.vue'
import SupportTickets from '@/views/merchant/tickets/Index.vue';

import Media from '@/views/merchant/media/Index.vue';
import Audience from '@/views/merchant/audience/Index.vue';

let routes = [
    {
        name: 'dashboard',
        path: 'dashboard',
        component: Dashboard,
        meta: {
            title: 'Dashboard'
        }
    },

    {
        name: 'campaigns',
        path: 'campaigns',
        component: Campaigns,
        meta: {
            title: 'Campaigns'
        }
    },

    {
        name: 'media',
        path: 'media',
        component: Media,
        meta: {
            title: 'Media'
        }
    },

    {
        name: 'audience',
        path: 'audience',
        component: Audience,
        meta: {
            title: 'Media'
        }
    },

    {
        name: 'view_campaign',
        path: 'campaigns/view/:campaign_id',
        component: ViewCampaign,
        props: true,
        meta: {
            title: 'View Campaign'
        }
    },

    {
        name: 'teams',
        path: 'teams',
        component: TeamManagement,
        meta: {
            title: 'Manage Team'
        }
    },

    {
        name: 'profile',
        path: 'profile',
        component: ProfilePage,
        meta: {
            title: 'Profile Page'
        }
    },

    {
        name: 'support_tickets',
        path: 'tickets',
        component: SupportTickets,
        meta: {
            title: 'Support Tickets'
        }
    },
]

export default routes.map(route => {
    return {...route, name: `merchant_${route.name}`}
});