import Service from '@/services/intents';

const state = {
    all: [],
    pageDetails: {
        to: 0,
        from: 0,
        total: 0,
        perPage: 0,
        lastPage: 0,
        currentPage: 0,
    },

    colorValues: [
        "Red",
        "Orange",
        "Yellow",
        "Green",
        "Blue",
        "Cyan",
        "Purple",
        "Brown",
        "Magenta",
        "Tan",
        "White",
        "Black",
        "Olive",
        "Maroon",
        "Navy",
        "Aquamarine",
        "Turquoise",
        "Silver",
        "Lime",
        "Teal",
        "Indigo",
        "Violet",
        "Pink",
        "Gray",
    ],

    sizes: ['S', 'M', 'L', 'XL', 'XXL'],
};

const getters = {
    getAll(state) {
        return state.all;
    },

    getPageDetails(state) {
        return state.pageDetails;
    },

    getColors(state) {
        return state.colorValues;
    },

    getSizes(state) {
        return state.sizes;
    }
};

const mutations = {
    SET_INTENTS(state, { clearState, payload }) {
        state.pageDetails = {
            total: payload.total || 0,
            from: payload.from || 0,
            to: payload.to || 0,
            currentPage: payload.current_page || 0,
            lastPage: payload.last_page || 0,
            perPage: payload.per_page || 0
        }

        let all = payload.data ? payload.data : payload;

        if (clearState) {
            state.all = all;
        } else {
            state.all = [...state.all, ...all];
        }
    },

    ADD_INTENT(state, intent) {
        state.all.unshift(intent);
    },

    UPDATE_INTENT(state, response) {
        for (let [index, intent] of state.all.entries()) {
            if (intent.id == response.id) {
                state.all[index] = response;
                return;
            }
        }
    },

    REMOVE_INTENT(state, id) {
        for (let [index, intent] of state.all.entries()) {
            if (intent.id == id) {
                state.all.splice(index, 1);
                return;
            }
        }
    }
};

const actions = {
    index({ commit }, params) {
        return Service.index(params)
            .then(response => {
                commit('SET_INTENTS', { clearState: params.clearState, payload: response });
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    store({ commit }, params) {
        return Service.store(params)
            .then(response => {
                commit('ADD_INTENT', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    update({ commit }, params) {
        return Service.update(params.id, params.data)
            .then(response => {
                commit('UPDATE_INTENT', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    show({commit}, id) {
        return Service.show(id)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    destroy({commit}, id) {
        return Service.destroy(id)
            .then(response => {
                commit('REMOVE_INTENT', id);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    pin({ commit }, data) {
        return Service.pin(data)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    getPreferences({ commit }, params) {
        return Service.getPreferences(params)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    saveAttribute({ commit }, params) {
        return Service.saveAttribute(params)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    revoveAttribute({ commit }, id) {
        return Service.revoveAttribute(id)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    updatePreferences({ commit }, params) {
        return Service.updatePreferences(params)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    checkDuplicates({ commit }, params) {
        return Service.checkDuplicates(params)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    shareIntent({ commit }, params) {
        return Service.shareIntent(params)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
}