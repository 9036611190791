import Service from '@/services/dashboards';

const state = {
    customerSummary: {
        hold_list: {
            data: [],
            more: 0
        },
        expires_soon: {
            data: [],
            more: 0
        },
        interests: {
            data: [],
            more: 0
        },
        preferences: {
            data: [],
            more: 0
        }
    }
};

const getters = {
    getCustomerSummary(state) {
        return state.customerSummary;
    }
};

const mutations = {
    SET_CUSTOMER_SUMMARY(state, payload) {
        state.customerSummary = {
            ...state.customerSummary,
            ...payload
        };
    }
};

const actions = {
    getCustomerSummary({ commit }, params) {
        return Service.getCustomerSummary(params)
            .then(response => {
                commit('SET_CUSTOMER_SUMMARY', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
}