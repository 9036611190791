<template>
<modal
    :show="show"
    :centered="true"
    :showFooter="true"
    :maxHeight="true"
    okText="Add Note"
    :showOk="false"
    @cancel="cancel">

    <h5 class="m-0 font-small font-weight-normal text-capitalize text-truncate" slot="header">
        <span v-if="advert">Add Note to {{ advert && advert.name }}</span>
        <span v-else>View comments</span>
    </h5>

    <div slot="body" class="p-1 font-small hold-list-item">
        <div class="d-flex align-items-center" v-if="advert">
            <base-textarea
                :noLabel="true"
                reference="note"
                :rows="2"
                placeholder="e.g Add notes/comments to this advert"
                v-model="payload.text"
                :error="validationErrors.text"
                class="flex-grow-1"
            />

            <div class="text-right ml-2">
                <button type="button" class="btn btn-primary" @click.prevent="submit">Submit</button>
            </div>
        </div>

        <div class="content">
            <div class="notes p-2">
                <div class="note pb-2 font-xs" v-for="(note, index) in notes" :key="index" :class="{'text-right': note.user_id === authUser.id}">
                    <p class="text-danger text-shadow mb-1">
                        @{{note.user.username}}
                        <span class="text-muted font-xs">{{ note.created_at | fromNow }}</span>
                    </p>
                    <p class="mb-1" v-if="!advert">
                        <span class="font-xs bg-soft-primary p-1 pr-3 pl-3 rounded ml-2">{{ note.advert.name }}</span>
                    </p>
                    <p class="m-0">{{ note.text }}</p>
                </div>

                <empty title="You haven't added any note" v-if="!notes.length"/>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    props: ['show', 'data'],

    data() {
        return {
            payload: {
                text: '',
            },

            validationErrors: {
                text: ''
            },

            notes: []
        }
    },

    computed: {
        advert() {
            return this.data && this.data.advert;
        },

        intent() {
            return this.data && this.data.intent;
        }
    },

    watch: {
        show() {
            if (this.intent) {
                this.getComments();
            }
        }
    },

    methods: {
        ...mapActions({
            store: 'Statuses/addComment',
            index: 'Statuses/getComments',
        }),

        cancel() {
            this.payload.text = '';
            this.validationErrors.text = '';

            this.$emit('cancel');
        },

        submit() {
            if (!this.payload.text) {
                return;
            }

            this.showLoader('Adding note... Please wait');

            this.store({
                text: this.payload.text,
                advert_id: this.advert && this.advert.id,
                intent_id: this.intent.id
            }).then(response => {
                this.payload.text = '';
                this.hideLoader();
                this.notes.unshift(response);
            })
            .catch(error => {
                this.hideLoader();
                console.log(error);
            })
        },

        getComments(query) {
            let params = {
                ...query,
                intent_id: this.intent.id
            }

            if (this.advert) {
                params.advert_id = this.advert.id;
            }

            this.index(params)
            .then(response => {
                this.notes = response;
            })
            .catch(error => {
                console.log(error);
            })
        }
    }
}
</script>