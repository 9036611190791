<template>
    <tr class="tabular-campaign">
        <td class="">
            <div class="d-flex align-items-start">
                <div class="media avatar-md mr-2">
                    <video :src="cover.url" class="avatar-md rounded" v-if="cover.isVideo"/>
                    <img :src="cover.url" class="avatar-md rounded" v-else/>
                </div>
                <div class="">
                    <p class="m-0 font-weight-bold">{{ advert && advert.name }}</p>
                    <p class="m-0 font-xs">{{ advert.intent_type.name }}</p>
                    <p class="m-0 font-10 text-muted">{{ advert.last_activity }}</p>
                </div>
            </div>
        </td>

        <td class="no-wrap">
            <button
                class="status-btn active font-10 success"
                type="button"
                v-if="statusText == 'Published'">
                <i class="mdi mdi-checkbox-marked-circle"></i> Published
            </button>

            <button
                class="status-btn active font-10 warning"
                style="border-radius:50px;"
                type="button"
                v-if="statusText == 'Cancelled'">
                <i class="mdi mdi-close"></i> Cancelled
            </button>

            <button
                class="status-btn active font-10 info"
                type="button"
                v-if="statusText == 'Draft'">
                <i class="mdi mdi-paperclip"></i> Draft
            </button>
        </td>

        <td class="no-wrap">
            <p class="m-0">{{ advert.impressions_count | numberFormat }}</p>
            <p class="m-0 text-muted font-xs">Impressions</p>

            <hr class="mb-1 mt-1"/>

            <p class="m-0">{{ advert.clicks | numberFormat }}</p>
            <p class="m-0 text-muted font-xs">Clicked</p>
        </td>

        <td class="no-wrap">
            <p class="m-0">{{ advert.views | numberFormat }}</p>
            <p class="m-0 text-muted font-xs">Views</p>

            <hr class="mb-1 mt-1"/>

            <p class="m-0">{{ advert.view_duration | numberFormat }}s</p>
            <p class="m-0 text-muted font-xs">Average View Time</p>
        </td>

        <td class="no-wrap">
            <p class="m-0">{{ advert.no_of_likes | numberFormat }}</p>
            <p class="m-0 text-muted font-xs">Likes <i class="mdi mdi-heart-outline text-danger"></i></p>

            <hr class="mb-1 mt-1"/>

            <p class="m-0">{{ advert.no_of_dislikes | numberFormat }}</p>
            <p class="m-0 text-muted font-xs">Dislikes <i class="mdi mdi-emoticon-sad"></i></p>
        </td>

        <td class="text-right no-wrap">
            <button
                type="button"
                @click.prevent="edit"
                class="status-btn font-xs warning">
                <i class="mdi mdi-pencil mr-1"></i> Edit
            </button>

            <button
                type="button"
                @click.prevent="view"
                class="status-btn font-xs primary">
                <i class="mdi mdi-chart-areaspline mr-1"></i> Analytics
            </button>
        </td>
    </tr>
</template>
<script>
export default {
    props: ['advert'],

    computed: {
        statusText() {
            return this.advert && this.advert.status ? this.advert.status.name : null;
        },

        cover() {
            if (this.advert && this.advert.cover_media) {
                return {
                    url: this.advert.cover_media.file_url,
                    isVideo: this.advert.cover_media.mime_type.startsWith('video/')
                };
            }

            return {
                url: this.advert.file_url,
                isVideo: false
            };
        }
    },

    methods: {
        edit() {
            this.$emit('edit', this.advert)
        },

        view() {
            this.$emit('view', this.advert)
        }
    }
}
</script>