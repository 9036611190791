import Attributes from './Attributes.vue'
import Media  from './Media.vue'
import Offer  from './Offer.vue'
import  Tabs from './Tabs.vue'
import ReturnPolicy from './ReturnPolicy.vue'
import AddToCart from './AddToCart.vue'

export default {
    Attributes,
    Media,
    Offer,
    Tabs,
    ReturnPolicy,
    AddToCart
}