/* eslint-disable no-console */

import { register } from 'register-service-worker'

const notifyUser = worker => {
  Swal.fire({
    title: 'An Updated is Ready',
    text: `Do you wish to install this update right away? This will reload the app`,
    showCancelButton: true,
    cancelButtonText: 'No',
    confirmButtonText: 'Yes'
  }).then((result) => {
    if (result.value) {
      worker.postMessage({
        type: "SKIP_WAITING"
      });
    }
  });
}


if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('New content is available; please refresh.')
      notifyUser(registration.waiting);
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })

  let refreshing;

  navigator.serviceWorker.addEventListener("controllerchange", e => {
    if (refreshing) return
    window.location.reload()
    refreshing = true
  });
}
