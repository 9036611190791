<template>
<modal
    :show="show"
    :centered="true"
    :showFooter="false"
    :maxHeight="true"
    :opaque="false"
    @ok="submit"
    @cancel="cancel"
    okIcon="mdi mdi-recycle">

    <h5 class="m-0 font-small font-weight-normal" slot="header">
        <b>Update</b>: {{ name }}
    </h5>

    <div slot="body" class="p-2 pt-0 font-small">
        <base-textarea
            label="Response"
            placeholder="e.g Can't login"
            :rows="10"
            v-model="payload.text"
            :error="validationErrors.text"/>

        <div class="text-center mt-4">
            <button
                type="button"
                @click="submit"
                class="btn elevation font-xs login-btn text-uppercase btn-block btn-success">
                    <i class="mdi mdi-recycle"></i> SUBMIT
            </button>
        </div>
    </div>
</modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default{
    props: ['show', 'name', 'ticket_id'],

    data() {
        return {
            payload: {
                text: '',
            },

            validationErrors: {
                text: ''
            }
        }
    },

    computed: {
        ...mapGetters({

        })
    },

    watch: {
    },

    methods: {
        ...mapActions({
            store: 'Tickets/storeUpdate',
        }),

        cancel() {
            for (let key of Object.keys(this.payload)) {
                this.payload[key] = '';
                this.validationErrors[key] = '';
            }

            this.$emit('cancel');
        },

        submit() {
            if (!this.payload.text) {
                return;
            }

            this.buildValidationErrors({})

            this.showLoader('Updating ticket... Please Wait');

            this.store({...this.payload, ticket_id: this.ticket_id})
            .then(response => {
                this.hideLoader();

                this.cancel();
            })
            .catch(error => {
                this.hideLoader();

                if (error.message) {
                    this.swalError('Oops', error.message);
                }

                this.buildValidationErrors(error.errors);
            });
        }
    },

    created() {

    }
}
</script>