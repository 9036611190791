<template>
    <div class="table-responsive bg-white m-0 p-1 elevation">
        <table class="table m-0">
            <tbody>
                <campaign
                    v-for="(campaign, index) in all"
                    :key="index"
                    :campaign="campaign"/>
            </tbody>
        </table>
    </div>
</template>
<script>
import Campaign from './partials/TabularCampaign.vue';

export default {
    props: ['all'],

    components: {
        Campaign
    }
}
</script>