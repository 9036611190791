<template>
    <div class="login-page v-stretch">
        <div class="row v-stretch">
            <div class="col-md-4 v-stretch sidebar d-flex flex-column justify-content-between align-items-end pr-0 pt-5 pb-5">
                <div class="d-flex flex-column links w-100">
                    <a href="#">
                        <i class="mdi mdi-twitter icon"></i>
                        Twitter
                    </a>
                    <a href="#">
                        <i class="mdi mdi-linkedin icon"></i>
                        Tiktok
                    </a>
                    <a href="#">
                        <i class="mdi mdi-facebook icon"></i>
                        Facebook
                    </a>

                    <a href="#">
                        <i class="mdi mdi-whatsapp icon"></i>
                        Whatsapp
                    </a>
                </div>
                <div class="d-flex flex-column our-socials flex-grow-1">
                    <router-link :to="{name: 'login'}" href="#" active-class="active">Login</router-link>
                    <router-link :to="{name: 'register'}" href="#" active-class="active">Sign In</router-link>
                </div>
                <div class="d-flex flex-column socials w-100 flex-grow-1 d-flex flex-column justify-content-end">
                    <p class="m-0 pl-2 font-small text-white">Copyrite c 2022</p>
                </div>
            </div>

            <div class="col-md-8 v-stretch d-flex flex-column justify-content-center align-items-center left p-5">
                <div class="mb-5 title">
                    <logo/>
                </div>

                <div class="socials text-center mb-4">
                    <p class="font-small">Login using social networks</p>
                    <div class="buttons">
                        <button class="facebook elevation mr-3">
                            <i class="mdi mdi-facebook"></i>
                        </button>
                        <button class="google elevation">
                            <i class="mdi mdi-google"></i>
                        </button>
                    </div>
                </div>

                <div class="row justify-content-center w-100">
                    <div class="col-md-5">
                        <div class="row mb-3 align-items-center">
                            <div class="col-5">
                                <div class="line"></div>
                            </div>
                            <div class="col-2 text-uppercase">Or</div>
                            <div class="col-5">
                                <div class="line"></div>
                            </div>
                        </div>

                        <form @submit.prevent="submit" method="POST">
                            <div class="form-group">
                                <i class="mdi mdi-account icon"></i>
                                <input
                                    class="form-control"
                                    type="text"
                                    placeholder="Username"
                                    name="username"
                                    v-model="credentials.username"/>
                            </div>
                            <div class="form-group">
                                <i class="mdi mdi-lock icon"></i>
                                <input
                                    class="form-control"
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    v-model="credentials.password"/>
                            </div>

                            <div class="row justify-content-center mt-5">
                                <div class="col-8">
                                    <button type="submit" class="btn sign-in btn-block elevation">
                                        Sign In
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'Login',

    data() {
        return {
            credentials: {
                username: '',
                password: ''
            }
        }
    },

    methods: {
        ...mapActions({
            login: 'Authentications/login'
        }),

        signup() {
            this.$router.push({name: 'register'});
        },

        submit() {
            this.showLoader('Authenticating... Please wait');

            this.login(this.credentials)
                .then(response => {
                    this.hideLoader();
                    this.goHome();
                })
                .catch(error => {
                    this.hideLoader();
                    this.swalError('Authentication Failed', error.message, false);
                });
        },

        goHome() {
            let route = null;

            if (this.$route.query.redirect) {
                route = this.$route.query.redirect;
            } else {
                route = {name: `${this.userType}_dashboard`};

                if (this.isMerchantUser) {
                    route = {name: 'merchant_campaigns'}
                }
            }

            if (route) {
                this.$router.push(route);
            }
        }
    },

    created() {
        if (this.isLoggedIn) {
            this.goHome();
        }
    }
}
</script>