<template>
    <app-card class="">
        <h5 class="text-tertiary font-weight-bold font-small mb-1">Offer</h5>
        <p class="mb-4 text-muted">Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam debitis earum iure officiis ut deserunt a laudantium aut neque quaerat. Beatae quas quisquam veniam veritatis a autem temporibus ullam modi.</p>

        <div>
            <div class="row">
                <div class="col-md-6">
                    <base-input
                        v-model="payload.price"
                        type="number"
                        noMargin
                        label="Price (USD)"
                        placeholder="Enter the price"/>
                </div>
            </div>

            <hr/>

            <div class="row">
                <div  class="col-md-6">
                    <base-select-wrapper label="Offer Category" tooltip>
                        <select v-model="payload.category_id" class="form-control font-small">
                            <option v-for="(category, index) in offerCategories" :key="index" :value="category.id">
                                {{ category.name }}
                            </option>
                        </select>
                    </base-select-wrapper>
                </div>

                <div  class="col-md-6" v-if="showFixedPrice">
                    <base-input
                        v-model="payload.discount_price"
                        type="number"
                        noMargin
                        label="Discount Price (USD)"
                        placeholder="Enter the discount price"/>
                </div>

                <div  class="col-md-6" v-if="showXamountOff">
                    <base-input
                        v-model="payload.amount_off"
                        noMargin
                        type="number"
                        label="X amount Off"
                        placeholder="Enter the discount amount"/>
                </div>

                <div  class="col-md-6" v-if="showXPercentOff">
                    <base-input
                        v-model="payload.percent_off"
                        type="number"
                        noMargin
                        label="X percent Off"
                        placeholder="Enter the discount percent"/>
                </div>
            </div>

            <div class="row" v-if="showXY">
                <div  class="col-md-6">
                    <base-input
                        v-model="payload.x_quantity"
                        type="number"
                        noMargin
                        label="X"
                        placeholder="Quantity to buy"/>
                </div>
                <div  class="col-md-6">
                    <base-input
                        v-model="payload.y_quantity"
                        type="number"
                        noMargin
                        label="Y"
                        placeholder="Quantity to get for free"/>
                </div>
            </div>

            <hr/>

            <div class="row">
                <div  class="col-md-6">
                    <base-select-wrapper label="Free Shipping" tooltip>
                        <select v-model="payload.shipping_id" class="form-control font-small">
                            <option v-for="(shipping, index) in shippings" :key="index" :value="shipping.id">
                                {{ shipping.name }}
                            </option>
                        </select>
                    </base-select-wrapper>
                </div>
                <div  class="col-md-6" v-if="showFreeShippingAboveX">
                    <base-input
                        v-model="payload.free_shipping_above"
                        type="number"
                        noMargin
                        label="Free Shipping Amount"
                        placeholder="minimum amount for free shipping"/>
                </div>
            </div>
        </div>
    </app-card>
</template>
<script>
import bus from '@/event-bus'
import {debounce} from 'lodash'
import { mapActions, mapGetters } from 'vuex'

export default {
    props: ['advert'],

    data() {
        return {
            payload: {
                price: '',
                discount_price: '',
                category_id: 1,
                x_quantity: '',
                y_quantity: '',
                amount_off: '',
                percent_off: '',

                shipping_id: 1,
                free_shipping_above: ''
            }
        }
    },

    computed: {
        ...mapGetters({
            offerCategories: 'Adverts/getOfferCategories',
            shippings: 'Adverts/getOfferShippings'
        }),

        category() {
            return this.offerCategories.find(item => item.id == this.payload.category_id);
        },

        shipping() {
            return this.shippings.find(item => item.id == this.payload.shipping_id);
        },

        showXY() {
            return this.category && this.category.name == 'Buy X Get Y Free';
        },

        showFixedPrice() {
            return this.category && this.category.name == 'Fixed Price';
        },

        showXPercentOff() {
            return this.category && this.category.name == 'X % off';
        },

        showXamountOff() {
            return this.category && this.category.name == 'X amount off';
        },

        showFreeShippingAboveX() {
            return this.shipping && this.shipping.name == 'Free Shipping Above X Amount';
        },
    },

    watch: {
        payload: {
            deep: true,
            immediate: true,
            handler() {
                this.submit();
            }
        }
    },

    methods: {
        ...mapActions({
            getOfferCategories: 'Adverts/getOfferCategories',
            getOfferShippings: 'Adverts/getOfferShippings',
        }),

        submit: debounce(function () {
            let payload = {
                id: this.advert.id,
                data: {
                    stage: 'offer',
                    ...this.payload
                }
            }

            // console.log(payload)
            bus.$emit('update-advert', payload)
        },  2000),

        populate() {
            if (!this.advert || !this.advert.offer) return;

            const offer  = this.advert.offer;

            // console.log(offer);

            this.payload.price = offer.price;
            this.payload.category_id = offer.category_id;
            this.payload.x_quantity = offer.x_quantity;
            this.payload.y_quantity = offer.y_quantity;
            this.payload.amount_off = offer.amount_off;
            this.payload.percent_off = offer.percent_off;
            this.payload.shipping_id = offer.shipping_id;
            this.payload.free_shipping_above = offer.free_shipping_above;
        }
    },

    created() {
        if (!this.offerCategories.length) {
            this.getOfferCategories().catch(error => undefined);
        }

        if (!this.shippings.length) {
            this.getOfferShippings().catch(error => undefined);
        }

        this.populate()
    }
}
</script>