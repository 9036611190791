<template>
    <content-wrapper>
        <div class="row no-gutters font-small">
            <div class="col-md-12">
                <app-card>
                    <div class="d-flex justify-content-between mb-3">
                        <h5 class="font-big">Profile Attributes</h5>
                        <div>
                            <button type="button" @click.prevent="showAddAttribute = true" class="btn btn-secondary font-small btn-sm ml-1">
                                <i class="mdi mdi-plus"></i> Add Attribute
                            </button>
                        </div>
                    </div>
                    <table class="table table-striped">
                        <thead>
                            <th>Name</th>
                            <th>System Name</th>
                            <th>System Type</th>
                            <th>Group</th>
                            <th></th>
                        </thead>
                        <tbody>
                            <tr v-for="(attribute, index) in attributes" :key="index">
                                <td>
                                    {{ attribute.name }} <br/>
                                    <p class="m-0 font-xs text-muted">{{ attribute.question }}</p>
                                </td>
                                <td>{{ attribute.system_name }}</td>
                                <td>
                                    {{ attribute.system_type }} <hr class="mt-1 mb-1"/>
                                    <code>{{ attribute.settings }}</code>
                                </td>
                                <td>{{ attribute.group && attribute.group.name }}</td>
                                <td>
                                    <button @click.prevent="editAttr(attribute)" type="button" class="btn btn-warning btn-sm font-small">
                                        <i class="mdi mdi-pencil"></i>
                                    </button>
                                    <button type="button" class="btn btn-danger btn-sm ml-1 font-small">
                                        <i class="fa fa-trash-o"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <p class="font-small text-center mt-5 mb-5" v-if="!attributes.length">No attribute recorded</p>

                    <pagination v-if="pageDetails.lastPage > 1" :pageDetails="pageDetails" @navigate="loadAttributes"/>
                </app-card>
            </div>
        </div>

        <add-profile-attribute
            :show="showAddAttribute"
            @refresh="loadAttributes"
            @cancel="showAddAttribute = false"
            :attribute="selectedAttribute"/>
    </content-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AddProfileAttribute from './partials/AddProfileAttribute';

export default {
    components: {
        AddProfileAttribute
    },

    data() {
        return {
            showAddAttribute: false,

            selectedAttribute: null
        }
    },

    computed: {
        ...mapGetters({
            pageDetails: 'ProfileAttributes/getPageDetails',
            attributes: 'ProfileAttributes/getAll',
            types: 'IntentTypes/getAll'
        })
    },

    methods: {
        ...mapActions({
            getAttributes: 'ProfileAttributes/index',
        }),

        editAttr(attribute) {
            this.selectedAttribute = attribute;
            this.showAddAttribute = !!attribute;
        },

        loadAttributes(query) {
            let params = {
                ...query,
                paginate: true,
                per_page: 50,
            };

            this.showLoader('Loading attributes... Please wait');

            this.getAttributes(params)
                .then(response => {
                    this.hideLoader();
                })
                .catch(error => {
                    this.hideLoader();
                })
        }
    },

    created() {
        this.loadAttributes();
    }
}
</script>