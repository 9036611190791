<template>
<div class="">
    <div class="row no-gutters">
        <div class="col-12 col-md-6 min-height-full pb-2" v-for="(feature, index) in features" :key="index">
            <div class="card elevation p-3 m-1 min-height-full no-radius">
                <div class="icon d-flex justify-content-center">
                    <!-- <img :src="feature.icon" class="avatar-xl circle border-secondary border bg-secondary p-1"/> -->
                    <div class="avatar-xl circle border-secondary border bg-secondary p-1 d-flex justify-content-center align-items-center">
                        <span :class="feature.icon" class="text-danger" style="font-size:4s0px;"></span>
                    </div>
                </div>
                <h5 class="font-xs text-center mb-0">{{ feature.title }}</h5>
                <hr class="mt-2 mb-2"/>
                <p class="text-justify">{{ feature.description }}</p>
            </div>
        </div>
    </div>
</div>
</template>
<script>
    export default {
        computed: {
            features() {
                return [
                    {
                        icon: 'mdi mdi-comment-account-outline',
                        title: 'Post Feeds',
                        description: `Post texts, photos and get interactive with your followers. Followers can like and comment on posts you share to your Pescedi feed.`,
                    },

                    {
                        icon: 'mdi mdi-trophy',
                        title: 'League Competitions',
                        description: `Create, join, share and follow football league(s). You may restrict particular club(s) from joining your league.`,
                    },

                    {
                        icon: 'mdi mdi-soccer-field',
                        title: 'Team Formations, Management & Tactics',
                        description: `Set-up your team per your preferred tactics, formations, and player choice. Substitute players based on their available qualifications and specialties, and assign roles to players to bolster the squad.`,
                    },

                    {
                        icon: 'mdi mdi-account-switch',
                        title: 'Transfers Window',
                        description: `Scout for players, buy players with virtual currency and manage their transfer.`,
                    },

                    {
                        icon: 'mdi mdi-cash-refund',
                        title: 'Cashouts',
                        description: `Perform a number of transactions including purchases, refunds and withdrawal. Withdraw money via mobile money or bank account.`,
                    },

                    {
                        icon: 'mdi mdi-chart-bar-stacked',
                        title: 'All Time Ranking',
                        description: `See the display of your all time ranking with respect to other clubs. Play more and win to boost your position on the table.`,
                    }
                ];
            }
        }
    }
</script>