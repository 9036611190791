import { axios } from './index';

export default {
    /**
     * Get available interests
     *
     * @param {Object} params
     * @returns {Object}
     */
    index(params) {
        return axios.get('interests', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    getTypes(params) {
        return axios.get('interest_types', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    store(data) {
        return axios.post('interests', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    saveSnoozeSettings(data) {
        return axios.post('interests/snooze-settings', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },
}