<template>
    <div>
        <div class="d-flex justify-content-between mt-4">
            <div class="">
                <button
                    type="button"
                    @click.prevent="prev"
                    class="btn btn-warning pt-2 pb-2 font-small btn-lg"
                    :disabled="currentStep == 1">
                    <i class="mdi mdi-arrow-left"></i> {{ prevText }}
                </button>
            </div>
            <div class="">
                <button
                    type="button"
                    @click.prevent="next"
                    class="btn pt-2 pb-2 font-small btn-lg"
                    :class="nextClass">
                    {{ nextText }} <i class="mdi mdi-arrow-right"></i>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        currentStep: {
            type: [Number, String]
        },

        isLastStep: {
            type: Boolean
        },

        nextText: {
            type: String,
            default: 'NEXT'
        },

        nextClass: {
            type: String,
            default: 'btn-primary'
        },

        prevText: {
            type: String,
            default: 'PREVIOUS'
        }
    },

    methods: {
        next() {
            this.$emit('next');
        },

        prev() {
            this.$emit('prev');
        },
    }
}
</script>