<template>
    <app-card class="">
        <h5 class="text-tertiary font-weight-bold font-small mb-1">Return Policy</h5>
        <p class="mb-4 text-muted">Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam debitis earum iure officiis ut deserunt a laudantium aut neque quaerat. Beatae quas quisquam veniam veritatis a autem temporibus ullam modi.</p>

        <div class="row">
            <div class="col-md-12">
                <base-input
                    type="number"
                    min="0"
                    label="Days for return (0 for no returns)"
                    placeholder="Days for return (0 for no returns)"
                    v-model="payload.days"/>
            </div>

            <transition name="router">
                <div class="col-md-12 mb-2" v-if="showReturns">
                    <p class="text-blue">Select from:</p>

                    <label class="cursor-pointer mr-5">
                        <input
                            type="radio"
                            name="returns"
                            value="money_back"
                            v-model="payload.return_type"
                            class="mr-2"/>
                        <span>Money Back</span>
                    </label>

                    <label class="cursor-pointer">
                        <input
                            type="radio"
                            name="returns"
                            value="store_credit"
                            v-model="payload.return_type"
                            class="mr-2"/>
                        <span>Store Credit</span>
                    </label>
                </div>
            </transition>

            <div class="col-md-12">
                <p class="text-blue">Return Shipping:</p>

                <label class="cursor-pointer mr-5">
                    <input
                        type="radio"
                        name="return_shipping"
                        value="free"
                        v-model="payload.return_shipping"
                        class="mr-2"/>
                    <span>Free</span>
                </label>

                <label class="cursor-pointer">
                    <input
                        type="radio"
                        name="return_shipping"
                        value="customer_pays"
                        v-model="payload.return_shipping"
                        class="mr-2"/>
                    <span>Customer Pays</span>
                </label>
            </div>
        </div>
    </app-card>
</template>
<script>
import bus from '@/event-bus';
import {debounce} from 'lodash';

export default {
    props: ['advert'],

    data() {
        return {
            payload: {
                days: '',
                return_type: '',
                return_shipping: ''
            }
        }
    },

    computed: {
        showReturns() {
            let n = parseInt(this.payload.days);

            return n && n > 0;
        },

        shouldUpdate() {
            return this.payload.days || this.payload.return_type || this.payload.return_shipping;
        }
    },

    watch: {
        payload: {
            immediate: true,
            deep: true,
            handler() {
                this.submit();
            }
        }
    },

    methods: {
        submit: debounce(function () {
            if (!this.shouldUpdate) return;

            let payload = {
                id: this.advert.id,
                data: {
                    stage: 'return_policy',
                    ...this.payload
                }
            }

            bus.$emit('update-advert', payload);
        },  2000),

        populate() {
            if (!this.advert || !this.advert.return_policy) return;

            const return_policy  = this.advert.return_policy;

            this.payload.days = return_policy.days;
            this.payload.return_type = return_policy.return_type;
            this.payload.return_shipping = return_policy.return_shipping;
        }
    },

    created() {
        this.populate();
    }
}
</script>