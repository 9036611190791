<template>
<modal
    :show="show"
    :centered="true"
    :lg="true"
    :showFooter="true"
    :fixedHeight="true"
    okText="Save Changes"
    okIcon="mdi mdi-content-save-all"
    @ok="submit"
    @cancel="cancel">
    <h5 class="m-0 font-small font-weight-bold text-capitalize" slot="header">
        Update Attributes for {{ advert && advert.name }}
    </h5>

    <div slot="body" class="font-small p-3">
        <template v-if="attributeValues.length">
            <base-search-box
                v-model="keyword"
                :showLeftBtn="true"
                @search="filterAttributes"
                placeholder="Search attributes">
            </base-search-box>

            <div class="mt-3">
                <transition-group name="router">
                    <div class="mt-5" v-for="(stepGroup, index) in stepGroups" :key="`index-${index}`">
                        <h5 class="rounded bg-primary-light-3 text-tertiary mb-4 font-small p-3 font-weight-bold">{{ stepGroup.label }}</h5>

                        <div class="row">
                            <attribute-field
                                v-for="(attribute, i) in stepGroup.attributes"
                                :attribute="attribute"
                                v-model="values[`attribute_${attribute.intent_attribute_id}`]"
                                :key="i"/>
                        </div>

                        <!-- <hr class="mt-1 mb-1"/> -->
                    </div>
                </transition-group>

                <empty title="No attribute matches your search" v-if="!stepGroups.length"/>
            </div>
        </template>

        <empty v-else title="No attribute found"/>
    </div>
</modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AttributeField from './AttributeField.vue';

export default {
    components: {
        AttributeField
    },

    props: ['show', 'advert'],

    data() {
        return {
            keyword: '',
            values: {},
            filtered: []
        }
    },

    computed: {
        ...mapGetters({
            attributeValues: 'Adverts/getAttributeValues'
        }),

        stepGroups() {
            let groups = {};

            for (let attribute of this.filtered) {
                let key = attribute.group.name;

                if (!groups.hasOwnProperty(key)) {
                    groups[key] = {
                        label: key,
                        attributes: []
                    };
                }

                groups[key].attributes.push(attribute);

                this.values[`attribute_${attribute.intent_attribute_id}`] = attribute.value;
            }

            return Object.values(groups);
        }
    },

    watch: {
        keyword() {
            this.filterAttributes()
        },

        attributeValues() {
            this.filterAttributes();
        }
    },

    methods: {
        ...mapActions({
            saveAttributeValues: 'Adverts/saveAttributeValues',
        }),

        cancel() {
            this.$emit('cancel');
        },

        filterAttributes() {
            if (!this.keyword) {
                this.filtered = this.attributeValues;
                return;
            }

            let parts = this.keyword.split(/[-\s+]/).filter(p => !!p.trim());

            let fields = ['name'];

            // console.log(this.attributeValues)

            this.filtered = this.attributeValues.filter(attributeValue => {
                for (let part of parts) {
                    for (let field of fields) {
                        let value = attributeValue.attribute[field];

                        if (value && value.toLowerCase().includes(part.toLowerCase())) {
                            return true;
                        }

                        return false;
                    }
                }

                return false;
            })
        },

        submit() {
            let values = [];

            for (let attribute of Object.keys(this.values)) {
                let [, attribute_id] = attribute.split('_');

                values.push({attribute_id: parseInt(attribute_id), value: this.values[attribute]})
            }

            this.showLoader('Updating attributes... Please wait');

            this.saveAttributeValues({advert_id: this.advert.id, values})
                .then(response => {
                    this.hideLoader();

                    this.cancel();
                })
                .catch(error => {
                    this.hideLoader();
                })
        },
    },

    mounted() {
        //
    }
}
</script>