import Service from '@/services/countries';

const state = {
    all: []
};

const getters = {
    getAll(state) {
        return state.all;
    }
};

const mutations = {
    SET_COUNTRIES(state, payload) {
        state.all = payload.data ? payload.data : payload;
    }
};

const actions = {
    index({ commit }, params) {
        return Service.index(params)
            .then(response => {
                commit('SET_COUNTRIES', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    getStates({ commit }, params) {
        return Service.getStates(params)
            .then(response => {
                // commit('SET_COUNTRIES', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    }
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
}