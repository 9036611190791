<template>
    <header class="mobile-header elevation-2">
        <div class="page-title">
            <a href="#" @click.prevent="toggleSidebar">
                <i class="mdi mdi-close" v-if="sidebarOpened"></i>
                <i class="mdi mdi-menu" v-else></i>
            </a>
            <h4 class="font-big">{{ pageTitle }}</h4>
        </div>
        <div class="actions">
            <a href="#" class="notification">
                <i class="mdi mdi-bell-outline"></i>
                <span class="badge badge-danger">9+</span>
            </a>

            <a href="#" class="user">
                <img src="/img/user.jpg"/>
            </a>
        </div>
    </header>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            sidebarOpened: 'getSidebarOpened'
        }),

        pageTitle() {
            return this.$route.meta.title;
        }
    },

    methods: {
        ...mapActions({
            openSidebar: 'openSidebar',
            closeSidebar: 'closeSidebar',
        }),

        toggleSidebar() {
            if (this.sidebarOpened) {
                this.closeSidebar();
            } else {
                this.openSidebar();
            }
        }
    }
}
</script>