<template>
    <div class="">
        <nav aria-label="breadcrumb" v-if="value">
            <ol class="breadcrumb m-0 elevation mt-2 mb-3 bg-gray font-xs no-radius">
                <li class="breadcrumb-item font-weight-bold">
                    <a href="#" class="text-secondary text-underline" @click.prevent="setValue(null)">
                        <i class="mdi mdi-chevron-left-box"></i> Back To Main Settings
                    </a>
                </li>
                <li class="breadcrumb-item font-weight-bold" aria-current="page">
                    {{ value.name }}
                </li>
            </ol>
        </nav>

        <template v-if="!value">
            <div v-for="(group, g) in links" :key="g" class="mb-3">
                <p class="font-small p-1 text-gray font-weight-bold mt-0 mb-2">{{ group.label }}</p>

                <div class="setting-links font-xs" :class="{'danger-zone': group.danger}">
                    <a href="#" class="elevation-2 mb-2 rounded" @click.prevent="setValue(item)" v-for="(item, index) in group.items" :key="index">
                        <span class="text-tertiary">
                            <i class="font-big mr-2 text-primary font-20 icon" :class="item.icon"></i>
                            {{ item.name }}
                        </span>
                        <i class="mdi mdi-greater-than text-secondary"></i>
                    </a>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    props: ['value'],

    computed: {
        page() {
            return this.$route.query.p;
        },

        linkIds() {
            let ids = [];

            for (let group of this.links) {
                for (let item of group.items) {
                    ids.push(item.id);
                }
            }

            return ids;
        },

        links(){
            return [
                {
                    label: 'Account',
                    items: [
                        {
                            id: 'logout',
                            icon: 'mdi mdi-logout',
                            name: 'Logout'
                        },

                        {
                            id: 'ch_pa',
                            icon: 'mdi mdi-lock-reset',
                            name: 'Change Password'
                        },

                        {
                            id: 'ed_pr',
                            icon: 'mdi mdi-account-circle',
                            name: 'Edit Profile'
                        },

                        {
                            id: 'pr_se',
                            icon: 'mdi mdi-lock-clock',
                            name: 'Privacy Settings'
                        },

                        {
                            id: '2fa',
                            icon: 'mdi mdi-lock-outline',
                            name: 'Two Factor Authentication (2FA)'
                        },

                        // {
                        //     id: 'ref',
                        //     icon: 'mdi mdi-account-group',
                        //     name: 'Referrals'
                        // },
                    ]
                },
                {
                    label: 'Woonmi',
                    items: [
                        {
                            id: 'about',
                            icon: 'mdi mdi-information-outline',
                            name: 'About Woonmi'
                        },

                        {
                            id: 'tc_p',
                            icon: 'mdi mdi-file-search-outline',
                            name: 'Terms & Conditions of Use and Privacy Policy'
                        },

                        {
                            id: 'support',
                            icon: 'mdi mdi-help-circle',
                            name: 'Help & Support'
                        },
                    ]
                },
            ]
        }
    },

    watch: {
        page() {
            this.prepopulate();
        }
    },

    methods:{
        ...mapActions({
            signOut: 'Authentications/logout'
        }),

        logout() {
            this.swalConfirm('Are you sure?', `Please confirm you wish to logout`, `YES, Logout`, `No, I'll stay`)
            .then(result => {
                if (result.value) {
                    this.signOut()
                    .then(response => null)
                    .catch(error => null)
                } else {
                    this.$emit('input')
                }
            })
        },

        setValue(item) {
            if (item && item.id == 'about') {
                return window.open(`https://woonmi.com`);
            }

            this.$emit('input', item);

            if (item) {
                if (item.id == 'logout') {
                    this.logout();
                } else if (item.id == 'about') {
                    return window.open(`https://woonmi.com`);
                } else {
                    if (item.id && item.id != this.page) {
                        this.$router.push(`?p=${item.id}`);
                    }
                }
            } else if (this.page) {
                this.$router.replace(`?`);
            }

        },

        prepopulate() {
            let value = null;

            if (this.linkIds.includes(this.page)) {
                for (let group of this.links) {
                    for (let item of group.items) {
                        if (item.id == this.page) {
                            value = item;
                            break;
                        }
                    }

                    if (value) break;
                }
            }

            this.setValue(value)
        }
    },

    mounted() {
        this.prepopulate();
    }
}
</script>