<template>
    <div class="radio-group p-0" :class="{'horizontal': horizontal}">
        <div
            class="option rounded mb-2"
            v-for="(option, index) in choices"
            :key="index"
            :class="{'active': thisValue == option.id}"
            @click="setValue(option)">
            <div class="icon">
                <i class="mdi mdi-checkbox-blank-circle-outline unchecked"></i>
                <i class="mdi  mdi-checkbox-marked-circle checked"></i>
            </div>
            <div class="content">
                <div class="title mb-0">
                    <span class="name font-xs">{{ option.name }}</span>
                </div>
                <div class="description" v-if="!noDescription">
                    {{ option.description }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true
        },

        options: {
            type: Array,
            required: true
        },

        value: [String, Number],

        horizontal: {
            type: Boolean,
            default: false
        },

        noDescription: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            thisValue: ''
        }
    },

    computed: {
        choices() {
            if (this.options && this.options.length && typeof this.options[0] == 'object') {
                return this.options;
            }

            return this.options.map(item => {
                return {
                    id: item,
                    name: item,
                    description: item
                }
            })
        }
    },

    watch: {
        value() {
            this.thisValue = this.value;
        }
    },

    methods: {
        setValue(option) {
            this.thisValue = option.id;
            this.$emit('input', this.thisValue);
        }
    },

    mounted() {
        this.thisValue = this.value;
    }
}
</script>