<template>
    <div class="media-files">
        <video autoplay loop v-if="media.mime_type.includes('video')" class="media-icon rounded" :src="media.file_url"/>
        <img v-else class="media-icon rounded" :src="media.file_url"/>

        <button class="play font-small" type="button" v-if="isStory">
            <i class="mdi mdi-play"></i> Play Story
        </button>

        <button class="play font-small" type="button" v-else>
            <i class="mdi mdi-details"></i> View Details
        </button>

        <div class="comments">
            <div class="buttons">
                <button @click="showMessages = !showMessages" class="btn mr-1 bg-secondary" type="button">
                    <i v-if="showMessages" class="mdi mdi-eye-off font-big text-white"></i>
                    <i v-else class="mdi mdi-comment-eye-outline font-big text-white"></i>
                </button>
                <button class="btn" type="button">
                    <i class="mdi mdi-chevron-up font-big text-white"></i>
                </button>
            </div>

            <div class="messages" v-if="showMessages">
                <div class="message font-xs text-white" v-for="(comment, index) in commentData" :key="index">
                    <img :src="comment.user.image_url" class="mr-2"/>
                    <span class="font-weight-bold font-xs text-primary">
                        {{ comment.user.username }}
                    </span>
                    <span class="font-xs">{{ comment.text }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    props: ['advert', 'intent'],

    data() {
        return {
            showMessages: false,
            commentData: [],
            page: 0,
            lastPage: 1
        }
    },

    computed: {
        media() {
            return this.advert && this.advert.cover_media;
        },

        isStory() {
            return this.advert && this.advert.display_format && this.advert.display_format.name == 'Story Telling';
        }
    },

    watch: {
        showMessages() {
            if (this.showMessages) {
                this.loadComments();
            }
        }
    },

    methods: {
        ...mapActions({
            getComments: 'Statuses/getComments'
        }),

        loadComments() {
            if (this.page > this.lastPage) {
                return;
            }

            let params = {
                advert_id: this.advert.id,
                page: this.page + 1,
                per_page: 10,
                intent_id: this.intent.id
            };

            this.getComments(params).then(response => {
                this.commentData = response.data;
                this.lastPage = response.last_page;
                this.page = response.current_page;
                console.log(response)
            }).catch(error => {
                console.log(error);
            })
        }
    },

    mounted() {
        // console.log(this.isStory)
    }
}
</script>