<template>
    <div class="form-group mb-3">
        <div class="checkbox checkbox-primary mb-2">
            <input v-model="isChecked" :id="name" type="checkbox" :name="name">
            <label :for="name">{{ label }}</label>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true
        },

        id: {
            type: String,
        },

        checkAll: {
            type: Boolean,
            default: false
        },

        label: {
            type: String,
        }
    },

    data () {
        return {
            isChecked: false
        }
    },

    watch: {
        checkAll () {
            this.isChecked = this.checkAll;
        }
    },

    methods: {
        check () {
            this.isChecked = !this.isChecked;

            this.$emit('onCheck', {
                checked: this.isChecked,
                id: this.id
            });
        }
    },

    mounted () {
        this.isChecked = this.checkAll;
    }
}
</script>
