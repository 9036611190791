<template>
<modal
    :show="show"
    :centered="true"
    :lg="true"
    :fixedHeight="true"
    :showOk="false"
    okText="Okay"
    okIcon="mdi mdi-check"
    @ok="cancel"
    @cancel="cancel">
    <h5 class="m-0 font-small font-weight-bold text-capitalize" slot="header">
        Upload Media for {{ advert && advert.name }}
    </h5>

    <div slot="body" class="pr-2">
        <template v-if="selectedMedia.length">
            <div class="row">
                <div class="col-md-4" v-for="(media, index) in selectedMedia" :key="`${index}-${media.id}`">
                    <media-item
                        class="mb-2 active sm"
                        :isSelected="true"
                        :media="media"
                        @edit="editMedia(media, true)"
                        @onDeleted="onDeleted"
                        @deselect="deselectMedia"/>
                </div>
            </div>

            <hr/>
        </template>

        <div class="d-flex justify-content-between">
            <div>
                <button
                    @click.prevent="activeTab = 'active'"
                    class="btn p-2 font-xs text-left font-weight-bold m-1 pl-4 pr-4"
                    :class="{'btn-secondary': activeTab == 'active', 'btn-default': activeTab != 'active'}">
                    <i class="mdi mdi-checkbox-marked-circle mr-1"></i> Active
                </button>

                <button
                    @click.prevent="activeTab = 'trashed'"
                    class="btn p-2 font-xs text-left font-weight-bold m-1 pl-4 pr-4"
                    :class="{'btn-secondary': activeTab == 'trashed', 'btn-default': activeTab != 'trashed'}">
                    <i class="mdi mdi-delete mr-1"></i> Trashed
                </button>
            </div>

            <div>
                <button
                    @click.prevent="activeTab = 'new'"
                    class="btn p-2 font-xs text-left font-weight-bold elevation m-1"
                    :class="{'btn-secondary': activeTab == 'new', 'btn-primary': activeTab != 'new'}">
                    <i class="mdi mdi-cloud-upload mr-1"></i> New Upload
                </button>
            </div>
        </div>

        <div class="mt-3">
            <h5 class="pl-2 font-small font-weight-bold mb-3 text-capitalize text-center">{{ title }}</h5>

            <hr/>

            <template v-if="activeTab == 'new'">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <upload-media
                            :advert="advert"
                            @setMedia="setMedia"
                            :media="mediaToEdit"
                            @cancel="editMedia(null)"/>
                    </div>
                </div>
            </template>

            <template v-if="isListing">

                <div class="row no-gutters" v-if="unselected.length">
                    <div class="col-lg-4 col-md-6 p-2" v-for="(media, index) in unselected" :key="index">
                        <media-item
                            :media="media"
                            @onDeleted="onDeleted"
                            @select="setMedia"
                            @edit="editMedia(media)"/>
                    </div>
                </div>

                <empty :title="`No ${activeTab} media file found`" v-else/>
            </template>
        </div>
    </div>
</modal>
</template>
<script>
import bus from '@/event-bus.js';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import MediaItem from './MediaItem.vue'
import UploadMedia from './UploadMedia.vue';

export default {
    props: ['show', 'advert'],

    components: {
        MediaItem,
        UploadMedia
    },

    data() {
        return {
            activeTab: 'active',
            mediaToEdit:  null,
            selectedMedia: []
        }
    },

    computed: {
        ...mapMutations({
            addMedia: 'AdvertMedia/ADD_MEDIA',
            removeMedia: 'AdvertMedia/REMOVE_MEDIA'
        }),

        ...mapGetters({
            all: 'AdvertMedia/getAll',
            pageDetails: 'AdvertMedia/getPageDetails',
        }),

        selectedIds() {
            return this.selectedMedia.map(media => media.id);
        },

        title() {
            if (this.activeTab == 'new') {
                return `Upload new photos or videos`;
            } else if (this.activeTab == 'trashed') {
                return `Media files scheduled to be deleted after 30 days of inactivity`
            } else {
                return `Media files in active use`
            }
        },

        isListing() {
            return ['active', 'trashed'].includes(this.activeTab);
        },

        unselected() {
            return this.all.filter(item => !this.selectedIds.includes(item.id))
        }
    },

    watch: {
        show() {
            if (this.show) {
                this.loadData({clearState: true});

                this.selectedMedia = this.advert.advert_media || [];
            }
        },

        activeTab() {
            if (this.isListing) {
                this.loadData({clearState: true})
            }
        }
    },

    methods: {
        ...mapActions({
            index: 'AdvertMedia/index'
        }),

        setMedia(media) {
            if (this.selectedMedia.length > 2) {
                return this.swalError(`Limit Exceeded`, `You can only upload up to 3 media files for any advert`);
            }

            this.selectedMedia.push(media);

            this.submit({action: 'add', media_id: media.id});
        },

        editMedia(media, isSelected = false) {
            this.mediaToEdit = media;

            if (media)  {
                this.activeTab = 'new';
            } else {
                this.activeTab = 'active'
            }
        },

        onDeleted(id) {
            this.deselectMedia({id}, false);
        },

        deselectMedia(media, save = true) {
            this.selectedMedia = this.selectedMedia.filter(item => item.id !== media.id);

            if (save) {
                this.submit({action: 'remove', media_id: media.id});
            }
        },

        submit(data) {
            bus.$emit('update-advert', {
                id: this.advert.id,
                data: {
                    stage: 'media(add/remove)',
                    ...data
                }
            });
        },

        cancel() {
            this.$emit('cancel');
        },

        loadData(query) {
            const params = {
                ...query,
                status: this.activeTab,
                paginate: true,
                per_page: 20
            }

            // this.showLoader('Loading media files... Please wait');

            this.index(params)
                .then(response => {
                    this.hideLoader();
                })
                .catch(error => {
                    this.hideLoader();
                })
        }
    },

    mounted() {
        //
    }
}
</script>