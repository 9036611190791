<template>
    <content-wrapper class="">
        <base-search-box
            class="sticky"
            @leftAddonClick="showFilter = true"
            v-model="keyword"
            @search="performFiltering"
            placeholder="Search specific ads">
        </base-search-box>

        <app-card class="mt-3" v-if="all.length">
            <table class="table">
                <thead class="bg-default-dark text-dark">
                    <th>Color</th>
                    <th>Name</th>
                    <th>Min Age</th>
                    <th>Max Age</th>
                    <th>Gender Group</th>
                    <th>Restrictions</th>
                    <th></th>
                </thead>
                <tbody>
                    <tr v-for="(audience, index) in all" :key="index">
                        <td class="">
                            <div class="label-color" :style="{background: audience.label_color || `#EFF6F6`}"></div>
                        </td>
                        <td>
                            {{ audience.name }}
                        </td>
                        <td>{{ audience.age_from }} <small class="text-muted">years</small></td>
                        <td>{{ audience.age_to }} <small class="text-muted">years</small></td>
                        <td class="text-capitalize">{{ audience.gender ? audience.gender : 'All' }}</td>
                        <td>
                            <div v-if="audience.restriction_type == 'region'">
                                Country: <b>{{ audience.country && audience.country.name }}</b> <br/>

                                State: <b v-if="audience.state">{{ audience.state.name}}</b>
                                <small class="text-muted" v-else>Not applicable</small> <br/>

                                City: <b v-if="audience.city">{{ audience.city}}</b>
                                <small class="text-muted" v-else>Not applicable</small> <br/>
                            </div>

                            <div v-if="audience.restriction_type == 'gps'">
                                GPS Latitude: <b>{{ audience.gps_lat }}</b> <br/>
                                GPS Longitude: <b>{{ audience.gps_long }}</b> <br/>
                                Distance from coordinates: <b>{{ audience.gps_distance }} km</b> <br/>
                            </div>

                            <small v-if="!audience.restriction_type">No restrictions</small>
                        </td>
                    </tr>
                </tbody>
            </table>
        </app-card>

        <empty title="No purchasing intents found" v-if="!all.length && !loading"/>

        <scroll-loader :loader-method="loadNextPage" :loader-disable="!showLoadMore"/>

        <add-audience
            :show="showAddAudience"
            :audience="audience"
            @cancel="editAudience(null)"/>

        <button class="btn btn-primary floating pl-3 pr-3 font-small" @click.prevent="showAddAudience = true">
            <i class="mdi mdi-plus"></i> <span class="font-14 ml-1">New Audience</span>
        </button>
    </content-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddAudience from './partials/AddAudience';

export default {
    components: {
        AddAudience,
    },

    data() {
        return {
            showAddAudience: false,
            selectedAd: null,
            showFilter: false,

            loading: false,

            keyword: '',

            params: {},

            audience: null
        }
    },

    computed: {
        ...mapGetters({
            all: 'Audience/getAll',
            pageDetails: 'Audience/getPageDetails'
        })
    },

    methods:{
        ...mapActions({
            index: 'Audience/index'
        }),

        editAudience(advert) {
            this.selectedAd = advert;
            this.showAddAudience = !!advert;
        },

        performFiltering() {
            this.loadData({clearState: true});
        },

        loadNextPage() {
            if (this.showLoadMore) {
                this.loadData(this.loadMore);
            }
        },

        loadData(query) {
            let params = {
                paginate: true,
                per_page: 20,
                ...query,
                ...this.params,
                keyword: this.keyword
            }

            this.loading = true;

            this.showLoader('Loading ads... Please wait');

            this.index(params)
                .then(response => {
                    this.hideLoader();

                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.hideLoader();
                })
        }
    },

    created() {
        this.loadData({clearState: true});
    }
}
</script>