<template>
    <div class="mb-5 pl-3 pr-3">
        <div class="mb-3">
            <button type="button" @click.prevent="$emit('cancel')" class="btn btn-warning font-xs">
                <i class="mdi mdi-chevron-left"></i> Back
            </button>
        </div>

        <div v-for="(item, index) in items" :key="index">
            <h5 class="font-small"><i class="mdi mdi-help-circle mr-1 text-primary"></i>{{ item.name }}</h5>
            <p class="text-muted font-xs">{{ item.description }}</p>
            <hr/>
        </div>
    </div>
</template>
<script>
export default {
    props: ['items']
}
</script>