<template>
    <content-wrapper class="">
        <page-title title="Team Members">
            <button @click.prevent="showAddMember = true" class="btn btn-secondary font-small elevation-2 pt-2 pb-2 ml-2">
                <i class="mdi mdi-plus-circle"></i> Add Member
            </button>
        </page-title>

        <div class="campaign-filters mb-3">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="mb-3">
                        <button
                            @click.prevent="params.status = 'all'"
                            :class="{'active': params.status == 'all'}"
                            class="status-btn font-xs secondary"
                            type="button">
                            <i class="mdi mdi-format-list-bulleted"></i> All
                        </button>

                        <button
                            @click.prevent="params.status = 'active'"
                            :class="{'active': params.status == 'active'}"
                            class="status-btn success font-xs"
                            type="button">
                            <i class="mdi mdi-checkbox-marked-circle"></i> Active
                        </button>

                        <button
                            @click.prevent="params.status = 'inactive'"
                            class="status-btn font-xs warning"
                            :class="{'active': params.status == 'inactive'}"
                            type="button">
                            <i class="mdi mdi-block-helper"></i> Inactive
                        </button>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="mb-3">
                        <table-search-input
                            class="flex-grow-1"
                            v-model="keyword"
                            @search="loadData"
                            placeholder="Search by keywords...">
                        </table-search-input>
                    </div>
                </div>
            </div>
        </div>

        <app-card>
            <empty title="No team member found" v-if="!all.length"/>

            <div class="table-responsive">
                <table class="table font-small">
                    <tbody>
                        <tr v-for="(item, index) in all" :key="index">
                            <td>
                                <div class="d-flex">
                                    <img :src="item.user.image_url" class="avatar-sm mr-2 image-border circle"/>
                                    <div class="flex-grow-1">
                                        <b>{{ item.user.fullname }}</b><br/>
                                        <small class="text-info">@{{ item.user.username }}</small>
                                        <p class="font-xs m-0 text-muted">{{ item.user.email }}</p>
                                    </div>
                                </div>
                            </td>

                            <td class="text-no-wrap">
                                <button
                                    class="status-btn active success font-xs"
                                    style="border-radius:50px;"
                                    type="button">
                                    <i class="mdi mdi-checkbox-marked-circle"></i> Active
                                </button>

                                <button
                                    class="status-btn active font-10 warning"
                                    style="border-radius:50px;"
                                    type="button">
                                    <i class="mdi mdi-block-helper"></i> Inactive
                                </button>
                            </td>

                            <td class="text-right text-no-wrap">
                                <button
                                    class="status-btn font-xs warning"
                                    type="button"
                                    @click="addMember(item)">
                                    <i class="mdi mdi-pencil mr-1"></i> Edit
                                </button>

                                <button
                                    class="status-btn font-xs danger"
                                    type="button"
                                    @click="deleteMember(item)">
                                    <i class="mdi mdi-delete mr-1"></i> Disable
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </app-card>

        <add-member
            :member="member"
            :show="showAddMember"
            @done="loadData"
            @cancel="addMember(null)"/>
    </content-wrapper>
</template>

<script>
import {debounce} from 'lodash';
import AddMember from './partials/AddMember';
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
        AddMember
    },

    data() {
        return {
            keyword: '',
            member: null,
            showAddMember: false,

            params: {
                status: 'all'
            }
        }
    },

    computed: {
        ...mapGetters({
            all: 'MerchantTeamMembers/getAll'
        })
    },

    methods: {
        ...mapActions({
            index: 'MerchantTeamMembers/index',
            destroy: 'MerchantTeamMembers/destroy',
        }),

        addMember(member) {
            this.showAddMember = !!member;
            this.member = member;
        },

        async deleteMember(member) {
            let result = await this.swalConfirm(`Are you sure?`, `Please confirm you wish to delete this member from your team`, `Yes, Delete`);

            if (!result.value) {
                return;
            }

            this.showLoader();

            this.destroy(member.id)
                .then(response => {
                    this.hideLoader();
                    this.loadData();
                })
                .catch(error => {
                    this.hideLoader();
                })
        },

        loadData(query) {
            let params = {
                ...query,
                keyword: this.keyword
            }

            this.showLoader('Getting team members... Please wait')

            this.index(params)
                .then(response => {
                    this.hideLoader()
                })
                .catch(error => {
                    this.hideLoader()
                })
        }
    },

    created() {
        this.loadData();
    }
}
</script>