<template>
    <content-wrapper>
        <!-- <page-title>Notifications</page-title> -->
        <div class="">
            <div class="d-flex mb-3 justify-content-between mt-2">
                <div class="">
                    <button @click="markAll" type="button" class="btn btn-warning waves-effect waves-light elevation-2 font-xs mr-1">
                        <i class="mdi mdi-email-open"></i> Mark all as read
                    </button>
                </div>

                <div class="">
                    <button @click="deleteAll" type="button" class="btn btn-danger waves-effect waves-light elevation-2 font-xs">
                        <i class="mdi mdi-delete"></i> Delete all
                    </button>
                </div>
            </div>

            <template v-if="showTopLoader">
                <notification-loader v-for="n in 20" :key="n + '-t'"/>
            </template>

            <template v-else>
                <notification-item
                    v-for="(notification, index) in all"
                    :key="index"
                    :notification="notification"/>

                <!-- <template v-if="loading">
                    <notification-loader v-for="n in 20" :key="n + '-b'"/>
                </template> -->

                <scroll-loader
                    v-if="!loading"
                    :loader-method="loadNextPage"
                    :loader-disable="!showLoadMore"/>

                <empty v-if="!all.length && !loading" title="You don't have any notification"/>
            </template>
        </div>
    </content-wrapper>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import NotificationItem from './partials/NotificationItem';
import NotificationLoader from './partials/NotificationLoader';

export default {
    components: {
        NotificationItem,
        NotificationLoader
    },

    data() {
        return {
            loading: true,
            showTopLoader: false,

            activeTab: 'notifications'
        }
    },

    computed: {
        ...mapGetters({
            pagination: 'getPagination',
            all: 'Notifications/getAll',
            pageDetails: 'Notifications/getPageDetails'
        }),
    },

    watch: {
        activeTab() {
            this.showTopLoader = true;
            this.loadData({clearState: true});
        }
    },

    methods: {
        ...mapActions({
            index: 'Notifications/index',
            delete: 'Notifications/delete',
            markAsRead: 'Notifications/markAsRead',
        }),

        deleteAll() {
            this.swalConfirm('Are you sure?', `Some of your notifications may contain links which you have not opened yet. Deleting all notifications will delete those links. Please confirm this action.`, `YES, Delete All`, `No, I'll Keep Them`)
            .then(result => {
                if (result.value) {
                    this.delete('all')
                        .then(response => {
                        })
                        .catch(error => {
                        })
                }
            })
        },

        markAll() {
            this.swalConfirm('Are you sure?', `All your unread notifications will be marked as read. Please confirm this action.`, `YES, Mark As Read`, `No, I'll Read Later`)
            .then(result => {
                if (result.value) {
                    this.markAsRead({id: 'all'})
                        .then(response => {
                        })
                        .catch(error => {
                        })
                }
            })
        },

        /**
         * Loads next page data
         *
         * @returns {Undefined}
         */
        loadNextPage() {
            if (this.showLoadMore) {
                this.loadData(this.loadMore);
            }
        },

        /**
         * Load leagues
         *
         * @param {Object}
         * @returns {Undefined}
         */
        loadData(query) {
            let params = {
                ...query,
                filter: this.activeTab,
                ...this.pagination,
            };

            this.loading = true;

            this.index(params)
            .then(response => {
                this.loading = false;
                this.showTopLoader = false;
            })
            .catch(error => {
                this.loading = false;
                this.showTopLoader = false;
                this.swalError('Something unexpected happened')
            });
        },
    },

    mounted() {
        this.loadData({clearState: true})
    }
}
</script>