<template>
    <div class="intent-ad elevation d-flex flex-column rounded bg-intent-info-soft">
        <div class="poster d-flex mb-1">
            <div class="avatar">
                <img :src="`/img/sneakers/logos/${ad.logo}`"/>
            </div>
            <div class="info flex-grow-1 ml-2">
                <h6 class="name text-primary font-weight-bold mb-1">
                    {{ ad.name }}
                    <i class="mdi mdi-verified"></i></h6>
                <p class="font-10 m-0">{{ ad.id }} months ago</p>
            </div>
        </div>

        <div class="main flex-grow-1">
            <div class="row no-gutters">
                <div class="col-md-6 col-12 p-1">
                    <div class="media">
                        <img :src="`/img/sneakers/${ad.image}`" class="rounded"/>
                    </div>
                </div>
                <div class="col-md-6 col-12 p-1 d-flex flex-column">
                    <!-- <p class="text text-justify flex-grow-1 font-small">
                        {{ ad.text }}
                    </p> -->
                    <div class="rounded font-small" style="border:1px solid #ddd;">
                        <table class="table m-0">
                            <tbody>
                                <tr>
                                    <th>Price</th>
                                    <td>$70</td>
                                </tr>
                                <tr>
                                    <th>Vendor</th>
                                    <td>Macys</td>
                                </tr>
                                <tr>
                                    <th>Return Policy</th>
                                    <td>30 days</td>
                                </tr>
                                <tr>
                                    <th>Expires</th>
                                    <td>Dec 20, 2020</td>
                                </tr>
                                <tr>
                                    <th>Material</th>
                                    <td>Textile & Synthetic</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="actions mt-2">
                <a href="#" class="btn btn-secondary font-small elevation btn-sm text-uppercase">
                    <i class="mdi mdi-web mr-1"></i> Visit
                </a>
                <a href="#" class="btn btn-warning btn-sm elevation font-small text-uppercase float-right">
                    <i class="mdi mdi-pin mr-1"></i> Hold
                </a>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    props: ['ad']
}
</script>