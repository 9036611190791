<template>
    <content-wrapper no_padding>
        <div class="hold-list-item">
            <layout title="Preview Campaign" :close="false">
                <div class="p-2">
                    <hold-list-item
                        :flat="true"
                        :advert="advert"
                        :advert_id="advert_id"
                        @viewStatus="selectedUpdate = {...advert}"/>
                </div>
            </layout>

            <layout title="Summary" :close="false" :availableTabs="availableTabs.summary">
                <div class="content p-2">
                    <camp-summary/>
                </div>
            </layout>

            <layout title="Related Ads" :close="false">
                <div class="content p-2">
                    Related ads
                </div>
            </layout>
        </div>

        <transition name="router">
            <view-status
                v-if="selectedUpdate"
                :campaign="selectedUpdate"
                @cancel="selectedUpdate = null;"
                @navigate="selectedUpdate = null"/>
        </transition>
    </content-wrapper>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Profile from './partials/Profile.vue';
import Notes from './partials/tabs/Notes.vue';
import ListMedia from './partials/tabs/Media.vue';
import CampSummary from './partials/tabs/Summary.vue';
import ViewStatus from '../interests/partials/ViewStatus.vue';
import Layout from '@/views/merchant/profile/partials/Layout.vue';
import HoldListItem from './partials/HoldListItem';

export default {
    props: ['advert_id'],

    components: {
        Layout,
        Notes,
        Profile,
        ListMedia,
        ViewStatus,
        CampSummary,
        HoldListItem
    },

    data() {
        return {
            showAddNote: false,
            selectedUpdate: null,

            advert: null
        }
    },

    computed: {
        ...mapGetters({
            campaign: 'Statuses/getSelectedCampaign'
        }),

        availableTabs() {
            return {
                summary: [
                    {id: 'attributes', icon:"mdi mdi-details", name: 'Product Attributes'},
                    {id: 'offer', icon:"mdi mdi-library-books", name: 'Offer'},
                    {id: 'return_policy', icon:"mdi mdi-keyboard-return", name: 'Return Policy'},
                ]
            }
        }
    },

    methods:  {
        ...mapActions({
            updateClicks: 'Statuses/updateClicks',
            show: 'Adverts/show'
        }),

        goto() {
            if (!this.campaign || !this.campaign.advert) return;

            this.updateClicks({advert_id: this.campaign.id}).catch(error => null);

            window.open(this.campaign.advert.action_url, '_blank');
        },

        viewStatus() {
            this.selectedUpdate = {...this.campaign};
        },

        loadAdvert() {
            this.showLoader('Loading Campaign... Please wait');

            this.show(this.advert_id)
                .then(response => {
                    this.hideLoader();
                    this.advert = response;
                    console.log(response)
                })
                .catch(error => {
                    this.hideLoader();
                    console.log(error);
                });
        }
    },

    created() {
        this.loadAdvert();
    }
}
</script>