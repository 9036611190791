import { mapActions } from 'vuex';
import { debounce } from 'lodash';

export default {
    ...mapActions({
        showLoader: 'showLoader',
        hideLoader: 'hideLoader'
    }),

    formatAge(value) {
        if (!value) return null;

        return moment(value).fromNow();
    },

    capitalize(value) {
        var value = value.toString();

        return value.charAt(0).toUpperCase() + value.slice(1);
    },

    /**
         * Converts category strings into category class names
         *
         * @param {String} category
         */
    convertCategoryIntoClassNames(category) {
        category = category.toString();

        if (category) {
            return category.split(' ').map(i => i.trim()).join('-').toLowerCase();
        }

        return '';
    },

    /**
         * Change the orientation for the icon to hide or show a group's files
         *
         * @param {Object} event The click event
         */
    toggleCategoryIcon(event) {
        $(event.currentTarget).find('i').toggleClass('mdi-chevron-down mdi-chevron-up');
    },


    /**
     * Shows a confirmation dialog with sweet alert
     *
     * @param {Object} confirmation
     * @param {Object} params
     * @returns {Object}
     */
    confirmDelete(confirmation, params) {
        return new Promise((resolve, reject) => {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, continue!'
            }).then((result) => {
                if (result.value) {
                    if (confirmation) {
                        confirmation(params)
                            .then(() => {
                                Swal.fire(
                                    'Done!',
                                    '',
                                    'success'
                                );
                            })
                            .catch(() => {
                                Swal.fire(
                                    'Failed!',
                                    '',
                                    'error'
                                );
                            })
                    }
                }
            })
        });
    },

    /**
     * Shows or hides modal
     *
     * @param {Boolean} show
     * @param {String} modalEl modal to be used, has priority if set
     * @param {Object} options additional options to be passed to modal
     * @returns {Undefined}
     */
    showModal(show, modalEl = null, options = {backdrop: 'static'}) {
        let modal = this.modalId;

        if (modalEl) {
            modal = modalEl
        }

        if (modal) {
            if (show) {
                $(`#${modal}`).modal(options)
            } else {
                $(`#${modal}`).modal('hide');
            }
        }
    },

    /**
     * Build html from an array, an object or a string containing errors.
     *
     * @param {Object|Array|String} errors
     * @return {Object}
     */
    buildErrors(errors) {
        let message = '';
        let title = 'User Error Alert';

        if (process.env.NODE_ENV !== 'production') {
            // console.log(errors);
        }

        if (errors.errors) {
            errors = errors.errors;
        } else if (errors.items) {
            errors = ['Sorry, the form validation failed. Fix all field errors.'];
        } else if (errors.error) {
            errors = [errors.error];
        } else if (typeof errors === 'object') {
            errors = Object.values(errors);
        } else {
            errors = ['Something unexpected happened.'];
        }

        message = '<ul>';

        $.each(errors, function (index, error) {
            message += `<li>${error}</li>`;
        });

        message += '<ul>';

        return message;
    },

    /**
     * Builds validation errors from server
     *
     * @param {Object} errors
     * @returns {Object}
     */
    buildValidationErrors(errors) {
        if (!this.hasOwnProperty('validationErrors')) {
            return console.error('You need to define validationErrors property');
        }

        for (let key of Object.keys(this.validationErrors)) {
            if (errors && errors[key] && errors[key].length) {
                this.validationErrors[key] = errors[key][0];
            } else {
                this.validationErrors[key] = '';
            }
        }
    },

    /**
     * Shows a sweet alert dialog
     *
     * @param {String} title
     * @param {String} text
     * @returns {Object}
     */
     swalConfirm(title = 'Are you sure?', text = 'Please confirm your action', okText = 'Yes', cancelText = 'Cancel!') {
         return Swal.fire({
             title,
             html: text,
             type: 'warning',
             showCancelButton: true,
             confirmButtonText: okText,
             cancelButtonText: cancelText,
             confirmButtonClass: 'btn elevation-2 btn-success mt-2 font-11',
             cancelButtonClass: 'btn elevation-2 btn-danger ml-2 mt-2 font-11',
             buttonsStyling: false
         });
     },

    /**
     * Shows sweetalert success popup
     *
     * @param {String} title
     * @param {String} text
     * @returns {Object}
     */
    swalSuccess(title = 'Success', text = '') {
        return Swal.fire({
            title,
            text,
            type: 'success',
            confirmButtonClass: 'btn elevation bg-success mt-2 font-11',
        });
    },

    /**
     * Shows sweet alert error popup
     *
     * @param {String} title
     * @param {String} text
     * @param {Boolean} timer
     * @returns {Object}
     */
    swalError(title = 'Error', text = '', timer = true) {
        let options = {
            title,
            text,
            type: 'error',
            confirmButtonClass: 'btn elevation bg-danger mt-2 font-11',
        };

        if (timer) {
            options.timer = 5000;
        }

        return Swal.fire(options);
    },

    /**
     * Shows sweet alert error popup
     *
     * @param {String} title
     * @param {String} text
     * @param {Boolean} timer
     * @returns {Object}
     */
    swalWarn(title = 'Error', text = '', timer = true) {
        let options = {
            title,
            text,
            type: 'warning',
            confirmButtonClass: 'btn btn-confirm mt-2',
        };

        if (timer) {
            options.timer = 5000;
        }

        return Swal.fire(options);
    },

    /**
     * Shows an error notification
     *
     * @param {String} text
     * @returns {Undefined}
     */
    notifySuccess(text, title = 'Well Done!') {
        $.NotificationApp.send(title, text, 'top-right', '#5ba035', 'success', 1000 * 10);
    },

    /**
     * Shows an error notification
     *
     * @param {String} text
     * @returns {Undefined}
     */
    notifyError(text, title = 'Oh snap!') {
        $.NotificationApp.send(title, text, 'top-right', '#bf441d', 'error', 1000 * 10);
    },

    /**
     * Shows an warning notification
     *
     * @param {String} text
     * @returns {Undefined}
     */
    notifyWarn(text, title = 'Heads up!') {
        $.NotificationApp.send(title, text, 'top-right', '#da8609', 'warning', 1000 * 10);
    },

    /**
     * Shows an info notification
     *
     * @param {String} text
     * @returns {Undefined}
     */
    notifyInfo(text, title = 'Heads up!') {
        $.NotificationApp.send(title, text, 'top-left', '#3b98b5', 'info', 1000 * 5);
    },

    /**
     * Initiates a tour for page
     *
     * @returns {Undefined}
     */
    startTour(tour) {
        hopscotch.startTour(tour);
    },

    resizeTextarea() {
        function OnInput() {
            this.style.height = 'auto';
            this.style.height = (this.scrollHeight) + 'px';
        }

        const tx = document.getElementsByTagName('textarea');

        for (let i = 0; i < tx.length; i++) {
            tx[i].setAttribute('style', 'height:' + (tx[i].scrollHeight) + 'px;overflow-y:hidden;');
            tx[i].addEventListener("input", OnInput, false);
        }
    },

    isURL(string) {
        try {
            new URL(string);
        } catch (_) {
            return false;
        }

        return true;
    },

    parsePostContent(text) {
        return text.toString().split(/\r?\n/).map(line => {
            return line.split(/[ ]/).map(word => {
                if (word.startsWith('@') || this.isURL(word) || word.startsWith('#')) {
                    let href = `/fantasy/overview?`;
                    let is_external = false;

                    if (word.startsWith('@')) {
                        href += `u=${word.substr(1, word.length)}`;
                    } else if (word.startsWith('#')) {
                        href += `tag=${word.substr(1, word.length)}`;
                    } else if (this.isURL(word)) {
                        href = word;
                        is_external = true;
                    }

                    return `<a href="${href}" ${is_external ? 'target="_blank"' : ''} class="text-info">${word}</a>`;
                }

                return word;
            }).join(' ');
        }).join(`<br/>`);
    },

    playNotificationAlert() {
        let audio = new Audio('/assets/audio/alert.mp3');
        audio.play()
    },

    initKnob() {
        $('[data-plugin="knob"]').each(function (idx, obj) {
            $(this).knob();
        });
    },

    toDashboard() {
        return this.$router.replace({ name: `${this.userType}_dashboard` });
    },

    updateRouteQuery(query) {
        let current = `${new URLSearchParams({...this.$route.query})}`;
        let path = `${new URLSearchParams({...this.$route.query, ...query})}`;

        if (current != path) {
            this.$router.replace(`?${path}`);
        }
    },

    async uploadPresignedFile(file, url) {
        try {
            await fetch(url, {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': file.type,
                    'Cache-Control': 'public,max-age=31536000,immutable',
                    'x-amz-acl': 'public-read',
                }
            });

            return true;
        } catch (error) {
            return false;
        }
    },
}