<template>
<modal
    :show="show"
    :centered="true"
    :showFooter="true"
    :maxHeight="true"
    okIcon="mdi mdi-share"
    @ok="submit"
    okText="Share"
    @cancel="cancel">

    <h5 class="m-0 font-small font-weight-normal text-capitalize" slot="header">Share {{ name || 'Advert' }}</h5>

    <div slot="body" class="p-5 font-small">
        <p class="alert alert-warning text-primary font-small text-center mb-5">
            You can only add up to 5 friends at a time
        </p>

        <div class="d-flex">
            <img 
                v-if="url" 
                class="avatar-md mr-2 circle" 
                style="object-fit:cover;" 
                :src="url"/>

            <div class="flex-grow-1">
                <div class="token-input" v-if="sharedTos.length">
                    <label>You've shared with {{ sharedTos.length }} people</label>
                    <div class="token-field">
                        <span
                            class="token p-1 pl-3 pr-2 m-1 d-inline-flex align-items-center font-xs"
                            v-for="(item, index) in sharedTos" :key="index"
                            :class="`token-${index < numberOfTokenColors ? index : index % numberOfTokenColors}`">
                            {{ item }}
                        </span>
                    </div>
                </div>
                <base-token-input
                    label="Enter friend's username"
                    name="usernames"
                    :disabled="true"
                    placeholder="Enter username"
                    v-model="usernames"
                />
            </div>
        </div>
    </div>
</modal>
</template>

<script>
import storage from '@/storage.js';
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['advert','show'],

    data() {
        return {
            usernames: [],
            showError: false,
            numberOfTokenColors: 8
        }
    },

    computed: {
        ...mapGetters({
            reasons: 'Statuses/getReportReasons'
        }),

        name() {
            return this.advert && this.advert.name
        },

        url() {
            if (this.advert) {
                if (this.advert.cover_media) {
                    return this.advert.cover_media.file_url
                } else if (this.advert.advert_media.length) {
                    return this.advert.advert_media[0].file_url
                }
            }
        },

        sharedTos() {
            if (!this.advert || !this.advert.user_shares || !this.advert.user_shares.length) return []

            return this.advert.user_shares.map(item => item.shared_to.username)
        }
    },

    watch: {
        usernames(newVal, oldVal) {
            if (this.usernames.length > 5) {
                this.showError = true;
                this.usernames = oldVal;
            } else {
                this.showError = false;
            }
        }
    },

    methods: {
        ...mapActions({
            delete: 'Statuses/delete',
            shareAdvert: 'Statuses/shareAdvert',
        }),

        cancel(clear = true) {
            this.$emit('cancel');
        },

        submit() {
            let params = {
                advert_id: this.advert.id,
                usernames: this.usernames
            };

            this.showLoader('Sharing advert... Please wait')
            this.shareAdvert(params)
                .then(response => {
                    this.hideLoader()
                    this.cancel();
                    console.log(response)
                })
                .catch(error => {
                    this.hideLoader()
                    this.swalError(error.message)
                })
        }
    },

    mounted() {
    }
}
</script>