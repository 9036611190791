<template>
<div>
    <custom-modal :title="title" @cancel="cancel" v-if="advert" :showFooter="false">
        <div class="main-posting bg-white p-2 elevation rounded mb-4">
            <profile :advert="advert" class=""/>

            <media :advert="advert"/>
        </div>

        <div class="row">
            <div class="col-md-6 v-stretch">
                <offer :advert="advert" class="v-stretch"/>
            </div>
            <div class="col-md-6 v-stretch">
                <return-policy :advert="advert" class="v-stretch"/>
            </div>
            <div class="col-md-12" v-if="advert && advert.values && advert.values.length">
                <attributes :advert="advert" class="mt-3"/>
            </div>
        </div>

    </custom-modal>
</div>
</template>
<script>
import bus from '@/event-bus.js'
import { mapActions } from 'vuex';
import Partials  from  '@/views/customer/adverts';
import Profile from '@/views/customer/hold-list/partials/Profile.vue';
import StatusUpdate from '@/views/customer/interests/partials/StatusUpdate.vue';

export default {
    props: [],

    components: {
        Profile,
        StatusUpdate,
        ...Partials
    },

    data() {
        return {
            relatedAds: [],
            mediaIndex: 0,

            activeTab: 'media',

            eventName: 'view-related-ad',
            advert: null
        }
    },

    computed: {
        title() {
            if (this.advert) return this.advert.name;
        },

        mediaFiles() {
            return this.advert && this.advert.advert_media;
        },

        currentMedia() {
            return this.mediaFiles[this.mediaIndex]
        }
    },

    methods: {
        ...mapActions({
            show: 'Adverts/show'
        }),

        cancel() {
            this.activeTab = 'media'
            this.advert = null;
        },

        loadAdvert({advert_id, advert_name}) {
            this.showLoader(`Getting ${advert_name}... Please wait`);

            this.show(advert_id)
                .then(response => {
                    this.hideLoader()
                    console.log(response);
                    this.advert = response;
                })
                .catch(error => {
                    this.hideLoader()
                    console.log(error);
                })
        }
    },

    created() {
        bus.$on(this.eventName,  this.loadAdvert);
    },

    beforeDestroy() {
        bus.$off(this.eventName,  this.loadAdvert);
    }
}
</script>