<template>
    <div class="">
        <div class="table-responsive" v-if="audience">
            <table class="table table-bordered">
                <tbody>
                    <tr class="bg-primary-light-3 text-secondary font-weight-bold">
                        <td colspan="3">Audience Details</td>
                    </tr>

                    <tr>
                        <td>
                            <div class="d-flex">
                                <div class="m-0 text-tertiary mr-3">
                                    <div class="label-color" :style="{background: audience.label_color}"></div>
                                </div>
                                <div>
                                    <p class="m-0 text- font-weight-bold font-xs mb-1 text-capitalize">Name</p>
                                    <p class="m-0 text-tertiary">{{ audience.name }}</p>
                                </div>
                            </div>
                        </td>
                        <td>
                            <p class="m-0 text- font-weight-bold font-xs mb-1 text-capitalize">Gender Group</p>
                            <p class="m-0 text-tertiary text-capitalize">{{ audience.gender || 'All' }}</p>
                        </td>
                        <td>
                            <p class="m-0 text- font-weight-bold font-xs mb-1 text-capitalize">Potential Reach</p>
                            <p class="m-0 text-tertiary text-capitalize">200K to 1.5M people</p>
                        </td>
                    </tr>

                    <tr class="bg-primary-light-3 text-secondary font-weight-bold">
                        <td colspan="3">
                            Demographical Restriction <br/>
                            <span class="font-weight-normal text-tertiary font-xs">
                                <span v-if="audience.restriction_type == 'region'">Restricted by Region (Country, State, City)</span>
                                <span v-if="audience.restriction_type == 'gps'">Restricted by GPS Coordinates</span>
                            </span>
                        </td>
                    </tr>

                    <tr v-if="audience.restriction_type == 'region'">
                        <!-- <td>
                            <p class="m-0 text- font-weight-bold font-xs mb-1 text-capitalize">Restriction Type</p>
                            <p class="m-0 text-tertiary">
                                Restricted to region (Country, State & City)
                            </p>
                        </td> -->
                        <td>
                            <p class="m-0 text- font-weight-bold font-xs mb-1 text-capitalize">Country</p>
                            <p class="m-0 text-tertiary">{{ audience.country ? audience.country.name : 'Not applicable' }}</p>
                        </td>
                        <td>
                            <p class="m-0 text- font-weight-bold font-xs mb-1 text-capitalize">State</p>
                            <p class="m-0 text-tertiary">{{ audience.state ? audience.state.name : 'Not applicable' }}</p>
                        </td>
                        <td>
                            <p class="m-0 text- font-weight-bold font-xs mb-1 text-capitalize">State</p>
                            <p class="m-0 text-tertiary">{{ audience.city || 'Not applicable' }}</p>
                        </td>
                    </tr>

                    <tr v-if="audience.restriction_type == 'gps'">
                        <!-- <td>
                            <p class="m-0 text- font-weight-bold font-xs mb-1 text-capitalize">Restriction Type</p>
                            <p class="m-0 text-tertiary"></p>
                        </td> -->

                        <td>
                            <p class="m-0 text- font-weight-bold font-xs mb-1 text-capitalize">GPS Latitude</p>
                            <p class="m-0 text-tertiary">{{ audience.gps_lat || 'Not applicable' }}</p>
                        </td>

                        <td>
                            <p class="m-0 text- font-weight-bold font-xs mb-1 text-capitalize">GPS Longitude</p>
                            <p class="m-0 text-tertiary">{{ audience.gps_long || 'Not applicable' }}</p>
                        </td>

                        <td>
                            <p class="m-0 text- font-weight-bold font-xs mb-1 text-capitalize">Distance From Coordinates</p>
                            <p class="m-0 text-tertiary">{{ audience.gps_distance || 'Not applicable' }}</p>
                        </td>
                    </tr>

                    <tr v-if="!audience.restriction_type">
                        <td colspan="3">
                            <p class="m-0 text- font-weight-bold font-xs mb-1 text-capitalize">Restriction Type</p>
                            <p class="m-0 text-tertiary">No Restriction</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <empty title="No audience added for this campaign" v-else/>
    </div>
</template>

<script>
export default {
    props: ['audience']
}
</script>