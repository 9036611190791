<template>
    <div class="links flex-grow-1">
            <div class="top">
                <router-link
                    :to="{name: 'admin_dashboard'}"
                    href="/admin/dashboard"
                    class="font-small rounded"
                    active-class="active">
                    <i class="mdi mdi-view-dashboard mr-1 icon"></i>
                    Dashboard
                </router-link>

                <router-link
                    :to="{name: 'admin_customers'}"
                    href="/admin/customers"
                    class="font-small rounded"
                    active-class="active">
                    <i class="mdi mdi-account-multiple-outline mr-1 icon"></i>
                    Customers
                </router-link>

                <router-link
                    :to="{name: 'admin_merchants'}"
                    href="/admin/merchants"
                    class="font-small rounded"
                    active-class="active">
                    <i class="mdi mdi-bank mr-1 icon"></i>
                    Merchants
                </router-link>

                <!-- <hr class="m-0 mt-1 mb-1"/> -->

                <router-link
                    :to="{name: 'admin_intents'}"
                    href="/admin/intents"
                    class="font-small rounded"
                    active-class="active">
                    <i class="mdi mdi-lightbulb-outline mr-1 icon"></i>
                    Intents
                </router-link>

                <router-link
                    :to="{name: 'admin_intent_types'}"
                    href="/admin/intent_types"
                    class="font-small rounded"
                    active-class="active">
                    <i class="mdi mdi-settings mr-1 icon"></i>
                    Intent Settings
                </router-link>

                <!-- <hr class="m-0 mt-1 mb-1"/> -->

                <router-link
                    :to="{name: 'admin_profile_attributes'}"
                    href="/admin/profiles/attributes"
                    class="font-small rounded"
                    active-class="active">
                    <i class="mdi mdi-account mr-1 icon"></i>
                    Profile Attributes
                </router-link>

                <router-link
                    :to="{name: 'admin_support_tickets'}"
                    href="/admin/tickets"
                    class="font-small rounded"
                    active-class="active">
                    <i class="mdi mdi-help-circle mr-1 icon"></i>
                    Support Tickets
                </router-link>
            </div>

            <commons/>
        </div>
</template>

<script>
import Commons from './Common';

export default {
    components: {
        Commons
    }
}
</script>