<template>
     <content-wrapper no_padding>
        <view-updates type="interest"/>
     </content-wrapper>
</template>
<script>
import ViewUpdates from './ViewUpdates.vue';

export default {
    components: {
        ViewUpdates
    },

    created() {
        if (!this.authUser.interests || !this.authUser.interests.length) {
            return this.$router.replace({name: 'customer_select_interests'});
        }
    }
}
</script>