/**
 * Automatically load all modules recursively (.i.e sub folders)
 */
const modulesContext = require.context('./', true);

let modules = {};

let regex = /[a-zA-Z0-9\s_\\.\-\(\):].js$/i;

/**
 * Now import each vuex module state
 */
modulesContext.keys().forEach(filepath => {
    if (regex.test(filepath) && filepath !== './index.js') {
        let module = modulesContext(filepath);
        modules[getModuleNamespace(filepath)] = module.default;
    }
});

/**
 * Accepts a filename(fullpath) including its extension
 * Extract the name of the file
 *
 * @param {String} filename
 * @return {String}
 */
function getModuleNamespace(filepath) {
    return filepath.split('/').pop().split('.').shift().toString().split('_')
        .map(word => (word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()))
        .join('');
}

export default modules;