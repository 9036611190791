<template>
<modal
    :show="show"
    :centered="true"
    :showFooter="true"
    :maxHeight="true"
    :showInfo="false"
    okText="Update"
    @ok="submit"
    @cancel="cancel"
    okIcon="mdi mdi-account-circle">

    <h5 class="m-0 font-small font-weight-normal" slot="header">
        Help
    </h5>

    <div slot="body" class="p-3 font-small">
        <field-description :items="items"/>
    </div>
</modal>
</template>

<script>
import bus from '@/event-bus.js';
import {mapGetters, mapActions} from 'vuex';
import FieldDescription from './FieldDescription.vue';

export default {
    components: {
        FieldDescription
    },

    data() {
        return {
            show: false,
            items: []
        }
    },

    methods: {
        cancel() {
            this.show = false;
        },

        submit() {

        },

        init(data) {
            this.items = data;
        }
    },

    beforeDestroy() {
        bus.$off('view-field-description', this.init);
    },

    created() {
        bus.$on('view-field-description', this.init);
    }
}
</script>