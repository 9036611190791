<template>
    <div class="media-item rounded">
        <img v-if="media.mime_type.startsWith('image')" :src="media.file_url"/>
        <video v-if="media.mime_type.startsWith('video')" :src="media.file_url"/>


        <div class="text font-xs">
            <div class="title text-truncate">{{ isEditing  ? renderedTitle : media.title }}</div>

            <div class="caption  text-truncate">
                {{ isEditing ? renderedCaption : media.caption }}
            </div>
        </div>

        <div class="actions rounded" v-if="!isEditing">
            <button
                @click="deselect"
                type="button"
                title="Deselect"
                class="btn btn-warning elevation"
                v-if="isSelected">
                <i class="mdi mdi-close"></i>
            </button>

            <button
                @click="select"
                type="button"
                title="Select"
                class="btn btn-success elevation"
                v-else>
                <i class="mdi mdi-check"></i>
            </button>

            <button
                @click="$emit('edit', media)"
                type="button"
                title="Edit Title/Caption"
                class="btn btn-info elevation">
                <i class="mdi mdi-pencil"></i>
            </button>

            <button
                @click="deleteMedia"
                type="button"
                title="Delete"
                class="btn btn-danger elevation">
                <i class="mdi mdi-delete"></i>
            </button>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import bus from '@/event-bus.js';

export default {
    props: ['title', 'caption', 'media', 'isSelected', 'isEditing'],

    data() {
        return {
            payload: {
                title: '',
                caption: ''
            }
        }
    },

    computed: {
        renderedTitle() {
            return this.payload.title || this.title
        },

        renderedCaption() {
            return this.payload.caption || this.caption
        },
    },

    methods:{
        ...mapActions({
            destroy: 'AdvertMedia/destroy',
        }),

        async deleteMedia() {
            let result = await this.swalConfirm(`Are you sure?`, `This will irreversibly remove this media file completely`, `Yes, Delete It`);

            if (!result.value) return;

            this.destroy(this.media.id).catch(error => this.swalError(error.media));

            this.$emit('onDeleted', this.media.id);
        },

        deselect() {
            this.$emit('deselect', this.media);
        },

        select() {
            this.$emit('select', this.media)
        },

        onUpdate({media_id,  title,  caption}) {
            // console.log(media_id)
            if (this.media.id == media_id) {
                // console.log('setting')
                this.payload.title = title;
                this.payload.caption = caption;
            }
        }
    },

    beforeDestroy() {
        bus.$off('update-title-caption', this.onUpdate);
    },

    created() {
        bus.$on('update-title-caption', this.onUpdate);
    }
}
</script>