<template>
    <modal
        :show="show"
        :centered="true"
        :maxHeight="true"
        @cancel="cancel"
        @ok="submit"
        ok-icon="mdi mdi-plus"
        okText="Add Now">
        <h5 class="m-0 font-small font-weight-bold text-capitalize" slot="header">Add {{ advert && advert.name }} to Cart</h5>

        <div slot="body" class="p-3 font-small">
            content
        </div>
    </modal>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    props: ['show', 'advert'],

    components: {
    },

    data() {
        return {
        }
    },

    computed: {
    },

    watch: {
        show() {
            if (this.show) {
                console.log(this.advert)
            }
        }
    },

    methods: {
        ...mapActions({
            
        }),

        submit() {},

        cancel() {
            this.$emit('cancel')
        }
    }
}
</script>