import FormComponents from '@/components/forms/intents';

export default {
    components: {
        ...FormComponents
    },

    computed: {
        yesNoOptions() {
            return [
                {
                    id: 'Yes',
                    name: 'Yes',
                    description: `Used to give an affirmative response.`
                },

                {
                    id: 'No',
                    name: 'No',
                    description: `Used to give a negative response.`
                },

                {
                    id: 'None',
                    name: `None`,
                    description: `Used to give a not any response.`
                }
            ]
        },
    }
}