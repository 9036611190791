<template>
    <content-wrapper class="">
        <page-title title="Campaigns">
            <button @click.prevent="showAddCampaign = true" class="btn btn-secondary font-small elevation-2 pt-2 pb-2 ml-2">
                <i class="mdi mdi-plus-circle"></i> Create Campaign
            </button>
        </page-title>

        <div class="campaign-filters mb-3">
            <div class="row align-items-center">
                <div class="col-md-8 mb-3">
                    <div class="btn-group">
                        <button
                            type="button"
                            class="btn btn-primary elevation dropdown-toggle text-capitalize font-small p-2 pl-4 pr-4"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                            {{ params.status }} Campaigns
                        </button>
                        <div class="dropdown-menu font-small">
                            <a class="dropdown-item" href="#" @click.prevent="setStatus('all')">
                                <i class="mdi mdi-format-list-bulleted"></i> All
                            </a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#" @click.prevent="setStatus('published')">
                                <i class="mdi mdi-checkbox-marked-circle"></i> Published
                            </a>
                            <a class="dropdown-item" href="#" @click.prevent="setStatus('in review')">
                                <i class="mdi mdi-sync"></i> In Review
                            </a>
                            <a class="dropdown-item" href="#" @click.prevent="setStatus('scheduled')">
                                <i class="mdi mdi-clock"></i> Scheduled
                            </a>
                            <a class="dropdown-item" href="#" @click.prevent="setStatus('draft')">
                                <i class="mdi mdi-paperclip"></i> Draft
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 d-flex mb-3">
                    <table-search-input
                        class="flex-grow-1"
                        v-model="keyword"
                        @search="performFiltering"
                        placeholder="Search by keywords...">
                    </table-search-input>

                    <div class="ml-2">
                        <button
                            type="button"
                            @click.prevent="display = 'list'"
                            class="btn rounded bg-default-dark elevation text-dark"
                            v-if="display == 'tabular'">
                            <i class="mdi mdi-view-dashboard"></i>
                        </button>

                        <button
                            type="button"
                            @click.prevent="display = 'tabular'"
                            class="btn rounded bg-default-dark elevation text-dark"
                            v-if="display == 'list'">
                            <i class="mdi mdi-format-list-bulleted"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <template v-if="all.length">
            <tabular-display :all="all" v-if="display == 'tabular'"/>
            <grid-display :all="all" v-if="display == 'list'"/>
        </template>

        <app-card v-if="!all.length && !loading">
            <empty title="No campaign found"/>
        </app-card>

        <scroll-loader :loader-method="loadNextPage" :loader-disable="!showLoadMore"/>

        <add-campaign
            :show="showAddCampaign"
            :campaign="selectedCampaign"
            @cancel="editCampaign(null)"/>

        <filter-campaigns
            @cancel="showFilter = false;"
            :show="showFilter"
            v-model="filters"
            @submit="performFiltering"/>
    </content-wrapper>
</template>

<script>
import storage from '@/storage.js';
import GridDisplay from './layouts/Grid.vue';
import { mapActions, mapGetters } from 'vuex';
import TabularDisplay from './layouts/Tabular.vue';
import AddCampaign from './partials/others/AddCampaign';
import FilterCampaigns from './partials/others/FilterCampaigns';

export default {
    components: {
        TabularDisplay,
        GridDisplay,
        AddCampaign,
        FilterCampaigns,
    },

    data() {
        return {
            showAddCampaign: false,
            selectedCampaign: null,
            showFilter: false,

            loading: false,

            keyword: '',

            params: {
                status: 'all',
            },

            filters: {},

            display: 'tabular',

            displayName: 'campaign_list_display'
        }
    },

    computed: {
        ...mapGetters({
            all: 'Campaigns/getAll',
            pageDetails: 'Campaigns/getPageDetails'
        })
    },

    watch: {
        params: {
            deep: true,
            immediate: true,
            handler() {
                this.loadData({clearState: true});
            }
        },

        display() {
            storage.set(this.displayName, this.display);
        }
    },

    methods:{
        ...mapActions({
            index: 'Campaigns/index'
        }),

        setStatus(status) {
            this.params.status = status;
        },

        editCampaign(advert) {
            this.selectedCampaign = advert;
            this.showAddCampaign = !!advert;
        },

        performFiltering() {
            this.loadData({clearState: true});
        },

        loadNextPage() {
            if (this.showLoadMore) {
                this.loadData(this.loadMore);
            }
        },

        loadData(query) {
            let params = {
                paginate: true,
                per_page: 20,
                ...query,
                ...this.params,
                keyword: this.keyword
            }

            this.loading = true;

            this.showLoader('Loading ads... Please wait');

            this.index(params)
                .then(response => {
                    this.hideLoader();

                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.hideLoader();
                })
        }
    },

    created() {
        this.display = storage.get(this.displayName);

        if (!['tabular', 'list'].includes(this.display)) {
            this.display = 'tabular';
        }

        // this.loadData({clearState: true});
    }
}
</script>