<template>
<modal
    :show="show"
    :centered="true"
    :showFooter="true"
    :fixedHeight="true"
    okText="Save Categories"
    okIcon="mdi mdi-content-save-all"
    @ok="submit"
    @cancel="cancel">

    <h5 class="m-0 font-small font-weight-normal text-capitalize" slot="header">
        Select all categories that may apply to this product
    </h5>

    <div slot="body" class="p-1 mt-3 font-small">
        <base-search-box
            v-model="keyword"
            @search="search"
            :showLeftBtn="false"
            :placeholder="`Type to search category...`">
        </base-search-box>

        <div class="topics mt-3">
            <transition-group name="swipe">
                <div
                    class="topic"
                    v-for="type in all"
                    :key="type.id"
                    :class="{'active': selectedTypes.includes(type.id)}"
                    @click="toggleProductType(type)">
                    <div class="flex-grow-1">
                        <p class="">{{ type.name }}</p>
                        <p class="text-muted font-10 mt-1">
                            {{ type.category }}
                        </p>
                    </div>

                    <span class="icon mdi mdi-checkbox-marked-circle-outline"></span>
                </div>
            </transition-group>

            <scroll-loader
                :loader-method="loadNextPage"
                :loader-disable="!showLoadMore"/>

            <empty title="No category matches your search criteria." v-if="!all.length"/>

        </div>
    </div>
</modal>
</template>

<script>
import bus from '@/event-bus.js';
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['show', 'advert'],

    data() {
        return {
            keyword: '',
            selectedTypes: []
        }
    },

    computed: {
        ...mapGetters({
            all: 'ProductTypes/getAll',
            pageDetails: 'ProductTypes/getPageDetails'
        }),
    },

    watch: {
        show() {
            if (this.show && this.advert) {
                this.selectedTypes = this.advert.product_types.map(type => type.id);
            } else {
                this.cancel();
            }
        }
    },

    methods: {
        ...mapActions({
            index: 'ProductTypes/index',
            update: 'Campaigns/update'
        }),

        toggleProductType(type) {
            let index = this.selectedTypes.indexOf(type.id);

            if (index > -1) {
                this.selectedTypes.splice(index, 1);
            } else {
                this.selectedTypes.push(type.id);
            }
        },

        submit() {
            if (!this.selectedTypes.length) {
                return this.swalError('Validation Error', `You must select at least 1 product category`);
            }

            bus.$emit('update-advert', {
                id: this.advert.id,
                data: {
                    stage: 'categories',
                    product_type_ids: this.selectedTypes
                }
            })

            this.cancel();
        },

        search() {
            this.loadData({clearState: true})
        },

        loadNextPage() {
            if (this.showLoadMore) {
                this.loadData(this.loadMore);
            }
        },

        loadData(query) {
            // this.showLoader(`Getting Product Categories... Please Wait`)

            let params = {
                ...query,
                keyword: this.keyword
            };

            this.index(params)
            .then((response) => {
                // console.log(this.all)
                // this.hideLoader();
                // this.cancel();
            })
            .catch(error => {
                // this.hideLoader();
            })
        },

        cancel() {
            this.$emit('cancel');
            this.keyword = '';
        },
    },

    created() {
        this.loadData({clearState: true})
    }
}
</script>