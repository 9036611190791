<template>
    <div class="form-group search-box">
        <button
            class="btn-left"
            type="button"
            v-if="showLeftBtn"
            @click="$emit('leftAddonClick')">
            <i :class="leftBtnIcon"></i>
        </button>

        <input
            type="text"
            class="form-control font-small"
            v-model="keyword"
            @input="$emit('input', keyword)"
            @keydown.enter="search"
            :class="{'has-left-btn': leftBtnIcon}"
            :placeholder="placeholder">

        <button class="btn-right" type="button" @click="search" v-if="showRightBtn">
            <i class="mdi mdi-magnify"></i>
        </button>
    </div>
</template>

<script>
export default {
    props: {
        placeholder: String,

        leftBtnIcon: {
            type: String,
            default: 'mdi mdi-filter-outline'
        },

        value: String,

        showLeftBtn: {
            type: Boolean,
            default: true
        },

        showRightBtn: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            keyword: ''
        }
    },

    methods: {
        search() {
            this.$emit('search', {keyword: this.keyword, clearState: true});
        }
    }
}
</script>