<template>
<modal
    :show="show"
    :centered="true"
    :lg="true"
    :maxHeight="true"
    :showHeader="false"
    :showOk="false"
    okText="Okay"
    okIcon="mdi mdi-check"
    @ok="cancel"
    @cancel="cancel">
    <h5 class="m-0 font-small font-weight-bold text-capitalize" slot="header">
        Audience
    </h5>

    <div slot="body" class="pr-2">
        <layout
            title="Audience"
            :elevation="false"
            :close="false"
            :availableTabs="availableTabs"
            v-model="activeTab">
            <audience
                :tab="activeTab"
                :campaign="campaign"
                :advert="advert"/>
        </layout>
    </div>
</modal>
</template>
<script>
import bus from '@/event-bus.js';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Layout from '@/views/merchant/profile/partials/Layout.vue';
import Audience from './audience/Index.vue';

export default {
    props: ['show', 'advert', 'campaign'],

    components: {
        Audience,
        Layout
    },

    data() {
        return {
            activeTab: 'create',
        }
    },

    computed: {
        availableTabs() {
            return [
                {id: 'view', name: 'Audience', icon: `mdi mdi-account-star`},
                {id: 'create', name: 'Create', icon: `mdi mdi-plus`},
                {id: 'existing', name: 'Select Existing', icon: `mdi mdi-account-multiple-outline`},
                {id: 'insights', name: 'Insights', icon: `mdi mdi-account-multiple-outline`},
            ]
        }
    },

    watch: {
        show() {
            
        },
    },

    methods: {
        ...mapActions({
            index: 'AdvertMedia/index'
        }),

        cancel() {
            this.$emit('cancel');
        }
    },

    mounted() {
        //
    }
}
</script>