<template>
    <content-wrapper>
        <template v-if="advert">
            <div class="row">
                <div class="col-md-8">
                    <div class="main-posting bg-white p-2 elevation rounded mb-4">
                        <profile :advert="advert" class=""/>
                        <media :advert="advert" @addToCart="addToCart"/>
                    </div>

                    <h5>Product Description</h5>
                    <p class="text-justify">{{description}}</p>

                    <div class="row">
                        <div class="col-md-6 v-stretch mb-2">
                            <offer :advert="advert" class="v-stretch"/>
                        </div>
                        <div class="col-md-6 v-stretch">
                            <return-policy :advert="advert" class="v-stretch"/>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bg-white p-2 elevation rounded mb-3">
                        <p class="font-weight-bold mb-0 text-center">Product Attributes</p>
                        <attributes
                            :advert="advert"
                            class="mt-3"
                            :intentAttributes="parsedAttributes"/>
                    </div>
                </div>
            </div>
            <template v-if="relatedAds.length">
                <h5 class="p-1 font-weight-bold text-tertiary mt-3">Related Items from {{advert.merchant.name}}</h5>

                <div  class="row no-gutters">
                    <div class="col-md-3 col-6 p-1"  v-for="(ad, index) in relatedAds" :key="index">
                        <status-update
                            :storeFront="true"
                            :advert="ad"
                            :noProfile="true"
                            @view="viewAd(ad)"/>
                    </div>
                </div>
            </template>
        </template>

        <add-to-cart :advert="advert" :show="showAddToCart" @cancel="showAddToCart = false"/>
    </content-wrapper>
</template>
<script>
import bus from '@/event-bus.js';
import { mapActions } from 'vuex';
import Partials  from  '@/views/customer/adverts';
import Profile from '@/views/customer/hold-list/partials/Profile.vue';
import StatusUpdate from '@/views/customer/interests/partials/StatusUpdate.vue';

export default {
    props: ['posting_id'],

    components: {
        Profile,
        ...Partials,
        StatusUpdate
    },

    data() {
        return {
            activeTab: 'media',
            advert: null,
            intent: null,

            showAddToCart: false
        }
    },

    computed: {
        title() {
            if (this.advert) return this.advert.name;
        },

        relatedAds() {
            if (!this.advert) return [];

            return this.advert.related_ads;
        },

        description() {
            let values = this.advert ? this.advert.values : [];
            let description = values.find(item => item.name === 'description');

            return description ? description.value : '';
        },

        plug_id() {
            return parseInt(this.$route.query.plug);
        },

        parsedAttributes() {
            let values = {};
            let attributes = this.intent ? this.intent.product_attributes : [];

            attributes.forEach(item => {
                if (!item.value) return;

                values[item.system_name] = item.value.toString().split(',').map(value => {
                    return isNaN(value) ? value.toLowerCase() : parseInt(value);
                });
            });

            return values;
        }
    },

    watch: {
        activeTab() {
            this.updateRouteQuery({tab: this.activeTab})
        }
    },

    methods: {
        ...mapActions({
            show: 'Adverts/show',
            showIntent: 'Intents/show'
        }),

        addToCart() {
            this.showAddToCart = true;
        },

        viewAd(advert) {
            if (!advert) return;

            bus.$emit('view-related-ad', {
                advert_id: advert.id,
                advert_name: advert.name
            });
        },

        loadAdvert() {
            this.show(this.posting_id)
                .then(response => {
                    // console.log(response);
                    this.advert = response;
                })
                .catch(error => {
                    console.log(error);
                })
        },

        loadPlug() {
            if (!this.plug_id) {
                this.loadAdvert();
                return;
            };

            this.showIntent(this.plug_id)
                .then(response => {
                    this.intent = response;
                    console.log(this.parsedAttributes)
                    this.loadAdvert();
                })
                .catch(error => {
                    console.log(error);
                });
        }
    },

    mounted() {
        this.loadPlug();
    }
}
</script>