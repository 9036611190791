<template>
    <content-wrapper no_padding>
        <div class="mt-2 mb-5 d-flex flex-wrap justify-content-center">
            <span
                class="token p-2 m-1 d-inline-flex align-items-center font-small round elevation pr-3 pl-3 bg-default-dark text-primary"
                v-for="(type, index) in productTypes" :key="index">
                {{ type.name }}
            </span>
        </div>

        <div class="mt-3">
            <view-updates type="intent" :intent_id="intent_id"/>
        </div>
    </content-wrapper>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ViewUpdates from '../../interests/ViewUpdates.vue';

export default {
    props: ['intent_id'],

    components: {
        ViewUpdates,
    },

    data() {
        return {
            intent: null
        }
    },

    computed: {
        productTypes() {
            if (!this.intent || !this.intent.product_types) return [];

            return this.intent.product_types;
        },
    },

    methods: {
        ...mapActions({
            show: 'Intents/show',
        }),

        getIntent() {
            this.showLoader(`Getting intent details... Please wait`);

            this.show(this.intent_id)
                .then(response => {
                    this.hideLoader();
                    this.intent = response;
                })
                .catch(error => {
                    this.hideLoader();
                    console.log(error);
                })
        }
    },

    created() {
        this.getIntent();
    }
}
</script>