import { axios } from './index';

export default {
    /**
     * Get available profile attributes
     *
     * @param {Object} params
     * @returns {Object}
     */
    index(params) {
        return axios.get('profile_attributes', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    getGroups(params) {
        return axios.get('profile_attribute_groups', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    store(data) {
        return axios.post('profile_attributes', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    update(id, data) {
        return axios.put('profile_attributes/' + id, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    saveProfile(data) {
        return axios.post('profiles', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    enquire(params) {
        return axios.get('profiles/enquire', params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    }
}