<template>
<modal
    :show="show"
    :lg="true"
    :centered="true"
    :showFooter="false"
    :maxHeight="true"
    okText="Next"
    @cancel="cancel"
    okIcon="mdi mdi-arrow-right">

    <h5 class="m-0 font-small font-weight-bold" slot="header">{{ title }}</h5>

    <div class="font-small p-2 bg-default" slot="body" style="min-height:250px;position:relative;padding-bottom:70px !important;">
        <div class="mb-3">
            <template v-for="(stage, index) in tableOfContents">
                <a href="#" :class="{'text-secondary': activeChapter == stage, 'text-muted': activeChapter != stage}" class="font-weight-bold p-1" :key="`a-${index}`">
                   {{ stage }}
                </a>
                <i class="mdi mdi-chevron-right ml-1" v-if="index < tableOfContents.length - 1" :key="`i-${index}`"></i>
            </template>
            <hr/>
        </div>
        <div class="mb-2">
            <h5 class="bg-secondary text-white font-small p-3 font-weight-bold">1. Basic Information</h5>

            <div class="question-group p-2 app-card elevation-2 full-height">
                <div class="font-big app-card mb-1">
                    <p class="font-weight-bold font-small m-0 mb-1">Intent Type</p>
                    <!-- <p class="font-small m-0 text-muted">Tell us what you are looking for. This will help narrow down your results</p> -->
                </div>

                <div class="">
                    <radio-group
                        name="intent_type"
                        :horizontal="true"
                        :no-description="true"
                        :options="intentTypeOptions"
                        v-model="payload.intent_type_id"/>
                </div>
            </div>

            <div class="row no-gutters">
                <div class="col-md-6 p-1 full-height">
                    <div class="question-group p-2 app-card elevation-2 mb-2 full-height">
                        <div class="font-big app-card mb-1">
                            <p class="font-weight-bold font-small m-0 mb-1">Expiration Date</p>
                            <!-- <p class="font-small m-0 text-muted">
                                When do you no longer need this {{ selectedIntentType ? selectedIntentType.name : 'product/service' }}
                            </p> -->
                        </div>

                        <div class="">
                            <div class="form-group type-2">
                                <label class="font-xs">Expiry Date</label>
                                <div class="icon"><i class="mdi mdi-calendar"></i></div>
                                <datetime
                                    input-class="form-control font-small no-radius elevation text-input"
                                    v-model="payload.expiry_date"
                                    title="Expiration Date"></datetime>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 p-1">
                    <div class="question-group app-card p-2 elevation-2">
                        <div class="font-big app-card mb-0">
                            <p class="font-weight-bold font-small m-0 mb-1">Summary</p>
                            <!-- <p class="font-small m-0 text-muted">
                                Give a short description. This will help you remember this intent
                            </p> -->
                        </div>

                        <div class="pb-2">
                            <div class="form-group type-2">
                                <textarea
                                    class="form-control text-input font-small p-2"
                                    placeholder="Short Description"
                                    rows="4"
                                    v-model="payload.title"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template v-if="steps.length">
            <transition-group name="router">
                <div class="mb-3" v-for="(stepGroup, index) in visibleSteps" :key="`index-${index}`">
                    <h5 class="bg-secondary text-white font-small p-3 font-weight-bold">{{index + 2}}. {{ stepGroup.label }}</h5>

                    <div class="row no-gutters">
                        <div class="col-md-6 p-1 full-height" v-for="(attribute, i) in stepGroup.attributes" :key="i">
                            <div class="app-card question-group elevation-2 full-height">
                                <div class="font-big app-card mb-0">
                                    <p class="font-weight-bold font-small m-0 mb-1">{{ attribute.name | capitalize }}</p>
                                    <p class="font-small m-0 text-muted">{{ attribute.description.replace('[intent_type]', selectedIntentType.name) }}</p>
                                </div>

                                <div class="">
                                    <radio-group
                                        v-if="attribute.system_type == 'YesNo'"
                                        name="yes_no"
                                        v-model="attributeValues[`attribute_${attribute.id}`]"
                                        :options="yesNoOptions"/>

                                    <base-input
                                        label="Response"
                                        icon="mdi mdi-information-outline"
                                        :isTextInput="true"
                                        placeholder="Type response here..."
                                        v-model="attributeValues[`attribute_${attribute.id}`]"
                                        v-if="attribute.system_type == 'TextInput'"/>

                                    <base-input
                                        label="Response"
                                        icon="mdi mdi-information-outline"
                                        :isTextInput="true"
                                        placeholder="Type response here..."
                                        type="number"
                                        v-model="attributeValues[`attribute_${attribute.id}`]"
                                        v-if="attribute.system_type == 'NumericInput'"/>

                                    <div class="form-group type-2" v-if="attribute.system_type == 'DateInput'">
                                        <label class="font-xs">Response</label>
                                        <div class="icon"><i class="mdi mdi-calendar"></i></div>
                                        <datetime
                                            input-class="form-control font-small no-radius elevation text-input"
                                            :title="attribute.name"
                                            v-model="attributeValues[`attribute_${attribute.id}`]"></datetime>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition-group>
        </template>

        <div class="actions d-flex justify-content-between mt-4 position-absolute p-2" style="bottom:0;right:0;left:0;">
            <div class="">
                <button class="btn btn-lg btn-warning font-small mr-1" :disabled="!showNext" @click.prevent="next">
                    {{ nextChapter }} <i class="mdi mdi-arrow-right"></i>
                </button>
            </div>

            <div class="">
                <button class="btn btn-lg btn-success font-small" @click="submit" v-if="showSubmit">
                    Save <i class="mdi mdi-content-save-all"></i>
                </button>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
import Fields from '@/mixins/fields';
import { mapActions, mapGetters } from 'vuex';


export default {
    props: ['show', 'intent'],

    mixins: [Fields],

    data() {
        return {
            payload: {
                intent_type_id: '',
                expiry_date: '',
                title: ''
            },

            activeStepIndex: -1,

            showStep: false,
            value: '',

            attributeValues: {}
        }
    },

    computed: {
        ...mapGetters({
            intentTypes: 'IntentTypes/getAll'
        }),

        activeChapter() {
            return this.tableOfContents[this.activeStepIndex + 1]
        },

        nextChapter() {
            let length = this.tableOfContents.length;

            if (!length || length == 1) {
                return 'More Details'
            }

            return this.tableOfContents[this.activeStepIndex + 2]
        },

        tableOfContents() {
            let items = this.stepGroups.map(g => g.label);

            return ['General Intent Information', ...items];
        },

        stepGroups() {
            let groups = {};

            for (let attribute of this.steps) {
                let key = attribute.pivot.group_name;

                if (!groups.hasOwnProperty(key)) {
                    groups[key] = {
                        label: key,
                        attributes: []
                    };
                }

                groups[key].attributes.push(attribute);
            }

            return Object.values(groups);
        },

        minStepIndex() {
            return -1;
        },

        title() {
            if (!this.activeStep) {
                return 'Add a new intent';
            }

            return `${this.activeStep.pivot.group_name} - ${this.activeStep.name.toUpperCase()}`
        },

        selectedIntentType() {
            return this.intentTypeOptions.find(type => type.id == this.payload.intent_type_id);
        },

        steps() {
            let intent_type = this.intentTypes.find(type => type.id == this.payload.intent_type_id);

            if (!intent_type) {
                return [];
            }

            let steps = intent_type.attributes;

            return steps;
        },

        intentTypeOptions() {
            return this.intentTypes.map(type => {
                return {
                    id: type.id,
                    name: type.name,
                    description: type.description
                }
            })
        },

        showNext() {
            if (!this.showSubmit) {
                return false;
            }

            return this.activeStepIndex < this.stepGroups.length - 1 && this.stepGroups.length
        },

        showSubmit() {
            for (let key of Object.keys(this.payload)) {
                if (!this.payload[key]) {
                    return false;
                }
            }

            return true;
        },

        visibleSteps() {
            if (this.activeStepIndex == -1) {
                return [];
            }

            let steps = [];

            for (let i = 0; i <= this.activeStepIndex; i++) {
                steps.push(this.stepGroups[i]);
            }

            return steps;
        },

        intentValues() {
            let values = {};

            if (!this.intent) {
                return values;
            }

            for (let value of this.intent.values) {
                let key = `attribute_${value.intent_attribute_id}`;

                values[key] = value.value;
            }

            return values;
        }
    },

    watch: {
        show() {
            if (!this.show) {
                this.reset();
            }
        },

        'payload.intent_type_id'() {
            this.activeStepIndex = this.minStepIndex;

            this.initAttributeValues();
        },

        activeStepIndex() {
            // this.initAttributeValues();
        },

        intent: {
            deep: true,
            immediate: true,
            handler() {
                if (this.intent) {
                    this.prepopulate();
                }
            }
        }
    },

    methods: {
        ...mapActions({
            index: 'IntentTypes/index',
            store: 'Intents/store',
            update: 'Intents/update'
        }),

        initAttributeValues() {
            this.attributeValues = {};

            for (let attribute of this.steps) {
                let key = `attribute_${attribute.id}`;

                this.attributeValues[key] = this.intentValues.hasOwnProperty(key) ? this.intentValues[key] : '';
            }
        },

        reset() {
            this.activeStepIndex = this.minStepIndex;
            this.payload.intent_type_id = '';
            this.payload.expiry_date = '';
            this.payload.title = '';
            this.attributeValues = {}
        },

        async next() {
            if (!this.payload.intent_type_id) {
                return alert(`Please tell us what you're looking for..`);
            }

            if (!this.intent && this.activeStepIndex == this.minStepIndex) {
                let result = await this.swalConfirm(`Save and continue later?`, `You are about to provide additional data for this intent. At this point you can save your progress and continue later. To save click the "Submit" button, otherwise browse through the form and provide as much details as you can, however, most fields can be skiped. This will improve search results for better hits.`, `Yes, I'll Submit`, `No, I'll Stay`);

                if (result.value) {
                    return this.submit();
                }
            }

            if (this.showNext) {
                this.activeStepIndex = this.activeStepIndex + 1;
            }
        },

        getRequestPayload() {
            let data = {...this.payload}
            data.attributes = [];

            for (let key of Object.keys(this.attributeValues)) {
                let [,intent_attribute_id] = key.split('_');

                if (this.attributeValues[key]) {
                    data.attributes.push({
                        intent_attribute_id: parseInt(intent_attribute_id),
                        value: this.attributeValues[key]
                    });
                }
            }

            return data;
        },

        cancel() {
            this.reset();
            this.$emit('cancel');
        },

        submit() {
            let data = this.getRequestPayload();

            let method = null;

            if (this.intent) {
                method = this.update;
                data = {data, id: this.intent.id};
            } else {
                method = this.store;
            }

            if (!method) {
                return;
            }

            this.showLoader('Processing... Please wait');

            method(data)
                .then(response => {
                    this.hideLoader();
                    this.cancel();
                })
                .catch(error => {
                    this.hideLoader();
                    this.swalError(`Something Unexpected Happened`, error.message);
                })
        },

        prepopulate() {
            this.payload = {
                intent_type_id: this.intent.intent_type_id,
                expiry_date: this.intent.expiry_date,
                title: this.intent.title
            };
        }
    },

    created() {
        if (!this.intentTypes.length) {
            this.index()
                .then(response => {

                })
                .catch(error => null)
        }
    }
}
</script>