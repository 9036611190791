<template>
    <modal
        :show="show"
        :centered="true"
        :showFooter="true"
        :maxHeight="true"
        okText="Save Settings"
        okIcon="mdi mdi-bell"
        @ok="submit"
        @cancel="cancel">
    
        <h5 class="m-0 font-small font-weight-normal text-capitalize" slot="header">Snooze Settings</h5>
    
        <div slot="body" class="p-1 font-small" id="snooze-settings">
            <label
                v-for="(interest, index) in interests"
                :key="index"
                class="cursor-pointer bg-default d-flex justify-content-between p-2 rounded mb-1 font-xs">
                <span class="ml-3">{{ interest.interest_type.name }}</span>

                <label class="switch" :for="`interest_${interest.id}`">
                    <input 
                        :name="interest.id" 
                        :id="`interest_${interest.id}`" 
                        class="switch-input" 
                        type="checkbox"
                        :checked="interest.snoozed == 1"/> 
                    <span class="switch-label on elevation">Snoozed</span> 
                    <span class="switch-label off">Off</span> 
                </label>
            </label>
        </div>
    </modal>
    </template>
    
    <script>
    import { mapActions, mapGetters } from 'vuex';
    
    export default {
        props: ['advert','show'],
    
        data() {
            return {
                values: [],
                reason_id: ''
            }
        },
    
        computed: {
            ...mapGetters({
                reasons: 'Statuses/getReportReasons'
            }),
    
            interests() {
                return this.authUser.interests;
            }
        },
    
        watch: {
        },
    
        methods: {
            ...mapActions({
                store: 'Interests/saveSnoozeSettings',
            }),
    
            cancel(clear = true) {
                this.$emit('cancel');
            },
    
            submit() {
                let settings = []

                $('#snooze-settings input.switch-input').each(function () {
                    settings.push({
                        id: $(this).attr('name'),
                        snoozed: $(this).is(':checked') ? 1 : 0
                    })
                })

                this.showLoader('Saving settings... Please wait')
    
                this.store({interests: settings})
                .then(response => {
                    this.hideLoader()
                    this.cancel()
                    console.log(response)
                })
                .catch(error => {
                    this.hideLoader()
                })
            }
        }
    }
    </script>