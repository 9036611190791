import Intents from '@/views/admin/intents/Index.vue';
import Dashboard from '@/views/admin/dashboard/Index.vue';
import Customers from '@/views/admin/customers/Index.vue';
import Merchants from '@/views/admin/merchants/Index.vue';
import SupportTickets from '@/views/admin/tickets/Index.vue';
import IntentTypes from '@/views/admin/intent-types/Index.vue';
import ProfileAttributes from '@/views/admin/profile-attributes/Attributes.vue';

let routes = [
    {
        name: 'dashboard',
        path: 'dashboard',
        component: Dashboard,
        meta: {
            title: 'Dashboard'
        }
    },

    {
        name: 'intents',
        path: 'intents',
        component: Intents,
        meta: {
            title: 'Intents'
        }
    },

    {
        name: 'intent_types',
        path: 'intent_types',
        component: IntentTypes,
        meta: {
            title: 'Intent Types'
        }
    },

    {
        name: 'customers',
        path: 'customers',
        component: Customers,
        meta: {
            title: 'Customers'
        }
    },

    {
        name: 'merchants',
        path: 'merchants',
        component: Merchants,
        meta: {
            title: 'Merchants'
        }
    },

    {
        name: 'profile_attributes',
        path: 'profiles/attributes',
        component: ProfileAttributes,
        meta: {
            title: 'Profile Attributes'
        }
    },

    {
        name: 'support_tickets',
        path: 'tickets',
        component: SupportTickets,
        meta: {
            title: 'Support Tickets'
        }
    },
];

export default routes.map(route => {
    return { ...route, name: `admin_${route.name}` }
});