import Config from '@/config';
import Portal from '@/views/common/layouts/Master.vue';


let routes = [
    {
        path: '/common',
        name: 'common_module',
        component: Portal,
        meta: {
            requiresAuth: true
        },
        children: require('./common').default
    }
];

if (Config.userType == 'customer') {
    routes.push({
        path: '/customer',
        name: 'customer_module',
        component: Portal,
        meta: {
            requiresAuth: true
        },
        children: require('./customer').default
    })
}

if (Config.userType == 'admin') {
    routes.push({
        path: '/admin',
        name: 'admin_module',
        component: Portal,
        meta: {
            requiresAuth: true
        },
        children: require('./admin').default
    })
}

if (Config.userType == 'merchant') {
    routes.push({
        path: '/merchant',
        name: 'merchant_module',
        component: Portal,
        meta: {
            requiresAuth: true
        },
        children: require('./merchant').default
    })
}

export default routes;