import Service from '@/services/reports.js';

const state = {
    engagements: [],
    audience_distribution: [],
    pollReports: []
};

const getters = {
    getEngagements(state) {
        return state.engagements;
    },

    getAudienceDistribution(state) {
        return state.audience_distribution;
    },

    getPollReports(state) {
        return state.pollReports;
    }
};

const mutations = {
    SET_ENGAGEMENT_REPORTS(state, payload) {
        state.engagements.push(payload);
    },

    SET_AUDIENCE_DISTRIBUTION(state, payload) {
        state.audience_distribution.push(payload);
    },

    SET_POLL_RESULTS(state, payload) {
        state.pollReports.push(payload);
    }
};

const actions = {
    getEngagementReports({ commit }, params) {
        return Service.getEngagementReports(params)
            .then(response => {
                commit('SET_ENGAGEMENT_REPORTS', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    getAudienceDistribution({ commit }, params) {
        return Service.getAudienceDistribution(params)
            .then(response => {
                commit('SET_AUDIENCE_DISTRIBUTION', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    getPollReports({ commit }, params) {
        return Service.getPollReports(params)
            .then(response => {
                commit('SET_POLL_RESULTS', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    }
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
}