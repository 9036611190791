<template>
    <div class="table-responsive">
        <table class="table table-striped table-bordered">
            <thead>
                <th>Question</th>
                <th>Created At</th>
                <th class="text-center">Options</th>
                <th class="text-center">Total Votes</th>
            </thead>
            <tbody>
                <tr v-for="(item,index) in versions" :key="index" :class="{'text-success font-weight-bold': campaign.current_poll_id == item.id}">
                    <td>{{ item.question }}</td>
                    <td>{{ item.created_at | formattedDateTime }}</td>
                    <td class="text-center">{{ item.options.length }}</td>
                    <td class="text-center">{{ getVotes(item) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
    props: ['campaign'],

    computed: {
        versions() {
            return this.campaign?.polls;
        }
    },

    methods: {
        getVotes(item) {
            let total = 0;

            for (let opt of item.options) {
                total += opt.votes;
            }

            return total;
        },
    },

    created() {
        // console.log(this.versions)
    }
}
</script>