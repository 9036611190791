<template>
    <div class="content-wrapper" :class="{'boxed-content': boxed}" @click="closeSidebar">
        <div class="content" :class="{'pr-2 pl-2': !no_padding}">
            <slot/>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'content-wrapper',

    props: {
        no_padding: {
            type: Boolean,
            default: false
        },

        boxed: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        ...mapGetters({
            sidebarOpened: 'getSidebarOpened',
        })
    },

    methods:{
        ...mapActions({
            closeSidebar: 'closeSidebar'
        })
    }
}
</script>

<style>

</style>
