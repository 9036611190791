<template>
<modal
    :show="show"
    :md="true"
    :centered="true"
    :showFooter="true"
    :maxHeight="true"
    okText="Submit"
    @ok="submit"
    @cancel="$emit('cancel')"
    okIcon="mdi mdi-check-all">

    <h5 class="m-0 font-small font-weight-bold" slot="header">{{ title }}</h5>

    <div class="font-small p-2 bg-default" slot="body">
        <div class="row no-gutters">
            <div class="col-6 pr-1">
                <base-input
                    type="text"
                    label="Name"
                    v-model="payload.name"
                    :error="validationErrors.name"
                    placeholder="This will be used by humans"/>
            </div>
            <div class="col-6">
                <base-input
                    type="text"
                    label="System Name"
                    placeholder="This will be used by system"
                    :error="validationErrors.system_name"
                    v-model="payload.system_name"/>
            </div>
        </div>

        <base-select
            label="System Type"
            v-model="payload.system_type"
            :error="validationErrors.system_type">
            <option value="">None</option>
            <option v-for="(type, index) in systemTypes" :key="index" :value="type">{{ type }}</option>
        </base-select>

        <div class="form-group type-2">
            <label class="font-small">Description</label>
            <div class="icon"><i class="mdi mdi-information-outline"></i></div>
            <textarea
                v-model="payload.description"
                :error="validationErrors.description"
                placeholder="Write some description about this attribute"
                class="form-control font-small text-input"
                rows="5"></textarea>
            <span class="error-label font-10 d-block mt-1 ml-1">{{ validationErrors.description }}</span>
        </div>
    </div>
</modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['show', 'attribute'],

    data() {
        return {
            payload: {
                name: '',
                system_name: '',
                system_type: '',
                description: ''
            },

            validationErrors: {
                name: '',
                system_name: '',
                system_type: '',
                description: ''
            },

            systemTypes: []
        }
    },

    computed: {
        ...mapGetters({
            intentTypes: 'IntentTypes/getAll'
        }),

        title() {
            if (!this.attribute) {
                return 'Add a new intent attribute';
            }

            return `Edit ${this.attribute.name}`;
        }
    },

    watch: {
        show() {
            if (this.show) {
                this.reset();
            }
        },

        attribute: {
            deep: true,
            immediate: true,
            handler() {

            }
        }
    },

    methods: {
        ...mapActions({
            getSystemTypes: 'IntentAttributes/getSystemTypes',
            store: 'IntentAttributes/store'
        }),

        reset() {
            this.payload.name = '';
            this.payload.system_name = '';
            this.payload.system_type = '';
            this.payload.description = '';
        },

        cancel() {
            this.reset();
            this.$emit('cancel');
        },

        submit() {
            this.buildValidationErrors({})

            this.showLoader('Adding attribute... Please wait');

            this.store(this.payload)
                .then(response => {
                    this.hideLoader();
                    this.cancel();
                })
                .catch(error => {
                    this.hideLoader();
                    this.buildValidationErrors(error);
                })
        }
    },

    created() {
        this.getSystemTypes()
            .then(response => {
                this.systemTypes = response;
            })
            .catch(error => null)
    }
}
</script>