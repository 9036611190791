<template>
    <div class="row justify-content-center">
        <div :class="{'col-md-8 col-9': !preview, 'col-12': preview}" v-if="testimonial">
            <div class="testimonial-template template-3" :class="{preview: preview}" v-if="testimonial.template == 'template-3'">
                <p class="text">
                    "{{ testimonial.text }}"
                </p>

                <div class="text-center">
                    <p class="author m-0">{{ testimonial.author }}</p>
                    <p class="stars">
                        <i class="mdi mdi-star text-primary" v-for="n in testimonial.stars" :key="n"></i>
                    </p>
                </div>
            </div>

            <div class="testimonial-template template-2" :class="{preview: preview}" v-if="testimonial.template == 'template-2'">
                <div class="text-center">
                    <p class="text mb-3">
                        "{{ testimonial.text }}"
                    </p>

                    <p class="author m-0">{{ testimonial.author }}</p>
                </div>

                <p class="stars">
                    <i class="mdi mdi-star text-warning" v-for="n in testimonial.stars" :key="n"></i>
                </p>
            </div>

            <div class="testimonial-template template-1" :class="{preview: preview}" v-if="testimonial.template == 'template-1'">
                <div class="row no-gutters">
                    <div class="col-6 left-side">
                        <h2 class="text-uppercase text-white">Take a Hike</h2>
                        <p></p>
                    </div>

                    <div class="col-6 right-side">
                        <div class="text-center">
                            <p class="text mb-3">
                                "{{ testimonial.text }}"
                            </p>
                        </div>

                        <div>
                            <p class="author m-0">{{ testimonial.author }}</p>
                            <p class="stars">
                                <i class="mdi mdi-star text-warning" v-for="n in testimonial.stars" :key="n"></i>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['testimonial', 'preview'],

    mounted() {
    }
}
</script>