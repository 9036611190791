import Config from '@/config';
import filters from './filters';
import methods from './methods';
import { mapGetters } from 'vuex';

export default {
	data() {
		return {
			dateFormat: 'ddd Do MMM, YYYY'
		}
	},

	computed: {
		...mapGetters({
			authUser: 'Authentications/getUser',
			isLoggedIn: 'Authentications/isLoggedIn',
			pagination: 'getPagination'
		}),

		userType() {
			return Config.userType;
		},

		isCustomerUser() {
			return Config.userType == 'customer';
		},

		isMerchantUser() {
			return Config.userType == 'merchant';
		},

		isAdminUser() {
			return Config.userType == 'admin';
		},

		serviceFee() {
			return 0.3;
		},

		loadMore() {
			return { page: this.pageDetails.currentPage + 1 }
		},

		showLoadMore() {
			return this.pageDetails.currentPage < this.pageDetails.lastPage;
		},

		accountSetupComplete() {
			return this.authUser && this.authUser.phone && this.authUser.country_id
		},

		/**
		 * Getter for auth user's currency
		 *
		 * @returns {Object}
		 */
		currency() {
			return this.authUser.country.currency;
		}
	},

	filters,

	methods,

	mounted() {
		$(function () {
			$('[data-toggle="tooltip"]').tooltip()
		})
	}
}