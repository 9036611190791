<template>
    <div>
        <div class="row no-gutters">
            <div class="col-md-6 col-12 p-1">
                <base-input
                    label="Question"
                    class="filter"
                    v-model="payload.question"
                    placeholder="Ask a question"/>

                <div class="row">
                    <div class="col-6">
                        <base-input
                            label="Option 1"
                            class="filter"
                            v-model="payload.options.option_1"
                            placeholder="Enter option 1"/>
                    </div>
                    <div class="col-6">
                        <base-input
                            label="Option 2"
                            class="filter"
                            v-model="payload.options.option_2"
                            placeholder="Enter option 2"/>
                    </div>
                    <div class="col-6">
                        <base-input
                            label="Option 3"
                            class="filter"
                            v-model="payload.options.option_3"
                            placeholder="Enter option 3"/>
                    </div>
                    <div class="col-6">
                        <base-input
                            label="Option 4"
                            class="filter"
                            v-model="payload.options.option_4"
                            placeholder="Enter option 4"/>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-42 p-1">
                <poll :poll="poll" v-if="poll && poll.question"/>

                <p class="bg-default rounded pt-5 pb-5 text-center font-small elevation m-1" v-else>
                    No ad poll created
                </p>
            </div>

            <div class="col-12" v-if="pollIsDirty || !currentPoll">
                <div class="row justify-content-center mt-3">
                    <div class="col-md-6">
                        <button class="btn btn-primary login-btn font-small btn-block" @click.prevent="submit">
                            <i class="mdi mdi-pencil"></i> CREATE POLL
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import Poll from './Poll';

export default {
    props: ['advert'],

    components: {
        Poll
    },

    data() {
        return {
            payload: {
                question: '',

                options: {
                    option_1: '',
                    option_2: '',
                    option_3: '',
                    option_4: '',
                }
            },

            tempPoll: null
        }
    },

    computed: {
        poll() {
            let options = [];

            for (let key of Object.keys(this.payload.options)) {
                if (!this.payload.options[key]) continue;

                options.push(this.payload.options[key].trim());
            }

            return {
                question: this.payload.question.trim(),
                options
            }
        },

        currentPoll() {
            if (!this.advert || !this.advert.polls) return null;

            return this.advert.polls.find(p => p.id == this.advert.poll_id);
        },

        pollIsDirty() {
            let poll = this.currentPoll || this.tempPoll;

            if (!poll) return false;

            if (poll.question !== this.payload.question) return true;

            for(let [index, option] of poll.options.entries()) {
                if (this.payload.options[`option_${index+1}`] !== option.name) return true;
            }

            return false
        }
    },

    methods: {
        ...mapActions({
            storePoll: 'Adverts/storePoll',
        }),

        resetForm() {
            this.payload = {
                question: '',
                options: {
                    option_1: '',
                    option_2: '',
                    option_3: '',
                    option_4: '',
                }
            }
        },

        async submit() {
            if (!this.payload.question) {
                return this.swalError('Validation Error', `Poll question field is required`);
            }

            if (this.poll.options.length < 2) {
                return this.swalError('Validation Error', `A poll must have at least 2 options`);
            }

            if (this.pollIsDirty) {
                const result = await this.swalConfirm(
                    "Are you sure?", 
                    `Submitting this form will create a new poll version. Please confirm`,
                    'Yes, Submit'
                )
                if (!result.value) return;
            }

            this.showLoader('Creating Poll... Please wait')

            this.storePoll({
                advert_id: this.advert.id,
                ...this.poll
            })
            .then(response => {
                console.log(response)
                // this.$emit('refresh')
                this.tempPoll = response;
                this.hideLoader()
            })
            .catch(error => {
                console.log(error);
                this.swalError(`Error`, error.message);
                // this.resetForm();
                this.hideLoader()
            })
        },

        populate() {
            if (!this.currentPoll) return;

            this.payload.question = this.currentPoll.question;

            for(let [index, option] of this.currentPoll.options.entries()) {
                this.payload.options[`option_${index+1}`] = option.name;
            }
        }
    },

    mounted() {
        this.populate();
    }
}
</script>