<template>
    <div>
        <base-search-box
            class="sticky mb-4"
            leftBtnIcon="mdi mdi-sort"
            v-model="keyword"
            @search="performFiltering"
            placeholder="Search specific profile information">
        </base-search-box>

        <layout
            class="mb-3"
            v-for="(attributeGroup, index) in attributes"
            :close="false"
            :key="index"
            :title="`${attributeGroup.group.name}`">

            <div class="p-2">
                <hr class="mt-0"/>
                <div class="">
                    <div class="row">
                        <div
                            class="col-12"
                            v-for="(attribute, i) in attributeGroup.attributes"
                            :class="{'col-md-6': !fullWidth(attribute), 'col-md-12': fullWidth(attribute)}"
                            :key="i">
                            <div class="">
                                    <radio-group
                                        v-if="attribute.system_type == 'YesNo'"
                                        name="yes_no"
                                        :options="yesNoOptions"/>

                                    <base-select-wrapper
                                        :label="attribute.name"
                                        icon="mdi mdi-information-outline"
                                        v-if="attribute.system_type == 'SelectOption'">
                                        <select
                                            v-model="attributeValues[getKeyFor(attribute.id)]"
                                            :name="attribute.system_name"
                                            class="form-control font-xs pb-1">
                                            <option value="">None</option>
                                            <option v-for="(option, index) in attribute.settings.options" :value="option" :key="index">
                                                {{ option }}
                                            </option>
                                        </select>
                                    </base-select-wrapper>

                                    <base-select-wrapper
                                        :label="attribute.name"
                                        icon="mdi mdi-information-outline"
                                        v-if="attribute.system_type == 'Country'">
                                        <select
                                            v-model="attributeValues[getKeyFor(attribute.id)]"
                                            :name="attribute.system_name"
                                            class="form-control font-xs pb-1">
                                            <option value="">None</option>
                                            <option v-for="(country, index) in attribute.settings_data.countries" :value="country.id" :key="index">
                                                {{ country.name }}
                                            </option>
                                        </select>
                                    </base-select-wrapper>

                                    <base-input
                                        icon="mdi mdi-information-outline"
                                        :label="attribute.name"
                                        v-model="attributeValues[getKeyFor(attribute.id)]"
                                        :name="attribute.system_name"
                                        :placeholder="attribute.question || attribute.description || attribute.name"
                                        v-if="attribute.system_type == 'TextInput'"/>

                                    <base-input
                                        :label="attribute.name"
                                        v-model="attributeValues[getKeyFor(attribute.id)]"
                                        :name="attribute.system_name"
                                        :placeholder="attribute.question || attribute.description || attribute.name"
                                        type="number"
                                        v-if="attribute.system_type == 'NumericInput'"/>

                                    <div class="form-group type-4" v-if="attribute.system_type == 'DateInput'">
                                        <label class="font-xs m-0">{{ attribute.name }}</label>
                                        <datetime
                                            v-model="attributeValues[getKeyFor(attribute.id)]"
                                            input-class="form-control font-xs"
                                            :placeholder="attribute.question || attribute.description || attribute.name"
                                            :title="attribute.name"
                                        ></datetime>
                                    </div>

                                    <div class="rounded mb-3" v-if="attribute.system_type == 'GPSCoordinates'">
                                        <div class="d-flex justify-content-between font-xs">
                                            <label class="font-xs text-dark-default">{{ attribute.name }}</label>

                                            <label>
                                                <input type="checkbox"/>
                                                Use GPS
                                            </label>
                                        </div>
                                        <div class="row no-gutters">
                                            <div class="col-6 pr-1">
                                                <base-input
                                                    :typeNumber="3"
                                                    label="Latitude"
                                                    placeholder="Enter coordinate for latitude"/>
                                            </div>
                                            <div class="col-6 pl-1">
                                                <base-input
                                                    :typeNumber="3"
                                                    label="Longitude"
                                                    placeholder="Enter coordinate for longitude"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>

                    <div class="text-right">
                        <button
                            @click="submit(attributeGroup.attributes.map(item => item.id))"
                            type="button"
                            class="btn elevation btn-success font-xs text-white login-btn text-uppercase">
                            <i class="mdi mdi-content-save-edit-outline"></i>
                            UPDATE {{ attributeGroup.group.name }}
                        </button>
                    </div>
                </div>
            </div>
        </layout>
    </div>
</template>

<script>
import {debounce} from 'lodash';
import Fields from '@/mixins/fields';
import { mapActions, mapGetters } from 'vuex';
import Layout from './partials/Layout.vue';
import BaseInput from '@/components/forms/BaseInput.vue';

export default {
    components: {
        Layout
    },

    mixins: [Fields],

    data() {
        return {
            attributes: [],

            attributeValues: {},

            keyword: '',

            data: [],
            filtered: []
        }
    },

    watch: {
        keyword() {
            this.performFiltering()
        },

        filtered: {
            deep: true,
            immediate: true,
            handler() {
                if (!this.keyword.trim()) {
                    this.setAttributes(this.data);
                } else {
                    this.setAttributes(this.filtered);
                }
            }
        }
    },

    methods: {
        ...mapActions({
            index: 'ProfileAttributes/index',
            saveProfile: 'ProfileAttributes/saveProfile'
        }),

        performFiltering: debounce(function () {
            let parts = this.keyword.split(' ').filter(part => part.trim());

            this.filtered = this.data.filter(attribute => {
                for (let part of parts) {
                    if (
                        attribute.name.toLowerCase().includes(part.toLowerCase()) ||
                        (attribute.question && attribute.question.toLowerCase().includes(part.toLowerCase())) ||
                        (attribute.description && attribute.description.toLowerCase().includes(part.toLowerCase()))
                    ) {
                        return true;
                    }
                }

                return false;
            });
        }, 200),

        fullWidth(attribute) {
            return ['GPSCoordinates'].includes(attribute.system_type);
        },

        getKeyFor(attribute_id) {
            return `attribute_${attribute_id}`;
        },

        setAttributeValues(attributes) {
            // this.attributeValues = {};

            let key, value;

            for (let attribute of attributes) {
                key = this.getKeyFor(attribute.id);

                value = attribute.values && attribute.values.length ? attribute.values[0] : null;

                this.attributeValues[key] = value ? value.value : '';
            }
        },

        setAttributes(response) {
            this.setAttributeValues(response);

            let groups = {};

            for (let attribute of response) {
                let key = `group-${attribute.group.id}`;

                if (!groups.hasOwnProperty(key)) {
                    groups[key] = {
                        attributes: [],
                        group: attribute.group
                    }
                }

                groups[key].attributes.push(attribute);
            }

            this.attributes = Object.values(groups);
        },

        getAttributeValues(attributes) {
            let key;

            let values = [];

            for (let attribute_id of attributes) {
                key = this.getKeyFor(attribute_id);

                values.push({
                    attribute_id,
                    value: this.attributeValues[key]
                })
            }

            return values;
        },

        submit(attributes) {
            this.showLoader('Updating Profile... Please wait');

            attributes = this.getAttributeValues(attributes);

            this.saveProfile({profile_attributes: attributes})
                .then(response => {
                    this.hideLoader()
                    this.data = response;

                    if (this.keyword.trim()) {
                        this.performFiltering();
                    }
                })
                .catch(error => {
                    this.hideLoader()
                })
        }
    },

    created() {
        this.index({by_group: 1, with_values: 1})
            .then(response => {
                this.data = response;
                this.filtered = response;

                // this.setAttributes(response);
            })
            .catch(error => null)
    }
}
</script>