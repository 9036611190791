<template>
<modal
    :show="show"
    :lg="true"
    :centered="true"
    :showFooter="true"
    :showCancel="false"
    :maxHeight="true"
    okText="Okay"
    @cancel="$emit('cancel')"
    @ok="$emit('cancel')"
    okIcon="mdi mdi-check-all">

    <h5 class="m-0 font-small font-weight-bold" slot="header">{{ intent ? intent.title : '' }}</h5>

    <div class="font-small p-2 bg-default" slot="body">
        <h5 class="bg-secondary text-white font-small p-3 font-weight-bold">Basic Information</h5>

        <div class="row no-gutters">
            <div class="col-12 col-md-6 p-1">
                <div class="app-card elevation-2">
                    <p class="m-0 font-small font-weight-bold">Intent Type</p>
                    <hr class="m-0 mt-1 mb-1"/>
                    <p class="m-0 font-small d-flex justify-content-between">
                        <span>{{ intent && intent.type ? intent.type.name : '' }}</span>

                        <template v-if="intent && intent.type">
                            <img src="/img/product.png" style="height:40px;width:40px;border-radius:50%;object-fit:cover;" v-if="intent.type.name == 'Product'"/>
                            <img src="/img/service.png" style="height:40px;width:40px;border-radius:50%;object-fit:cover;" v-if="intent.type.name == 'Service'"/>
                        </template>
                    </p>
                </div>
            </div>
            <div class="col-12 col-md-6 p-1 full-height">
                <div class="app-card elevation-2 full-height">
                    <p class="m-0 font-small font-weight-bold">Expiration Date</p>
                    <hr class="m-0 mt-1 mb-1"/>
                    <p class="m-0 font-small">
                        {{ intent && intent.expiry_date | formattedDate }}
                    </p>
                </div>
            </div>
            <div class="col-12 p-1">
                <div class="app-card elevation-2">
                    <p class="m-0 font-small font-weight-bold">Description</p>
                    <hr class="m-0 mt-1 mb-1"/>
                    <p class="m-0 font-small">
                        {{ intent && intent.title }}
                    </p>
                </div>
            </div>
        </div>

        <div class="mt-3" v-for="(valueGroup, index) in valueGroups" :key="index">
            <h5 class="bg-secondary text-white font-small p-3 font-weight-bold">{{ valueGroup.label }}</h5>

            <div class="row no-gutters">
                <div class="col-12 col-md-6 p-1" v-for="(value, v) in valueGroup.values" :key="v">
                    <div class="app-card elevation-2">
                        <p class="m-0 font-small font-weight-bold">{{ value.attribute.name | capitalize }}</p>
                        <hr class="m-0 mt-1 mb-1"/>
                        <p class="m-0 font-small">{{ parseValue(value) }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
export default {
    props: ['intent', 'show'],

    computed: {
        valueGroups() {
            if (!this.intent) return [];

            let groups = {};

            for (let value of this.intent.values) {
                let key = this.groupNames[value.intent_attribute_id];

                if (!groups.hasOwnProperty(key)) {
                    groups[key] = {
                        label: key,
                        values: []
                    };
                }

                groups[key].values.push(value);
            }

            return Object.values(groups);
        },

        groupNames() {
            let group = {};

            if (!this.intent || !this.intent.structure) return group;

            for (let item of this.intent.structure) {
                group[item.intent_attribute_id] = item.group_name;
            }

            return group;
        }
    },

    methods: {
        parseValue({attribute, value}) {
            if (attribute.system_type == 'DateInput') {
                return value ? moment(value).format('ddd Do MMM, YYYY') : 'n/a'
            } else if (attribute.system_type == 'YesNo') {
                if (value == 1 || value == 'Yes') {
                    return 'Yes';
                } else if (value == 2 || value == 'No') {
                    return 'No'
                } else {
                    return 'n/a'
                }
            }

            return value;
        }
    }
}
</script>