<template>
    <div class="card p-1 m-0 mb-2 card-layout" :class="{'elevation': elevation}">
        <div
            @click.prevent.stop="collapse = !collapse"
            class="heading font-small"
            v-if="!!title">
            <span class="text-shadow">{{ title }}</span> <br/>
            <small class="text-tertiary font-9">
                (Click to {{ collapse ? 'expand' : 'collapse' }})
            </small>

            <span class="float-right">
                <i class="mdi mdi-menu-down-outline" v-if="collapse"></i>
                <i class="mdi mdi-menu-up-outline" v-else></i>
            </span>
        </div>


        <transition name="router">
            <div v-if="!collapse">
                <div class="tabs mb-3" v-if="availableTabs && availableTabs.length">
                    <button
                        class="tab-item font-xs"
                        :class="{'active': tab.id == value}"
                        type="button"
                        v-for="(tab, index) in availableTabs"
                        :key="index"
                        @click.prevent="$emit('input', tab.id)">
                        <i :class="tab.icon" class="mr-1"></i>
                        <span>{{ tab.name }}</span>
                    </button>
                </div>

                <slot/>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        title: String,

        elevation: {
            type: Boolean,
            default: true
        },

        close: {
            type: Boolean,
            default: true
        },

        availableTabs: {
            type: Array,
        },

        value: String
    },

    data() {
        return {
            collapse: true
        }
    },

    watch: {
        close() {
            this.collapse = this.close;
        }
    },

    mounted() {
        this.collapse = this.close;
    }
}
</script>