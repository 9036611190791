<template>
    <div class="font-small">
        <div class="card p-2 alert alert-info font-xs font-weight-bold elevation">
            For each person that registers through your referral link, you gain GHS 0.50 (50 pesewas) once they signup and play their first league competition.
        </div>

        <div class="card no-radius p-2 elevation">
            <p class="text-secondary">Referral Link</p>

            <a href="#" @click.prevent="" class="text-info">{{ link }}</a>

            <div class="mt-2 text-right">
                <a href="#" class="btn btn-secondary btn-sm mr-2 font-xs" @click.prevent="copyLink">
                    <i class="mdi mdi-content-copy"></i> Copy Link
                </a>

                <a href="#" class="btn btn-secondary text-success btn-sm font-xs" v-if="showShare"  @click.prevent="share">
                    <i class="mdi mdi-share-variant"></i> Share
                </a>
            </div>
        </div>

        <div class="card p-2 elevation no-radius" v-if="summary">
            <h4 class="mt-0 font-16">Revenue From Referrals</h4>
            <h2 class="text-success my-3 text-center">{{ summary.revenue }}</h2>
            <p class="text-white mb-0">
                You referred {{ summary.total_users }} people
                <span class="float-right">
                    {{ summary.effective_users }} people played their first league
                </span>
            </p>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            summary: null
        }
    },

    computed: {
        link() {
            return `https://app.personalyy.com/register?referral=${this.authUser.id}`
        },

        showShare() {
            return !!navigator.share;
        }
    },

    methods: {
        ...mapActions({
            // index: 'Users/getReferrals'
        }),

        copyLink() {
            const el = document.createElement('textarea');
            el.value = this.link;

            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);

            const selected =
                document.getSelection().rangeCount > 0
                ? document.getSelection().getRangeAt(0)
                : false;

            el.select();

            document.execCommand('copy');

            // this.notifyInfo(`Your referral link has been copied to clipboard.`, 'Link Copied')

            document.body.removeChild(el);

            if (selected) {
                document.getSelection().removeAllRanges();
                document.getSelection().addRange(selected);
            }
        },

        share() {
            if (!this.showShare) {
                return;
            }

            navigator.share({
                url: this.link,
                title: 'Join Woonmi',
                text: `Woonmi is a novel virtual soccer Application that brings an awe-inspiring verve and fun into fantasy football. It comes with splendid specs. In fantasy football, Woonmi is definitely a go-to App. Have fun & make money.`
            });
        }
    },

    mounted() {
        // this.index()
        //     .then(response => {
        //         this.summary = {
        //             ...response
        //         }
        //     })
        //     .catch(error => {
        //     })
    }
}
</script>