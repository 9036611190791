<template>
<div class="login-page">
    <heading :title="`Login via ${provider}`" description="Elevate your shopping game"/>

    <div class="row justify-content-center m-0 mt-5">
        <div class="col-12 col-sm-6 col-md-4">
            <app-card class="mt-5 text-tertiary pt-2 pb-2 pl-4 pr-4">
                <h4 class="text-center mt-3">Logging you in....</h4>
                <hr/>

                <div class="d-flex justify-content-center align-items-center mb-3" v-if="authUser.image">
                    <img :src="authUser.image" class="avatar-xl bg-secondary circle" style="object-fit:cover;"/>
                </div>

                <p class="text-justify font-small">
                    Your account on Woonmi will now be linked to {{ provider }}. If you want to login with username or password combination, you will have to select forgot password to set a new password.
                </p>

            </app-card>
        </div>
    </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Heading from './partials/GuestHeading';

export default {
    name: 'SocialLogin',

    props: ['provider'],

    components: {
        Heading
    },

    data() {
        return {
            data: null,
            showEdit: false
        }
    },

    computed: {
        ...mapGetters({
            user: 'Authentications/getUser'
        }),
    },

    methods: {
        ...mapActions({
            socialLoginHandleCallback: 'Authentications/socialLoginHandleCallback',
        }),

        proceed() {
            this.toDashboard()
        },

        /**
         * Verifies user email account
         *
         * @returns {Undefined}
         */
        submit() {
            this.showLoader('Logging you in... Please wait')

            this.socialLoginHandleCallback({provider: this.provider, params: this.$route.query})
                .then(response => {
                    this.hideLoader();
                    // console.log(response)
                    this.proceed();
                })
                .catch(error => {
                    this.hideLoader()
                    // console.log(error)
                    this.swalError(`${this.$options.filters.capitalize(this.provider)} Authorization Failed`, error.message, false);
                    this.$router.replace({name: 'login'});
                });
        }
    },

    created() {
        if (!this.$route.query.code) {
            return this.$router.replace({name: 'login'})
        }

        this.submit();
    }
}
</script>

<style lang="scss">
</style>
