import Service from '@/services/tickets';

const state = {
    all: [],
    pageDetails: {
        to: 0,
        from: 0,
        total: 0,
        perPage: 0,
        lastPage: 0,
        currentPage: 0,
    },

    topics: [],
    statuses: [],

    updates: {
        all: [],
        pageDetails: {
            to: 0,
            from: 0,
            total: 0,
            perPage: 0,
            lastPage: 0,
            currentPage: 0,
        },
    }
};

const getters = {
    getAll(state) {
        return state.all;
    },

    getPageDetails(state) {
        return state.pageDetails;
    },

    getAllUpdates(state) {
        return state.updates.all;
    },

    getUpdatesPageDetails(state) {
        return state.updates.pageDetails;
    },

    getTopics(state) {
        return state.topics;
    },

    getStatuses(state) {
        return state.statuses;
    }
};

const mutations = {
    SET_TICKETS(state, { clearState, payload }) {
        state.pageDetails = {
            total: payload.total || 0,
            from: payload.from || 0,
            to: payload.to || 0,
            currentPage: payload.current_page || 0,
            lastPage: payload.last_page || 0,
            perPage: payload.per_page || 0
        }

        let all = payload.data ? payload.data : payload;

        if (clearState) {
            state.all = all;
        } else {
            state.all = [...state.all, ...all];
        }
    },

    ADD_TICKET(state, intent) {
        state.all.unshift(intent);
    },

    UPDATE_TICKET(state, response) {
        for (let [index, ticket] of state.all.entries()) {
            if (ticket.id == response.id) {
                state.all[index] = response;
                return;
            }
        }
    },

    REMOVE_TICKET(state, id) {
        for (let [index, intent] of state.all.entries()) {
            if (intent.id == id) {
                state.all.splice(index, 1);
                return;
            }
        }
    },

    SET_TOPICS(state, topics) {
        state.topics = topics;
    },

    SET_STATUSES(state, statuses) {
        state.statuses = statuses;
    },

    SET_TICKET_UPDATES(state, { clearState, payload }) {
        state.updates.pageDetails = {
            total: payload.total || 0,
            from: payload.from || 0,
            to: payload.to || 0,
            currentPage: payload.current_page || 0,
            lastPage: payload.last_page || 0,
            perPage: payload.per_page || 0
        }

        let all = payload.data ? payload.data : payload;

        if (clearState) {
            state.updates.all = all;
        } else {
            state.updates.all = [...state.updates.all, ...all];
        }
    },

    ADD_UPDATE(state, update) {
        state.updates.all.push(update);
    }
};

const actions = {
    index({ commit }, params) {
        return Service.index(params)
            .then(response => {
                commit('SET_TICKETS', { clearState: params.clearState, payload: response });
                return Promise.resolve(response);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    store({ commit }, data) {
        return Service.store(data)
            .then(response => {
                commit('ADD_TICKET', response.ticket);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    update({ commit }, params) {
        return Service.update(params.id, params.data)
            .then(response => {
                commit('UPDATE_TICKET', response.ticket);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    show({ commit }, params) {
        return Service.show(params.id, params.params)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    destroy({ commit }, id) {
        return Service.destroy(id)
            .then(response => {
                commit('REMOVE_TICKET', id);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    getTopics({ commit }, params) {
        return Service.getTopics(params)
            .then(response => {
                commit('SET_TOPICS', response)
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    getStatuses({ commit }, params) {
        return Service.getStatuses(params)
            .then(response => {
                commit('SET_STATUSES', response)
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    storeUpdate({ commit }, params) {
        return Service.storeUpdate(params)
            .then(response => {
                commit('ADD_UPDATE', response.ticket_update)
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    getUpdates({ commit }, params) {
        return Service.getUpdates(params)
            .then(response => {
                commit('SET_TICKET_UPDATES', { clearState: params.clearState, payload: response })
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    sendPageComments({commit}, data) {
        return Service.sendPageComments(data)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
}