import UpdateIntent from '@/views/customer/intents/update/Index.vue';
import ViewIntent from '@/views/customer/intents/view/Index.vue';
import CreateIntentWizard from '@/views/customer/intents/create/CreateIntentWizard.vue';

import Dashboard from '@/views/customer/dashboard/Index.vue';
import HoldList from '@/views/customer/dashboard/HoldList.vue';
import FriendsHoldList from '@/views/customer/dashboard/FriendsHoldList.vue';
import Interests from '@/views/customer/interests/Index.vue'
import ViewHoldListItem from '@/views/customer/hold-list/View.vue';
import SelectInterests from '@/views/customer/interests/SelectInterests.vue'
import ViewStoreFront from '@/views/customer/store-front/Index.vue'

let routes = [
    {
        name: 'create_intent',
        path: 'intents/create',
        component: CreateIntentWizard,
        meta: {
            title: 'Create New Channel'
        }
    },

    {
        name: 'update_intent',
        path: 'intents/update/:intent_id',
        component: UpdateIntent,
        meta: {
            title: 'Update Intent'
        },
        props: true
    },

    {
        name: 'view_intent',
        path: 'intents/view/:intent_id',
        component: ViewIntent,
        meta: {
            title: 'View Intent'
        },
        props: true
    },

    // {
    //     name: 'wallet',
    //     path: 'wallet',
    //     component: Wallet,
    //     meta: {
    //         title: 'Wallets'
    //     }
    // },

    {
        name: 'dashboard',
        path: 'dashboard',
        component: Dashboard,
        meta: {
            title: 'Dashboard'
        }
    },

    {
        name: 'dashboard_hold_list',
        path: 'dashboard/hold-list',
        component: HoldList,
        meta: {
            title: 'Your Hold List'
        }
    },

    {
        name: 'friends_hold_list',
        path: 'dashboard/friends/hold-list',
        component: FriendsHoldList,
        meta: {
            title: 'Friends Hold List'
        }
    },

    {
        name: 'view_hold_list_item',
        path: 'dashboard/hold-list/:advert_id',
        component: ViewHoldListItem,
        props: true,
        meta: {
            title: 'View Hold List Item'
        }
    },

    // {
    //     name: 'profile',
    //     path: 'profile',
    //     component: Profile,
    //     meta: {
    //         title: 'Profile'
    //     }
    // },

    {
        name: 'select_interests',
        path: 'interests/setup',
        component: SelectInterests,
        meta: {
            title: 'Select You Interests'
        }
    },

    {
        name: 'interests',
        path: 'interests',
        component: Interests,
        meta: {
            title: 'Interests'
        }
    },

    {
        name: 'view_store_front',
        path: 'postings/store-fronts/:posting_id',
        component: ViewStoreFront,
        props: true,
        meta: {
            title: 'View Store Front'
        }
    }
];

export default routes.map(route => {
    return { ...route, name: `customer_${route.name}` }
});