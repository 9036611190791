<template>
<modal
    :show="show"
    :centered="true"
    :showFooter="true"
    :maxHeight="true"
    okText="Select"
    okIcon="mdi mdi-checkbox-marked-circle"
    @ok="submit"
    @cancel="cancel">

    <h5 class="m-0 font-small font-weight-normal text-capitalize" slot="header">Select Label Color</h5>

    <div slot="body" class="p-0 font-small label-colors text-justify">
        <div
            class="label-color d-inline-flex justify-content-center align-items-center"
            v-for="(color, index) in colors"
            :key="index"
            :style="{background: color}"
            @click.prevent="selected = color">
            <i class="mdi mdi-checkbox-marked-circle text-white icon font-big" v-if="color == selected"></i>
        </div>
    </div>
</modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['show', 'value'],

    data() {
        return {
            selected: ''
        }
    },

    computed: {
        colors() {
            return [
                `#8be2c5`, `#fa6971`, `#b688dd`, `#01b638`, `#11a0e9`,
                `#bcc98e`, `#ed1e8b`, `#0f0448`, `#b5ea47`,`#4700be`,
                `#84ecac`, `#b58f08`, `#e6c32d`, `#5fef52`, `#3e1b15`,
                `#663249`, `#c579ab`, `#6e8084`, `#2523db`, `#d20840`
            ];
        }
    },

    watch: {
        value() {
            this.selected = this.value;
        }
    },

    methods: {
        cancel() {
            this.$emit('cancel')
        },

        submit() {
            this.$emit('submit', this.selected)
            this.cancel()
        }
    },

    mounted() {
        this.selected = this.value;
    }
}
</script>