import { axios } from './index';

export default {
    /**
     * Get available intent attributes
     *
     * @param {Object} params
     * @returns {Object}
     */
    index(params) {
        return axios.get('intent_attributes', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    getSystemTypes(params) {
        return axios.get('intent_attribute_system_types', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    store(data) {
        return axios.post('intent_attributes', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    update(id, data) {
        return axios.put('intent_attributes/' + id, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },
}