<template>
    <div class="form-group elevation mb-3 type-2" :class="divClass">
        <label class="font-small m-0" v-if="!noLabel">{{label ? label : placeholder}}</label>

        <div class="icon" v-if="icon">
            <span :class="`${icon} ${error ? 'shake-bell text-danger' : ''}`"></span>
        </div>

        <span class="font-10 error-label" v-if="error">{{ error }}</span>

        <datetime :input-class="`form-control font-small ${!!error ? 'padded' : ''}`" v-model="payload" :title="label"></datetime>
    </div>
</template>

<script>

export default {
    props: {
        value: {
            type: [String, Number]
        },

        icon: String,

        name: {
            type: String
        },

        type: {
            type: String,
            default: 'text'
        },

        label: {
            type: String,
            default: ''
        },

        error: {
            type: String,
            default: ''
        },

        placeholder: {
            type: String,
            default: ''
        },

        noLabel: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        reference: {
            type: String
        },

        divClass: {
            type: String
        },

        desc: {
            type: String
        }
    },

    data () {
        return {
            payload: ''
        }
    },

    watch: {
        value() {
            this.payload = this.value;
        },

        payload() {
            this.$emit('input', this.payload)
        }
    },

    methods: {
        /**
         * Emits typed value
         *
         * @param {Object} $event
         * @return {Undefined}
         */
        onInput($event) {
            this.$emit('input', $event.target.value);
        }
    }
}
</script>

<style>

</style>
