import Service from '../../services/notifications';

const state = {

    all: [],

    pageDetails: {
        to: 0,
        from: 0,
        total: 0,
        perPage: 0,
        lastPage: 0,
        currentPage: 0,
    },

    unread: {
        count: 0,
        all: [],
        pageDetails: {
            to: 0,
            from: 0,
            total: 0,
            perPage: 0,
            lastPage: 0,
            currentPage: 0,
        },
    }
};

const getters = {
    getUnreadCount(state) {
        return state.unread.count
    },

    getUnread(state) {
        return state.unread;
    },

    getAll(state) {
        return state.all;
    },

    getPageDetails(state) {
        return state.pageDetails;
    }
};

const mutations = {
    SET_NOTIFICATIONS(state, { clearState, payload }) {
        state.pageDetails = {
            total: payload.total || 0,
            from: payload.from || 0,
            to: payload.to || 0,
            currentPage: payload.current_page || 0,
            lastPage: payload.last_page || 0,
            perPage: payload.per_page || 0
        }

        let all = payload.data ? payload.data : payload;

        if (clearState) {
            state.all = all;
        } else {
            state.all = [...state.all, ...all];
        }
    },

    SET_UNREAD_NOTIFICATIONS(state, payload) {
        state.unread.pageDetails = {
            total: payload.total || 0,
            from: payload.from || 0,
            to: payload.to || 0,
            currentPage: payload.current_page || 0,
            lastPage: payload.last_page || 0,
            perPage: payload.per_page || 0
        }

        state.unread.all = payload.data ? payload.data : payload;

        state.unread.count = payload.total || 0;

        // console.log(state.unread)
    },

    ADD_UNREAD_NOTIFICATION(state, payload) {
        if (payload) {
            state.unread.all.unshift(payload);
            state.unread.count += 1;
        } else {
            state.unread.count -= 1;
        }

        state.unread.all = state.unread.all.slice(0, 3);
    },

    ADD_NOTIFICATION(state, payload) {
        if (payload) {
            state.all.unshift(payload);
        }
    },

    REMOVE_NOTIFICATION(state, id) {
        for (let [index, item] of state.all.entries()){
            if (item.id == id) {
                return state.all.splice(index, 1);
            }
        }
    },

    MARK_NOTIFICATIONS_AS_READ(state) {
        for (let index = 0; index < state.all.length; index++) {
            state.all[index].read_at = moment();
        }
    },

    MARK_NOTIFICATION_AS_READ(state, id) {
        console.log(id)

        for (let index = 0; index < state.all.length; index++) {
            if (state.all[index].id == id) {
                state.all[index].read_at = moment();
                return;
            }
        }
    },

    COUNT_UNREAD(state, payload) {
        state.unread.count = payload.value;
    }
};

const actions = {
    index({ commit }, params) {
        return Service.index(params)
            .then(response => {
                commit('SET_NOTIFICATIONS', { clearState: params.clearState, payload: response });
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    countUnread({ commit }, params) {
        return Service.countUnread(params)
            .then(response => {
                commit('COUNT_UNREAD', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    getUnread({ commit }, params) {
        return Service.index(params)
            .then(response => {
                commit('SET_UNREAD_NOTIFICATIONS', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    markAsRead({ commit }, params) {
        if (params.id == 'all') {
            commit('MARK_NOTIFICATIONS_AS_READ');
            commit('SET_UNREAD_NOTIFICATIONS', []);
        } else {
            commit('MARK_NOTIFICATION_AS_READ', params.id);
        }

        return Service.markAsRead(params.id, params.params)
            .then(response => {
                commit('SET_UNREAD_NOTIFICATIONS', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    addUnreadNotification({commit}, notification) {
        commit('ADD_UNREAD_NOTIFICATION', notification);
        commit('ADD_NOTIFICATION', notification)
    },

    delete({commit}, id) {
        if (id == 'all') {
            commit('SET_UNREAD_NOTIFICATIONS', []);
            commit('SET_NOTIFICATIONS', { clearState: true, payload: [] });
        } else {
            commit('REMOVE_NOTIFICATION', id);
        }

        return Service.delete(id)
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => Promise.resolve(error));
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}