<template>
<modal
    :show="show"
    :md="true"
    :centered="true"
    :showFooter="true"
    :showHeader="false"
    :maxHeight="true"
    :opaque="true"
    okText="Got it"
    @ok="ok"
    @cancel="cancel"
    okIcon="mdi mdi-thumb-up">

    <h5 class="m-0 font-small font-weight-bold" slot="header">Disclaimer</h5>
    <div slot="body">
        <img src="/img/disclaimer.png" class="w-100 h-100"/>
    </div>
</modal>
</template>
<script>
import storage from '@/storage';

export default {
    data() {
        return {
            show: false,
        }
    },

    computed: {
        key() {
            return  `disclaimer-${this.authUser.id}`;
        }
    },

    methods: {
        ok() {
            this.show = false;
            storage.set(this.key, false);
        },

        cancel() {
            this.show = false;
        }
    },

    mounted() {
        setTimeout(() => {
            this.show = storage.get(this.key, true);
        }, 0);
    }
}
</script>