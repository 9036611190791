<template>
    <div class="img-holder ad-media m-2 elevation">
        <div class="actions rounded elevation-2" v-if="media">
            <button @click="edit" type="button" class="btn btn-warning font-small elevation-2 mr-2" v-if="number">
                <i class="mdi mdi-pencil"></i>
            </button>

            <button @click="destroy" type="button" class="btn btn-danger font-small elevation-2">
                <i class="mdi mdi-delete"></i>
            </button>
        </div>

        <div class="img-uploader hold-video" v-if="fileType == 'video'">
            <video
                class="video"
                :id="mediaElementId"
                :src="previewUrl"
                @click="changeVideo"
                controls>
            </video>
        </div>


        <div class="img-uploader" v-else>
            <img v-if="previewUrl" :src="previewUrl" class=""/>

            <span class="label rounded p-2 pr-3 pl-3" v-if="!previewUrl" @click.prevent="$emit('showUploadMediaModal', number)">
                <i class="mdi mdi-plus"></i>
                <span class="font-small">Add Media</span>
            </span>
        </div>

        <div class="footer d-flex justify-content-between" v-if="media">
            <div class="caption">
                <p class="link text-tertiary font-xs mb-2 text-uppercase">
                    {{ getHostName }}
                </p>
                <h5 class="font-small font-weight-bold text-secondary">
                    {{ media.caption }}
                </h5>
            </div>
            <div class="action d-flex flex-column align-items-center justify-content-center pl-2">
                <button class="btn btn-secondary font-xs login-btn elevation-2" type="button">
                    <i class="mdi  mdi-link"></i> {{ media.action_name }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    props: ['media', 'number'],

    computed: {
        getHostName() {
            if (this.media) {
                try {
                    let url = new URL(this.media.action_lead);
                    return url.hostname;
                } catch (error) {
                    //
                }
            }

            return `Upload media file`
        },

        previewUrl() {
            return this.media && this.media.file_url
        },

        fileType() {
            if (!this.media || !this.media.mime_type) return null;

            return this.media.mime_type.split('/').shift();
        },

        mediaElementId() {
            let id;

            if (this.media) {
                id = this.media.id;
            } else {
                id = Math.random().toString(16).split('.').pop();
            }

            return `media_${id}`;
        }
    },

    methods: {
        ...mapActions({
            deleteMedia: 'AdvertMedia/destroy'
        }),

        edit() {
            if (this.fileType == 'vidoe') {
                this.changeVideo();
            } else {
                this.$emit('showUploadMediaModal', this.number)
            }
        },

        changeVideo() {
            if (this.media) return;

            this.$emit('changeVideo', this.number)
        },

        async destroy() {
            let result = await this.swalConfirm(
                `Delete Media 1`,
                `This media will be deleted completely from all users. Are you sure?`,
                `Delete Media`
            );

            if (!result.value) return;

            this.$emit('deleteMedia', this.number)

            this.deleteMedia(this.media.id)
                .then(response => null)
                .catch(error => null);
        }
    }
}
</script>