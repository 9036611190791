<template>
    <div class="d-flex justify-content-between align-items-center" :class="className">
        <div>
            <h1 class="text-muted">{{ title }}</h1>
            <p class="text-muted">{{ desc }}</p>
        </div>
        <div>
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-title',

    props: {
        title: String,
        desc: String,
        className: {
            type: String,
            default: 'mb-3'
        }
    },
}
</script>

<style>

</style>
