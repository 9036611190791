<template>
    <div class="">
        <div class="" v-if="preferences.length">
            <div >
                <transition-group name="swipe" class="row no-gutters">
                    <div class="col-lg-4 col-md-4 col-6 p-1 full-height" v-for="(type, index) in filtered" :key="`${index}-interest`">
                        <button type="button" @click.prevent="addPreference(type)" class="preference-item elevation rounded btn-block full-height" :class="{'selected': selected.includes(type.id)}">
                            <i class="mdi mdi-checkbox-marked-circle font-xl check"></i>
                            <i class="mdi mdi-checkbox-blank-circle-outline font-xl uncheck"></i>
                            <img :src="type.image_url" class="preference-img"/>
                            <p class="m-0 mt-2 text-capitalize font-xs">{{ type.name }}</p>
                        </button>
                    </div>
                </transition-group>
            </div>

            <slot name="actions"/>
        </div>

        <empty title="No preference available" v-if="!preferences.length"/>
    </div>
</template>
<script>
import TypeIt from 'typeit';
import { mapActions } from 'vuex';
import Icons from '@/components/icons';

export default {
    props: {
        value: {
            type: Array,
            default: (() => [])
        },

        sticky: {
            type: Boolean,
            default: true
        },

        type: {
            type: String
        }
    },

    components: {
        ...Icons
    },

    data() {
        return {
            selected: [],
            preferences: [],
            filtered: [],
            keyword: ''
        }
    },

    computed: {
        leftBtnIcon() {
            if (this.selected.length) {
                return `mdi mdi-select-off`
            } else {
                return `mdi mdi-select-all`
            }
        }
    },

    watch: {
        keyword() {
            this.performFiltering()
        },

        value() {
            this.prepopulate();
        }
    },

    methods: {
        ...mapActions({
            getPreferences: 'Intents/getPreferences'
        }),

        async unselect() {
            let title, description, button;

            if (this.selected.length) {
                button = `Unselect selected`
                title = `Unselect Interests?`;
                description = `This will unselect all the interests that you've previously selected`;
            } else {
                button = `Select All`
                title = `Select All Interests?`;
                description = `This will all interests that are displayed here`;
            }

            let result = await this.swalConfirm(title, description, button);

            if (!result.value) return;

            if (this.selected.length) {
                this.selected = [];
            } else {
                this.selected = this.filtered.map(type => type.id);
            }
        },

        loadPreferences() {
            this.showLoader('Getting preferences... Please wait');

            this.getPreferences({type: this.type})
                .then(response => {
                    this.hideLoader();
                    this.preferences = response;
                    // this.typeHeading();
                    this.performFiltering();
                })
                .catch(error => {
                    this.hideLoader();
                    console.log(error);
                })
        },

        addPreference(preference) {
            let index = this.selected.indexOf(preference.id);

            if (index > -1) {
                this.selected.splice(index, 1);
            } else {
                this.selected.push(preference.id)
            }

            this.$emit('input', this.selected);
        },

        performFiltering() {
            if (!this.keyword) {
                this.filtered = [...this.preferences];
            } else {
                let parts = this.keyword.split(' ').filter(part => part.trim());

                this.filtered = this.preferences.filter(preference => {
                    for (let part of parts) {
                        part = part.toLowerCase();

                        if (preference.name.toLowerCase().includes(part)) {
                            return true;
                        }
                    }

                    return false;
                })
            }
        },

        prepopulate() {
            this.selected = [...new Set(this.value)];
        }
    },

    mounted() {
        this.prepopulate();
    },

    created() {
        this.loadPreferences();
    }
}
</script>