<template>
    <app-card class="">
        <h5 class="text-tertiary font-weight-bold font-small mb-1">Categories</h5>
        <p class="mb-4 text-muted">Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam debitis earum iure officiis ut deserunt a laudantium aut neque quaerat. Beatae quas quisquam veniam veritatis a autem temporibus ullam modi.</p>

        <div class="mb-3" v-if="categories.length">
            <span style="border-radius:20px;" class="badge badge-default-dark p-2 font-xs m-1" v-for="(category, index) in categories" :key="index">
                {{ category.name }}
            </span>
        </div>

        <button class="btn btn-warning font-weight-bold font-xs" @click.prevent="openModal">
            <i class="mdi mdi-plus-circle"></i> Update Category
        </button>
    </app-card>
</template>
<script>
import bus from '@/event-bus.js';

export default {
    props: ['advert'],

    data() {
        return {
            categories: []
        }
    },

    watch: {
        advert: {
            deep: true,
            immediate: true,
            handler() {
                this.initCategories()
            }
        }
    },

    methods: {
        openModal() {
            bus.$emit('create-ad > show-modal', 'product_types');
        },

        initCategories() {
            if (this.advert && this.advert.product_types && Array.isArray(this.advert.product_types)) {
                this.setCategories(this.advert.product_types);
            }
        },

        setCategories(categories) {
            this.categories = categories;
        }
    },

    created() {
        bus.$on('create-ad > categories', this.setCategories);

        this.initCategories();
    },

    beforeDestroy() {
        bus.$off('create-ad > categories', this.setCategories);
    }
}
</script>