<template>
    <div class="font-small p-3">
        <div class="row justify-content-center">
            <form @submit.prevent="submit" class="col-12 pb-3">
                <media-item
                    v-if="media"
                    :media="media"
                    class="mb-5"
                    :isEditing="true"
                    :caption="payload.caption"/>

                <caption-templates
                    v-model="payload.caption_template"
                    :error="validationErrors.caption_template"/>

                <div class="row mb-4">
                    <div class="col-md-6">
                        <base-input v-model="payload.text_color" type="color" label="Caption Text Color"/>
                    </div>
                    <div class="col-md-6">
                        <base-input v-model="payload.background_color" type="color" label="Caption Background Color"/>
                    </div>
                </div>

                <div class="form-group type-4 mb-4">
                    <label class="font-xs" style="margin-bottom:0 !important;">Caption or description</label>
                    <textarea
                        class="form-control text-input font-small p-2"
                        placeholder="Optional caption which will help you identify this ad"
                        rows="4"
                        v-model="payload.caption"></textarea>
                </div>

                <template v-if="!media">
                    <div class="text-left">
                        <label
                            for="file"
                            class="choose-file text-muted elevation" :class="{'success': isValidFile}">
                            <div class="flex-grow-1 d-flex justify-content-center align-items-center pb-3 pt-3" v-if="!isValidFile">
                                <i class="mdi mdi-file-document mr-1"></i>
                                <span>Click to  browse files</span>
                            </div>

                            <template v-if="isValidFile">
                                <div class="media-file rounded">
                                    <video
                                        v-if="isVideo && this.preview"
                                        class="img-uploader rounded"
                                        id="preview_video"
                                        :src="this.preview"
                                        @click="changeVideo"
                                        controls>
                                    </video>

                                    <img v-if="!isVideo" :src="preview" class="rounded"/>
                                </div>

                                <div class="file-desc ml-2 rounded flex-grow-1">
                                    <p class="m-0 font-small">File: <b class="text-tertiary">{{ this.payload.file.name }}</b></p>
                                    <p class="m-0 font-small">Type: <b class="text-tertiary">{{ this.payload.file.type }}</b></p>
                                    <p class="m-0 font-small" v-if="duration">Duration: <b class="text-tertiary">{{ duration }}</b></p>
                                    <p class="m-0 font-small">Size: <b class="text-tertiary">{{ sizeInMb }}</b></p>
                                </div>

                                <div class="d-flex uploaded-icon">
                                    <i class="mdi mdi-checkbox-marked-circle"></i>
                                </div>
                            </template>

                            <input
                                type="file"
                                accept="image/*, video/*"
                                class="d-none" id="file"
                                @change="uploadMediaFile"/>
                        </label>
                    </div>

                    <label class="d-block mt-4">
                        <input type="checkbox" v-model="certify" class="mr-1"/> <span>I certify that I have rights to this content.</span>
                    </label>
                </template>


                <div class="mt-4 text-right">
                    <button @click="cancel" type="button" class="btn btn-default font-xs mr-2">Clear</button>

                    <button type="submit" class="btn btn-success font-xs">
                        <i class="mdi mdi-cloud-upload mr-1"></i>
                        UPLOAD MEDIA
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import bus from '@/event-bus';
import MediaItem from './MediaItem.vue';
import CaptionTemplates from './CaptionTemplates.vue';

export default {
    props: ['media', 'advert'],

    components: {
        MediaItem,
        CaptionTemplates
    },

    data() {
        return {
            payload: {
                text_color: '#dc1c67',
                background_color: '#1C0E6F',
                caption: '',
                caption_template: '',
                file: '',
            },

            validationErrors: {
                caption: '',
                caption_template: '',
            },

            preview: '',
            videoLenght: 20,
            maxSizeInMB: 3,

            isVideo: false,

            duration: null,
            certify: false
        }
    },

    computed: {
        maxSizeInBytes() {
            return this.maxSizeInMB * 1024 * 1024 * 1024;
        },

        isValidFile() {
            return !!this.payload.file
        },

        sizeInMb() {
            if (!this.isValidFile) return;

            if (this.payload.file.size === 0) return '0 Bytes';

            const k = 1024;
            const dm = 2;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            const i = Math.floor(Math.log(this.payload.file.size) / Math.log(k));

            return parseFloat((this.payload.file.size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }
    },

    watch: {
        media() {
            if (this.media) {
                this.populate();
            }
        }
    },

    methods: {
        ...mapActions({
            store: 'AdvertMedia/store',
            update: 'AdvertMedia/update',
            deleteFile: 'AdvertMedia/deleteFileFromBucket',
            storeOnBucket: 'AdvertMedia/storeOnBucket'
        }),

        changeVideo() {
            let input = $('#file');

            input.trigger('click');
        },

        uploadMediaFile(event) {
            if (!event.target.files || !event.target.files[0]) {
                return;
            }

            this.payload.file = event.target.files[0];

            let [type,] = this.payload.file.type.split('/')

            if (this.payload.file.size >= this.maxSizeInBytes) {
                return this.swalError(`File size too big`, `You are only allowed to upload files less than ${this.maxSizeInMB}MB media.`);
            }

            if (type == 'video') {
                this.uploadVideo();
            } else if (type == 'image') {
                this.uploadImage();
            } else {
                this.swalError('Invalid media format', `The media file you uploaded is unrecognized. Please upload images or videos`);
            }
        },

        uploadImage() {
            if (!this.payload.file) return;

            this.isVideo = false;

            const reader = new FileReader();

            reader.onload = (e) => {
                this.preview = e.target.result;
            }

            reader.readAsDataURL(this.payload.file);
        },

        uploadVideo() {
            this.isVideo = true;

            this.validateVideoFile(this.payload.file)
            .then(result => {
                this.preview = URL.createObjectURL(this.payload.file);
            })
            .catch(error => {
                this.clearFile();
                this.swalError(`Invalid Video!`, error, false);
            });
        },

        validateVideoFile(file) {
            return new Promise((resolve, reject) => {
                var video = document.createElement('video');

                video.preload = 'metadata';

                video.onloadedmetadata = () => {
                    window.URL.revokeObjectURL(video.src);

                    this.duration = this.$options.filters.dp2(video.duration) + 's';

                    if (video.duration < 1) {
                        return reject(`Video is less than a second.`);
                    } else if (video.duration > this.videoLenght) {
                        return reject(`Video duration should not be more than ${this.videoLenght} seconds.`);
                    }

                    resolve()
                }

                video.src = URL.createObjectURL(file);
            });
        },

        submit() {
            if (this.payload.file && !this.certify) {
                return this.swalError('Oops', `You must certify that you have rights to this content you're about to upload.`);
            }

            this.buildValidationErrors({});

            if (!this.media && !this.payload.file) {
                return this.swalError('File Upload Error', `Please upload a media file`);
            }

            if (!this.payload.caption_template) {
                return this.swalError('Error', `Provide a style which the caption will b displayed.`);
            }

            if (this.media) {
                this.updateMedia();
            } else {
                this.storeMedia();
            }
        },

        deleteOldMediaFile(id) {
            this.deleteFile(id)
                .then(response => null)
                .catch(error => null);
        },

        getPayload() {
            const data = {
                advert_id: this.advert.id,
                caption_template: this.payload.caption_template,
                caption: this.payload.caption,
                text_color: this.payload.text_color,
                background_color: this.payload.background_color,
            }

            if (this.media) {
                data.media_id = this.media.id;
            }

            if (this.payload.file) {
                data.has_file = 1;
                data.file_name = this.payload.file.name;
                data.mime_type = this.payload.file.type;
                data.file_type = this.payload.file.type.split('/').shift();
                data.file_size = this.payload.file.size;
            }

            return data;
        },

        updateMedia() {
            let loaderText = `Updating media details... Please wait`;

            let data = this.getPayload();

            this.showLoader(loaderText);

            this.update({id: this.media.id, data})
                .then(response => {
                    this.hideLoader();
                    bus.$emit('update-title-caption', data);
                    this.cancel();
                })
                .catch(error => {
                    this.hideLoader();
                    this.handleError(error);
                })
        },

        storeMedia() {
            this.showLoader(`1/2: Preparing file for upload... Please wait`);

            let data = this.getPayload();

            this.store(data)
            .then(response => {
                this.uploadToBucket(response);
            })
            .catch(error => {
                this.hideLoader();
                this.handleError(error);
            });
        },

        async createVideoGif(video_url, file_type) {
            let type = file_type.split('/').shift();

            if (type != 'video') {
                return;
            }


        },

        async uploadToBucket(response) {
            if (!this.payload.file) {
                return this.swalError(`File required`, `Please make sure you select a valid file to upload`);
            }

            this.showLoader(`2/2: Uploading file ${this.payload.file.name}... Please wait`);

            const url = `${response.presigned_url}`;

            try {
                let result = await fetch(url, {
                    method: 'PUT',
                    body: this.payload.file,
                    headers: {
                        'Content-Type': this.payload.file.type,
                        'Cache-Control': 'public,max-age=31536000,immutable',
                        'x-amz-acl': 'public-read',
                    }
                });

                this.createVideoGif(response.file_url, this.payload.file.type);

                this.$emit('setMedia', response);

                this.cancel();

                this.swalSuccess(`Upload successful`, `${response.file_name} media file has been uploaded successfully`);
            } catch (error) {
                this.swalError(`Upload Failed`, `${response.file_name} media file could not be uploaded successfully`);
            }

            this.hideLoader();
        },

        cancel() {
            this.reset();
            this.$emit('cancel');
        },

        reset() {
            this.preview = '';
            this.certify = false;
            this.isVideo = false;

            for (let key of Object.keys(this.payload)) {
                this.payload[key] = ``;
            }
        },

        clearFile() {
            document.getElementById('file').value = null;
            this.payload.file = '';
            this.isVideo = false;
        },

        handleError(error) {
            if (error.message) {
                this.swalError(`Attention!`, error.message);
            }

            this.buildValidationErrors(error.errors);
        },

        populate() {
            this.payload = {
                ...this.payload,
                caption: this.media && this.media.caption,
                title: this.media && this.media.title
            }

            this.preview = this.media && this.media.file_url;
            this.isVideo = this.media && this.media.type == 'video';
        }
    },

    created() {
        this.populate();
    }
}
</script>