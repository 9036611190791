import { axios } from './index';

export default {
    searchUsers(params) {
        return axios.get('users/search', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    savePushToken(data) {
        return axios.post('users/push_tokens', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },
}