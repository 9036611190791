<template>
    <modal 
        :show="show" 
        :centered="true" 
        :showFooter="false" 
        :maxHeight="true" 
        @ok="cancel" 
        @cancel="cancel"
        :content-padding="false"
        overlay="poll-overlay">

        <h5 class="m-0 font-small font-weight-normal text-capitalize" slot="header">Poll: {{advert && advert.name}}</h5>

        <div slot="body" class="font-small preview-poll">
            <poll v-if="advert && advert.poll" :poll="advert.poll" @voteCasted="cancel"/>
        </div>
    </modal>
</template>

<script>
import Poll from './Poll.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['advert', 'show'],

    components: {
        Poll
    },

    methods: {
        cancel() {
            console.log('cancel')
            this.$emit('cancel');
        },
    },

    mounted() {
    }
}
</script>