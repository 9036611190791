<template>
    <div>
        <div class="previewer mt-3">
            <img class="media" :src="currentMedia.file_url" v-if="currentMedia.mime_type.startsWith('image')"/>
            <video controls class="media" :src="currentMedia.file_url" v-else/>
        </div>

        <div class="thumbs mt-3" v-if="mediaFiles.length > 1">
            <template v-for="(media, index) in mediaFiles">
                <img
                    v-if="media.mime_type.startsWith('image')"
                    :key="index"
                    :src="media.file_url"
                    class="avatar-md cursor-pointer"
                    @click="mediaIndex = index"
                    :class="{'active': index == mediaIndex}"/>

                <video
                    v-if="media.mime_type.startsWith('video')"
                    :key="index"
                    :src="media.file_url"
                    class="avatar-md cursor-pointer"
                    @click="mediaIndex = index"
                    :class="{'active': index == mediaIndex}"/>
            </template>
        </div>

        <div class="mt-4 d-flex justify-content-end">
            <button class="btn round btn-success elevation font-xs mr-1" @click="addToCart">
                <i class="mdi mdi-cart"></i> Add to Cart
            </button>
        </div>
    </div>
</template>
<script>
export default {
    props: ['advert'],

    data() {
        return {
            mediaIndex: 0,
        }
    },

    computed: {
        mediaFiles() {
            return this.advert && this.advert.advert_media;
        },

        currentMedia() {
            return this.mediaFiles[this.mediaIndex]
        },
    },

    methods: {
        addToCart() {
            this.$emit('addToCart')
        }
    }
}
</script>