import { axios } from './index';

export default {
    /**
     * Get available tickets
     *
     * @param {Object} params
     * @returns {Object}
     */
    index(params) {
        return axios.get('tickets', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Store ticket
     *
     * @param {Object} data
     * @returns {Object}
     */
    store(data) {
        return axios.post('tickets', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    update(id, data) {
        return axios.put(`tickets/${id}`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    show(id, params) {
        return axios.get(`tickets/${id}`, { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    destroy(id) {
        return axios.delete(`tickets/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    getTopics(params) {
        return axios.get(`tickets/topics`, { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    getStatuses(params) {
        return axios.get(`tickets/statuses`, { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    storeUpdate(data) {
        return axios.post(`tickets/updates`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    getUpdates(params) {
        return axios.get(`tickets/updates`, {params})
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    sendPageComments(params) {
        return axios.post(`tickets/page-comments`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    }
}