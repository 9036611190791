<template>
    <div class="full-height" :class="colSize">
        <div class="mb-3" v-if="isColor">
            <p class="mb-1 ml-3 font-xs text-secondary font-weight-bold">Color</p>
            <div class="colors flex-wrap">
                <div
                    @click="setMultiSelectValue(color)"
                    :class="{'active': model.split(',').includes(color)}"
                    class="color"
                    v-for="color in colors"
                    :key="color"
                    :style="{background: color}">
                    <i class="mdi mdi-check font-big"></i>
                </div>
            </div>
        </div>

        <div class="mb-4" v-if="isSize">
            <p class="mb-1 ml-3 font-xs text-secondary font-weight-bold">Size</p>
            <div class="colors flex-wrap">
                <div
                    @click="setMultiSelectValue(size)"
                    :class="{'active': model.split(',').includes(size)}"
                    class="color"
                    v-for="size in sizes"
                    :key="size">
                    <span class="font-xs">{{ size }}</span>
                </div>
            </div>
        </div>

        <base-token-input
            :label="field.name"
            :name="field.system_name"
            :placeholder="placeholder"
            v-model="model"
            :typeahead="searchConfig"
            v-if="isSearchTokenizer"/>

        <base-input
            :tooltip="tooltip"
            :typeahead="searchConfig"
            :reference="field.system_name"
            @selection="onInputSelection"
            :label="field.name"
            v-model="model"
            :placeholder="placeholder"
            v-if="isSearchSelect"/>

        <base-input
            :tooltip="tooltip"
            :reference="field.system_name"
            @selection="onInputSelection"
            :label="field.name"
            v-model="model"
            :placeholder="placeholder"
            v-else-if="isText"/>

        <base-token-input
            :label="field.name"
            :name="field.system_name"
            :placeholder="placeholder"
            v-model="model"
            v-if="isTokenizer"/>

        <base-textarea
            :tooltip="tooltip"
            :reference="field.system_name"
            @selection="onInputSelection"
            :label="field.name"
            v-model="model"
            :placeholder="placeholder"
            v-else-if="isTextArea"/>

        <base-input
            :tooltip="tooltip"
            :reference="field.system_name"
            @selection="onInputSelection"
            :label="field.name"
            type="number"
            v-model="model"
            :placeholder="placeholder"
            v-else-if="isNumber"/>

        <div class="row no-gutters" v-else-if="isNumberRange">
            <div class="col-6">
                <base-input
                    :tooltip="tooltip"
                    :reference="field.system_name"
                    @selection="onInputSelection"
                    :label="`Min. ${field.name}`"
                    type="number"
                    :placeholder="placeholder"/>
            </div>
            <div class="col-6">
                <base-input
                    :tooltip="tooltip"
                    :reference="field.system_name"
                    @selection="onInputSelection"
                    :label="`Max. ${field.name}`"
                    type="number"
                    :placeholder="placeholder"/>
            </div>
        </div>

        <div class="form-group type-4" v-else-if="isDate">
            <label class="font-xs m-0">{{ field.name }}</label>
            <datetime
                input-class="form-control font-small no-radius text-input"
                placeholder="Enter date"
                v-model="model"
                :title="field.name">
            </datetime>
        </div>

        <div v-else-if="isReturnPolicy">
            Return POLICY
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: ['attribute', 'value'],

    data() {
        return {
            model: ''
        }
    },

    computed: {
        ...mapGetters({
            colors: 'Intents/getColors',
            sizes: 'Intents/getSizes',
        }),

        field() {
            return this.attribute.attribute;
        },

        placeholder() {
            if (!this.field.options || !this.field.options.placeholder) return 'Enter Value';

            return this.field.options.placeholder
        },

        tooltip() {
            return !!this.field.description;
        },

        colSize() {
            if (
                this.isTextArea || this.isReturnPolicy || this.isTokenizer || this.isColor || this.isSize ||
                this.isSearchTokenizer
            ) {
                return `col-12`;
            }

            return `col-md-4 col-12`
        },

        searchConfig() {
            return {
                url: `intent_attributes/search_values/${this.field.options.url}`,
                options: {
                    name: this.field.system_name,
                    display: 'name'
                }
            };
        },

        isSearchSelect() {
            return this.field.system_type == 'SEARCH-SELECT';
        },

        isText() {
            return this.field.system_type == 'TEXT';
        },

        isTextArea() {
            return this.field.system_type == 'TEXTAREA';
        },

        isDate() {
            return this.field.system_type == 'DATE';
        },

        isSelectGroup() {
            return this.field.system_type == 'SEARCH-SELECT-GROUP';
        },

        isNumber() {
            return this.field.system_type == 'NUMBER';
        },

        isNumberRange() {
            return this.field.system_type == 'NUMBER-RANGE';
        },

        isReturnPolicy() {
            return this.field.system_type == 'RETURN-POLICY';
        },

        isTokenizer() {
            return this.field.system_type == 'TOKENIZER';
        },

        isColor() {
            return this.field.system_type == 'COLOR';
        },

        isSize() {
            return this.field.system_type == 'SIZE';
        },

        isSearchTokenizer() {
            return this.field.system_type == 'SEARCH-TOKENIZER';
        },
    },

    watch: {
        model() {
            this.$emit('input', this.model);
        },

        value() {
            this.setValue();
        }
    },

    methods: {
        onInputSelection(params) {
            this.$emit('input', params.name);
        },

        setValue() {
            this.model = this.value || '';
        },

        setMultiSelectValue(value) {
            const values = this.model.split(',');

            if (values.includes(value)) {
                values.splice(values.indexOf(value), 1);
            } else {
                values.push(value);
            }

            this.model = values.join(',');
        }
    },

    created() {
        this.setValue();
    }
}
</script>

<style>

</style>