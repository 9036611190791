<template>
    <div class="tab-1 mt-3">
        <button
            type="button"
            class="tab-1-item"
            :class="{'active': value == 'media'}"
            @click.prevent.stop="setTab('media')">
            Media
        </button>

        <button
            type="button"
            class="tab-1-item"
            :class="{'active': value == 'product_attributes'}"
            @click.prevent.stop="setTab('attributes')">
            Attributes
        </button>

        <button
            type="button"
            class="tab-1-item"
            :class="{'active': value == 'offer'}"
            @click.prevent.stop="setTab('offer')">
            Offer
        </button>

        <button
            type="button"
            class="tab-1-item"
            :class="{'active': value == 'return_policy'}"
            @click.prevent.stop="setTab('return_policy')">
            Return Policy
        </button>
    </div>
</template>
<script>
export default {
    props: ['value'],

    data() {
        return {
        }
    },

    methods: {
        setTab(tab) {
            this.$emit('input', tab)
        }
    }
}
</script>