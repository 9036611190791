<template>
    <div class="p-3 pt-4">
        <div class="row justify-content-between">
            <div class="col-md-8 mb-3">
                <button
                    @click.prevent="chartType = 'bar'"
                    class="status-btn mb-1 font-xs cadet-blue"
                    :class="{'active': chartType == 'bar'}"
                    type="button">
                    <i class="mdi mdi-chart-bar"></i> Bar
                </button>

                <button
                    @click.prevent="chartType = 'pie'"
                    :class="{'active': chartType == 'pie'}"
                    class="status-btn mb-1 font-xs cadet-blue"
                    type="button">
                    <i class="mdi mdi-chart-pie"></i> Pie
                </button>

                <button
                    @click.prevent="chartType = 'doughnut'"
                    class="status-btn mb-1 font-xs cadet-blue"
                    :class="{'active': chartType == 'doughnut'}"
                    type="button">
                    <i class="mdi mdi-chart-arc"></i> Doughnut
                </button>
            </div>
            <div class="col-md-4">
                <base-select label="Distribution Parameter" v-model="distribution">
                    <option value="geographical">Geographic Location</option>
                    <option value="gender">Gender</option>
                    <option value="age_group">Age Group</option>
                </base-select>
            </div>
        </div>

        <div>
            <canvas :id="chartId" style="max-height:500px;"></canvas>
        </div>

    </div>
</template>
<script>
import Chart from '@/chart.js';
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['campaign'],

    data() {
        return {
            loading: false,
            report: null,
            chartType: 'bar',
            distribution: 'geographical'
        }
    },

    computed: {
        ...mapGetters({
            reports: 'Reports/getAudienceDistribution',
            stateColors: 'getColors'
        }),

        chartId() {
            return `ces-${Math.random().toString(16).split('.').pop()}`;
        }
    },

    watch: {
        report: {
            deep: true,
            immediate: true,
            handler() {
                if (!this.report) return;

                this.drawChart();
            }
        },

        chartType() {
            this.drawChart();
        },

        distribution() {
            this.getReport();
        }
    },

    methods: {
        ...mapActions({
            getAudienceDistribution: 'Reports/getAudienceDistribution'
        }),

        drawChart() {
            this.$nextTick().then(() => {
                const config = {
                    tension: 0.5,
                    fill: true,
                    borderWidth: 2
                };

                if (this.chart) {
                    this.chart.destroy();
                }

                this.chart = new Chart($(`#${this.chartId}`), {
                    type: this.chartType,
                    data: {
                        labels: this.report.labels,

                        datasets: [
                            {
                                label: 'Audiences',
                                data: this.report.datasets,
                                borderColor: 'transparent',
                                backgroundColor: this.stateColors,
                                ...config
                            }
                        ]
                    },

                    options: {
                        plugins: {
                            title: {
                                display: true,
                                text: `Audience Distributions Chart`
                            },
                            legend: {
                                display: false
                            }
                        },
                    }
                });
            })
        },

        getReport() {
            this.report = this.reports.find(item => item.campaign_id == this.campaign.id);

            if (!this.report) {
                this.loading = true;
            }

            this.getAudienceDistribution({
                campaign_id: this.campaign.id,
                distribution: this.distribution
            })
            .then(response => {
                this.report = response;
                // console.log(response);
                this.loading = false;
            })
            .catch(error => {
                this.loading = false;
            })
        }
    },

    mounted() {
        this.getReport();
    }
}
</script>