<template>
<modal
    :show="show"
    :centered="true"
    :showFooter="false"
    :maxHeight="true"
    :showInfo="false"
    okText="Create Ticket"
    @ok="submit"
    @cancel="cancel"
    okIcon="mdi mdi-account-circle">

    <h5 class="m-0 font-small font-weight-normal" slot="header">
        Create New Support Ticket
    </h5>

    <div slot="body" class="p-2 pt-0 font-small">

        <base-select
            label="Ticket Topic"
            v-model="payload.ticket_topic_id"
            :error="validationErrors.ticket_topic_id">
            <option value="">-- None --</option>
            <option
                v-for="(topic, index) in topics"
                :key="index"
                :value="topic.id">{{ topic.name }}</option>
        </base-select>

        <base-input
            label="Specify topic if other"
            placeholder="e.g Can't login"
            v-model="payload.other_topic"
            :error="validationErrors.other_topic"
            v-if="showOtherTopic"/>

        <base-textarea
            label="Tell us your problem"
            placeholder="e.g Can't login"
            v-model="payload.text"
            :error="validationErrors.text"/>

        <label for="attachment" class="btn btn-default">
            <i class="mdi mdi-paperclip"></i> Attachment: <span class="text-primary">{{payload.image && payload.image.name}}</span>
        </label>
        <a href="#" @click.prevent="removeAttachment" class="text-danger ml-3" v-if="payload.image"><i class="fa fa-trash-o"></i></a>

        <input type="file" class="d-none" id="attachment" @change="addAttachment" accept="image/*"/>

        <div class="text-center mt-4 mb-4">
            <button
                type="button"
                @click="submit"
                class="btn elevation font-xs login-btn text-uppercase btn-block btn-success">
                    <i class="mdi mdi-plus"></i> Add ticket
            </button>
        </div>
    </div>
</modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default{
    props: ['show', 'ticket'],

    data() {
        return {
            payload: {
                ticket_topic_id: '',
                other_topic: '',
                text: '',
                image: ''
            },

            validationErrors: {
                ticket_topic_id: '',
                other_topic: '',
                text: ''
            },

            showOtherTopic: false
        }
    },

    computed: {
        ...mapGetters({
            topics: 'Tickets/getTopics'
        })
    },

    watch: {
        'payload.ticket_topic_id'() {
            let topic = this.topics.find(topc => topc.id == this.payload.ticket_topic_id);

            this.showOtherTopic = topic && topic.name.toLowerCase() == 'other';
        }
    },

    methods: {
        ...mapActions({
            store: 'Tickets/store',
            index: 'Tickets/getTopics'
        }),

        addAttachment(e) {
            this.payload.image = e.target.files.length ? e.target.files[0] : '';
        },

        removeAttachment() {
            this.payload.image = '';
        },

        cancel() {
            for (let key of Object.keys(this.payload)) {
                this.payload[key] = '';
                this.validationErrors[key] = '';
            }

            this.$emit('cancel');
        },

        submit() {
            let hasError = false;

            if (!this.payload.ticket_topic_id) {
                hasError = true;
                this.validationErrors.ticket_topic_id = 'The ticket topic field is required';
            }

            if (!this.payload.text) {
                hasError = true;
                this.validationErrors.text = `Ticket body field is required`;
            }

            if (this.showOtherTopic && !this.payload.other_topic) {
                hasError = true;
                this.validationErrors.other_topic = `Other topic field is required`;
            }

            if (!this.showOtherTopic) {
                this.payload.other_topic = ''
            }

            if (hasError) {
                return;
            }

            const data = {
                ...this.payload
            }

            delete data.image;

            if (this.payload.image) {
                data.mime_type = this.payload.image.type;
                data.extension = this.payload.image ? this.payload.image.name.split('.').pop() : '';
            }

            console.log(data);

            this.buildValidationErrors({})

            this.showLoader('Adding ticket... Please Wait');

            this.store(data)
            .then(response => {
                this.hideLoader();

                this.uploadFile(response.presigned_url);

                if (response.message) {
                    this.swalSuccess('Ticket Created', response.message);
                }
            })
            .catch(error => {
                this.hideLoader();
                this.$emit('cancel');

                if (error.message) {
                    this.swalError('Oops', error.message);
                }

                this.buildValidationErrors(error.errors);
            });
        },

        async uploadFile(url) {
            if (url) {
                await this.uploadPresignedFile(this.payload.image, url);
            }
            this.cancel();
        },

        getTopics() {
            this.index()
            .then(response => null)
                .catch(error => null)
        }
    },

    created() {
        this.getTopics();
    }
}
</script>