<template>
<div class="full-height">
    <app-loader v-if="loader.show" :text="loader.text"/>

    <!-- <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__bounceInLeft animate__delay-1s"
      leave-active-class="animate__animated animate__backOutRight">
      <router-view/>
    </transition> -->

    <!-- <transition name="router"> -->
      <router-view/>
    <!-- </transition> -->
    <testing-messages/>
</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AppLoader from '@/components/layouts/AppLoader';
import TestingMessages from '../TestingMessages.vue';

export default {
    components: {
        AppLoader,
        TestingMessages
    },

    computed: {
        ...mapGetters({
            loader: 'getLoader'
        })
    },

    methods: {
        ...mapActions({
            getCountries: 'Countries/index',
            getProductTypes: 'ProductTypes/index'
        })
    },

    created() {
        this.getCountries()
        .then(response => null)
        .catch(error => null);

        this.getProductTypes({clearState: true})
        .then(response => {
            // console.log(response);
        })
        .catch(error => console.log(error));
    }
}
</script>

<style scoped>

</style>