<template>
<div class="third-header">
    <div class="empty" v-if="!isGuest"></div>
    <div class="items" :style="{'padding-left': isGuest ? '10px' : '95px'}" :class="{'guest': isGuest}" v-if="!editTabs">
        <button type="button" class="item" :class="{'active': value === 1}" @click.prevent="navigate(1)">
            <i class="mr-1 mdi mdi-magnify-plus"></i>
            <span class="name">Add Product</span>
        </button>
        <div class="line"></div>
        <button type="button" class="item" :class="{'active': value === 2}" @click.prevent="navigate(2)">
            <i class="mr-1 mdi mdi-thumb-up-outline"></i>
            <span class="name">Add Preferences</span>
        </button>
        <div class="line"></div>
        <template v-if="!isGuest">
            <button type="button" class="item" :class="{'active': value === 3}">
                <i class="mr-1 mdi mdi-library-plus"></i>
                <span class="name">Additional Product</span>
            </button>
            <div class="line"></div>
        </template>
        <button type="button" class="item" :class="{'active': value === 4}">
            <i class="mr-1 mdi mdi-clipboard-check"></i>
            <span class="name">Finish</span>
        </button>
    </div>

    <div class="items" :class="{'guest': isGuest}" v-if="editTabs">
        <template v-for="(item, index) in editTabs">
            <button :key="index" type="button" class="item" :class="{'active': value == item.id}" @click.prevent="navigate(item.id)">
                <i class="mr-1 mdi" :class="item.icon"></i>
                <span class="name">{{item.name}}</span>
            </button>
            <div :key="`${index}-l`" class="line" v-if="index < (editTabs.length - 1)"></div>
        </template>
    </div>
</div>
</template>
<script>
export default {
    props: ['value', 'isGuest', 'editTabs'],

    methods: {
        navigate(tab) {
            if (tab == 3 && !parseInt(this.$route.query.intent_id)) return;

            this.$emit('input', tab);
        }
    }
}
</script>