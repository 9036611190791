<template>
    <div>
        <h3 class="text-center mb-4 mt-3" id="buyerType"></h3>

        <div class="buyer-type-options">
            <div
                v-for="(option, index) in options"
                :key="index"
                class="p-3 bg-white elevation option mb-1 rounded text-success"
                :class="{'active': option.id == value}"
                @click.prevent="$emit('input', option.id)">
                <div class="font-small pb-2 mb-2" style="border-bottom:1px solid rgba(0,0,0,0.1)">
                    <i class="mr-1 mdi mdi-radiobox-blank unchecked"></i>
                    <i class="mr-1 mdi mdi-radiobox-marked checked"></i>

                    <b>{{ option.name }}</b>
                </div>
                <p class="text-muted font-small m-0">
                    {{ option.description }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import TypeIt from "typeit";

export default {
    props: ['value', 'options'],

    methods: {
        typeHeading() {
            new TypeIt("#buyerType", {
                speed: 50,
                startDelay: 100
            })
            .empty()
            .type(`What type of a buyer are you?`, {delay: 500})
            .go()
        }
    },

    mounted() {
        this.typeHeading();
    }
}
</script>