import moment from 'moment';

export default {
    fromNow(value) {
        let date = moment(value);
        return date.fromNow();
    },

    /**
     * Convert a date value to a readable date format.
     * Eg. Tue 14 Mar, 1996.
     *
     * @param  {String|Number} value
     * @return {String}
     */
    formattedDate(value) {
        let date = moment(value);
        return date.isValid() ? date.format('ddd Do MMM, YYYY') : 'N/A';
    },

    /**
     * Convert a date value to a readable date and time format.
     * Eg. Tue 14 Mar, 1996 06:34 PM.
     *
     * @param  {String|Number} value
     * @return {String}
     */
    formattedDateTime(value) {
        let date = moment(value);
        return date.isValid() ? date.format('ddd Do MMM, YYYY hh:mm:A') : 'N/A';
    },

    /**
     * Get the first letter of the value passed.
     *
     * @param  {String} value
     * @return {String}
     */
    firstLetter(value) {
        return value.toString().toUpperCase().charAt(0);
    },

    /**
     * Capitalize a string
     *
     * @param {String} value
     * @return {String}
     */
    capitalize(value) {
        var value = value.toString();

        return value.charAt(0).toUpperCase() + value.slice(1);
    },

    /**
     * Converts snake casing to Title Casing
     *
     * @param {String} value
     * @returns {String}
     */
    titleCase(value) {
        value = value.toString();
        return value.split('_').map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ');
    },

    /**
     * Replace the new line character with a break element.
     *
     * @param {String} value
     * @return {String}
     */
    makeNewLinesBreak(value) {
        return value.toString().replace("\n", "<br />");
    },

    /**
     * Returns N/A if string is not set
     *
     * @param {String} value
     * @returns {String}
     */
    parse(value) {
        if (value) {
            return value;
        }

        return 'N/A';
    },

    creator(createdBy) {
        if (createdBy) {
            return `${createdBy.surname} ${createdBy.othernames}`;
        }

        return 'N/A';
    },

    dp2NA(value) {
        if (value) {
            return Number(value).toFixed(2);
        }
        return 'N/A'
    },

    dp2(value) {
        if (value) {
            return Number(value).toFixed(2);
        }
        return 0
    },

    moneyWithSign(value) {
        if (value) {
            value = Number(value).toFixed(2);
        } else {
            value = 0;
        }

        return '¢ ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    moneyFormat(value) {
        if (!value) {
            value = 0;
        }

        value = Number(value).toFixed(2);

        return value.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)$)/g, "$1,");
    },

    numberFormat(x) {
        if (!x) return 0;

        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    trim(value) {
        if (value && value.length > 30) {
            return `${value.toString().substr(0, 30)}...`;
        }

        return value;
    },

    trimLg(value) {
        if (value && value.length > 50) {
            return `${value.toString().substr(0, 50)}...`;
        }

        return value;
    },

    playerLastName(name) {
        if (!name) return '';

        let [, lastname] = name.toString().split(' ');

        return lastname;
    },

    playerFreekick(player) {
        return parseInt((player.accuracy + player.shooting + player.finishing) / 3);
    },

    HTTP_STATUS(value) {
        switch (value) {
            case 200:
                return 'Successful';
                break;
            case 201:
                return 'Created a resource';
                break;
            case 422:
                return 'Failed due to errors in data';
                break;
            case 500:
                return 'Faild, something unexpected occured';
                break;
            case 401:
                return 'Failed, user was not authenticated';
                break;

            default:
                return 'N/A'
                break;
        }
    },

    shortenNumber(num) {
        if (!num) return 0;

        let str, suffix = '', decimalPlaces = 1;

        decimalPlaces = decimalPlaces || 0;
        num = +num;

        let factor = Math.pow(10, decimalPlaces);


        if (num < 1000) {
            str = num;
        } else if (num < 1000000) {
            str = Math.floor(num / (1000 / factor)) / factor;
            suffix = 'K';
        } else if (num < 1000000000) {
            str = Math.floor(num / (1000000 / factor)) / factor;
            suffix = 'M';
        } else if (num < 1000000000000) {
            str = Math.floor(num / (1000000000 / factor)) / factor;
            suffix = 'B';
        } else if (num < 1000000000000000) {
            str = Math.floor(num / (1000000000000 / factor)) / factor;
            suffix = 'T';
        }
        return str + suffix;
    }
}