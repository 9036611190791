<template>
    <div class="form-group mb-4" :class="divClass + ` type-${typeNumber} `">
        <label class="font-xs m-0" style="margin-bottom:0 !important;" v-if="!noLabel" :class="labelClass">
            {{label}}

            <a
                v-if="tooltip"
                href="#"
                :id="reference"
                @click.prevent="showTooltip">
                <span class="mdi mdi-help-circle"></span>
            </a>

            <span :id="`t-${reference}`"></span>

            <small class="font-8 text-primary" v-if="required">(Required)</small>
            <small class="font-8 text-muted" v-if="optional">(Optional)</small>
        </label>

        <input
            :type="type"
            class="form-control font-xs"
            :class="{'padded': !!error, 'text-input': isTextInput, 'disabled': disabled}"
            :disabled="disabled"
            :placeholder="placeholder"
            :name="name"
            ref="date_range"
            @focus="$emit('focus')"
            @keypress.enter="$emit('enter')"
        >

        <div class="icon" v-if="icon">
            <span :class="`${icon} ${error ? 'shake-bell text-danger' : ''}`"></span>
        </div>

        <span class="font-10 error-label" v-if="error">{{ error }}</span>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Number]
        },

        required: {
            type: Boolean,
            default: false
        },

        optional: {
            type: Boolean,
            default: false
        },

        typeNumber: {
            type: [Number, String],
            default: 4
        },

        icon: String,

        isTextInput: {
            type: Boolean,
            default: true
        },

        name: {
            type: String,
            required: true
        },

        type: {
            type: String,
            default: 'text'
        },

        label: {
            type: String,
            default: ''
        },

        error: {
            type: String,
            default: ''
        },

        placeholder: {
            type: String,
            default: ''
        },

        noLabel: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        reference: {
            type: String
        },

        divClass: {
            type: String
        },

        desc: {
            type: String
        },

        labelClass: {
            type: String
        },

        tooltip: {
            type: Boolean,
            default: false
        },

        help: {
            type: Array
        },

        range: {
            type: String,
            default: 'Last 30 Days'
        },

        placement: {
            type: String,
            default: 'left'
        },
    },

    data () {
        return {
            payload: ''
        }
    },

    computed: {
        format() {
            return `YYYY-MM-DD HH:mm:ss`
        },

        ranges() {
            return {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        }
    },

    methods: {
        showTooltip() {
            let el = document.querySelector(`#${this.reference}`);
            let t = document.querySelector(`#t-${this.reference}`);

            new Popper(el, t, {});
        },

        onInput(startDate, endDate) {
            let value = `${startDate.format(this.format)} to ${endDate.format(this.format)}`

            this.$emit('input', value);
        },

        setup() {
            this.$nextTick().then(() => {
                let range;

                if (Object.keys(this.ranges).includes(this.range)) {
                    range = this.range;
                } else {
                    range = 'Last 30 Days';
                }

                let [startDate, endDate] = this.ranges[this.range];

                startDate = startDate.startOf('day');
                endDate = endDate.endOf('day');

                this.onInput(startDate, endDate)

                $(this.$refs.date_range).daterangepicker({
                    // timePicker: true,
                    opens: this.placement,
                    ranges: this.ranges,
                    startDate,
                    endDate,
                    locale: { format: 'Do MMM, YYYY' }
                }, this.onInput);
            })
        }
    },

    mounted() {
        this.setup();
    }
}
</script>

<style>

</style>
